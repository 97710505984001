import { Box } from "@mui/material";
import { Icons } from "../assets";

export default function Truupeheader({ height }: any) {
  return (
    <Box
      sx={{
        height: {
          xs: "6vh",
          md: "8vh",
        },
        width: "100%",
        px: {
          xs: "4vw",
          md: "2vw",
        },
        py: {
          xs: "1.5vh",
          md: "1vh",
        },
      }}
    >
      <img height="100%" src={Icons.truupe} alt="Loading..." />
    </Box>
  );
}
