import {
  Box,
  Divider,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { loginwithgmail, requestOtp, verifyotp } from "../../api/services";

import { authImage, Icons } from "../../assets";
import Truupeheader from "../../layout/Truupeheader";
import { isIndianLocation } from "../../utils/Location";
import OtpModal from "../otpVerification/OtpModal";
import LocationModel from "./LocationModel";
import SignupForm from "./SignupForm";

const Container = styled(Box)`
  height: 100vh;

  @media (max-width: 1200px) {
    height: 90vh; /* For large screens */
  }

  @media (max-width: 992px) {
    height: 85vh; /* For medium screens */
  }

  @media (max-width: 768px) {
    height: 80vh; /* For small screens */
  }

  @media (max-width: 576px) {
    height: 75vh; /* For extra small screens */
  }
`;

const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 92vh;

  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }

  @media (max-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 10% 0% 0% 0%;
  }

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ImageContainer = styled(Box)`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    width: 70%;
     margin-bottom: 20px;
`;

const AuthImage = styled.img`
  width: 100%;
  max-width: 800px;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 60%;
  }
`;

const LoadingBox = styled(Box)`
  height: 8vh;
`;

const Signup = () => {
  const [openModel, setOpenModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [formdata, setformdata] = useState<any>();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    isIndianLocation((result: any) => {
      if (result === "prompt") {
        setOpenModel(true);
      } else if (result) {
        setOpenModel(false);
      } else {
        navigate("/access-denied", { replace: true });
        setOpenModel(false);
      }
    });
  }, []);

  const { mutate: verifyemailotp, isLoading: otpLoader } = useMutation(
    verifyotp,
    {
      onSuccess: () => {
        setOpen(false);
        navigate("/register");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: Loginwithgmail, isLoading } = useMutation(loginwithgmail, {
    onSuccess: (res: any) => {
      Cookies.set("access_token", res?.data?.access_token);
      localStorage.setItem("isTermsAccepted", res?.data?.isTermsAccepted);
      if (res?.data?.isTermsAccepted) {
        navigate("/home", { replace: true });
      } else {
        navigate("/terms", { replace: true });
      }
    },
    onError: () => {
      navigate("/register", { replace: true });
    },
  });

  const { mutate: SendOtpemail } = useMutation(requestOtp, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      toast.success("OTP Sent to your email successfully");
      localStorage.setItem("otpaccessktoken", res?.data?.access_token);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const ResendOTP = () => {
    const Formdata = {
      inputValue: localStorage.getItem("signupEmail"),
      inputType: "EMAIL",
    };
    SendOtpemail(Formdata);
  };

  const handleVerify = (otp: string) => {
    const token = localStorage.getItem("otpaccessktoken");

    verifyemailotp({
      inputType: "EMAIL",
      inputValue: localStorage.getItem("signupEmail"),
      token: token,
      otp: otp,
    });
  };

  if (isLoading) {
    return (
      <LoadingBox>
        <LinearProgress />
        <Typography variant="h1" sx={{ fontWeight: "500" }}>
          One Moment Please......
        </Typography>
      </LoadingBox>
    );
  }

  return (
    <Container>
      {isMobile || isTablet ? null : <Truupeheader />}
      <ContentWrapper>
        <ImageContainer>
          {isMobile ? (
            <AuthImage src={Icons.truupe} alt="Loading..." />
          ) : isTablet ? (
            <AuthImage src={Icons.truupe} alt="Loading..." />
          ) : (
            <AuthImage src={authImage} alt="Loading..." />
          )}
        </ImageContainer>
        <Divider orientation="vertical" flexItem sx={{ color: "#33333333" }} />
        <SignupForm
          setFormData={setformdata}
          setOpen={setOpen}
          LoginWithGmail={Loginwithgmail}
          isMobile={isMobile}
        />
        <OtpModal
          ResendOTP={ResendOTP}
          Text="email address."
          open={open}
          handleVerify={handleVerify}
          mobileNumber={formdata}
          isSignUp
          isRegister={false}
          isLoading={otpLoader}
        />
      </ContentWrapper>
      <LocationModel setOpenModel={setOpenModel} openModel={openModel} />
    </Container>
  );
};

export default Signup;
