import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import { requestOtp } from "../../api/services";
import { Icons } from "../../assets";

import { ContainedButton } from "../../components/buttons/Buttons";
import AuthFormWrapper from "../../components/wrapper/Authformwarpper";

const validationSchema = yup.object().shape({
  Email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name should be at least 2 characters")
    .max(50, "Name should not exceed 50 characters")
    .matches(/^[A-Za-z\s]+$/, "Name should contain only alphabets"),
  mobile: yup
    .string()
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .length(10, "Mobile number should be exactly 10 digits")
    .required("Mobile number is required"),
});

const StyledFormControl = styled(FormControl)`
  width: 27vw;
  margin-top: 15px;

  & .MuiOutlinedInput-input {
    font-size: 14px;
    height: 1vh;
  }
`;

const outLinedInput = (isMobile: any) => ({
  marginTop: "15px",
  width: { xs: "350px", lg: "350px", xl: "27vw" },
  "& .MuiOutlinedInput-input": {
    fontSize: "14px",
    height: "1vh",
  },
});

type SignUpFormProps = {
  setFormData: any;
  setOpen: any;
  isMobile?: any;
};

const SignupForm = ({ setFormData, setOpen, isMobile }: SignUpFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [autofill, setAutofill] = useState(false);
  const navigate = useNavigate();
  const email = localStorage.getItem("signupEmail");

  useEffect(() => {
    if (email) {
      setAutofill(true);
      reset({ Email: email });
    }
  }, [email, reset]);

  const { mutate: requestMobileOtp, isLoading: loading1 } = useMutation(
    requestOtp,
    {
      onSuccess: (res: any) => {
        localStorage.setItem("otpaccessktoken", res?.data?.access_token);
        localStorage.setItem("mobile", res?.data?.mobile);
        toast.success(res?.data?.message);
        toast.success("OTP sent to your mobile number successfully");
        setOpen(true);
      },
      onError: (error: any) => {
        localStorage.clear();
        navigate("/login");
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (data: any) => {
    localStorage.setItem("mobile", data?.mobile);
    const userData = {
      name: data.name,
      emailAddress: data.Email,
      countryCode: "+91",
      mobileNumber: data.mobile,
    };
    const otpData = {
      inputType: "MOBILE",
      inputValue: data?.mobile,
      emailAddress: data?.Email,
    };
    requestMobileOtp(otpData);
    setFormData(userData);
  };

  return (
    <AuthFormWrapper
      isRegister={true}
      title="Create your account"
      // buttonText="Sign up"
      // DividerText="SIGN UP USING YOUR EMAIL ADDRESS"
      // googleButtontext="Sign up with Google"
    >
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack marginTop="2vh" width="100%" gap="2vh" alignItems="center">
            <Controller
              name="Email"
              control={control}
              render={({ field }) => (
                <StyledFormControl fullWidth error={!!errors.Email}>
                  <InputLabel
                    size="normal"
                    shrink
                    sx={{ marginLeft: "-0.7vw" }}
                  >
                    Email Address
                  </InputLabel>
                  <OutlinedInput
                    sx={outLinedInput(isMobile)}
                    endAdornment={
                      <InputAdornment position="end">
                        <img height="25px" src={Icons.email} alt="Email Icon" />
                      </InputAdornment>
                    }
                    disabled={autofill}
                    placeholder="Enter your Email ID.."
                    {...field}
                  />
                  <FormHelperText>
                    {errors.Email && errors.Email.message}
                  </FormHelperText>
                </StyledFormControl>
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledFormControl fullWidth error={!!errors.name}>
                  <InputLabel
                    size="normal"
                    shrink
                    sx={{ marginLeft: "-0.7vw" }}
                  >
                    Full Name
                  </InputLabel>
                  <OutlinedInput
                    sx={outLinedInput}
                    placeholder="Enter your full name"
                    {...field}
                  />
                  <FormHelperText>
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </StyledFormControl>
              )}
            />
            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <StyledFormControl fullWidth error={!!errors.mobile}>
                  <InputLabel shrink sx={{ marginLeft: "-0.7vw" }}>
                    Mobile Number
                  </InputLabel>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              borderRight: "2px solid grey",
                              paddingRight: "5px",
                            }}
                          >
                            <Typography variant="subtitle2">+91</Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter your mobile"
                    {...field}
                  />
                  <FormHelperText sx={{ marginTop: "20px" }}>
                    {errors.mobile && errors.mobile.message}
                  </FormHelperText>
                </StyledFormControl>
              )}
            />
            <ContainedButton
              type="submit"
              Title="Register"
              submitLoader={loading1}
            />
          </Stack>
        </form>
        <Box
          sx={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "500" }}
            marginRight="10px"
          >
            Have an account?
          </Typography>
          <Link to="/login">
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "500" }}
              color="primary"
            >
              Log in
            </Typography>
          </Link>
        </Box>
      </>
    </AuthFormWrapper>
  );
};

export default SignupForm;
