import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

export default function SignatoryDirectors({
  directorsData,
  setSignDirector,
  profile,
}: any) {
  useEffect(() => {
    setSignDirector(directorsData[0]?.id);
  }, [directorsData[0]]);

  const verifiedDirectorsData = directorsData?.filter(
    (director: { status: string }) => director.status === "Verified"
  );

  useEffect(() => {
    if (verifiedDirectorsData.length > 0) {
      setSignDirector(verifiedDirectorsData[0]?.id); // Set first verified bank detail as default
    }
  }, []);

  const handleChange = (event: any) => {
    setSignDirector(event.target.value);
  };

  return (
    <Box my={"10px"}>
      <Typography variant="subtitle2" mb={"10px"}>
        Signature Authority
      </Typography>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handleChange}
            defaultValue={
              verifiedDirectorsData[0] === undefined
                ? ""
                : verifiedDirectorsData[0]?.id
            }
          >
            {verifiedDirectorsData?.map((director: any) => (
              <Stack direction={"row"}>
                <FormControlLabel
                  key={director.id}
                  value={director.id}
                  control={<Radio />}
                  label=""
                  sx={{ width: "25px" }}
                />
                <Box
                  my={"1vh"}
                  display={"flex"}
                  gap={"2vw"}
                  // flexWrap={"wrap"}
                  // justifyContent={"space-between"}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "750px",
                      lg: "750px",
                      xl: "750px",
                    },
                    flexWrap: {
                      xs: "wrap",
                      sm: "wrap",
                      md: "nowrap",
                      lg: "nowrap",
                      xl: "nowrap",
                    },
                  }}
                >
                  <Stack sx={{ width: { xs: "100%", sm: "35%" } }}>
                    <Typography variant="caption">Name</Typography>
                    <Typography variant={"subtitle2"} marginTop={"0.5vh"}>
                      <>{director?.name}</>
                    </Typography>
                  </Stack>
                  <Stack sx={{ width: { xs: "100%", sm: "15%" } }}>
                    <Typography variant="caption">Designation</Typography>
                    <Typography variant="subtitle2" marginTop={"0.5vh"}>
                      {profile?.entities[0]?.companyType === "INDIVIDUAL" ? (
                        <>NA</>
                      ) : (
                        <>{director?.designation}</>
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: { xs: "none", md: "none", lg: "flex" },
                      width: "30%",
                    }}
                  >
                    <Typography variant="caption">Email</Typography>
                    <Typography variant="subtitle2" marginTop={"0.5vh"}>
                      {" "}
                      {profile?.entities[0]?.companyType === "INDIVIDUAL" ? (
                        <>{profile?.emailAddress}</>
                      ) : (
                        <>{director?.emailAddress}</>
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: { xs: "none", md: "none", lg: "flex" },
                      width: "20%",
                    }}
                  >
                    <Typography variant="caption">Mobile Number</Typography>
                    <Typography variant="subtitle2" marginTop={"0.5vh"}>
                      {profile?.entities[0]?.companyType === "INDIVIDUAL" ? (
                        <>{profile?.mobileNumber}</>
                      ) : (
                        <>{director?.mobileNumber}</>
                      )}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
