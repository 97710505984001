import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { rejectDeal } from "../../../../../api/services/deals";
import { CustomLoadingButton } from "../../../../../components/buttons/SubmitLoaderButton";
import Quill from "../../../../../components/Quill/Quill";

interface RejectionModalProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  secondUser?: any;
  deal?: any;
}

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  // transform: "translate(-50%, -50%)",
  // width: "32vw",
  height: 400,
  backgroundColor: "#FFFFFF",
  border: "1px solid #1212121A",
  borderRadius: "15px",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.up("xs")]: {
    width: "90%",
    top: "25%",
    left: "5%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    top: "25%",
    left: "10%",
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
    top: "25%",
    left: "25%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "40%",
    top: "25%",
    left: "30%",
  },
  [theme.breakpoints.up("xl")]: {
    width: "40%",
    top: "25%",
    left: "30%",
  },
}));

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "-40px",
  right: "0px",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
  borderRadius: "50%",
  height: "25px",
  width: "25px",
});

const SubmitButton = styled(Button)({
  width: 100,
  backgroundColor: "#7643EB",
  boxShadow: "0px 3px 6px #00000029",
});

const RejectionModal: React.FC<RejectionModalProps> = ({
  open,
  onClose,
  data,
  deal,
}) => {
  const [text, setText] = useState("");
  const queryClient = useQueryClient();

  const { mutate: reject, isLoading } = useMutation(rejectDeal, {
    onSuccess: () => {
      queryClient.invalidateQueries("deal");
      toast.success("Deal rejected successfully");
      onClose();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleReject = () => {
    const dealId = data?.code || deal?.code;
    if (dealId) {
      reject({ data: { comment: text }, id: dealId });
    }
  };

  const handleChange = (value: string) => {
    setText(value);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox>
        <CloseButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </CloseButton>
        <Typography variant="h6">Reason for Rejection</Typography>
        <Box>
          <Quill
            data={text}
            handleChange={handleChange}
            pageWidth="pageWidth"
          />
        </Box>

        <Box my={"1vh"}>
          <CustomLoadingButton
            onClick={handleReject}
            title={`Submit`}
            submitLoader={isLoading}
            width={"120px"}
          ></CustomLoadingButton>
        </Box>
      </StyledBox>
    </Modal>
  );
};

export default RejectionModal;
