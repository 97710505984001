import React from "react";
import { Controller } from "react-hook-form";
import DealInputField from "../../pages/dealProcess/deals/createDeal/DealInputField";

type TextFieldProps = {
  control: any;
  errors: any;
  placeholder: string;
  name: string;
  title?: string;
  isEmail?: boolean;
  sx?: any;
  setSearchFocus?: any;
};

export default function TextField({
  control,
  errors,
  name,
  title,
  placeholder,
  isEmail,
  sx,
  setSearchFocus,
}: TextFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DealInputField
          type={"text"}
          title={title}
          isEmail={isEmail}
          field={{
            ...field,
            onChange: isEmail
              ? (e: any) => {
                  setSearchFocus(true);
                  field.onChange(e.target.value);
                }
              : (e: any) => {
                  const value = e.target.value;
                  if (value.length <= 200) {
                    field.onChange(value);
                  }
                },
          }}
          sx={sx}
          placeholder={placeholder}
          iD={name}
          errors={errors}
        />
      )}
    />
  );
}
