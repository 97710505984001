import { Avatar, Box, LinearProgress, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { googleLogout } from "@react-oauth/google";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../api/services";
import KYCProcessCard from "./Components/KYCProcessCard";

// Styled Components
const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const StyledAvatar = styled(Avatar)({
  width: "8vh",
  height: "8vh",
  marginTop: 4,
  marginBottom: 2,
  marginX: 3,
  marginLeft: 0,
  borderRadius: "5px",
});

const UserDetails = styled(Box)({
  marginTop: 3,
});

const Main = () => {
  const navigate = useNavigate();
  const { data: profileData, isLoading: profileLoading } = useQuery(
    "getUser-id",
    getUserProfile,
    {
      onError: (data: any) => {
        if (data?.response?.data?.statusCode === 404) {
          clearCookiesAndLogout();
          navigate("/login", { replace: true });
        }
      },
    }
  );

  const clearCookiesAndLogout = () => {
    Cookies.remove("newEmail");
    Cookies.remove("access_token");
    Cookies.remove("otptoken");
    Cookies.remove("entityId");
    googleLogout();
  };

  const renderLoading = () => {
    return <LinearProgress />;
  };

  const renderAvatar = () => {
    const name = profileData?.data?.name || "N/A";
    const initials = name
      .split(" ")
      .map((word: any) => word.charAt(0))
      .join("")
      .toUpperCase()
      .substring(0, 2);

    return (
      <StyledAvatar variant="square" alt={name} src="">
        {initials}
      </StyledAvatar>
    );
  };

  const renderUserDetails = () => {
    return (
      <UserDetails>
        <Typography
          variant="subtitle1"
          sx={{ color: "black", fontWeight: "500" }}
        >
          {profileData?.data?.name}
        </Typography>
        <Typography variant="subtitle2" color={"GrayText"}>
          {profileData?.data?.emailAddress}
        </Typography>
      </UserDetails>
    );
  };

  const renderProfileContent = () => {
    return (
      <Container>
        <Stack
          direction={"row"}
          mx={"40px"}
          marginTop={"20px"}
          gap={"20px"}
          alignItems={"center"}
        >
          {renderAvatar()}
          {renderUserDetails()}
        </Stack>
        <KYCProcessCard
          profileData={profileData?.data}
          ProfileLoading={profileLoading}
        />
      </Container>
    );
  };

  return profileLoading ? renderLoading() : renderProfileContent();
};

export default Main;
