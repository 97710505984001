import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import IndividualTab from "./IndividualTab";
import IndividualTabMobile from "./IndividualTabMobile";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            backgroundColor: { xs: "none", md: "#f9f9f9" },
            borderTop: "1px solid #cacaca",
            width: "100vw",
            display: "felx",
            flexDirection: "row",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IndividualRegistration() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginX: "8%",
        }}
      >
        <Box>
          <Typography
            variant="h1"
            sx={{ color: "#7643EB", marginTop: 3, fontWeight: "400" }}
          >
            KYC/ KYB Application
          </Typography>
        </Box>
      </Box>

      <Box>
        <Tabs
          value={0}
          aria-label="basic tabs example"
          sx={{
            "& .Mui-selected": {
              paddingX: 0,
              width: "max-content",
            },
            "& .MuiTabs-indicator": {
              height: "4px",
              borderRadius: "10px",
            },
            "& .MuiTabs-flexContainer": {
              gap: "8%",
            },
            marginX: { xs: 0, md: "8%" },
            paddingLeft: { xs: "5vw", md: "20vw" },
          }}
        >
          <Tab
            label="Individual"
            {...a11yProps(0)}
            sx={{ fontWeight: 500, paddingBottom: "2px", fontSize: "16px" }}
          />
        </Tabs>

        <CustomTabPanel value={0} index={0}>
          {/* <IndividualTab /> */}
          {isMobile ? <IndividualTabMobile /> : <IndividualTab />}
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
