import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  getPanDetails,
  getStatusbyId,
  surepassValidations,
} from "../../../../api/services";
import { Icons } from "../../../../assets";

import Editbutton from "../../../../components/buttons/Editbutton";
import InputField from "../../../../components/formFields/kycFields/InputField";
import { kycDetailsStore } from "../../../../store/kycProcess";
import AadharAlertDialog from "../../components/AadharAlertModel";
import { Widgets } from "@mui/icons-material";

type Info = {
  directorName: string;
  dpin: string;
  aadharNumber: string;
  aadharOTP: string;
  registeredAddress: string;
  gender: string;
  dateOfBirth: string;
  panCard: string;
  Signatory: boolean;
};

interface DirectorFormProps {
  Dname: string;
  isnew: boolean;
  Dpin: string;
  index: number;
  panNumber: string;
  aadharNumber: string;
  address: string;
  dateOfBirth: string;
  gender: string;
  Signatory: boolean;
  directorData: any;
  id: any;
  isAadharMismatch: boolean;
  onSubmit: (formData: any) => void;
}

const schema = yup.object().shape({
  directorName: yup.string().required("Director Name is required"),
  dpin: yup
    .string()
    .typeError("DPIN must be a string")
    .required("DPIN is required"),
  aadharOTP: yup
    .string()
    .typeError("Aadhar OTP must be a number")
    .required("Aadhar OTP is required"),
  dateOfBirth: yup
    .string()
    .typeError("Date of Birth must be a date")
    .required("Date of Birth is required"),
  panCard: yup
    .string()
    .typeError("PAN Number must be a string")
    .required("PAN Number is required"),

  registeredAddress: yup
    .string()
    .typeError("Registered Address must be a string")
    .required("Registered Address is required"),
  aadharNumber: yup
    .string()
    .typeError("Aadhar Number must be a number")
    .required("Aadhar Number is required"),
  gender: yup
    .string()
    .typeError("Gender must be a sstring")
    .required("Gender is required"),

  Signatory: yup.boolean().required("Signatory is req"),
});

const formatOtp = (value: string): string => {
  // Remove all non-numeric characters
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  // Format as "x x x x x x" while the user is typing
  const formattedValue = onlyNumbers.replace(/(\d{1})(?=\d)/g, "$1 ");

  // Ensure no trailing space and limit to 11 characters (6 digits + 5 spaces)
  return formattedValue.slice(0, 11);
};

export default function DirectorForm({
  isnew = false,
  Dname,
  Dpin,
  panNumber,
  aadharNumber,
  dateOfBirth,
  address,
  gender,
  Signatory,
  isAadharMismatch = false,
  index,
  id = null,
  onSubmit,
}: DirectorFormProps) {
  const [showname, setshowname] = useState(false);
  const EntityId = Cookies.get("entityId");
  const [open, setOpen] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");
  const [disable, isdisable] = useState(false);
  const [clientId, setClientId] = useState();
  const [aadharVerified, setAadharVerified] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const [panAlert, setpanAlert] = useState(false);
  const [aadharAlert, setAadharalert] = useState(false);
  const [aadharOtp, setAadharOtp] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { mutate: verifyPan, isLoading: verifyPanloader } = useMutation(
    getPanDetails,
    {
      onSuccess: (res: any) => {
        setPanVerified(true);
        setshowname(true);
        const dateTime = moment(Date());
        const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = savedDateTime;
        });
        setValue("directorName", res?.data?.data?.full_name);
        const data = {
          directorName: getValues("directorName"),
          dpin: getValues("dpin"),
          aadharNumber: getValues("aadharNumber"),
          aadharOTP: getValues("aadharOTP"),
          registeredAddress: getValues("registeredAddress"),
          gender: getValues("gender"),
          dateOfBirth: getValues("dateOfBirth"),
          panCard: getValues("panCard"),
          isAadharVerified: aadharVerified,
          isPanVerified: true,
          isAadharValidate: !alert,
          isnew: isnew,
          id: id,
        };
        if (!data.id) {
          const newformdata = {
            ...data,
            id: `newDirector${index}`,
          };
          onSubmit(newformdata);
        } else {
          onSubmit(data);
        }
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter PAN Number");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: generateOTP, isLoading: getotploader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        const dateTime = moment(Date());
        const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = savedDateTime;
        });
        if (res?.data?.success) {
          setAadharOtp(true);
          toast.success(res?.data?.message);
          setClientId(res?.data?.clientId);
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: verifyOTP, isLoading: verifyotploader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        const address = res?.data?.data?.data?.address;
        const dob = res?.data?.data?.data?.dob;

        const concatenatedAddress = [
          address?.house,
          address?.street,
          address?.subdist,
          address?.dist,
          address?.po,
          address?.vtc,
          address?.loc,
          address?.landmark,
        ]
          .filter(Boolean)
          .join(", ");

        if (res?.data?.isAadharValid) {
          setAadharOtp(false);
          setAadharVerified(true);
          setAadharalert(false);

          const data = {
            directorName: getValues("directorName"),
            dpin: getValues("dpin"),
            aadharNumber: getValues("aadharNumber"),
            aadharOTP: getValues("aadharOTP"),
            panCard: getValues("panCard"),
            registeredAddress: concatenatedAddress,
            dateOfBirth: dob,
            gender: res?.data?.data?.data?.gender,
            isAadharVerified: true,
            isPanVerified: panVerified,
            isSignatoryAuthority: false,
            isAadharMismatch: false,
            isPanMismatch: panAlert,
            isnew: isnew,
            id: id,
          };
          if (!data.id) {
            const newformdata = {
              ...data,
              id: `newDirector${index}`,
            };
            onSubmit(newformdata);
          } else {
            onSubmit(data);
          }
        } else {
          setAadharalert(true);
          setAadharOtp(false);
          setAadharVerified(true);
          const data = {
            directorName: getValues("directorName"),
            dpin: getValues("dpin"),
            aadharNumber: getValues("aadharNumber"),
            aadharOTP: getValues("aadharOTP"),
            panCard: getValues("panCard"),
            gender: res?.data?.data?.data?.gender,
            registeredAddress: concatenatedAddress,
            dateOfBirth: dob,
            isAadharVerified: true,
            isPanVerified: panVerified,
            isSignatoryAuthority: false,
            isAadharMismatch: true,
            isPanMismatch: panAlert,
            isnew: isnew,
            id: id,
          };
          if (!data.id) {
            const newformdata = {
              ...data,
              id: `newDirector${index}`,
            };
            onSubmit(newformdata);
          } else {
            onSubmit(data);
          }
        }
        const dateTime = moment(Date());
        const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = savedDateTime;
        });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { data: statusDetails } = useQuery(
    ["status", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Info>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (Dname.length === 0 && Dpin.length === 0) {
      isdisable(false);
    } else {
      setValue("directorName", Dname);
      setValue("dpin", Dpin);
      setValue("aadharNumber", aadharNumber);
      setValue("panCard", panNumber);
      setValue("registeredAddress", address);
      setValue("dateOfBirth", dateOfBirth);
      setValue("gender", gender);
      setValue("Signatory", Signatory);
      isdisable(true);
    }
  }, [
    Dname,
    Dpin,
    isdisable,
    setValue,
    aadharNumber,
    panNumber,
    address,
    dateOfBirth,
    gender,
    Signatory,
  ]);
  return (
    <>
      <form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: 2,
          }}
        >
          <Divider sx={{ display: { xs: "none", md: "block" } }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "3vw",
                // justifyContent: { xs: "space-between", md: "flex-start" },
                justifyContent: "flex-start",
                WebkitAlignItems: "flex-end",
              }}
            >
              <Box
              // sx={{ width: { xs: "80%", md: "auto" } }}
              >
                <Controller
                  name="panCard"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={
                        false || statusDetails?.data?.company || panVerified
                      }
                      field={{
                        ...field,
                        onChange: (e: any) => {
                          e.target.value = e.target.value.toUpperCase();
                          field.onChange(e);
                        },
                      }}
                      iD={"panCard"}
                      name={"PAN Card"}
                      errors={errors}
                      sx={{
                        width: { xs: "100%", md: "auto" },
                        "& .MuiInputBase-input": {
                          width: { xs: "200px", md: "25vw" },
                        },
                      }}
                    />
                  )}
                />
              </Box>

              {verifyPanloader ? (
                <>
                  <CircularProgress
                    sx={{
                      marginLeft: "25px",
                      marginTop: "2vh",
                    }}
                    size={"2rem"}
                    color="primary"
                  />
                </>
              ) : (
                <>
                  {panVerified || statusDetails?.data?.company ? (
                    <Box display={"flex"} alignItems={"center"}>
                      {panAlert ? (
                        <Box sx={{ marginTop: "3vh" }}>
                          <img
                            height={"25px"}
                            src={Icons.alert}
                            alt="loading...."
                          />{" "}
                        </Box>
                      ) : (
                        <>
                          <Box sx={{ marginTop: "3vh" }}>
                            <img
                              height={"25px"}
                              src={Icons.greenCheck}
                              alt="loading...."
                            />
                          </Box>
                        </>
                      )}
                      {!statusDetails?.data?.company && (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          sx={{ marginTop: "2.5vh" }}
                        >
                          <Editbutton
                            onclick={() => {
                              setshowname(false);
                              setPanVerified(false);
                              setpanAlert(false);
                              setAadharalert(false);
                              setAadharVerified(false);
                              setValue("aadharNumber", "");
                              setValue("aadharOTP", "");
                            }}
                          />
                        </Box>
                      )}{" "}
                    </Box>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        disabled={statusDetails?.data?.company}
                        onClick={() => {
                          if (
                            getValues("panCard") === undefined ||
                            getValues("panCard") === ""
                          ) {
                            toast.error("Please enter Pan Number ");
                          } else {
                            verifyPan({
                              type: "PAN",
                              id_number: getValues("panCard"),
                              entityType: "INDIVIDUAL",
                            });
                          }
                        }}
                        sx={{
                          marginTop: "4vh",
                          padding: 0,
                          maxHeight: "30px",
                          fontSize: "12px",
                        }}
                      >
                        Verify
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          {panAlert ? (
            <Typography
              variant="body2"
              sx={{
                color: "#800000",
                mt: -1.6,
                fontStyle: "italic",
              }}
            >
              The Pan Number for {""}
              {getValues("directorName")} doesn't linked with DPIN.
            </Typography>
          ) : (
            <></>
          )}

          {showname || statusDetails?.data?.company ? (
            <>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 2,
                  rowGap: 0,
                }}
              >
                <Box
                // sx={{ width: { xs: "80%", md: "auto" } }}
                >
                  <Controller
                    name="directorName"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        isdisabled={disable || panVerified}
                        field={field}
                        iD={"directorName"}
                        name={"Individual Name"}
                        errors={errors}
                        sx={{
                          width: { xs: "100%", md: "auto" },
                          "& .MuiInputBase-input": {
                            width: { xs: "200px", md: "25vw" },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", md: "end" },
              gap: { xs: 0, md: 2 },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                gap: "3vw",
                width: { xs: "100%", md: "auto" },
                // justifyContent: { xs: "space-between", md: "flex-start" },
                justifyContent: "flex-start",
                WebkitAlignItems: "flex-end",
              }}
            >
              <Box
              // sx={{ width: { xs: "80%", md: "auto" } }}
              >
                <Controller
                  name="aadharNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={
                        false || statusDetails?.data?.company || aadharVerified
                      }
                      field={{
                        ...field,
                        onChange: (e: any) => {
                          // Remove any non-digit characters
                          const onlyNumbers = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );

                          // Add a space after every 4 digits
                          const formattedValue = onlyNumbers.replace(
                            /(\d{4})(?=\d)/g,
                            "$1 "
                          );

                          field.onChange(formattedValue);
                        },
                      }}
                      iD={"aadharNumber"}
                      name={"Aadhar Number"}
                      errors={errors}
                      sx={{
                        width: { xs: "100%", md: "auto" },
                        "& .MuiInputBase-input": {
                          width: { xs: "200px", md: "25vw" },
                        },
                      }}
                      // inputProps={{
                      //   type: "number", // Set input type to number
                      //   pattern: "[0-9]*" // Specify pattern to accept only numbers
                      // }}
                    />
                  )}
                />
              </Box>

              {getotploader ? (
                <>
                  <CircularProgress
                    sx={{
                      marginLeft: "20px",
                      marginTop: "15px",
                    }}
                    size={"2rem"}
                    color="primary"
                  />
                </>
              ) : (
                <>
                  {aadharVerified || statusDetails?.data?.company ? (
                    <Box
                      sx={{
                        width: { xs: "auto", md: "100%" },
                        marginTop: "12px",
                        display: " flex",
                        // marginLeft: { xs: "0", md: "18px" },
                        alignItems: "center",
                      }}
                    >
                      {aadharAlert || isAadharMismatch ? (
                        <>
                          <Box sx={{ marginTop: "3vh" }}>
                            <img
                              height={"25px"}
                              src={Icons.alert}
                              alt="loading...."
                            />{" "}
                          </Box>
                        </>
                      ) : (
                        <Box marginTop={"3vh"}>
                          <img
                            height={"25px"}
                            src={Icons.greenCheck}
                            alt="loading...."
                          />
                        </Box>
                      )}

                      {!statusDetails?.data?.company && (
                        <Box marginTop={"2vh"}>
                          <Editbutton
                            onclick={() => {
                              setAadharalert(false);
                              setAadharVerified(false);
                              setValue("aadharOTP", "");
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <>
                      <Button
                        disabled={statusDetails?.data?.company}
                        variant="contained"
                        onClick={() => {
                          if (getValues("aadharNumber") === undefined) {
                            toast.error("Please Enter Aadhar Number");
                            setValue("aadharOTP", "");
                          } else if (panVerified) {
                            setValue("aadharOTP", "");
                            setOpen(true);
                          } else toast.error("Please Verify before Aadhar");
                        }}
                        sx={{
                          // marginLeft: "1vw",
                          marginTop: "3vh",
                          padding: 0,
                          fontSize: "12px",
                          maxHeight: "30px",
                          // fontSize: "0.8vw",
                          // width: "5vw",
                          // height: "3.5vh",
                        }}
                      >
                        Get OTP
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>

            {statusDetails?.data?.company ? (
              <></>
            ) : (
              <>
                {aadharOtp && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100%", md: "auto" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: { xs: "100%", md: "auto" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          width: { xs: "100%", md: "auto" },
                          gap: "3vw",
                          // justifyContent: {
                          //   xs: "space-between",
                          //   md: "flex-start",
                          // },
                          justifyContent: "flex-start",
                          WebkitAlignItems: "flex-end",
                        }}
                      >
                        <Box
                        // sx={{ width: { xs: "80%", md: "auto" } }}
                        >
                          <Controller
                            name="aadharOTP"
                            control={control}
                            render={({ field }) => (
                              <InputField
                                isdisabled={
                                  false || statusDetails?.data?.company
                                }
                                field={{
                                  ...field,
                                  value: formattedValue,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const value = e.target.value;
                                    const formatted = formatOtp(value);
                                    setFormattedValue(formatted);
                                    field.onChange(
                                      value.replace(/[^0-9]/g, "")
                                    ); // Update field value without spaces
                                  },
                                }}
                                iD={"aadharOTP"}
                                name={"OTP"}
                                errors={errors}
                                sx={{
                                  marginBottom: 0,
                                  width: { xs: "100%", md: "auto" },
                                  "& .MuiInputBase-input": {
                                    width: { xs: "200px", md: "150px" },
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>

                        {verifyotploader ? (
                          <>
                            <CircularProgress
                              sx={{
                                marginLeft: "25px",
                                marginTop: "2vh",
                              }}
                              size={"2rem"}
                              color="primary"
                            />
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => {
                              verifyOTP({
                                type: "VERIFY_AADHAR_OTP",
                                clientId: clientId,
                                value: getValues("aadharOTP"),
                                pan: getValues("panCard"),
                                entityType: "INDIVIDUAL",
                                isNew: true,
                              });
                            }}
                            sx={{
                              marginLeft: { xs: "0", md: "1vw" },
                              marginTop: "3.7vh",
                              padding: 0,
                              maxHeight: "30px",
                              // fontSize: "0.8vw",
                              // width: "6vw",
                              // height: "4vh",
                            }}
                          >
                            Verify
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>

          {isAadharMismatch || aadharAlert ? (
            <Typography
              variant="body2"
              sx={{
                py: "2vh",
                color: "#800000",
                mt: -1.6,
                fontStyle: "italic",
              }}
            >
              The AADHAR Number for {""}
              {getValues("directorName")} doesn't match with PAN Card
              Number.Please verify.
            </Typography>
          ) : (
            <></>
          )}

          {/* {false && (
            <Box sx={{ display: "flex", marginY: 1, gap: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginY: 2,
                }}
              >
                <Controller
                  name="registeredAddress"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={false || statusDetails?.data?.company}
                      field={field}
                      iD={"registeredAddress"}
                      name={"Registered Address"}
                      errors={errors}
                    />
                  )}
                />
                <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        isdisabled={false || statusDetails?.data?.company}
                        field={field}
                        iD={"gender"}
                        name={"Gender"}
                        errors={errors}
                        sx={{ width: `calc(25vw/2)` }}
                      />
                    )}
                  />
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        isdisabled={false || statusDetails?.data?.company}
                        field={field}
                        iD={"dateOfBirth"}
                        name={"Date Of Birth"}
                        errors={errors}
                        sx={{ width: `calc(25vw/2)` }}
                        type="date"
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          )} */}
        </Box>
      </form>

      <AadharAlertDialog
        username={getValues().directorName}
        openDialogue={open}
        closeModel={setOpen}
        aadharNumber={getValues("aadharNumber")}
        sendOtp={() => {
          generateOTP({
            type: "GENERATE_AADHAR_OTP",
            value: getValues("aadharNumber").replace(/\s/g, ""),
            entityType: "INDIVIDUAL",
            entityId: EntityId,
          });
        }}
      />
    </>
  );
}
