import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { requestOtp } from "../../api/services";
import { Icons } from "../../assets";

import { ContainedButton } from "../../components/buttons/Buttons";
import AuthFormWrapper from "../../components/wrapper/Authformwarpper";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

type SignupFormProps = {
  setOpen: (value: boolean) => void;
  setFormData: (value: any) => void;
  LoginWithGmail: (data: any) => void;
  isMobile: boolean;
};

export default function SignupForm({
  setOpen,
  setFormData,
  LoginWithGmail,
  isMobile,
}: SignupFormProps) {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutate: sendOtpEmail, isLoading: load1 } = useMutation(requestOtp, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      toast.success("OTP Sent to your email successfully");
      setOpen(true);
      localStorage.setItem("otpaccessktoken", res?.data?.access_token);
      localStorage.setItem("newEmail", res?.data?.emailAddress);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const onSubmit = (data: any) => {
    const formData = { inputValue: data.email, inputType: "EMAIL" };
    sendOtpEmail(formData);
    localStorage.setItem("signupEmail", getValues("email"));
  };

  return (
    <AuthFormWrapper
      title="Secure your Deals with Truupe"
      // subtitle="Dive into a world of organized dealings, fortified security, and rapid transactions, all under one roof."
      // buttonText="Sign up"
      // DividerText1="Sign Up Using Your Google Account"
      // DividerText2="Sign Up Using Your Email Address"
      // googleButtonText="Sign up with Google"
      LoginWithGmail={LoginWithGmail}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={"2vh"}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel
                  size="normal"
                  shrink
                  sx={{ marginLeft: isMobile ? "-3vw" : "-0.7vw" }}
                >
                  Email Address
                </InputLabel>
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <img height={"25px"} src={Icons.email} alt="" />
                    </InputAdornment>
                  }
                  sx={{
                    marginTop: "15px",
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      fontSize: "14px",
                      height: "1vh",
                    },
                  }}
                  placeholder="Enter your Email ID.."
                  {...field}
                  error={!!errors.email}
                />
                <FormHelperText error>
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <ContainedButton
            type="submit"
            Title=" Sign up"
            submitLoader={load1}
          ></ContainedButton>
        </Stack>
      </form>
    </AuthFormWrapper>
  );
}
