import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getStatusbyId, getUserProfile } from "../../../api/services";
import Cookies from "js-cookie";
import { kycDetailsStore } from "../../../store/kycProcess";
import { formatDate } from "../../../utils/Kycprocessutils";
import IndividualInformation from "./IndividualInformation";
import KYCDocuments from "./KYCDocuments";
import EntityBankingInformation from "../components/EntityBankingInformation";

const steps = [
  { label: "Individual Information", key: "company" },
  { label: "Banking Information", key: "banking" },
  { label: "KYC Documents", key: "directorDocuments" },
];

export default function IndividualTabMobile() {
  const storeLastupdatedTime = kycDetailsStore.useState((s) => s.lastUpdatedAt);
  const [allStatus, setStatus] = useState<any>();
  const [activeStep, setActiveStep] = React.useState(0);
  const EntityId = Cookies.get("entityId");
  const { data }: any = useQuery("getUser-id", getUserProfile);
  const { data: statusDetails, isLoading: load } = useQuery(
    ["status-data", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    setStatus(statusDetails?.data);
  }, [statusDetails]);

  const inputTime = data?.data?.entities?.[0]?.lastUpdatedAt;
  const formattedTime = formatDate(inputTime);

  function countTrues(status: any) {
    if (!status || typeof status !== "object") return 0;
    return steps.filter((step) => status[step.key] === true).length;
  }

  useEffect(() => {
    if (countTrues(statusDetails?.data) === steps.length) {
      setActiveStep(steps.length - 1);
    } else {
      setActiveStep(countTrues(statusDetails?.data));
    }
  }, [statusDetails?.data]);

  const handleStep = (step: number) => {
    if (step <= countTrues(allStatus)) {
      setActiveStep(step);
    }
  };

  const isStepCompleted = (index: number) => allStatus?.[steps[index].key];

  const stepColor = (index: number) =>
    activeStep === index || isStepCompleted(index) ? "primary" : "inherit";

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        marginX: { xs: "5%", md: "8%" },
        minHeight: "75vh",
      }}
    >
      <Stepper nonLinear activeStep={activeStep} sx={{ mt: 2 }}>
        {steps.map((step, index) => {
          const isStepEnabled = index <= countTrues(allStatus);
          const showLabel = activeStep === index;
          const completed = isStepCompleted(index);
          const active = activeStep === index;
          return (
            <Step
              key={step.label}
              completed={completed}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "primary.main",
                },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "primary.main",
                },
                "& .MuiStepLabel-root .Mui-disabled": {
                  color: "grey.500",
                },
              }}
            >
              <StepButton
                onClick={() => handleStep(index)}
                disabled={!isStepEnabled}
                sx={{
                  color: stepColor(index),
                }}
              >
                {showLabel ? step.label : ""}{" "}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      <Box
        sx={{
          mt: 2,
          height: "75vh",
          overflow: "auto",
          // marginLeft: { xs: "0", sm: "14px" },
        }}
      >
        {activeStep === 0 && <IndividualInformation />}
        {activeStep === 1 && <EntityBankingInformation />}
        {activeStep === 2 && <KYCDocuments />}
      </Box>
    </Box>
  );
}
