import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box, Divider, IconButton, Modal, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { Icons } from "../assets/index";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function HowtoAddfundsmodel({ openModel, setOpenModel }: any) {
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null); // State to manage copied text index
  const EntityId = Cookies.get("entityId");
  const queryClient = useQueryClient();

  const handleClose = () => {
    setOpenModel(false);
  };

  const copyToClipboard = (text: string, index: number) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 3500);
    });
  };

  const accountDetails = [
    {
      label: "Escrow Account Name",
      value: "Janaspandana Software Solutions Pvt Ltd.",
    },
    {
      label: "Escrow Account Number",
      value: "SCTL 0864 2971",
    },
    {
      label: "IFSC Code",
      value: "ORBC0089765",
    },
  ];

  return (
    <Box>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                my: "1vh",
              }}
            >
              <IconButton
                sx={{ bgcolor: "white", size: "small" }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Box>

            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 24,
                width: "40vw",
                p: 4,
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: "8px", fontWeight: 500 }}
                id="payment-details-modal-title"
              >
                How to Add Funds
              </Typography>
              <Divider />
              <Typography
                variant="h6"
                sx={{ marginBottom: "8px", fontWeight: 500 }}
              >
                ESCROW ACCOUNT DETAILS
              </Typography>
              {accountDetails.map((detail, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="caption">{detail.label}</Typography>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                      {detail.value}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                    onClick={() => copyToClipboard(detail.value, index)}
                  >
                    {/* <ContentCopyIcon fontSize="small" sx={{ marginRight: "4px" }} />
                    {copiedIndex === index ? "Copied!" : "Copy"} */}
                    {copiedIndex ? (
                      <DoneAllIcon
                        sx={{ fontSize: "15px", marginRight: "4px" }}
                      />
                    ) : (
                      <ContentCopyIcon
                        sx={{ fontSize: "15px", marginRight: "4px" }}
                      />
                    )}
                  </Typography>
                </Box>
              ))}
              <Divider />
              <Typography
                variant="h6"
                sx={{ marginTop: "16px", fontWeight: 500 }}
              >
                STEPS TO FOLLOW WHILE ADDING FUNDS TO ESCROW ACCOUNT
              </Typography>
              <Box sx={{ marginTop: "16px" }}>
                {[
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. Eaque ipsa quae ab illo inventore veritatis et quasi architecto.",
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. Eaque ipsa quae ab illo inventore veritatis et quasi architecto.",
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. Eaque ipsa quae ab illo inventore veritatis et quasi architecto.",
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. Eaque ipsa quae ab illo inventore veritatis et quasi architecto.",
                ].map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={Icons.right}
                      alt="right icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                    <Typography variant="caption">{step}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      </Modal>
    </Box>
  );
}
