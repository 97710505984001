import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { truupeLogo } from "../../../assets/index";
import AccountMenu from "./menu";

type HeaderProps = {
  toggleDrawer: Function;
  profileData: any;
  deal?: Boolean;
  desktopAlert?: Boolean;
};

function Header({
  toggleDrawer,
  profileData,
  deal,
  desktopAlert,
}: HeaderProps) {
  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: "1px solid #cacaca",
        height: "8vh",
        display: "flex",
        justifyContent: "center",
        py: 2,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          marginX: desktopAlert ? 2 : 5,
          marginRight: "1%",
        }}
      >
        {deal ? (
          <Link to={"/deal-process/deals/all"}>
            <img src={truupeLogo} width={"100px"} alt="loading....." />
          </Link>
        ) : (
          <Link to={"/home"}>
            <Box
              sx={{
                width: {
                  xs: "26vw",
                  sm: "20vw",
                  md: "15vw",
                  lg: "10vw",
                  xl: "10vw",
                },
              }}
            >
              <img src={truupeLogo} width={"100%"} alt="loading....." />
            </Box>
          </Link>
        )}

        <Box
          sx={{
            flexGrow: 0,
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <AccountMenu
            profileData={profileData}
            deal={deal}
            toggleDrawer={toggleDrawer}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
