import Grid from "@mui/material/Grid2";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { dealAmount } from "../../../../../assets";
import DealCard from "./DealCard";

type DealAmountCardProps = {
  data: any;
  profileData: any;
};

const DealAmountCard = ({ data, profileData }: DealAmountCardProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { overview, amount, status } = data;
  const isUserBuyer = data?.dealUsers?.some(
    (user: any) =>
      user?.userId === profileData?.data?.id && user?.userType === "Buyer"
  );

  const formatNumberToIndianSystem = (number: number) =>
    new Intl.NumberFormat("en-IN").format(number);

  const handleRefresh = () => {
    queryClient.invalidateQueries("deal");
  };

  const dealCards = [
    {
      icon: dealAmount,
      label: "Deal Amount",
      value: formatNumberToIndianSystem(amount),
      progressText: "This is Total Deal Amount",
      progress: 100,
      showRefresh: false,
    },
    {
      icon: dealAmount,
      label: "Account Balance",
      value: formatNumberToIndianSystem(overview?.balance || 0),
      progressText: `₹ ${formatNumberToIndianSystem(
        overview?.balance || 0
      )} out of ₹ ${formatNumberToIndianSystem(amount)}`,
      progress: (Number(overview?.balance) / Number(amount)) * 100 || 0,
      showRefresh: ["Funds Awaited", "Funds Received"].includes(status),
      onRefresh: handleRefresh,
    },
    {
      icon: dealAmount,
      label: isUserBuyer ? "Payment Sent" : "Payment Received",
      value: formatNumberToIndianSystem(overview?.debit || 0),
      progressText: `₹ ${formatNumberToIndianSystem(
        overview?.debit || 0
      )} out of ₹ ${formatNumberToIndianSystem(amount)}`,
      progress: (Number(overview?.debit) / Number(amount)) * 100 || 0,
      showRefresh: false,
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      sx={{
        padding: "10px 5px",
      }}
    >
      {dealCards.map(
        (card, index) =>
          (index === 0 ||
            (index === 1 &&
              [
                "Funds Awaited",
                "Funds Received",
                "Payment Verification",
                "Deal Completed",
              ].includes(status)) ||
            (index === 2 &&
              [
                "Funds Received",
                "Payment Verification",
                "Deal Completed",
              ].includes(status))) && (
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <DealCard key={index} {...card} status={status} />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default DealAmountCard;
