import {
  Box,
  Divider,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  loginwithgmail,
  requestOtp,
  userLocation,
  verifyotp,
} from "../../api/services";
import { authImage, Icons } from "../../assets";
import Truupeheader from "../../layout/Truupeheader";
import { isIndianLocation } from "../../utils/Location";
import OtpModal from "../otpVerification/OtpModal";
import LocationModel from "../signup/LocationModel";
import LoginForm from "./LoginForm";

const Container = styled(Box)`
  height: 100vh;

  @media (max-width: 1200px) {
    height: 90vh; /* For large screens */
  }

  @media (max-width: 992px) {
    height: 85vh; /* For medium screens */
  }

  @media (max-width: 768px) {
    height: 80vh; /* For small screens */
  }

  @media (max-width: 576px) {
    height: 75vh; /* For extra small screens */
  }
`;

const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 92vh;
  width: 100vw;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }
  @media (max-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 10% 0% 0% 0%;
  }

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ImageContainer = styled(Box)`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    width: 70%;
    margin-bottom: 20px;
  }
`;

const AuthImage = styled.img`
  width: 100%;
  max-width: 800px;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 60%;
  }
`;

const LoadingBox = styled(Box)`
  height: 8vh;
`;

export default function Login() {
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [formdata, setformdata] = useState<any>();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    isIndianLocation((result: any) => {
      if (result === "prompt") {
        setOpenModel(true);
      } else if (result) {
        setOpenModel(false);
      } else {
        navigate("/access-denied", { replace: true });
        setOpenModel(false);
      }
    });
  }, [isIndianLocation]);

  localStorage.removeItem("newEmail");

  const { mutate: Loginwithgmail, isLoading } = useMutation(loginwithgmail, {
    onSuccess: (res: any) => {
      Cookies.set("access_token", res?.data?.access_token);
      localStorage.setItem("isTermsAccepted", res?.data?.isTermsAccepted);
      if (res?.data?.isTermsAccepted) {
        navigate("/home", { replace: true });
      } else {
        navigate("/terms", { replace: true });
      }
    },
    onError: () => {
      toast.error(
        `User with this email: ${localStorage.getItem(
          "signupEmail"
        )} does not exist. Please Register`
      );
      navigate("/register", { replace: true });
    },
  });

  const { mutate: userlocation } = useMutation(userLocation, {
    onSuccess: () => {
      // toast.success(res?.data?.message);
    },
    onError: () => {
      // toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: verifymobileotp, isLoading: otpLoader } = useMutation(
    verifyotp,
    {
      onSuccess: (res: any) => {
        const ip = Cookies.get("ip");
        const longitude = Cookies.get("longitude");
        const latitude = Cookies.get("latitude");

        userlocation({
          ipAddress: ip,
          latitude: latitude,
          longitude: longitude,
        });

        Cookies.set("access_token", res?.data?.access_token);
        localStorage.setItem("isTermsAccepted", res?.data?.isTermsAccepted);
        if (!res?.data?.isTermsAccepted) {
          navigate("/terms");
        } else if (res?.data?.isEntityVerified) {
          navigate("/deal-process/deals/all");
        } else {
          navigate("/home");
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: requestotp, isLoading: requestOtpLoader } = useMutation(
    requestOtp,
    {
      onSuccess: (res: any) => {
        toast.success("Otp Sent successfully");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const ResendOTP = () => {
    const Formdata = { inputValue: formdata, inputType: "LOGIN" };
    requestotp(Formdata);
  };

  const handleVerify = (otp: string) => {
    const token = localStorage.getItem("otpaccessktoken");
    verifymobileotp({
      inputType: "LOGIN",
      inputValue: formdata,
      token,
      otp,
    });
  };

  if (isLoading) {
    return (
      <LoadingBox>
        <LinearProgress />
        <Typography variant="h1" sx={{ fontWeight: "500" }}>
          One Moment Please......
        </Typography>
      </LoadingBox>
    );
  }

  return (
    <Container>
      {isMobile || isTablet ? null : <Truupeheader />}

      <ContentWrapper>
        <ImageContainer>
          {isMobile ? (
            <AuthImage src={Icons.truupe} alt="Loading..." />
          ) : isTablet ? (
            <AuthImage src={Icons.truupe} alt="Loading..." />
          ) : (
            <AuthImage src={authImage} alt="Loading..." />
          )}
        </ImageContainer>
        <Divider orientation="vertical" flexItem sx={{ color: "#33333333" }} />
        <LoginForm
          LoginWithGmail={Loginwithgmail}
          setFormData={setformdata}
          setOpen={setOpen}
          isMobile={isMobile}
        />
        {/* {open && isMobile ? (
          <OtpModalMobile
            open={open}
            isSignUp={false}
            isRegister={false}
            isLogin
            isLoading={otpLoader || requestOtpLoader}
          />
        ) : ( */}
        <OtpModal
          ResendOTP={ResendOTP}
          Text="mobile number."
          open={open}
          handleVerify={handleVerify}
          mobileNumber={formdata}
          isSignUp={false}
          isRegister={false}
          isLogin
          isLoading={otpLoader || requestOtpLoader}
        />
        {/* )} */}
      </ContentWrapper>
      <LocationModel setOpenModel={setOpenModel} openModel={openModel} />
    </Container>
  );
}
