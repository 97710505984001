import { Controller } from "react-hook-form";
import DealInputField from "../../pages/dealProcess/deals/createDeal/DealInputField";

type DateFieldProps = {
  control: any;
  getMinDate: string;
  getMaxDate: string;
  errors: any;
};


export default function DateField({
  control,
  getMinDate,
  getMaxDate,
  errors,
}: DateFieldProps) {
  return (
    <>
      <Controller
        name="closureDate"
        control={control}
        render={({ field }) => (
          <DealInputField
            type="date"
            title="Expected Deal Closure Date"
            field={{ ...field }}
            placeholder="Enter Deal Duration…"
            iD={"closureDate"}
            errors={errors}
            min={getMinDate}
            max={getMaxDate}
            sx={{
              width: {
                xs: "255px",
                sm: "520px",
                lg: "520px",
                xl: "520px",
              },
              "-webkit-appearance": "none",
              lineHeight: "1.5",
              verticalAlign: "middle", 
              "@supports (-webkit-touch-callout: none)": {
                "& input[type='date']": { // Target only date inputs
                  paddingTop: "5px", // Adjust the value as needed for proper alignment on iOS
                  paddingBottom: "15px",

                },
              },       
            }}
          />
        )}
      />
    </>
  );
}
