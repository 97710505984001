import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../api/services";

// Styled Components
const CardContainer = styled("div")<{ isMobile: boolean; isTablet: boolean }>(
  ({ isMobile, isTablet }) => ({
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    width: isMobile ? "100%" : isTablet ? "70%" : "50%",
    gap: "20px",
    borderRadius: "10px",
    boxShadow: "none",
    padding: isMobile ? "10px" : "20px",
    border: "1px solid #CACACA",
  })
);

const StatusBox = styled("div")<{ isMobile: boolean; isTablet: boolean }>(
  ({ isMobile, isTablet }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 0px",
    backgroundColor: "#7643EB",
    borderRadius: "5px",
    width: isMobile ? "130px" : isTablet ? "150px" : "150px",
  })
);

const BusinessDetailsCard = ({ data, isMobile, isTablet }: any) => {
  const { data: profileData } = useQuery("getUser-id", getUserProfile);
  const navigate = useNavigate();
  const renderDetails = () => {
    const isIndividual = data?.companyType === "INDIVIDUAL";
    const name = isIndividual ? profileData?.data?.name : data?.companyName;
    const status = profileData?.data?.entities[0]?.status?.replace(/_/g, " ");
    const entityId = profileData?.data?.entities[0]?.id;

    return (
      <>
        <Typography fontSize={isMobile ? "1.2em" : "1.8em"}>
          {isIndividual ? "Individual Details" : "Entity Details"}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Typography
              variant="caption"
              // fontSize={isMobile ? "10px" : "12px"}
            >
              {isIndividual ? "Individual Name" : "Entity Name"}
            </Typography>
            <Typography
              variant="caption"
              // fontSize={isMobile ? "10px" : "12px"}
            >
              {name?.toUpperCase()}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography
              variant="caption"
              // fontSize={isMobile ? "10px" : "12px"}
            >
              Status
            </Typography>
            <StatusBox isMobile={isMobile} isTablet={isTablet}>
              <Typography
                variant="caption"
                sx={{
                  // fontSize: isMobile ? "10px" : isIndividual ? "12px" : "14px",
                  color: "white",
                }}
              >
                {status?.toUpperCase()}
              </Typography>
            </StatusBox>
          </Stack>
        </Stack>

        {status === "Rejected" && (
          <Button
            variant="outlined"
            onClick={() => {
              navigate(`/kycprocess/resubmit-documents`, { replace: true });
            }}
          >
            Re-Submit Rejected Documents
          </Button>
        )}
      </>
    );
  };

  const renderReason = () => {
    const reason = profileData?.data?.entities[0]?.reason;
    return (
      <Stack>
        <Typography fontSize={isMobile ? "1.2em" : "1.8em"}>
          Reason for Rejection
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: reason }}
        />
      </Stack>
    );
  };

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      gap={"20px"}
      width={isMobile ? "100%" : "60vw"}
    >
      <CardContainer isMobile={isMobile} isTablet={isTablet}>
        {renderDetails()}
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Stack
            sx={{
              marginLeft: data?.companyType === "INDIVIDUAL" ? "80%" : "50%",
            }}
          />
        </Box>
      </CardContainer>
      {profileData?.data?.entities[0]?.status === "Rejected" && (
        <CardContainer isMobile={isMobile} isTablet={isTablet}>
          {renderReason()}
        </CardContainer>
      )}
    </Stack>
  );
};

export default BusinessDetailsCard;
