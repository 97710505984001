import { Box, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { dealtransaction } from "../../../../../api/services/deals";

import DealNextSteps from "../dealNextSteps/DealNextSteps";
import DealTimeline from "../dealTimeline/DealTimeline";
import EscrowAccountDetails from "../truupeAccount/overview/EscrowDetails-InfoCard";
import TransactionTableSortable from "../truupeAccount/transactions/ledger/TransactionTableSortable";
import DealAmountCard from "./DealAmountNew";

type DealDetailsProps = {
  data: any;
  accountOwner: any;
  profileData: any;
};
export default function DealDetails({
  data,
  accountOwner,
  profileData,
}: DealDetailsProps) {
  const { dealId } = useParams();
  const { data: transactionData }: any = useQuery(
    ["dealTransactions", dealId],
    dealtransaction
  );

  return (
    <Box>
      <DealNextSteps data={data} accountOwner={accountOwner} />
      <Divider />
      {(data?.status === "Funds Awaited" ||
        data?.status === "Payment Verification" ||
        data?.status === "Deal Completed" ||
        data?.status === "Funds Received") && (
        <>
          <EscrowAccountDetails data={data} profileData={profileData} />
          <Divider />
        </>
      )}
      <DealAmountCard data={data} profileData={profileData} />
      <Divider />
      {(data?.status === "Funds Awaited" ||
        data?.status === "Payment Verification" ||
        data?.status === "Deal Completed" ||
        data?.status === "Funds Received") && (
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              lg: "block",
              xl: "block",
            },
            // flexDirection:"column"
          }}
        >
          {transactionData?.data?.count > 0 && (
            <>
              {" "}
              <Typography
                variant="h2"
                sx={{ fontWeight: "500", px: "20px", paddingTop: "1vh" }}
              >
                Transactions
              </Typography>
              <Box sx={{ paddingTop: "1vh" }}>
                <TransactionTableSortable
                  dealData={data}
                  transactionData={transactionData}
                />
              </Box>
            </>
          )}
          <Divider />
        </Box>
      )}

      <DealTimeline data={data} />
    </Box>
  );
}
