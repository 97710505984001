import { Box, Stack, Typography } from "@mui/material";

export default function FundsAwaited({ data, secondUser, pay }: any) {
  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Credit funds into the account
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Please credit funds into the Account below.
        </Typography>
      </Stack>
    </Box>
  );
}
