import { Button } from "@mui/material";
import { styled } from "@mui/system";

interface EditButtonProps {
  onclick: (value: boolean) => void;
}

const StyledButton = styled(Button)({
  height: "25px",
  fontSize: "12px",
  marginLeft: "10px",
  width : "2vw",
});

export default function EditButton({ onclick }: EditButtonProps) {
  return (
    <StyledButton
      onClick={() => {
        onclick(false);
      }}
      variant="outlined"
    >
      Edit
    </StyledButton>
  );
}
