export const formatDate = (inputDate: any) => {
  const dateObj = new Date(inputDate);
  const year = dateObj.getFullYear();
  const month = dateObj
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes}${ampm}`;

  return formattedDate;
};

// utils.js
export const getFirmDocumentData = (
  firmDocuments: any[],
  subtype: string,
  field: string
) =>
  firmDocuments
    ?.filter((doc: any) => doc?.subtype === subtype)
    .map((doc: any) => doc[field])[0];
