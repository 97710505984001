import { Box, Stack, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { postNotify } from "../../../../../../api/services/deals";
import { CustomLoadingButton } from "../../../../../../components/buttons/SubmitLoaderButton";

export default function InvitationSent({ data, secondUser }: any) {
  const { mutate: notify, isLoading: userLoader } = useMutation(postNotify, {
    onSuccess: (res: any) => {
      toast.success("Notification sent successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleNotifyUser = () => {
    notify({ dealCode: data?.code, type: "Invitation" });
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Confirmation Awaiting
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Deal invitation has been succesfully sent to {secondUser?.userType} .
          Awaiting their acceptance.
        </Typography>
      </Stack>
      <Box my={"1vh"}>
        <CustomLoadingButton
          onClick={handleNotifyUser}
          title={` Notify ${secondUser?.userType}`}
          submitLoader={userLoader}
          width={"120px"}
        ></CustomLoadingButton>
      </Box>
    </Box>
  );
}
