import {
  Box,
  Button,
  Card,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getDealsDataById } from "../../../../../api/services/deals";
import { Icons } from "../../../../../assets";

const StyledBox = styled(Box)({
  height: "83vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

const StyledCard = styled(Card)({
  width: "50vw",
  // padding: "30px 25px",
  marginTop: "20px",
});

const CenteredStack = styled(Stack)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledImg = styled("img")({
  width: "80px",
  height: "80px",
  opacity: 1,
});

const DealDetailsTypography = styled(Typography)({
  margin: "10px 0",
});

const DetailStack = styled(Stack)({
  fontSize: "14px",
  color: "grey",
});

// const DetailTypography = styled(Typography)({
//   fontSize: "16px",
//   color: "grey",
// });

const CenteredButtonBox = styled(Box)({
  marginTop: "4vh",
  display: "flex",
  justifyContent: "center",
});

const getSecondUser = (data: any) =>
  data?.dealUsers?.find((user: any) => user?.userId !== data?.createdBy?.id);

const renderLoadingState = () => <LinearProgress />;

const dealDetails = [
  { label: "Deal ID", value: (data: any) => data?.code, color: "#7643EB" },
  { label: "Deal Title", value: (data: any) => data?.title },
  { label: "Type of deal", value: (data: any) => data?.type },
];

const dealAdditionalDetails = [
  {
    label: "Duration",
    value: (data: any) => `${data?.duration} ${data?.durationType}s`,
  },
  {
    label: "Role",
    value: (data: any, secondUser: any) =>
      secondUser?.userType === "Buyer" ? "Seller" : "Buyer",
  },
  { label: "Deal Amount", value: (data: any) => `₹ ${data?.amount}` },
];

const dealPartyInfo = [
  { label: "Deal Role", value: (secondUser: any) => secondUser?.userType },
  {
    label: (secondUser: any) => `${secondUser?.userType} Email Address`,
    value: (secondUser: any) =>
      secondUser?.user?.emailAddress ||
      localStorage.getItem("dealEmailAddress"),
  },
];

const renderDealDetails = (
  data: any,
  secondUser: any,
  navigate: any,
  modelType: any
) => (
  <StyledCard
    sx={{
      width: { xs: "95vw", sm: "70vw", lg: "50vw", xl: "50vw" },
      px: { xs: "20px", sm: "20px", lg: "20px", xl: "20px" },
      py: "20px",
      height: { xs: "auto", sm: "auto", lg: "auto", xl: "auto" },
    }}
  >
    <CenteredStack>
      <StyledImg src={Icons.checked} alt="Check Icon" />
      <Typography variant="h1" sx={{ my: "1vh" }} textAlign={"center"}>
        {modelType === "deal-accepted"
          ? "Deal Accepted Successfully"
          : "Deal Successfully Created!"}
      </Typography>
      <Typography
        variant="body1"
        textAlign={"center"}
        sx={{
          mx: "20px",
          textAlign: "center",
          fontWeight: 300,
          color: "#333333",
          opacity: 0.85,
          my: "20px",
        }}
      >
        {modelType === "deal-accepted"
          ? "Thank you for accepting the deal! Both parties have now agreed to the terms."
          : " Thank you for creating the deal! We're currently waiting for the other party. You'll be notified once they accept the invitation. Please check back later to proceed with the transaction"}
      </Typography>
    </CenteredStack>
    <Divider />
    <DealDetailsTypography>Deal Details</DealDetailsTypography>

    <Grid container my={"1vh"} spacing={3}>
      {dealDetails.map((detail, index) => (
        <Grid size={4} key={index}>
          <DetailStack>
            <Typography variant="body1">{detail.label}</Typography>
            <Typography sx={{ color: detail.color || "inherit" }}>
              {detail.value(data)}
            </Typography>
          </DetailStack>
        </Grid>
      ))}
    </Grid>
    <Grid container my={"20px"} spacing={3}>
      {dealAdditionalDetails.map((detail, index) => (
        <Grid size={4} key={index}>
          <DetailStack>
            <Typography variant="body1">{detail.label}</Typography>
            <Typography color={"gray"}>
              {detail.value(data, secondUser)}
            </Typography>
          </DetailStack>
        </Grid>
      ))}
    </Grid>
    <Divider />
    <DealDetailsTypography>Deal Party Information</DealDetailsTypography>
    <Grid container spacing={3}>
      {dealPartyInfo.map((detail, index) => (
        <Grid size={4} key={index}>
          <DetailStack>
            <Typography>
              {typeof detail.label === "function"
                ? detail.label(secondUser)
                : detail.label}
            </Typography>
            <Typography color={"gray"}>{detail.value(secondUser)}</Typography>
          </DetailStack>
        </Grid>
      ))}
    </Grid>
    <CenteredButtonBox>
      <Button
        onClick={() =>
          navigate(`/deal-process/deals/${data?.code}`, { replace: true })
        }
        variant="contained"
      >
        Continue
      </Button>
    </CenteredButtonBox>
  </StyledCard>
);

function DealSuccess() {
  const { modelType, dealId } = useParams();
  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById
  );
  const data = dealData?.data;
  const navigate = useNavigate();
  const secondUser = getSecondUser(data);

  if (isLoading) {
    return renderLoadingState();
  }

  return (
    <StyledBox>
      {renderDealDetails(data, secondUser, navigate, modelType)}
    </StyledBox>
  );
}

export default DealSuccess;
