import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  requestOtp,
  Signup,
  userLocation,
  verifyotp,
} from "../../api/services";
import { authImage, Icons } from "../../assets";
import Truupeheader from "../../layout/Truupeheader";
import { isIndianLocation } from "../../utils/Location";
import OtpModal from "../otpVerification/OtpModal";
import RegistrationForm from "./RegistrationForm";

const Container = styled(Box)`
  height: 100vh;

  @media (max-width: 1200px) {
    height: 90vh; /* For large screens */
  }

  @media (max-width: 992px) {
    height: 85vh; /* For medium screens */
  }

  @media (max-width: 768px) {
    height: 80vh; /* For small screens */
  }

  @media (max-width: 576px) {
    height: 75vh; /* For extra small screens */
  }
`;

const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 92vh;

  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }

  @media (max-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10% 0% 0% 0%;
  }

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ImageContainer = styled(Box)`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    width: 70%;
    margin-bottom: 20px;
  }
`;

const AuthImage = styled.img`
  width: 100%;
  max-width: 800px;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 60%;
  }
`;

export default function UserRegistration() {
  const [openModel, setOpenModel] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formdata, setformdata] = useState<any>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    isIndianLocation((result: any) => {
      if (result === "prompt") {
        setOpenModel(true);
      } else if (result) {
        setOpenModel(false);
      } else {
        navigate("/access-denied", { replace: true });
        setOpenModel(false);
      }
    });
  }, []);

  const handleLocationSuccess = (res: any) => {
    // toast.success(res?.data?.message);
  };

  const handleLocationError = (error: any) => {
    // toast.error(error?.response?.data?.message);
  };

  const { mutate: userlocation } = useMutation(userLocation, {
    onSuccess: handleLocationSuccess,
    onError: handleLocationError,
  });

  const handleSignupSuccess = (res: any) => {
    // toast.success(res?.data?.message);
    Cookies.set("access_token", res?.data?.access_token);

    const ip = Cookies.get("ip");
    const longitude = Cookies.get("longitude");
    const latitude = Cookies.get("latitude");
    userlocation({
      ipAddress: ip,
      latitude: latitude,
      longitude: longitude,
    });

    if (res?.data?.isTermsAccepted) {
      navigate("/home");
    } else {
      navigate("/terms");
    }
  };

  const handleSignupError = (error: any) => {
    toast.error(error?.response?.data?.message);
    toast.error("User creation failed");
  };

  const { mutate: userSignup } = useMutation(Signup, {
    onSuccess: handleSignupSuccess,
    onError: handleSignupError,
  });

  const handleVerifySuccess = (res: any) => {
    const data = {
      name: formdata.name,
      email: formdata.emailAddress,
      countryCode: "+91",
      mobile: formdata.mobileNumber,
    };
    userSignup(data);
  };

  const handleVerifyError = (error: any) => {
    toast.error(error?.response?.data?.message);
  };

  const { mutate: verifymobileotp } = useMutation(verifyotp, {
    onSuccess: handleVerifySuccess,
    onError: handleVerifyError,
  });

  const handleRequestOtpSuccess = (res: any) => {
    localStorage.setItem("otpaccessktoken", res?.data?.access_token);
    toast.success(res?.data?.message);
    toast.success("OTP sent to your mobile number successfully");
    setOpen(true);
  };

  const handleRequestOtpError = (error: any) => {
    navigate("/login");
    toast.error(error?.response?.data?.message);
  };

  const { mutate: requestmobileotp } = useMutation(requestOtp, {
    onSuccess: handleRequestOtpSuccess,
    onError: handleRequestOtpError,
  });

  const ResendOTP = () => {
    const otpdata = {
      inputType: "MOBILE",
      inputValue: formdata.mobileNumber,
      emailAddress: formdata.emailAddress,
    };
    requestmobileotp(otpdata);
  };

  const handleVerify = (otp: string) => {
    const token = localStorage.getItem("otpaccessktoken");

    verifymobileotp({
      inputType: "MOBILE",
      inputValue: formdata.mobileNumber,
      token: token,
      otp: otp,
    });
  };

  return (
    <Container>
      {isMobile || isTablet ? null : <Truupeheader />}
      <ContentWrapper>
        <ImageContainer>
          {isMobile ? (
            <AuthImage src={Icons.truupe} alt="Loading..." />
          ) : isTablet ? (
            <AuthImage src={Icons.truupe} alt="Loading..." />
          ) : (
            <AuthImage src={authImage} alt="Loading..." />
          )}
        </ImageContainer>
        <Divider orientation="vertical" flexItem sx={{ color: "#33333333" }} />
        <RegistrationForm
          setFormData={setformdata}
          setOpen={setOpen}
          isMobile={isMobile}
        />
        <OtpModal
          ResendOTP={ResendOTP}
          Text={"mobile number."}
          open={open}
          handleVerify={handleVerify}
          mobileNumber={formdata?.mobileNumber}
          isSignUp={false}
          isRegister={true}
        />
      </ContentWrapper>
    </Container>
  );
}
