export const formatIndianIntegers = (num: any) => {
  num = num?.toString();
  const [integerPart, decimalPart] = num?.split(".");

  const lastThree = integerPart.substring(integerPart.length - 3);
  const otherNumbers = integerPart.substring(0, integerPart.length - 3);

  const formattedInteger =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    (otherNumbers ? "," : "") +
    lastThree;

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const stripHTML = (htmlString: string) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
};
