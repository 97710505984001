import { Box, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../../api/services";
import Status from "../userDeal/dealStatus/DealStatus";

// Styled components
const StyledCard = styled(Card)({
  background: "#FFFFFF",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  padding: "20px",
  marginBottom: "20px",
  boxShadow: "0px 6px 14px #36408D08",
  cursor: "pointer",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontFamily: "Mundial, sans-serif",
}));

const DealCodeTypography = styled(StyledTypography)(({ theme }) => ({
  fontSize: "26px",
  lineHeight: "30px",
  color: "#7643EB",
  [theme.breakpoints.down("xl")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const DealTypeTypography = styled(StyledTypography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "28px",
  fontWeight: 300,
  color: "#333333",
  [theme.breakpoints.down("xl")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

const DealAmountTypography = styled(StyledTypography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "21px",
  color: "#121212",
  opacity: 0.7,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
  },
}));

const DealTitleTypography = styled(StyledTypography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "18px",
  marginBottom: "10px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.down("xl")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
    margin: "0",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    margin: "0",
    padding: "1%",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    margin: "0",
    padding: "1%",
  },
}));

const Divider = styled(Box)({
  height: "0px",
  border: "1px solid #1212121A",
  opacity: 1,
  marginTop: "10px",
  marginBottom: "10px",
});

const DealsGrid = ({ paginatedData, isMobile, isTablet, isDesktop }: any) => {
  const navigate = useNavigate();
  const { data: profileData } = useQuery("getUser-id", getUserProfile);

  const handleCardClick = (id: any) => {
    navigate(`/deal-process/deals/${id}`);
  };

  const formatNumberToIndianSystem = (number: any) =>
    new Intl.NumberFormat("en-IN").format(number);

  const renderStatus = (deal: any) => {
    const isUserDocumentSigned =
      deal?.dealSignatures?.find(
        (signature: any) => signature?.userId === profileData?.data?.id
      )?.status === "Completed";

    const profileUser = deal?.dealUsers.find(
      (user: any) => user?.userId === profileData?.data?.id
    );

    const dealCreator = deal?.createdBy?.id === profileData?.data?.id;

    switch (deal?.status) {
      case "Invitation Issued":
        return dealCreator ? (
          <Status variant="Confirmation Awaiting" />
        ) : (
          <Status variant="Deal Invitation Received" />
        );
      case "Signature Pending":
        return isUserDocumentSigned ? (
          <Status variant="Signature Awaiting" />
        ) : (
          <Status variant="Signature Pending" />
        );
      case "Fee Pending":
        return profileUser?.userType === "Buyer" ? (
          <Status variant="Service Payment" />
        ) : (
          <Status variant="Funds Awaited" />
        );
      case "Funds Awaited":
        return profileUser?.userType === "Buyer" ? (
          <Status variant="Awaiting Escrow Funds" />
        ) : (
          <Status variant="Funds Awaited" />
        );
      default:
        return <Status variant={deal?.status} />;
    }
  };

  return (
    <Grid
      container
      justifyContent="left"
      spacing={isMobile ? 0 : 2}
      marginTop={isMobile ? "5px" : "0px"}
    >
      {paginatedData.map((deal: any) => (
        <Grid key={deal.id} size={{ xs: 12, sm: 6, md: 4, lg: 4 }}>
          <StyledCard onClick={() => handleCardClick(deal?.code)}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="20px"
            >
              <Grid>
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <DealCodeTypography>{deal?.code}</DealCodeTypography>
                  {/* <DealTypeTypography>({deal?.type})</DealTypeTypography> */}
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    marginTop: isMobile ? "8px" : "0px",
                  }}
                >
                  {renderStatus(deal)}
                </Box>
              </Grid>
            </Grid>
            <Grid>
              <DealAmountTypography>
                ₹ {formatNumberToIndianSystem(deal.amount)}
              </DealAmountTypography>
              <Divider />
            </Grid>
            <Grid>
              <DealTitleTypography
                dangerouslySetInnerHTML={{ __html: deal?.title }}
              />
            </Grid>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default DealsGrid;
