import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

const stylesBefore = {
  width: "250px",
  height: "100px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "250px",
  height: "100px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

export default function SignatoryDocuments({
  director,
  index,
  statusDetails,
  CompanyDetails,
  setIsFileDeleted,
}: any) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [addressProofType, setAddressProofType] = useState<string | null>(
    director?.documents?.find(
      (doc: any) => doc?.subtype === "ADDRESS_PROOF_FRONT"
    )?.filetype || null
  );

  const FirmAddressProof = ["DRIVING_LICENSE", "PASSPORT", "VOTER_ID"];
  const [disableAddressProof, setDisableAddressProof] = useState(
    !!addressProofType // Set to true if addressProofType is non-null
  );
  // const [disableAddressProof, setDisableAddressProof] = useState(false);
  // const [isFileDeleted, setIsFileDeleted] = useState(false);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setAddressProofType(event.target.value);
  };

  return (
    <Box key={director?.id}>
      <Typography variant="h5" gutterBottom sx={{ mt: 1 }}>
        {director?.name}
      </Typography>
      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "20px" }}>
        Upload Aadhar Card
      </Typography>
      <Stack direction={isMobile ? "column" : "row"} spacing={3}>
        <Box>
          <Typography
            variant="caption"
            gutterBottom
            sx={{ marginTop: "20px", marginLeft: "10px" }}
          >
            Front <span style={{ color: "red" }}>*</span>
          </Typography>
          <FileUploadContainer
            actions={<></>}
            status={statusDetails?.data?.directorDocuments}
            data={"AADHAR_FRONT"}
            typeData="DIRECTOR"
            typeId={director?.id}
            uploaded={
              director?.documents?.filter(
                (i: any) => i?.subtype === "AADHAR_FRONT"
              )?.length
            }
            name={director?.documents
              ?.filter((i: any) => i?.subtype === "AADHAR_FRONT")
              .map((i: any) => i?.originalFileName)}
            url={director?.documents
              ?.filter((i: any) => i?.subtype === "AADHAR_FRONT")
              .map((i: any) => i?.url)}
            onFileChange={(key) => {
              if (key === "") {
                setIsFileDeleted(true);
              } else {
                setIsFileDeleted(false);
                queryClient.invalidateQueries("getcompanydetails");
              }
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Box>
        <Box>
          <Typography
            variant="caption"
            gutterBottom
            sx={{ marginTop: "20px", marginLeft: "10px" }}
          >
            Back
          </Typography>
          <FileUploadContainer
            actions={<></>}
            status={statusDetails?.data?.directorDocuments}
            data={"AADHAR_BACK"}
            typeData="DIRECTOR"
            typeId={director?.id}
            uploaded={
              director?.documents?.filter(
                (i: any) => i?.subtype === "AADHAR_BACK"
              )?.length
            }
            name={director?.documents
              ?.filter((i: any) => i?.subtype === "AADHAR_BACK")
              .map((i: any) => i?.originalFileName)}
            url={director?.documents
              ?.filter((i: any) => i?.subtype === "AADHAR_BACK")
              .map((i: any) => i?.url)}
            onFileChange={(e) => {
              queryClient.invalidateQueries("getcompanydetails");
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Box>
      </Stack>

      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "20px" }}>
        Upload PAN Card
      </Typography>
      <Stack direction={isMobile ? "column" : "row"} spacing={3}>
        <Box>
          <Typography
            variant="caption"
            gutterBottom
            sx={{ marginTop: "20px", marginLeft: "10px" }}
          >
            Front <span style={{ color: "red" }}>*</span>
          </Typography>
          <FileUploadContainer
            actions={<></>}
            typeId={director?.id}
            status={statusDetails?.data?.directorDocuments}
            data={"PAN_FRONT"}
            typeData="DIRECTOR"
            uploaded={
              director?.documents?.filter(
                (i: any) => i?.subtype === "PAN_FRONT"
              )?.length
            }
            name={director?.documents
              ?.filter((i: any) => i?.subtype === "PAN_FRONT")
              .map((i: any) => i?.originalFileName)}
            url={director?.documents
              ?.filter((i: any) => i?.subtype === "PAN_FRONT")
              .map((i: any) => i?.url)}
            onFileChange={(key) => {
              if (key === "") {
                setIsFileDeleted(true);
              } else {
                setIsFileDeleted(false);
                queryClient.invalidateQueries("getcompanydetails");
              }
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Box>
        <Box>
          <Typography
            variant="caption"
            gutterBottom
            sx={{ marginTop: "20px", marginLeft: "10px" }}
          >
            Back
          </Typography>
          <FileUploadContainer
            actions={<></>}
            typeId={director?.id}
            status={statusDetails?.data?.directorDocuments}
            data={"PAN_BACK"}
            typeData="DIRECTOR"
            uploaded={
              director?.documents?.filter((i: any) => i?.subtype === "PAN_BACK")
                ?.length
            }
            name={director?.documents
              ?.filter((i: any) => i?.subtype === "PAN_BACK")
              .map((i: any) => i?.originalFileName)}
            url={director?.documents
              ?.filter((i: any) => i?.subtype === "PAN_BACK")
              .map((i: any) => i?.url)}
            onFileChange={(e) => {
              queryClient.invalidateQueries("getcompanydetails");
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Box>
      </Stack>

      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "20px" }}>
        Address Proof
      </Typography>
      <Box sx={{ width: "350px" }}>
        <FormControl fullWidth>
          <Select
            labelId="address-proof-select-label"
            value={addressProofType || ""}
            onChange={handleSelectChange}
            size="small"
            sx={{
              width: { xs: "250px", md: "350px" },
              height: "50px",
              "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
                {
                  fontFamily: "mundial",
                  color: "#2B2D22",
                  fontSize: "16px",
                },
            }}
            disabled={disableAddressProof}
          >
            {FirmAddressProof?.map((proof: string) => (
              <MenuItem key={proof} value={proof}>
                {proof}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {addressProofType && (
        <Box>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ marginTop: "20px" }}
          >
            Address Proof Document
          </Typography>
          <Stack direction={isMobile ? "column" : "row"} spacing={3}>
            <Box>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ marginTop: "20px", marginLeft: "10px" }}
              >
                Front <span style={{ color: "red" }}>*</span>
              </Typography>
              <FileUploadContainer
                actions={<></>}
                typeId={director?.id}
                status={statusDetails?.data?.directorDocuments}
                filetype={addressProofType}
                data={"ADDRESS_PROOF_FRONT"}
                typeData="DIRECTOR"
                uploaded={
                  director?.documents?.filter(
                    (i: any) => i?.subtype === "ADDRESS_PROOF_FRONT"
                  )?.length
                }
                name={director?.documents
                  ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_FRONT")
                  .map((i: any) => i?.originalFileName)}
                url={director?.documents
                  ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_FRONT")
                  .map((i: any) => i?.url)}
                onFileChange={(key) => {
                  if (key === "") {
                    setIsFileDeleted(true);
                    setDisableAddressProof(false);
                  } else {
                    setIsFileDeleted(false);
                    setDisableAddressProof(true);
                    queryClient.invalidateQueries("getcompanydetails");
                  }
                }}
                {...(isMobile || isTablet
                  ? {
                      stylesBefore: stylesBefore,
                      stylesAfter: stylesAfter,
                      shrinked: true,
                    }
                  : {})}
              />
            </Box>
            <Box>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ marginTop: "20px", marginLeft: "10px" }}
              >
                Back
              </Typography>
              <FileUploadContainer
                actions={<></>}
                typeId={director?.id}
                status={statusDetails?.data?.directorDocuments}
                filetype={addressProofType}
                data={"ADDRESS_PROOF_BACK"}
                typeData="DIRECTOR"
                uploaded={
                  director?.documents?.filter(
                    (i: any) => i?.subtype === "ADDRESS_PROOF_BACK"
                  )?.length
                }
                name={director?.documents
                  ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_BACK")
                  .map((i: any) => i?.originalFileName)}
                url={director?.documents
                  ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_BACK")
                  .map((i: any) => i?.url)}
                onFileChange={(key) => {
                  if (key === "") {
                    setDisableAddressProof(false);
                  } else {
                    setDisableAddressProof(true);
                    queryClient.invalidateQueries("getcompanydetails");
                  }
                }}
                {...(isMobile || isTablet
                  ? {
                      stylesBefore: stylesBefore,
                      stylesAfter: stylesAfter,
                      shrinked: true,
                    }
                  : {})}
              />
            </Box>
          </Stack>
        </Box>
      )}
      {!statusDetails?.data?.directorDocuments && (
        <Typography
          variant="body1"
          sx={{
            color: "#800000",
            my: "25px",
            fontStyle: "italic",
            opacity: 0.8,
          }}
        >
          Please confirm that you have uploaded all the required KYB/KYC
          documents for the {director?.name}
        </Typography>
      )}

      {index !== CompanyDetails?.data?.signatoryDirectors?.length - 1 && (
        <Box
          sx={{
            borderTop: "1px dashed grey",
            mx: "5px",
            my: "10px",
          }}
        ></Box>
      )}
    </Box>
  );
}
