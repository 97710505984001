import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, styled, Typography } from "@mui/material";
import { desktopView } from "../../../assets";

interface DesktopModalProps {
  open: boolean;
  onClose: () => void;
}

const StyledBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   height: 400,
  backgroundColor: "#FFFFFF",
  border: "1px solid #1212121A",
  borderRadius: "15px",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "-40px",
  right: "0px",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
  borderRadius: "50%",
  height: "25px",
  width: "25px",
});

const DesktopModal: React.FC<DesktopModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox>
        <CloseButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </CloseButton>

        <Box sx={{ textAlign: "center", mb: "3%" }}>
          <img
            src={desktopView}
            alt="Card"
            style={{ width: "300px", height: "280px", objectFit: "cover" }}
          />
        </Box>

        <Typography variant="h6" style={{ textAlign: "center" }}>
          Please switch to a desktop computer to submit your KYC/KYB.
        </Typography>
      </StyledBox>
    </Modal>
  );
};

export default DesktopModal;
