import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Lottie from "lottie-react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getComapanyDetailsById, getusersbyid } from "../../../api/services";

import Tick from "./Lottie.json";

const SuccessScreen = () => {
  const navigate = useNavigate();
  const { kyctype } = useParams();
  const accessToken: any = Cookies.get("access_token");
  const userData: any = jwtDecode(accessToken);
  const { data }: any = useQuery(
    ["get-user-id", userData?.userId],
    getusersbyid
  );
  const EntityId = Cookies.get("entityId");
  const { data: CompanyDetails } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      enabled: !!EntityId,
    }
  );

  const StyledDfAcJc = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }));

  const navigateHome = () => {
    navigate("/home", { replace: true });
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          flex: "0 0 auto",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          pb: 2,
          borderBottom: "1px solid #CACACA",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginX: "8%",
          }}
        >
          <Avatar
            variant="square"
            alt={data?.data?.name || "BM"}
            src=""
            sx={{
              width: 65,
              height: 65,
              marginTop: 3,
              marginBottom: 0,
              marginX: 3,
              marginLeft: 0,
              borderRadius: "5px",
            }}
          >
            {data?.data?.name &&
              data?.data?.name
                .split(" ")
                .map((word: any) => word.charAt(0))
                .join("")
                .toUpperCase()
                .substring(0, 2)}
          </Avatar>
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h3" sx={{ color: "#444444" }}>
              {data?.data?.name}
            </Typography>
            <Typography variant="body1" color={"GrayText"}>
              {data?.data?.emailAddress}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F3EDED",
          width: "100vw",
          flex: "1 1 auto",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            // marginX: "8%",
            // minHeight: "75vh",
            // height: { xs: "80%", md: "60%" },
            margin: { xs: "5%", md: "5%" },
            marginTop: "1%",
            borderRadius: "8px",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignSelf: { xs: "center", sm: "start" },
            }}
          >
            <Box sx={{ p: 4, display: { xs: "none", md: "block" } }}>
              <Box sx={{ pb: 4 }}>
                <Typography variant="h3" color="black">
                  {/* TRP00{data?.data?.id} */}
                </Typography>
              </Box>

              {kyctype === "individual" ? (
                <>
                  <Grid container spacing={3}>
                    <Grid size={4}>
                      <Typography variant="body2">Individual Name</Typography>
                      <Typography variant="subtitle2">
                        {CompanyDetails?.data?.directors[0]?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid size={4}>
                      <Typography variant="body2">Entity Type</Typography>
                      <Typography variant="subtitle2">
                        {CompanyDetails?.data?.companyType.replaceAll("_", " ")}
                      </Typography>
                    </Grid>
                    {CompanyDetails?.data?.category && (
                      <Grid size={4}>
                        <Typography variant="body2">Entity Category</Typography>
                        <Typography variant="subtitle2">
                          {CompanyDetails?.data?.category}
                        </Typography>
                      </Grid>
                    )}
                    <Grid size={4}>
                      <Typography variant="body2">Firm Name</Typography>
                      <Typography variant="subtitle2">
                        {CompanyDetails?.data?.companyName}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <Divider sx={{ mt: 4 }} />
            </Box>

            <StyledDfAcJc>
              <Box
                sx={{
                  maxWidth: { xs: "150px", md: "250px" },
                  maxHeight: { xs: "150px", md: "250px" },
                }}
                marginBottom={-1}
                marginTop={-1}
              >
                <Lottie animationData={Tick} loop={false} autoplay />
              </Box>
              <Typography variant="h4">Congratulations!</Typography>
              <Typography
                fontSize={"18px"}
                sx={{
                  margin: "0 auto",
                  px: { xs: "10%", md: "20%" },
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  textAlign: "justify",
                }}
              >
                Thank you for completing the KYC/KYB process and submitting your
                application. Your application is now under review by our team.
                The verification process typically takes 24-48 hours to ensure
                compliance with all necessary regulations and guidelines. During
                this waiting period, you can monitor the status of your
                application in your dashboard.
              </Typography>

              <Button
                type="submit"
                onClick={navigateHome}
                variant="contained"
                sx={{
                  paddingX: "20px",
                  // paddingY: 3,
                  // mt: 2,
                  fontSize: "15px",
                  mb: 2,
                }}
              >
                Go Back To Home
              </Button>
            </StyledDfAcJc>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default SuccessScreen;
