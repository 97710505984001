import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getComapanyDetailsById,
  getSignatoryAuthority,
  getStatusbyId,
} from "../../../api/services";

import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import InputField from "../../../components/formFields/kycFields/InputField";
import SignatoryAuthorityForm from "../components/SignatoryAuthorityForm";
import SignatoryAuthorityModel from "../components/SignatoryAuthorityModel";

interface Director {
  din_number: string;
  director_name: string;
  isnew: boolean;
  id?: any;
}

const DirectorInformation = ({ setAddDirector }: any) => {
  const [addsignatory, Setaddsignatory] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [isdataSaved, setisDataSaved] = useState(true);
  const EntityId = Cookies.get("entityId");
  const queryClient = useQueryClient();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const { data: statusDetails } = useQuery(
    ["status-data", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  const { mutate: signaturyAuth, isLoading } = useMutation(
    getSignatoryAuthority,
    {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries("status-data");
        queryClient.invalidateQueries("getcompanydetails");
        setOpenModel(false);
      },
      onError: (error: any) => {},
    }
  );

  const { data: CompanyDetails, isLoading: companyLoading } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      onSuccess: (res: any) => {
        setValue("numberOfDirectors", res?.data?.signatoryDirectors?.length);
      },
    }
  );
  useEffect(() => {
    if (
      CompanyDetails?.data?.signatoryDirectors?.length === 0 &&
      CompanyDetails?.data?.directors?.length > 0
    ) {
      setOpenModel(true);
    } else {
      Setaddsignatory(true);
    }
  }, [
    CompanyDetails?.data?.signatoryDirectors,
    CompanyDetails?.data?.directors?.length,
  ]);

  const [Directors, setDirectors] = useState<Director[]>([]);

  function reorderDirectorsData(directorsData: any) {
    const nullEmailVerified: any = [];
    const nonNullEmailVerified: any = [];

    directorsData.forEach((obj: any) => {
      if (obj.isEmailVerified == null || obj.isEmailVerified === undefined) {
        nullEmailVerified.push(obj);
      } else {
        nonNullEmailVerified.push(obj);
      }
    });

    return nonNullEmailVerified.concat(nullEmailVerified);
  }

  function checkArrayForNull(array: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === null) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (
      CompanyDetails &&
      CompanyDetails?.data &&
      CompanyDetails?.data?.signatoryDirectors
    ) {
      const directorsData = CompanyDetails?.data?.signatoryDirectors?.map(
        (director: any) => {
          const dataArray = [
            director?.name,
            director?.id,
            director?.aadharNumber,
            director?.panNumber,
            director?.address,
            director?.isAadharMismatch,
            director?.isPanMisMatch,
            director?.designation,
            director?.mobileNumber,
            director?.emailAddress,
            director?.isMobileVerified,
            director?.isEmailVerified,
            director?.isNewDirector,
          ];
          return {
            din_number: director?.dpin || "",
            director_name: director?.name || "",
            id: director?.id || "",
            aadharNumber: director?.aadharNumber || "",
            panNumber: director?.panNumber,
            address: director?.address,
            dateOfBirth: director?.dateOfBirth,
            gender: director?.gender,
            isAadharMismatch: director?.isAadharMismatch,
            isPanMisMatch: director?.isPanMisMatch,
            isSignatoryAuthority: director?.isSignatoryAuthority,
            designation: director?.designation,
            mobileNumber: director?.mobileNumber,
            emailAddress: director?.emailAddress,
            isMobileVerified: director?.isMobileVerified,
            isEmailVerified: director?.isEmailVerified,
            isnew: director?.isNewDirector,
            isDataSaved: checkArrayForNull(dataArray),
          };
        }
      );
      setDirectors(reorderDirectorsData(directorsData));
    }
    const areAllVerified = CompanyDetails?.data?.signatoryDirectors.every(
      (director: any) => director.isEmailVerified && director.isMobileVerified
    );
    setisDataSaved(!areAllVerified);
  }, [CompanyDetails?.data?.signatoryDirectors, CompanyDetails]);

  const deleteDirector = (index: number) => {
    const noofDirectors = getValues("numberOfDirectors");
    setValue("numberOfDirectors", noofDirectors - 1);
    setDirectors((prevDirectors) => {
      const newDirectors = [...prevDirectors];
      for (let i = index; i < newDirectors.length - 1; i++) {
        newDirectors[i] = newDirectors[i + 1];
      }
      newDirectors.length = newDirectors.length - 1;
      return newDirectors;
    });
  };

  const addDirector = () => {
    const noofDirectors = getValues("numberOfDirectors");
    setValue("numberOfDirectors", noofDirectors + 1);
    setDirectors([
      ...Directors,
      { din_number: "", director_name: "", isnew: true },
    ]);
  };

  useEffect(() => {
    if (Directors?.length < 1) {
      addDirector();
    }
  }, [Directors]);

  const onSubmit = () => {
    const arrayOfsignatoryDirectorsIds =
      CompanyDetails?.data?.signatoryDirectors?.map((item: any) => item?.id);
    if (arrayOfsignatoryDirectorsIds?.length === 0) {
      toast.error(
        "Please add Signatory Authority details to continue the KYB/KYC process"
      );
    } else if (arrayOfsignatoryDirectorsIds.length > 0) {
      signaturyAuth({
        entityid: EntityId,
        body: {
          directorIds: arrayOfsignatoryDirectorsIds,
          isSignatorySelected: true,
        },
      });
    } else {
    }
  };

  return (
    <>
      {companyLoading ? (
        <Box
          sx={{
            width: "50vw",
            height: "98%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              overflow: "auto",
              width: "64vw",
              height: "57.9vh",
              px: 2,
              mt: "1vh",
              display: "flex",
              flexDirection: "column",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                my: "1vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">
                Signatory Authority Information
              </Typography>

              {false && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenModel(true);
                    }}
                    disabled={statusDetails?.data?.director}
                  >
                    Get Director Information
                  </Button>
                  {/* {!statusDetails?.data?.director && (
                    <>
                      <Button variant="outlined" onClick={addDirector}>
                        Add Signatory Authority
                      </Button>
                    </>
                  )} */}
                </Box>
              )}
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  gap: "20px",
                }}
              >
                {!statusDetails?.data?.director && (
                  <>
                    <Button variant="outlined" onClick={addDirector}>
                      Add Signatory Authority
                    </Button>
                  </>
                )}
              </Box>
            </Box>

            <>
              {companyLoading ? (
                <Box
                  sx={{
                    height: "50vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {" "}
                  <Box>
                    {false && (
                      <Controller
                        name="numberOfDirectors"
                        control={control}
                        render={({ field }) => (
                          <InputField
                            isdisabled={true}
                            field={field}
                            iD={"numberOfDirectors"}
                            name={"Number Of Directors"}
                            errors={errors}
                          />
                        )}
                      />
                    )}

                    {Directors.map((director: any, index: any) => {
                      return (
                        <>
                          <SignatoryAuthorityForm
                            entityType={"PARTNERSHIP"}
                            popDirector={deleteDirector}
                            isnew={director?.isnew}
                            key={index}
                            id={director?.id}
                            Dname={director?.director_name || ""}
                            Dpin={director?.din_number}
                            aadharNumber={director?.aadharNumber || ""}
                            panNumber={director?.panNumber || ""}
                            address={director?.address || ""}
                            Signatory={false}
                            index={++index}
                            directorData={director}
                            isAadharMismatch={
                              director?.isAadharMismatch || false
                            }
                            isPanMisMatch={director?.isPanMisMatch || false}
                            mobileNumber={director?.mobileNumber || ""}
                            emailAddress={director?.emailAddress || ""}
                            isMobileVerified={
                              director?.isMobileVerified || false
                            }
                            isEmailVerified={director?.isEmailVerified || false}
                            designation={director?.designation || ""}
                            isDataSaved={director?.isDataSaved || false}
                            SignatoryAuthoritydata={
                              CompanyDetails?.data?.signatoryDirectors || []
                            }
                          />
                        </>
                      );
                    })}
                  </Box>
                </>
              )}
            </>
          </Box>

          <Box
            sx={{
              paddingY: 2,
              marginTop: "3.5vh",
              marginX: 0,
              textAlign: "end",
              alignContent: "center",
              borderTop: "1px solid #cacaca",
            }}
          >
            <CustomLoadingButton
              submitLoader={isLoading}
              onClick={() => onSubmit()}
              isDisabled={
                statusDetails?.data?.director ||
                getValues("numberOfDirectors") !==
                  CompanyDetails?.data?.signatoryDirectors?.length ||
                isdataSaved
              }
              title="Next"
            />
            {}
          </Box>
        </>
      )}
      <SignatoryAuthorityModel
        openModel={openModel}
        setOpenModel={setOpenModel}
        data={CompanyDetails?.data?.directors}
        signatoryDirectors={CompanyDetails?.data?.signatoryDirectors}
        addDirector={addDirector}
      />
    </>
  );
};

export default DirectorInformation;
