import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { buyer, buyerHi, services, servicesHi } from "../../../../assets";

type DealRoleProps = {
  dealType: string;
  setDealRole: (role: string) => void;
};

export default function DealRole({ setDealRole, dealType }: DealRoleProps) {
  const [onselect, setOnselect] = useState(true);

  return (
    <>
      <Box sx={{}}>
        <Typography variant="caption" sx={{ color: "#00000099" }}>
          Select Your Role <span style={{ color: "red" }}>*</span>
        </Typography>
        <Box
          sx={{
            width: {
              xs: "250px",
              sm: "520px",
              lg: "520px",
              xl: "520px",
            },
            display: "flex",
            justifyContent: "start",
          }}
        >
          {onselect ? (
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                width: "130px",
                height: "80px",
                paddingTop: "5px",
                background: "#F1EBFF",
                boxShadow: "0px 3px 6px #00000014",
                border: "1px solid #7643EB",
                borderRadius: "8px",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img height={"50%"} src={buyerHi} alt="loading..." />
              <Typography variant="body1" color={"primary"} marginTop={"10px"}>
                Buyer
              </Typography>
            </Box>
          ) : (
            <Box
              onClick={() => {
                setOnselect(true);
                setDealRole("Buyer");
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                width: "130px",
                height: "80px",
                paddingTop: "5px",
                background: "#FCFCFC",
                backgroundPosition: "0% 0%",
                backgroundRepeat: "no-repeat",
                border: "1px solid #33333333",
                borderRadius: "8px",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img height={"50%"} src={buyer} alt="loading..." />
              <Typography variant="body1" marginTop={"10px"}>
                Buyer
              </Typography>
            </Box>
          )}

          {/* {!(dealType === "INDIVIDUAL") && ( */}
            <>
              {onselect ? (
                <>
                  {" "}
                  <Box
                    onClick={() => {
                      setOnselect(false);
                      setDealRole("Seller");
                    }}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      marginLeft: "20px",
                      width: "130px",
                      height: "80px",
                      paddingTop: "5px",
                      background: "#FCFCFC",
                      backgroundPosition: "0% 0%",
                      backgroundRepeat: "no-repeat",
                      border: "1px solid #33333333",
                      borderRadius: "8px",
                      opacity: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img height={"50%"} src={services} alt="loading..." />
                    <Typography variant="body1" marginTop={"10px"}>
                      Seller
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      marginLeft: "20px",
                      paddingTop: "5px",
                      width: "130px",
                      height: "80px",
                      background: "#F1EBFF",
                      boxShadow: "0px 3px 6px #00000014",
                      border: "1px solid #7643EB",
                      borderRadius: "8px",
                      opacity: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img height={"50%"} src={servicesHi} alt="loading..." />
                    <Typography
                      variant="body1"
                      color={"primary"}
                      marginTop={"10px"}
                    >
                      Seller
                    </Typography>
                  </Box>
                </>
              )}
            </>
          {/* )} */}
        </Box>
      </Box>
    </>
  );
}
