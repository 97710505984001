import { Button, Typography } from "@mui/material";
import { Icons } from "../../assets";

export default function GoogleButton({
  typeText = "continue with Google ",
  actionOnClick,
  width,
}: any) {
  return (
    <Button
      variant="outlined"
      onClick={actionOnClick}
      sx={{
        width: width,
        borderRadius: "5px",
        border: "1px solid #7643EB",
        "&:hover": {
          backgroundColor: "white",
        },
      }}
    >
      <img height={"85%"} src={Icons.google} alt="....loading" />
      <Typography paddingLeft={"10px"}>{typeText}</Typography>
    </Button>
  );
}
