import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { accessDenied } from "../../assets";

import { useEffect } from "react";
import { isIndianLocation } from "../../utils/Location";

export default function AccessDenied() {
  const navigate = useNavigate();

  useEffect(() => {
    isIndianLocation((result: any) => {
      if (result === "prompt") {
      } else if (result) {
        navigate("/home", { replace: true });
      } else {
        navigate("/access-denied", { replace: true });
      }
    });
  }, [isIndianLocation]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        padding: 2,
      }}
    >
      <Box sx={{ width: "35%" }}>
        <img width="100%" src={accessDenied} alt="Access Denied" />
      </Box>

      <Typography variant="h4" fontWeight={400} color="red" mt={2}>
        403 - Access Denied
      </Typography>
      <Typography my={3} variant="h6" gutterBottom>
        You don't have permission to access this page. Please turn on your
        location services to continue.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          sx={{ width: "180x" }}
          onClick={() => {
            navigate("/access-denied/location-help");
          }}
          variant="contained"
        >
          How to Enable location
        </Button>
      </Box>
    </Box>
  );
}
