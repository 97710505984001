import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getEntityPreview } from "../../../../api/services";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";
import {
  maskAadharNumber,
  maskedPanNumber,
} from "../../../../utils/previewUtils";
import BankingInformation from "../../../userProfile/banking-information/BankingInformation";

const SectionContainer = styled(Box)`
  padding: 4;
`;

const SectionTitle = styled(Typography).attrs(() => ({
  variant: "h6",
  color: "black",
}))`
  padding-bottom: 4;
`;

const InfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const InfoGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 3,
}))``;

const InfoItem = styled(Grid).attrs(() => ({
  size: 4,
}))``;

const InfoText = styled(Typography).attrs(({ variant }) => ({
  variant: variant || "subtitle2",
}))``;

const DocumentBox = styled(Box)`
  width: 18vw;
  height: 12vh;
  border: 1px solid #1212121a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const DocumentInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DocumentTitle = styled(Typography).attrs(() => ({
  variant: "body1",
  gutterBottom: true,
}))`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentDate = styled(Typography).attrs(() => ({
  variant: "body1",
  gutterBottom: true,
}))`
  margin-top: 10px;
`;

interface PreviewComponentProps {
  kycType: any;
  data: any;
  EntityId: any;
}

export default function ProprietorshipPreviewComponent({
  data,
  EntityId,
  kycType,
}: PreviewComponentProps) {
  const { data: previewData, isLoading }: any = useQuery(
    ["get-user-id", EntityId],
    getEntityPreview
  );

  if (isLoading) {
    return (
      <Box
        display={"flex"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={"4rem"}></CircularProgress>;
      </Box>
    );
  }

  return (
    <InfoBox>
      <SectionContainer>
        <SectionTitle>Entity Information</SectionTitle>
        <InfoGrid>
          {[
            { label: "Business Category", value: previewData?.data?.category },
            { label: "GST Number", value: previewData?.data?.gstNumber },
            { label: "Firm Name", value: previewData?.data?.companyName },
            { label: "PAN Number", value: previewData?.data?.panNumber },
          ].map((info, idx) => (
            <InfoItem key={idx}>
              <InfoText variant="caption">{info.label}</InfoText>
              <InfoText>{info?.value}</InfoText>
            </InfoItem>
          ))}
        </InfoGrid>
      </SectionContainer>

      <Divider sx={{ my: "2vh" }} />

      <SectionContainer>
        <SectionTitle>Proprietor Information</SectionTitle>
        {previewData?.data?.directors?.map((director: any, index: number) => (
          <React.Fragment key={index}>
            <Box sx={{ mt: 2, mb: 1 }}></Box>
            <InfoGrid direction="row" spacing={6}>
              {[
                { label: "Name", value: director?.name || "Not Available" },
                {
                  label: "Designation",
                  value: director?.designation || "Not Available",
                },
                {
                  label: "Email Address",
                  value: director?.emailAddress || "Not Available",
                },
                {
                  label: "Mobile Number",
                  value: director?.mobileNumber || "Not Available",
                },
                {
                  label: "Aadhar Number",
                  value:
                    maskAadharNumber(director?.aadharNumber) || "Not Available",
                },
                {
                  label: "Aadhar Status",
                  value: director?.isAadharMismatch ? (
                    <Typography color="red">Mismatch</Typography>
                  ) : (
                    <Typography color="green">Verified</Typography>
                  ),
                },
                {
                  label: "PAN Number",
                  value:
                    maskedPanNumber(director?.panNumber) || "Not Available",
                },
              ].map((info, idx) => (
                <InfoItem key={idx}>
                  <InfoText variant="caption">{info?.label}</InfoText>
                  <InfoText>{info?.value}</InfoText>
                </InfoItem>
              ))}
            </InfoGrid>
          </React.Fragment>
        ))}
      </SectionContainer>

      <Divider sx={{ my: "2vh" }} />

      <Box sx={{ width: "98%" }}>
        <BankingInformation data={previewData} isEditable={false} />
      </Box>

      <Divider sx={{ my: "2vh" }} />

      <SectionContainer>
        <SectionTitle>Firm Documents</SectionTitle>
        <Grid container spacing={3}>
          {previewData?.data?.firmDocuments?.map((doc: any, idx: number) => {
            return (
              <Grid size={4} key={idx}>
                <FileUploadContainer
                  typeId={previewData?.data?.id}
                  actions={<></>}
                  data={doc}
                  typeData="ENTITY"
                  name={doc.originalFileName}
                  url={doc?.url}
                  subType={doc.subtype}
                  showIcons={false}
                  uploaded={true}
                  onFileChange={() => {}}
                />
              </Grid>
            );
          })}
        </Grid>
      </SectionContainer>

      <Divider sx={{ my: "2vh" }} />

      <SectionContainer>
        <SectionTitle>
          {kycType === "individual"
            ? "Individual Documents"
            : "Signatory Documents"}
        </SectionTitle>
        {previewData?.data?.directors?.map((director: any, index: number) => (
          <Box key={index}>
            <Box sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h5">{director?.name}</Typography>
            </Box>
            <InfoGrid>
              {director?.documents?.map((doc: any, idx: number) => {
                return (
                  <Grid size={4} key={idx}>
                    <FileUploadContainer
                      typeId={director?.id}
                      actions={<></>}
                      data={doc}
                      typeData="DIRECTOR"
                      name={doc?.originalFileName}
                      url={doc?.url}
                      subType={doc?.subtype}
                      showIcons={false}
                      uploaded={true}
                      onFileChange={() => {}}
                    />
                  </Grid>
                );
              })}
            </InfoGrid>
          </Box>
        ))}
      </SectionContainer>
    </InfoBox>
  );
}
