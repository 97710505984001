import { http } from "../http";

export const getUsersByemail = (params: any) => {
  return http.get("/users", { params: { search: params?.email } });
};

export const postDealInformation = (data: any) => {
  return http.post("/deals", data);
};

export const getDealsDataById = ({ queryKey }: any) => {
  return http.get(`/deals/${queryKey[1]}`);
};

export const postNotify = (data: any) => {
  return http.post("/deals/notify", data);
};

export const getUserDeals = (queryKey: any) => {
  return http.get("/deals/user-deals", {
    params: { ...queryKey },
  });
};

export const acceptDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/accept`, data);
};

export const rejectDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/reject`, data);
};

export const publishDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/publish`, data);
};

export const updateStatus = ({ data }: any) => {
  return http.put(`/deals/signer-status`, data);
};

export const getSignerStatus = ({ data }: any) => {
  return http.post(`/deals/signer-status`, data);
};

export const createRazorpayOrder = (data: any) => {
  return http.post("/deal-service-fee/create-order", data);
};

export const createOrderBipass = (data: any) => {
  return http.post("/deal-service-fee/complete", data);
};

export const verifyTransaction = (data: any) => {
  return http.post("/deal-service-fee/verify-transaction", data);
};

//Truupe
export const Initiatetransaction = (data: any) => {
  return http.post("/deal-transaction/initiate", data);
};

export const InitiatetransactionVerify = (data: any) => {
  return http.post("/deal-transaction/verify", data);
};

export const dealtransaction = ({ queryKey }: any) => {
  return http.get(`/deal-transaction/${queryKey[1]}`);
};

export const sellerPaymnetRequest = (data: any) => {
  return http.post(`/payment-request`, data);
};

export const inititateCredit = ({ code, body }: any) => {
  return http.post(`/credit/${code}`, body);
};

export const GetPaymentRequestsByDeal = ({ queryKey }: any) => {
  return http.get(`/payment-request`, {
    params: { dealCode: queryKey[1] },
  });
};

// Coupons

export const applyCouponCode = (body: any) => {
  return http.post(`/deal-coupons/apply`, body);
};

export const removeCouponCode = (body: any) => {
  return http.post(`/deal-coupons/remove`, body);
};
