import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DealTabs() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/deal-process/deals")) {
      setValue(0);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            backgroundColor: "#7643EB",
            ".MuiTab-root": {
              marginLeft: "10px",
              color: "#C6B2F6",
              fontSize: "14px",
              padding: "6px 12px",
              "&:hover": {
                color: "white",
              },
              "&.Mui-selected": {
                color: "white",
              },
            },
            ".MuiTabs-indicator": {
              backgroundColor: "white",
            },
          }}
        >
          {/* <Tab
            label="Dashboard"
            {...a11yProps(0)}
            onClick={() => {
              navigate("dashboard/new");
            }}
          /> */}
          <Tab
            label="Deals"
            {...a11yProps(0)}
            onClick={() => {
              navigate("deals/all");
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
}
