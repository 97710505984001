import { Box, Button, CircularProgress, styled } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { escrow1, escrow2 } from "../../../../../assets";

interface CongratsProps {
  open: boolean;
  inDealDocument?: boolean;
  onClose?: () => void;
  data?: any;
  onSuccess?: any;
  formDate?: any;
  dealId?: any;
}

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  height: "50%",
  [theme.breakpoints.up("xs")]: {
    width: "90%",
    height: "25pc",
    top: "20%",
    left: "5%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    height: "25pc",
    top: "20%",
    left: "10%",
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
    top: "15%",
    height: "30pc",
    left: "20%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "40%",
    top: "15%",
    height: "30pc",
    left: "30%",
  },
  [theme.breakpoints.up("xl")]: {
    width: "40%",
    top: "15%",
    height: "35pc",
    left: "30%",
  },
}));

const Congrats: React.FC<CongratsProps> = ({
  open,
  onClose,
  inDealDocument = false,
}) => {
  const navigate = useNavigate();
  const { dealId } = useParams();
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const preloadImages = () => {
    const images = [escrow1, escrow2];
    let loaded = 0;
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loaded++;
        if (loaded === images.length) {
          setImagesLoaded(true);
        }
      };
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalBox>
        <Box
          sx={{
            bgcolor: "#FFFFFF",
            borderRadius: "8px",
            textAlign: "center",
            height: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {!imagesLoaded ? (
            <CircularProgress sx={{ mt: 10 }} />
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 16px",
              }}
            >
              <Box
                component="img"
                src={escrow1}
                alt="Card"
                sx={{
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
              <Box
                component="img"
                src={escrow2}
                alt="Card"
                sx={{
                  width: {
                    xs: "85%",
                    sm: "55%",
                    md: "55%",
                    lg: "50%",
                    xl: "45%",
                  },
                  objectFit: "cover",
                  position: "absolute",
                  bottom: 0,
                }}
              />
            </Box>
          )}

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 16px",
            }}
          >
            <Typography
              variant="h2"
              id="modal-title"
              sx={{
                textAlign: "center",
                // marginBottom: "5%",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "18px",
                },
              }}
            >
              <b> Congratulations !!</b>
              <br />
              <br />
              Your Account is now active and you can now start making payments
              into your Account.
              <br />
              <br />
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                // window.location.reload();
                navigate(`/deal-process/deals/${dealId}`, {
                  replace: true,
                });
              }}
              color="primary"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </StyledModalBox>
    </Modal>
  );
};

export default Congrats;
