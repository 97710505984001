import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Breadcrumbs, IconButton, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DealBreadcrumbs = ({ links }: { links: any[] }) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="center">
      <IconButton size="small" sx={{ mt: 0.5 }} onClick={() => navigate(-1)}>
        <ChevronLeftIcon fontSize="small" sx={{ color: "#7643EB" }} />
      </IconButton>
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#7643EB" }}>
        {links?.map((link: any, index: any) => (
          <Link
            key={index}
            underline="hover"
            color="#7643EB"
            href={link?.href}
            fontSize={"12px"}
          >
            {link?.label}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default DealBreadcrumbs;
