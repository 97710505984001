import { Box } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import { getUserProfile } from "../../api/services";

import DealTabs from "../../components/tabs/DealTabs";
import Header from "../../pages/home/Components/Header";

export default function DealLayout() {
  const [_, setOpen] = useState(false);
  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <Box>
      <Box position="sticky" top={0} zIndex={1}>
        <Header
          toggleDrawer={toggleDrawer}
          profileData={profileData}
          deal={true}
        />
        <DealTabs />
      </Box>
      <Box
        sx={{
          padding: { xs: "0px", sm: "10px", md: "20px", lg: "24px", xl: "24px" },
        }}
        bgcolor={"#F9F9F9"}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
