import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

type SimpleCheckoBoxProps = {
  setInfocheck: Function;
  control: any;
  text: String;
};

export default function SimpleCheckoBox({
  setInfocheck,
  control,
  text,
}: SimpleCheckoBoxProps) {
  return (
    <Controller
      name="checked"
      control={control}
      render={({ field }) => (
        <FormGroup sx={{ marginTop: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                onClick={(event: any) => {
                  setInfocheck(event?.target?.checked);
                }}
              />
            }
            label={text}
          />
        </FormGroup>
      )}
    />
  );
}
