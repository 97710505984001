import { Box, LinearProgress, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../../api/services";
import { getDealsDataById } from "../../../../api/services/deals";
import DealDetails from "./dealInformation/DealDetails";
import DealInfo from "./dealInformation/DealInfo";
import DealStates from "./dealStates/DealStates";

import { Deal } from "../../../../interfaces/deal";
import { DealBox, DealCard } from "../../../../styles/styled-components";
import DealTop from "./DealTop";

const InfoSection = styled(Box)({
  width: "25vw",
  borderRight: "1px solid #1212121A",
  padding: "0 16px",
});

const DetailsSection = styled(Box)({
  width: "75%",
  padding: "0 16px",
});

export default function UserDeal() {
  const { dealId }: any = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { data: profileData } = useQuery("getUser-id", getUserProfile);

  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deal-process/deals/all");
      },
    }
  );

  const deal: Deal = dealData?.data;
  const dealCreator = deal?.dealUsers?.find(
    (user: any) => user?.userId === deal?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.data?.id;

  const profileUserRole = dealData?.data?.dealUsers?.find((user: any) => {
    return user?.userId === profileData?.data?.id;
  })?.userType;

  localStorage.setItem("presentStatus", dealData?.data?.status);
  localStorage.setItem("presentState", dealData?.data?.state);
  localStorage.setItem("dealcode", dealId);
  localStorage.setItem("UserRole", profileUserRole);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <DealBox>
        <DealCard>
          <DealTop
            dealData={deal}
            profileData={profileData?.data}
            dealCode={dealId}
            breadcrumbs={[
              { label: "Deals", href: "/deal-process/deals/all" },
              { label: dealId, href: `/deal-process/deals/${dealId}` },
            ]}
          />
          {isMobile || isTablet ? (
            <>
              <Box display="flex" flexDirection={"column"}>
                <DealDetails
                  data={deal}
                  accountOwner={firstPerson}
                  profileData={profileData}
                />
                <DealInfo data={deal} accountOwner={firstPerson} />
              </Box>
            </>
          ) : (
            <>
              {" "}
              <Box display="flex">
                <InfoSection>
                  <DealInfo data={deal} accountOwner={firstPerson} />
                </InfoSection>
                <DetailsSection>
                  <DealDetails
                    data={deal}
                    accountOwner={firstPerson}
                    profileData={profileData}
                  />
                </DetailsSection>
              </Box>
              <DealStates data={deal} />
            </>
          )}
        </DealCard>
      </DealBox>
    </>
  );
}
