import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  LinearProgress,
  Box as MuiBox,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getusersbyid } from "../../../api/services";
import { Icons } from "../../../assets";
import { getUserId } from "../../../utils/authUtils";
import BusinessDetailsCard from "./BussinessDetailscard";
import CarouselComponent from "./CarouselComponent";
import DesktopModal from "./DesktopModal";
import HomeTimeline from "./hometimeline";
import SelectRole from "./SelectRole";
import VerificationRejectedCard from "./VerificationErrorCard";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTabPanel = styled("div")(({ theme }) => ({
  backgroundColor: "#f9f9f9",
  borderTop: "1px solid #cacaca",
  width: "100vw",
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    height: "80vh",
  },
}));

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledTabPanel>{children}</StyledTabPanel>}
    </div>
  );
};

type KYCProcessCardProps = {
  profileData: any;
  ProfileLoading: boolean;
};

const KYCProcessCard = ({
  profileData,
  ProfileLoading,
}: KYCProcessCardProps) => {
  const userID = getUserId();
  const navigate = useNavigate();
  const [open, setModel] = useState(false);
  const handleModelOpen = () => setModel(true);
  const handleModelClose = () => setModel(false);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleMobileOpen = () => setMobileOpen(true);
  const handleMobileClose = () => setMobileOpen(false);

  const [timeLineDialogOpen, setTimeLineDialogOpen] = useState(false);
  const handleOpenDialog = () => setTimeLineDialogOpen(true);
  const handleCloseDialog = () => setTimeLineDialogOpen(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const { data, isLoading }: any = useQuery(
    ["getUser-id", userID],
    getusersbyid
  );

  const companyType = data?.data?.entities[0]?.companyType;

  useEffect(() => {
    const entityId = data?.data?.entities[0]?.id;
    if (entityId === undefined) {
      Cookies.remove("entityId");
    } else {
      Cookies.set("entityId", entityId);
    }
  }, [data?.data?.entities]);

  const handleClick = () => {
    // if (isMobile || isTablet) {
    //   handleMobileOpen();
    // } else {
    handleModelOpen();
    // }
  };

  const renderVerificationStatus = () => {
    if (
      // profileData?.activity?.some(
      //   (activity: any) => activity.description === "KYB/KYC Process Rejected"
      // )
      profileData?.entities[0]?.status === "Rejected"
    ) {
      return (
        <>
          <Typography
            variant={isMobile ? "subtitle1" : "subtitle2"}
            sx={{ fontWeight: 500, color: "black" }}
          >
            Verification could not be completed
          </Typography>
          <VerificationRejectedCard />
        </>
      );
    } else if (
      // profileData?.activity?.some(
      //   (activity: any) => activity.description === "KYB/KYC Process in Review"
      // )
      profileData?.entities[0]?.status === "Kyc Submitted"
    ) {
      return (
        <>
          <Typography
            variant={isMobile ? "subtitle1" : "subtitle2"}
            sx={{ fontWeight: 500, color: "black" }}
          >
            Your KYC/ KYB process Is under review
          </Typography>
          <Box sx={{ borderRadius: "10px" }}>
            <Typography
              variant={isMobile ? "h6" : "body1"}
              sx={{ color: "GrayText", fontWeight: "100", width: "96%" }}
              display={{ xs: "none", sm: "block" }}
            >
              Our team is diligently assessing your submitted documents to
              ensure compliance and authenticity. Sit back and relax while we
              finalize the verification process. We'll notify you once
              everything is approved and your account is fully verified.
            </Typography>
          </Box>
        </>
      );
    } else if (
      // profileData?.activity?.some(
      //   (activity: any) => activity.description === "KYB/KYC Process in Draft"
      // )
      profileData?.entities[0]?.status === "In Progress"
    ) {
      return (
        <>
          <Typography
            variant={isMobile ? "subtitle1" : "subtitle2"}
            sx={{ fontWeight: 500, color: "black" }}
          >
            KYB/KYC process is in Draft, resume process to complete KYC
          </Typography>
          <Box sx={{ borderRadius: "10px" }}>
            <Typography
              variant={isMobile ? "h6" : "body1"}
              sx={{ color: "GrayText", fontWeight: "100", width: "96%" }}
              display={{ xs: "none", sm: "block" }}
            >
              Welcome back! Ready to pick up where you left off? Click the
              button below to resume your KYC/KYB process with TruuPe. Our
              platform saves your progress, ensuring a seamless continuation of
              your journey towards compliance and success.
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Typography
            variant={isMobile ? "subtitle1" : "subtitle2"}
            sx={{ fontWeight: 500, color: "black" }}
          >
            Start your KYC/ KYB process now
          </Typography>
          <Box sx={{ borderRadius: "10px" }}>
            <Typography
              variant={isMobile ? "h6" : "body1"}
              sx={{ color: "GrayText", fontWeight: "100", width: "96%" }}
              display={{ xs: "none", sm: "block" }}
            >
              Ready to kickstart your KYC/KYB journey with TruuPe? Click the
              button below to begin the process and unlock a world of
              possibilities for your business. Our intuitive platform guides you
              through each step, ensuring a smooth and hassle-free experience.
            </Typography>
          </Box>
        </>
      );
    }
  };

  const renderActionButton = () => {
    if (
      profileData?.activity?.some(
        (activity: any) => activity.description === "KYB/KYC Process in Review"
      )
    ) {
      return null;
    } else if (isLoading) {
      return <CircularProgress sx={{ marginTop: "25px" }} size={"2rem"} />;
    } else if (data?.data?.entities?.length === 0) {
      return (
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            width: "200px",
            height: "28px",
            marginTop: {
              xs: "15px",
              sm: "15px",
              md: "10px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          Start KYC/KYB Process
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          sx={{ width: "200px", height: "28px" }}
          onClick={() => {
            if (companyType === "PRIVATE_LIMITED") {
              navigate("/kycprocess/private-public", { replace: true });
            } else if (companyType === "LIMITED_LIABILITY_PARTNERSHIP") {
              navigate("/kycprocess/limited-liability", { replace: true });
            } else if (companyType === "SOLE_PROPRIETORSHIP") {
              navigate("/kycprocess/sole-proprietorship", { replace: true });
            } else if (companyType === "PARTNERSHIP") {
              navigate("/kycprocess/partnership-firm", { replace: true });
            } else if (companyType === "OTHER") {
              navigate("/kycprocess/other-entity", { replace: true });
            } else if (companyType === "INDIVIDUAL") {
              navigate("/kycprocess/individual", { replace: true });
            }
          }}
        >
          Resume KYC/KYB Process
        </Button>
      );
    }
  };

  if (ProfileLoading) {
    return <LinearProgress />;
  } else {
    return (
      <MuiBox>
        <Tabs
          value={0}
          onChange={() => {}}
          aria-label="basic tabs example"
          sx={{
            "& .Mui-selected": {
              paddingX: 0,
              width: "max-content",
            },
            "& .MuiTabs-indicator": {
              height: "4px",
              borderRadius: "10px",
            },
            marginX: "8%",
          }}
        >
          <Tab
            label="Getting Started"
            sx={{ fontWeight: 500, paddingBottom: "2px", fontSize: "14px" }}
          />
        </Tabs>

        <CustomTabPanel value={0} index={0}>
          <Stack
            sx={{
              height: "68vh",
              paddingY: 2,
              width: "100%",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <MuiBox
              sx={{
                marginLeft: isMobile ? "5%" : "8%",
                width: isMobile ? "90%" : isTablet ? "80%" : "65%",
              }}
            >
              <Typography
                variant={isMobile ? "h4" : "h1"}
                sx={{ fontWeight: 500 }}
              >
                Welcome to TruuPe
              </Typography>
              <MuiBox
                sx={{
                  display: "flex",
                  backgroundColor: profileData?.activity?.some(
                    (activity: any) =>
                      activity.description === "KYB/KYC Process Rejected"
                  )
                    ? "white"
                    : "#e3dafb",
                  borderRadius: "10px",
                  mt: "1.5vh",
                  alignItems: "center",
                  width: isMobile ? "90vw" : isTablet ? "90vw" : "62vw",
                }}
              >
                <MuiBox
                  sx={{
                    mx: isMobile ? "1vw" : "0.5vw",
                    alignContent: "center",
                    padding: "10px 0px",
                  }}
                >
                  <img
                    src={
                      profileData?.activity?.some(
                        (activity: any) =>
                          activity.description === "KYB/KYC Process Rejected"
                      )
                        ? Icons.verificationFailed
                        : Icons.verified
                    }
                    style={{ width: isMobile ? "20vw" : "7vw" }}
                    alt="loading..."
                  />
                </MuiBox>
                <MuiBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: isMobile ? "auto" : "120px",
                    padding: isMobile ? "5px" : "0px",
                    my: {
                      xs: "15px",
                      sm: "15px",
                      md: "18px",
                      lg: "10px",
                      xl: "10px",
                    },
                  }}
                >
                  {renderVerificationStatus()}
                  {renderActionButton()}
                </MuiBox>
                <DesktopModal open={mobileOpen} onClose={handleMobileClose} />
              </MuiBox>

              <MuiBox sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                {profileData?.activity?.some(
                  (activity: any) =>
                    activity.description === "KYB/KYC Process in Review"
                ) && (
                  <>
                    {isLoading ? (
                      <CircularProgress
                        sx={{
                          marginTop: isMobile ? "6vh" : "12vh",
                          marginLeft: isMobile ? "3vw" : "6vw",
                        }}
                      />
                    ) : (
                      <BusinessDetailsCard
                        data={data?.data?.entities[0]}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    )}
                  </>
                )}
              </MuiBox>
            </MuiBox>

            <Stack
              sx={{
                width: isMobile ? "90%" : isTablet ? "60%" : "20%",
                paddingLeft: isMobile ? 0 : isTablet ? 0 : 2,
                height: "61vh",
                marginLeft: isMobile ? "5%" : isTablet ? "8%" : "0",
                marginTop: isMobile || isTablet ? "20px" : "0px",
              }}
            >
              {ProfileLoading ? (
                <CircularProgress
                  sx={{
                    marginLeft: isMobile ? "40vw" : "11vw",
                    marginTop: "11vh",
                  }}
                  size={"2rem"}
                  color="primary"
                />
              ) : (
                <>
                  {profileData?.entities?.length > 0 ? (
                    isMobile || isTablet ? (
                      <Button variant="contained" onClick={handleOpenDialog}>
                        View Timeline
                      </Button>
                    ) : (
                      <HomeTimeline timeLineData={profileData?.activity} />
                    )
                  ) : (
                    <>
                      <Typography
                        variant={isMobile ? "h6" : "subtitle1"}
                        sx={{
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Required documents for KYC/KYB
                      </Typography>
                      <MuiBox sx={{ mt: 1 }}>
                        <CarouselComponent />
                      </MuiBox>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </CustomTabPanel>
        <SelectRole handleClose={handleModelClose} open={open} data={data} />
        <Dialog open={timeLineDialogOpen} onClose={handleCloseDialog} fullWidth>
          <DialogContent>
            <HomeTimeline
              timeLineData={profileData?.activity}
              isMobile={true}
            />
          </DialogContent>
        </Dialog>
      </MuiBox>
    );
  }
};

export default KYCProcessCard;
