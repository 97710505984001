import axios from "axios";
import Cookies from "js-cookie";

// Axios instance setup
export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
});

// Intercept requests to add Authorization header
http.interceptors.request.use((config) => {
  const token = Cookies.get("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Intercept responses to handle errors
http.interceptors.response.use(
  (response) => {
    return response; // Return the response if no errors
  },
  (error) => {
    // Log the error for debugging
    console.log("Error captured: ", error);

    // Handle network errors
    if (error.message === "Network Error" && !navigator.onLine) {
      alert("Network error. Please check your internet connection.");
    }

    // Handle request cancellation
    if (axios.isCancel(error)) {
      console.log("Request was canceled: ", error.message);
      alert("Request canceled.");
    }

    // Handle 401 Unauthorized
    else if (error.response && error.response.status === 401) {
      // Clear cookies and local storage
      Cookies.remove("access_token");
      Cookies.remove("newEmail");
      Cookies.remove("otptoken");
      Cookies.remove("entityId");
      localStorage.clear();

      // Redirect to signup page
      window.location.href = "/signup";
    }

    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

// SurePass API setup
export const surePassHttp = axios.create({
  baseURL: process.env.REACT_APP_SUREPASS_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
    Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}` || "",
  },
});
