import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function DealVerificationPending({ data, secondUser }: any) {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Verification Pending
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The Deal Document is now pending final verification. Our team is in
          the process of reviewing the signed Documents. You will be notified
          once the verification is complete.
        </Typography>
      </Stack>
      <Button
        variant="contained"
        onClick={() =>
          navigate(`/deal-process/deals/${data?.code}/documentationSignee`, {
            replace: true,
          })
        }
        sx={{ width: "auto", mt: 2 }}
      >
        View Document
      </Button>
    </Box>
  );
}
