// import 'katex/dist/katex.min.css';
import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import { useMediaQuery } from "@mui/material";
import katex from "katex";

// import 'katex/dist/katex.min.css';
window.katex = katex;

type QuillProps = {
  data: string;
  handleChange: (value: any) => void;
  placeholder?: string;
  minHeight?: string;
  pageWidth?: string;
};

const Quill = ({
  data,
  handleChange,
  placeholder,
  minHeight = "200px",
  pageWidth,
}: QuillProps) => {
  const matches = useMediaQuery("(max-width: 600px)");
  const [width, setWidth] = useState("520px");
  const parser = new DOMParser();
  const ref: any = useRef();

  useEffect(() => {
    if (matches) {
      setWidth("250px");
    } else {
      setWidth("520px");
    }
  }, [matches]);

  const doc: any = parser.parseFromString(data, "text/html");

  useEffect(() => {
    if (data.length === 1000) {
      localStorage.setItem("dealdescription", data);
    }
  }, [data]);

  const modules = useMemo(
    () => ({
      toolbar: {
        clipboard: {
          matchVisual: false,
        },
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          [{ color: [] }],
          ["clean"],
          ["formula", "code"],
        ],
      },
    }),
    []
  );

  return (
    <ReactQuill
      placeholder={placeholder}
      ref={ref}
      theme={"snow"}
      style={{
        minHeight,
        width: pageWidth === "pageWidth" ? "" : width,
        outline: "none",
        borderColor: "#E0E3E7",
      }}
      value={
        data?.length <= 1000 ? data : localStorage.getItem("dealdescription")
      }
      modules={modules}
      onChange={(content: any) => {
        handleChange(content);
      }}
    />
  );
};

export default Quill;
