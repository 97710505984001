import { yupResolver } from "@hookform/resolvers/yup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/system";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import * as yup from "yup";
import { getUserProfile } from "../../../../../api/services";
import FileUploadContainer from "../../../../../components/shared/FileUploadContainer";
import { getFirmDocumentData } from "../../../../../utils/Kycprocessutils";

const stylesBefore = {
  width: "230px",
  height: "70px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "230px",
  height: "70px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

type Info = {
  date: string;
  dealTitle?: string;
  dealType?: string;
  dealStartDate?: string;
  dealDuration?: string;

  sellerName?: string;
  sellerEmail?: string;
  sellerEntityName?: string;
  sellerAddress?: string;

  buyerName?: string;
  buyerEmail?: string;
  buyerEntityName?: string;
  buyerAddress?: string;

  secondPersonIfsc?: string;
  secondPersonAccNum?: string;
  secondPersonBranch?: string;

  firstPersonIfsc?: string;
  firstPersonAccNum?: string;
  firstPersonBranch?: string;
};

const schema = yup.object().shape({
  date: yup.string().required("Director Name is required"),
});

const StyledAccordion = styled(Accordion)({
  "&:hover": { backgroundColor: "transparent" },
  boxShadow: "none",
});

const CopyText = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

type DealInfoProps = {
  data: any;
  accountOwner?: any;
  doc?: any;
  submit?: any;
};

const DealInfo = ({ data, accountOwner, doc, submit }: DealInfoProps) => {
  const [copiedAccount, setCopiedAccount] = useState(false);
  const [copiedIfsc, setCopiedIfsc] = useState(false);
  const { data: profileData } = useQuery("getUser-id", getUserProfile);
  const queryClient = useQueryClient();
  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== profileData?.data?.id
  );

  const isNonTruupeUser = secondUser?.dealInvite?.length > 0;

  const firstUser = data?.dealUsers?.find(
    (user: any) => user?.userId === profileData?.data?.id
  );

  const copyToClipboard = (text: string, setCopied: any) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3500);
    });
  };

  const duration = data?.duration > 1 ? "Months" : "Month";

  const { setValue } = useForm<Info>({
    resolver: yupResolver(schema),
    defaultValues: {
      dealTitle: data?.title || "NA",
      dealType: data?.type || "NA",
      dealStartDate: data?.createdAt || "NA",
      dealDuration: data?.duration || "NA",
      sellerName: secondUser?.user?.name || "Not Registered",
      sellerEmail:
        secondUser?.user?.emailAddress ||
        localStorage.getItem("dealEmailAddress") ||
        "Not Registered",
      sellerEntityName: secondUser?.entity?.companyName || "Not Registered",
      sellerAddress: secondUser?.entity?.registeredAddress || "Not Registered",

      buyerName: firstUser?.user?.name || "Not Registered",
      buyerEmail: firstUser?.user?.emailAddress || "Not Registered",
      buyerEntityName: firstUser?.entity?.companyName || "Not Registered",
      buyerAddress: firstUser?.entity?.registeredAddress || "Not Registered",

      secondPersonIfsc: secondUser?.banking?.ifscCode || "Not Registered",
      secondPersonAccNum:
        secondUser?.banking?.accountNumber || "Not Registered",
      secondPersonBranch: secondUser?.banking?.branch || "Not Registered",

      firstPersonIfsc: firstUser?.banking?.ifscCode || "NA",
      firstPersonAccNum: firstUser?.banking?.accountNumber || "NA",
      firstPersonBranch: firstUser?.banking?.branch || "NA",

      date: data?.date || "",
    },
  });

  const renderAccordionDetails = (
    title: string,
    details: { label: string; value: string | JSX.Element }[]
  ) => (
    <AccordionDetails>
      <Stack gap={1} sx={{ width: "100%" }}>
        {details.map((detail, index) => (
          <Grid key={index}>
            <Typography variant="caption" sx={{ color: "grey" }}>
              {detail.label}
            </Typography>
            <Typography variant="subtitle2" sx={{ marginBottom: "5px" }}>
              {detail.value}
            </Typography>
          </Grid>
        ))}
      </Stack>
    </AccordionDetails>
  );

  return (
    <Box
      sx={{
        marginBottom: {
          xs: "5%",
          sm: "5%",
          md: "0%",
          lg: "0",
          xl: "0",
        },
      }}
    >
      <StyledAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="subtitle2">Deal Information</Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        {renderAccordionDetails("Deal Information", [
          { label: "Deal Title", value: data?.title },
          { label: "Deal Type", value: data?.type },
          {
            label: "Deal Start Date",
            value: moment(data?.createdAt).format("DD/MM/YYYY"),
          },
          { label: "Deal Duration", value: `${data?.duration} ${duration}` },
          {
            label: "Expected Closure Date",
            value: moment(data?.closureDate).format("DD/MM/YYYY"),
          },
          {
            label: "Deal Description",
            value: (
              <Typography
                variant="subtitle2"
                dangerouslySetInnerHTML={{ __html: data?.description }}
                sx={{
                  // marginBottom: "3px",
                  marginBottom: -1,
                  marginTop: -1,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            ),
          },
          ...(Array.isArray(data?.dealSupportingDocuments) &&
          data?.dealSupportingDocuments.length > 0
            ? [
                {
                  label: "Supporting Documents",
                  value: (
                    <FileUploadContainer
                      typeId={data?.id}
                      actions={<></>}
                      data={"INDIVIDUAL_SELLER"}
                      typeData={"DEAL"}
                      isSupporting={"true"}
                      uploaded={true}
                      shrinked={true}
                      stylesBefore={stylesBefore}
                      stylesAfter={stylesAfter}
                      showIcons={false}
                      name={getFirmDocumentData(
                        data?.dealSupportingDocuments,
                        "INDIVIDUAL_SELLER",
                        "originalFileName"
                      )}
                      url={getFirmDocumentData(
                        data?.dealSupportingDocuments,
                        "INDIVIDUAL_SELLER",
                        "url"
                      )}
                      onFileChange={() => {
                        queryClient.invalidateQueries("deal");
                      }}
                    />
                  ),
                },
              ]
            : []),
        ])}
      </StyledAccordion>

      <StyledAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="subtitle2">
            Deal {secondUser?.userType} Information
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        {renderAccordionDetails("Deal Second User Information", [
          {
            label: `${secondUser?.userType} Name`,
            value: secondUser?.user?.name || "Not Registered",
          },
          {
            label: `${secondUser?.userType} Email Address`,
            value: isNonTruupeUser
              ? secondUser?.dealInvite[0]?.emailAddress
              : secondUser?.user?.emailAddress || "Not Registered",
          },
          {
            label: `${secondUser?.userType} Entity Name`,
            value:
              secondUser?.entity?.companyType === "INDIVIDUAL"
                ? secondUser?.user?.name || "Not Registered"
                : secondUser?.entity?.companyName || "Not Registered",
          },
          {
            label: `${secondUser?.userType} Address`,
            value: secondUser?.entity?.registeredAddress || "Not Registered",
          },
          {
            label: "Beneficiary Name",
            value:
              secondUser?.entity?.companyType === "INDIVIDUAL"
                ? secondUser?.banking?.beneficiaryName || "Not Registered"
                : secondUser?.entity?.companyName || "Not Registered",
          },
          {
            label: "Bank Name",
            value: secondUser?.banking?.name || "Not Registered",
          },
          {
            label: "Account Number",
            value: (
              <Stack direction="row" gap="10px">
                <Typography variant="subtitle2" sx={{ marginBottom: "5px" }}>
                  {secondUser?.banking?.accountNumber || "Not Registered"}
                </Typography>
                {!isNonTruupeUser && (
                  <CopyText
                    variant="subtitle2"
                    onClick={() =>
                      copyToClipboard(
                        secondUser?.banking?.accountNumber,
                        setCopiedAccount
                      )
                    }
                  >
                    {/* <ContentCopyIcon
                      fontSize="small"
                      sx={{ marginRight: "4px" }}
                    />
                    {copiedAccount ? "Copied!" : "Copy"} */}
                    {copiedAccount ? (
                      <DoneAllIcon
                        sx={{ fontSize: "15px", marginRight: "4px" }}
                      />
                    ) : (
                      <ContentCopyIcon
                        sx={{ fontSize: "15px", marginRight: "4px" }}
                      />
                    )}
                  </CopyText>
                )}
              </Stack>
            ),
          },
          {
            label: "Bank IFSC CODE",
            value: (
              <Stack direction="row" gap="10px">
                <Typography variant="subtitle2" sx={{ marginBottom: "5px" }}>
                  {secondUser?.banking?.ifscCode || "Not Registered"}
                </Typography>
                {!isNonTruupeUser && (
                  <CopyText
                    variant="subtitle2"
                    onClick={() =>
                      copyToClipboard(
                        secondUser?.banking?.ifscCode,
                        setCopiedIfsc
                      )
                    }
                  >
                    {/* <ContentCopyIcon
                      fontSize="small"
                      sx={{ marginRight: "4px" }}
                    />
                    {copiedIfsc ? "Copied!" : "Copy"} */}
                    {copiedIfsc ? (
                      <DoneAllIcon
                        sx={{ fontSize: "15px", marginRight: "4px" }}
                      />
                    ) : (
                      <ContentCopyIcon
                        sx={{ fontSize: "15px", marginRight: "4px" }}
                      />
                    )}
                  </CopyText>
                )}
              </Stack>
            ),
          },
        ])}
      </StyledAccordion>
    </Box>
  );
};

export default DealInfo;
