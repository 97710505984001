import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../../../../api/services";
import {
  dealtransaction,
  getDealsDataById,
} from "../../../../../../api/services/deals";
import DealInformation from "./Dealinformation";

import { Deal } from "../../../../../../interfaces/deal";
import { DealBox, DealCard } from "../../../../../../styles/styled-components";
import DealTop from "../../DealTop";
import AccountCard from "../accountCard/Escrowcard";
import EscrowTransactionsCards from "../accountCard/EscrowTransactionsCards.tsx";
import TransactionTableSortable from "../transactions/ledger/TransactionTableSortable";

const EscrowTransactions = () => {
  const navigate = useNavigate();
  const { dealId } = useParams();
  const { data: profileData } = useQuery("getUser-id", getUserProfile);
  const { data: transactionData, isLoading: transactionLoading } = useQuery(
    ["dealTransactions", dealId],
    dealtransaction
  );
  const { data: dealData, isLoading: dealLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deal-process/deals/all");
      },
    }
  );

  const deal: Deal = dealData?.data;

  const BankDetails = deal?.bankingDetails;

  // const isBuyer =
  //   deal?.dealUsers?.find((user: any) => user?.userId === profileData?.data?.id)
  //     ?.userType === "Buyer";

  if (dealLoading || transactionLoading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <DealBox>
      <DealCard>
        <DealTop
          dealData={deal}
          profileData={profileData?.data}
          dealCode={deal?.code}
          breadcrumbs={[
            { label: "Deals", href: "/deal-process/deals/all" },
            { label: dealId, href: `/deal-process/deals/${dealId}` },
            {
              label: "Truupe Account",
              href: `/deal-process/deals/${dealId}/truupe-account`,
            },
          ]}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
            gap: 3,
            height: "100%",
            padding: {
              xs: "3%",
              sm: "3%",
              md: 2,
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" align="left">
              Account Summary
            </Typography>
            <AccountCard bankDetails={BankDetails} dealData={dealData} />
          </Box>

          <Box sx={{ flex: 2 }}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h4" align="left" sx={{ mb: 2 }}>
                Deal Information
              </Typography>
              {/* {(isBuyer || true) && (
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    navigate(`/deal-process/deals/${dealId}/request-received`);
                  }}
                >
                  {isMobile ? " View Request" : " View Payment Request"}
                </StyledButton>
              )} */}
            </Box>
            <DealInformation dealData={dealData} profileData={profileData} />
          </Box>
        </Box>

        <Box>
          <EscrowTransactionsCards dealData={dealData} dealId={dealId} />
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "block", lg: "block", xl: "block" },
          }}
        >
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" align="left" sx={{ mb: 2 }}>
                Transactions
              </Typography>
              {/* <Button variant="outlined">
                <IosShareRoundedIcon sx={{ fontSize: 18, mr: 1 }} />
                Export
              </Button> */}
            </Box>
          </Box>
          <TransactionTableSortable
            dealData={dealData?.data}
            transactionData={transactionData}
          />
        </Box>
        <Box
          width={"100%"}
          justifyContent={"center"}
          sx={{
            display: { xs: "flex", sm: "none", lg: "none", xl: "none" },
            marginBottom: { xs: "5%", sm: "0px", lg: "0px", xl: "0px" },
          }}
        >
          <Button
            sx={{ width: "200px" }}
            variant="contained"
            onClick={() => {
              navigate(
                `/deal-process/deals/${dealId}/truupe-account/transactions`
              );
            }}
          >
            {" "}
            View Transactions
          </Button>
        </Box>
      </DealCard>
    </DealBox>
  );
};

export default EscrowTransactions;
