import { http, surePassHttp } from "../http";

// Signup apis
export const requestOtp = (body: any) => {
  return http.post("auth/request-otp", body);
};

export const verifyotp = (body: any) => {
  return http.post("auth/verify-otp", body);
};

export const gamillogin = (body: any) => {
  return http.post("auth/verify-otp", body);
};

export const Signup = (body: any) => {
  return http.post("/auth/sign-up", body);
};

export const loginwithgmail = (body: any) => {
  return http.post("/auth/generate-token", body);
};

//User apis

export const createuser = (body: any) => {
  return http.post("/users", body);
};

export const getusers = (body: any) => {
  return http.get("/users", body);
};

export const getusersbyid = ({ queryKey }: any) => {
  return http.get(`/users/${queryKey[1]}`);
};

export const istermsaccepted = (body: any) => {
  return http.put(`users/accept-terms`, body);
};

export const getUserProfile = () => {
  return http.get("/users/profile");
};

export const userActivity = (body: any) => {
  return http.post("/user-activity", body);
};

export const userLocation = (body: any) => {
  return http.put("/users/location", body);
};

//KYC/KYB process apis
export const AddCompanyDetails = (body: any) => {
  return http.post("/entity", body);
};
export const MapDocuments = (body: any) => {
  return http.post("/entity/documents", body);
};
export const MapDirectorDocuments = (data: any) => {
  return http.post(`/director/documents`, data);
};
export const AddDirectorDetails = (body: any) => {
  return http.post("/director", body);
};

export const updateDirectorDetails = ({ id, body }: any) => {
  return http.put(`/director/${id}`, body);
};
export const AddbankingInformation = (body: any) => {
  return http.post("/banking", body);
};

export const getComapanyDetailsById = ({ queryKey }: any) => {
  return http.get(`/entity/${queryKey[1]}`);
};

export const VerifyIfscCode = (ifscCode: any) => {
  return http.get(`/banking/verify/${ifscCode}`);
};

export const getStatusbyId = ({ queryKey }: any) => {
  return http.get(`/entity/status/${queryKey[1]}`);
};

export const getFirmAddressProof = () => {
  return http.get(`/entity/address-proof`);
};

export const getDirectorAddressProof = () => {
  return http.get(`/director/address-proof`);
};

export const getEntityPreview = ({ queryKey }: any) => {
  return http.get(`/entity/preview/${queryKey[1]}`);
};

export const deleteComapanyDetailsById = (id: any) => {
  return http.delete(`/entity/${id}`);
};

export const surepassValidations = (body: any) => {
  return http.post(`/entity/validate`, body);
};

export const getSignatoryAuthority = ({ entityid, body }: any) => {
  return http.put(`entity/${entityid}/signatory`, body);
};

export const updateBankingDetails = ({ entityid, body }: any) => {
  return http.put(`entity/${entityid}/banking`, body);
};

export const SignatoryMobileEmailOtpGen = (body: any) => {
  return http.post(`auth/request-otp`, body);
};

export const SignatoryMobileEmailOtpVerify = (body: any) => {
  return http.post(`/auth/verify-otp`, body);
};

export const KycKybSubmitted = ({ id, body }: any) => {
  return http.put(`/entity/${id}/status`, body);
};

//surepass Apis

export const getCinDetails = (body: any) => {
  return surePassHttp.post("/api/v1/corporate/company-details", body);
};

export const getGstDetails = (body: any) => {
  return surePassHttp.post("/api/v1/corporate/gstin", body);
};

export const getAadharOtp = (body: any) => {
  return surePassHttp.post("/api/v1/aadhaar-v2/generate-otp", body);
};

export const verifyAadharOtp = (body: any) => {
  return surePassHttp.post("/api/v1/aadhaar-v2/submit-otp", body);
};

export const getPanDetails = (body: any) => {
  return surePassHttp.post("/api/v1/pan/pan-comprehensive", body);
};

export const getLlpinDetails = (body: any) => {
  return surePassHttp.post("/banking", body);
};

export const verifyBankDetails = (body: any) => {
  return surePassHttp.post("/api/v1/bank-verification/", body);
};

// Profile page apis

export const getEntityDetails = (id: string) => {
  return http.get(`/entity/${id}`);
};

export const updateKycDocs = (body: any) => {
  return http.put(`/entity/update-docs`, body);
};
