import { Box } from "@mui/material";
import { useQuery } from "react-query";
import styled from "styled-components";

import DealCompleted from "./dealCompleted/DealCompleted";
import AwaitingEscrowFunds from "./feePending/AwaitingEscrowFunds";

import InvitationCancelled from "./dealCancelled/InvitationCancelled";

import { getUserProfile } from "../../../../../api/services";
import InvitationRejected from "./dealRejected/InvitationRejected";
import Default from "./default/Default";
import DealFeePending from "./feePending/FeePending";
import FundsAwaited from "./fundsAwaited/FundsAwaited";
import FundsAwaitedSeller from "./fundsAwaited/FundsAwaitedSeller";
import InitiatePayment from "./fundsReceived/InitiatePayment";
import RequestPayment from "./fundsReceived/RequestPayment";
import InvitationReceived from "./invitationIssued/DealInvitationReceived";
import InvitationSent from "./invitationIssued/DealInvitationSent";
import UnderVerification from "./paymentVerification/UnderVerification";
import DealSignaturePending from "./signaturePending/dealAccepted/DealSignaturePending";
import DealSignatureAwaiting from "./signaturePending/DealSignatureAwaiting";
import DealVerificationPending from "./verificationPending/DealVerificationPending";

const StyledBox = styled(Box)`
  margin: 20px;
`;

const getUserRelatedData = ({ data, profileData }: any) => {
  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== profileData?.data?.id
  );

  const dealCreator = data?.createdBy?.id === profileData?.data?.id;

  const profileUser = data?.dealUsers?.find(
    (user: any) => user?.userId === profileData?.data?.id
  );

  const isUserDocumentSigned =
    data?.dealSignatures?.find(
      (signature: any) => signature?.userId === profileData?.data?.id
    )?.status === "Completed";

  return { secondUser, dealCreator, profileUser, isUserDocumentSigned };
};

const renderComponentBasedOnStatus = (
  status: any,
  dealCreator: any,
  profileUser: any,
  data: any,
  secondUser: any,
  isUserDocumentSigned: any
) => {
  switch (status) {
    case "Invitation Issued":
      return dealCreator ? (
        <InvitationSent data={data} secondUser={secondUser} />
      ) : (
        <InvitationReceived data={data} secondUser={secondUser} profileUser={profileUser}/>
      );
    case "Deal Accepted":
      return <DealSignaturePending data={data} secondUser={secondUser} />;
    case "Deal Rejected":
      return <InvitationRejected data={data} secondUser={secondUser} />;
    case "Signature Awaiting":
      return <InvitationSent data={data} secondUser={secondUser} />;
    case "Signature Pending":
      return isUserDocumentSigned ? (
        <DealSignatureAwaiting data={data} secondUser={secondUser} />
      ) : (
        <DealSignaturePending data={data} secondUser={secondUser} />
      );
    case "Deal Cancelled":
      return <InvitationCancelled data={data} secondUser={secondUser} />;
    case "Verification Pending":
      return <DealVerificationPending data={data} secondUser={secondUser} />;
    case "Fee Pending":
      return profileUser?.userType === "Buyer" ? (
        <DealFeePending data={data} secondUser={secondUser} />
      ) : (
        <AwaitingEscrowFunds data={data} secondUser={secondUser} />
      );
    case "Funds Awaited":
      return profileUser?.userType === "Buyer" ? (
        <FundsAwaited data={data} secondUser={secondUser} pay={true} />
      ) : (
        <FundsAwaitedSeller data={data} secondUser={secondUser} />
      );
    case "Funds Received":
      return profileUser?.userType === "Buyer" ? (
        <InitiatePayment data={data} secondUser={secondUser} pay={true} />
      ) : (
        <RequestPayment data={data} secondUser={secondUser} />
      );
    case "Payment Verification":
      return (
        <UnderVerification
          data={data}
          secondUser={secondUser}
          pay={profileUser?.userType === "Buyer"}
        />
      );
    case "Deal Completed":
      return <DealCompleted />;
    default:
      return <Default />;
  }
};

type DealNextStepsProps = {
  data: any;
  accountOwner: any;
};

export default function DealNextSteps({
  data,
  accountOwner,
}: DealNextStepsProps) {
  const { data: profileData } = useQuery("getUser-id", getUserProfile);

  const { secondUser, dealCreator, profileUser, isUserDocumentSigned } =
    getUserRelatedData({ data, profileData });

  return (
    <StyledBox>
      {renderComponentBasedOnStatus(
        data?.status,
        dealCreator,
        profileUser,
        data,
        secondUser,
        isUserDocumentSigned
      )}
    </StyledBox>
  );
}
