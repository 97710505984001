import { Button } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

interface GetDetailsProps {
  onclick: () => void;
  title: string;
}

const StyledButton = styled(Button)({
  maxHeight: "30px",
  fontSize: "12px",
});

const GetDetailsButton: React.FC<GetDetailsProps> = ({ onclick, title }) => {
  return (
    <StyledButton variant="contained" onClick={onclick}>
      {title}
    </StyledButton>
  );
};

export default GetDetailsButton;
