import { googleLogout } from "@react-oauth/google";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const logout = () => {
  Cookies.remove("newEmail");
  Cookies.remove("access_token");
  Cookies.remove("otptoken");
  Cookies.remove("access_token");
  Cookies.remove("entityId");
  localStorage.clear();
  googleLogout();
};

export const getUserId = () => {
  const accessToken: any = Cookies.get("access_token");
  const userData: any = jwtDecode(accessToken);
  return userData?.userId;
};
