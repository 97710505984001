import { Box, LinearProgress, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getComapanyDetailsById,
  getFirmAddressProof,
  getStatusbyId,
  userActivity,
} from "../../../api/services";

import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import DocumentUploader from "../../../components/shared/DocumentUploader";
import DocumentSelect from "./DocumentSelect";

const EntityDocuments = ({ onNextClick, tick }: any) => {
  const [togglesave, settogglesave] = useState(false);
  const EntityId = Cookies.get("entityId");
  const queryClient = useQueryClient();
  const [businessDoc1, setBusinessDoc1] = useState<string | null>(null);
  const [businessDoc2, setBusinessDoc2] = useState<string | null>(null);
  const [businessAddressProof, setBusinessAddressProof] = useState<
    string | null
  >(null);
  const [isFileDeleted, setIsFileDeleted] = useState(false);

  const { data: FirmAddressProof } = useQuery(
    "FirmAddressProof",
    getFirmAddressProof
  );

  const { data: CompanyDetails, isLoading: isCompanyDetailsLoading } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById
  );

  const DOCUMENT_TYPES = [
    "REGISTRATION_CERTIFICATE ",
    "SHOP_ESTABLISHMENT_LICENSE",
    "INCOME_TAX_RETURNS",
    "CST_CERTIFICATE",
    "VAT_CERTIFICATE",
    "GST_CERTIFICATE",
    "TAX_REGISTRATION_CERTIFICATE",
    "IMPORTER_EXPORTER_CODE",
    "PROFESSIONAL_LICENSE",
    "COMPLETE_INCOME_TAX_RETURN",
  ];

  const handleDoc1Change = (event: any) => {
    setBusinessDoc1(event.target.value);
  };

  const handleDoc2Change = (event: any) => {
    setBusinessDoc2(event.target.value);
  };

  const handleAddressProofChange = (event: any) => {
    setBusinessAddressProof(event.target.value);
  };

  const getFilteredDocumentOptions = (excludeDoc: any) =>
    DOCUMENT_TYPES.filter((doc) => doc !== excludeDoc);

  const filteredDocOptions1 = getFilteredDocumentOptions(businessDoc2);
  const filteredDocOptions2 = getFilteredDocumentOptions(businessDoc1);

  const firmDocuments = CompanyDetails?.data?.firmDocuments || [];

  // Function to extract fileType based on subtype
  const getFileTypeBySubtype = (documents: any[], subtype: string) => {
    const document = documents.find(
      (doc: any) => doc.subtype === subtype && doc.filetype !== "null"
    );
    return document ? document.filetype : null;
  };

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {},
  });

  const { data: statusDetails } = useQuery(
    ["status-data", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    const subtypes = CompanyDetails?.data?.firmDocuments?.map(
      (document: any) => document?.subtype
    );

    setBusinessDoc1(getFileTypeBySubtype(firmDocuments, "BUSINESS_DOCUMENT_1"));
    setBusinessDoc2(getFileTypeBySubtype(firmDocuments, "BUSINESS_DOCUMENT_2"));
    setBusinessAddressProof(
      getFileTypeBySubtype(firmDocuments, "ADDRESS_PROOF")
    );

    const requiredFiles = [
      "LOA",
      "BUSINESS_DOCUMENT_1",
      "BUSINESS_DOCUMENT_2",
      "ADDRESS_PROOF",
    ];

    const allDocsPresent = subtypes?.every((subtype: any) =>
      requiredFiles.includes(subtype)
    );

    const exactMatch =
      subtypes?.length === requiredFiles.length && allDocsPresent;

    if (exactMatch) {
      settogglesave(true);
    } else {
      settogglesave(false);
    }
  }, [CompanyDetails]);

  const onSubmit = () => {
    toast.success("Information updated Successfully");
    queryClient.invalidateQueries("entity");
    queryClient.invalidateQueries("status-data");
    queryClient.invalidateQueries("getuser-id");
    const accessToken: any = Cookies.get("access_token");
    const userdata: any = jwtDecode(accessToken);
    const draftdata = {
      userId: userdata?.userId,
      activity: "KYB/KYC Process in Draft",
      entityId: EntityId,
    };
    useractivity(draftdata);
  };

  const companyDocuments = CompanyDetails?.data?.firmDocuments || [];
  const documentList = [
    {
      label: "Letter of Authorization",
      subtype: "LOA",
      showTemplate: true,
      templateUrl:
        "https://truupe-staging-assets.s3.ap-south-1.amazonaws.com/docs/SOLE_P_LOA.docx",
      customMessage:
        "Update your details, please re-upload the signed document",
    },
  ];

  if (isCompanyDetailsLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: "64vw",
          height: "57.9vh",
          paddingLeft: 4,
          mt: 2,
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <form>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", color: "black" }}
            gutterBottom
          >
            Entity Documents
          </Typography>
          {documentList.map((doc) => (
            <DocumentUploader
              key={doc.subtype}
              documentLabel={doc.label}
              subtype={doc.subtype}
              entityId={EntityId}
              statusDetails={statusDetails}
              documents={companyDocuments}
              onFileChange={() =>
                queryClient.invalidateQueries("getcompanydetails")
              }
              showTemplate={doc.showTemplate}
              templateUrl={doc.templateUrl}
              customMessage={doc.customMessage}
            />
          ))}

          {/* BUSINESS DOCUMENT 1 */}

          <DocumentSelect
            value={businessDoc1}
            onChange={handleDoc1Change}
            options={filteredDocOptions1}
            disabled={statusDetails?.data?.documents}
            label="BUSINESS DOCUMENT 1"
            fileTitle="DOCUMENT 1"
            entityId={EntityId}
            fileType="BUSINESS_DOCUMENT_1"
            firmDocuments={firmDocuments}
            status={statusDetails?.data?.documents}
            setIsFileDeleted={setIsFileDeleted}
            queryKey={() => queryClient.invalidateQueries("getcompanydetails")}
          />

          {/* BUSINESS DOCUMENT 2 */}

          <DocumentSelect
            value={businessDoc2}
            onChange={handleDoc2Change}
            options={filteredDocOptions2}
            disabled={statusDetails?.data?.documents}
            label="BUSINESS DOCUMENT 2"
            entityId={EntityId}
            fileType="BUSINESS_DOCUMENT_2"
            firmDocuments={firmDocuments}
            status={statusDetails?.data?.documents}
            setIsFileDeleted={setIsFileDeleted}
            queryKey={() => queryClient.invalidateQueries("getcompanydetails")}
          />

          {/* BUSINESS ADDRESS PROOF */}

          <DocumentSelect
            value={businessAddressProof}
            onChange={handleAddressProofChange}
            options={FirmAddressProof?.data || []}
            disabled={statusDetails?.data?.documents}
            label="BUSINESS ADDRESS PROOF"
            entityId={EntityId}
            fileType="ADDRESS_PROOF"
            firmDocuments={firmDocuments}
            status={statusDetails?.data?.documents}
            setIsFileDeleted={setIsFileDeleted}
            queryKey={() => queryClient.invalidateQueries("getcompanydetails")}
          />
        </form>

        {!statusDetails?.data?.documents && (
          <Typography
            variant="body2"
            sx={{
              color: "#800000",
              my: "25px",
              fontStyle: "italic",
            }}
          >
            Please confirm that you have uploaded all the required documents for
            the {CompanyDetails?.data?.companyName} for KYB/KYC
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "3.4vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        <CustomLoadingButton
          // submitLoader={submitloader}
          onClick={() => onSubmit()}
          isDisabled={
            !togglesave || statusDetails?.data?.documents || isFileDeleted
          }
          title="Next"
        />
      </Box>
    </>
  );
};

export default EntityDocuments;
