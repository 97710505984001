import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box, Card, Collapse, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { DealUser } from "../../../../../../interfaces/deal";
import {
  formatIndianIntegers,
  stripHTML,
} from "../../../../../../utils/dealFunctions";
import Status from "../../dealStatus/DealStatus";

interface TransactionsCardProps {
  transaction: any;
  dealData: any;
  expanded: boolean;
  handleExpandClick: () => void;
}

export default function TransactionsCard({
  transaction,
  dealData,
  expanded,
  handleExpandClick,
}: TransactionsCardProps) {
  const [copiedTransactionId, setCopiedTransactionId] = React.useState(false);

  let seller: any = null;
  let buyer: any = null;

  dealData?.dealUsers?.forEach((user: any) => {
    if (user.userType === "Seller") {
      seller = user;
    } else if (user.userType === "Buyer") {
      buyer = user;
    }
  });

  const getTransactionRoute = (
    transaction: any,
    buyer: DealUser,
    seller: DealUser
  ) => {
    const getName = (entity: any, user: any) =>
      entity?.companyType === "INDIVIDUAL" ? user?.name : entity?.companyName;

    switch (transaction?.type) {
      case "Debit":
        return `Truupe to ${getName(seller?.entity, seller?.user)}`;
      case "Refund":
        return `Truupe to ${getName(buyer?.entity, buyer?.user)}`;
      case "Credit":
        return `${getName(buyer?.entity, buyer?.user)} to Truupe`;
      default:
        return "";
    }
  };

  const copyToClipboard = (text: string, setCopied: any) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3500);
    });
  };

  return (
    <Card
      sx={{
        margin: "10px",
        borderRadius: "10px",
        padding: "10px",
        maxWidth: "600px",
        cursor: "pointer",
        transition: "max-height 1s ease-in-out",
      }}
    >
      {/* Collapsed state content */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleExpandClick}
      >
        {/* Left side: Transaction route and Date */}
        <Box marginLeft="2%">
          <Typography variant="subtitle1" fontWeight="400">
            {getTransactionRoute(transaction, buyer, seller)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {moment(transaction?.createdAt).format("DD MMM YYYY [at] hh:mma")}
          </Typography>
        </Box>

        {/* Right side: Transaction amount and Status */}
        <Box textAlign="right" marginRight="2%">
          <Typography
            variant="h6"
            color={
              transaction.type === "Credit"
                ? "green"
                : transaction.type === "Debit"
                  ? "red"
                  : transaction.type === "Refund"
                    ? "orange"
                    : "green"
            }
          >
            ₹ {formatIndianIntegers(transaction?.amount)}
          </Typography>
        </Box>
      </Stack>

      {/* Expanded state content */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack padding={"3%"} paddingTop={"2%"}>
          <Stack marginTop={"10px"}>
            <Typography variant="subtitle2" color={"gray"}>
              Transaction ID
            </Typography>
            <Stack direction={"row"} gap={"15px"}>
              <Typography
                variant="subtitle2"
                color={"black"}
                fontWeight={"400"}
              >
                {transaction?.type === "Credit"
                  ? transaction?.utr
                  : transaction?.crn}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  color: "#7643EB",
                }}
                onClick={() =>
                  copyToClipboard(
                    transaction?.type === "Credit"
                      ? transaction?.utr
                      : transaction?.crn,
                    setCopiedTransactionId
                  )
                }
              >
                {/* <ContentCopyIcon
                  sx={{ fontSize: "15px", marginRight: "4px" }}
                />
                {copiedTransactionId ? "Copied!" : "Copy"} */}
                {copiedTransactionId ? (
                  <DoneAllIcon sx={{ fontSize: "15px", marginRight: "4px" }} />
                ) : (
                  <ContentCopyIcon
                    sx={{ fontSize: "15px", marginRight: "4px" }}
                  />
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack marginTop={"10px"}>
            <Typography variant="subtitle2" color={"gray"}>
              Transaction Type
            </Typography>
            <Typography
              variant="subtitle2"
              color={
                transaction.type === "Credit"
                  ? "green"
                  : transaction.type === "Debit"
                    ? "red"
                    : transaction.type === "Refund"
                      ? "orange"
                      : "green"
              }
            >
              {transaction?.type}
            </Typography>
          </Stack>
          <Stack marginTop={"10px"}>
            <Typography variant="subtitle2" color={"gray"}>
              Status
            </Typography>
            <Typography variant="body2">
              <Status variant={transaction?.status || "NA"} />
            </Typography>
          </Stack>
          <Stack marginTop={"10px"}>
            <Typography variant="subtitle2" color={"gray"}>
              Remarks
            </Typography>
            <Typography variant="subtitle2" color={"black"} fontWeight={"400"}>
              {stripHTML(transaction?.statusHistory[0]?.remarks) || "NA"}
            </Typography>
          </Stack>
        </Stack>
      </Collapse>
    </Card>
  );
}
