import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SimpleCheckoBox from "../../../../components/formFields/SimpleCheckoBox";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";

interface IndividualSellerModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  isLoading?: boolean;
  uploadKey: (key: string | null) => void;
}

const stylesBefore = {
  width: "220px",
  height: "90px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "220px",
  height: "90px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

const IndividualSellerModal: React.FC<IndividualSellerModalProps> = ({
  open,
  onClose,
  onConfirm,
  message,
  isLoading = false,
  uploadKey,
}) => {
  const [isFileDeleted, setIsFileDeleted] = useState(true);
  const { control, watch } = useForm({
    defaultValues: {
      infoCheck: false,
    },
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const infoCheck = watch("infoCheck");
  // const isConfirmEnabled = !isFileDeleted && infoCheck;
  const isConfirmEnabled = infoCheck;

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <Box p={2}>
        <Typography color="primary" gutterBottom variant="subtitle1">
          Confirmation
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
        <Typography variant="body1" paragraph align="left" textAlign="justify">
          <ol type="1">
            <li>
              You acknowledge that you have read, understood, and agreed to be
              bound by Truupe Terms of Use and Privacy Policy.
            </li>
            <li>
              These terms govern your use of the Truupe Platform and any related
              services.
            </li>
            <li>
              If you do not agree to these terms, please do not use the website.
            </li>
          </ol>
        </Typography>

        <Box mt={2} ml={3}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ marginTop: "20px" }}
          >
            Upload Supporting Document (if any)
          </Typography>
          <FileUploadContainer
            typeData="DEAL"
            data="INDIVIDUAL_SELLER"
            uploaded={false}
            url=""
            actions={null}
            status={false}
            isSupporting={"true"}
            onFileChange={(key) => {
              if (key === "") {
                setIsFileDeleted(true);
              } else {
                setIsFileDeleted(false);
                uploadKey(key);
              }
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Box>

        <Box mt={2} ml={2}>
          <Controller
            name="infoCheck"
            control={control}
            render={({ field }) => (
              <SimpleCheckoBox
                setInfocheck={field.onChange}
                control={control}
                text="I accept all the terms and conditions."
                key="5"
              />
            )}
          />
        </Box>

        <Box mt={3} display="flex" gap={2} justifyContent="flex-end">
          {isLoading ? (
            <LoadingButton
              loading
              loadingPosition="center"
              startIcon={<SaveIcon />}
              variant="outlined"
              sx={{
                border: "1px solid black",
                "& .MuiCircularProgress-root": {
                  color: "#7643EB",
                },
                "&.MuiButton-outlined": {
                  border: "2px solid #7643EB",
                },
              }}
            />
          ) : (
            <Button variant="outlined" size="small" onClick={onClose}>
              Cancel
            </Button>
          )}

          <Button
            variant="contained"
            size="small"
            onClick={onConfirm}
            disabled={!isConfirmEnabled}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default IndividualSellerModal;
