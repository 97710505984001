import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  getComapanyDetailsById,
  getStatusbyId,
  updateBankingDetails,
  userActivity,
} from "../../../api/services";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import EntityBankingForm from "./EntityBankingForm";
import InputField from "../../../components/formFields/kycFields/InputField";

interface Bank {
  id?: any;
  name: string;
  accountNumber: string;
  branch: string;
  ifscCode: string;
  beneficiaryName: string;
  cancelledCheque: any;
  isnew: boolean;
}

const schema = yup.object().shape({
  ifscCode: yup
    .string()
    .typeError("IFSC Code must be a string")
    .required("IFSC Code is required"),
  bankAccountNumber: yup
    .number()
    .typeError("Bank Account Number must be a number")
    .required("Bank Account Number is required"),
  bankName: yup
    .string()
    .typeError("Bank Name must be a string")
    .required("Bank Name is required"),
  branchName: yup
    .string()
    .typeError("Branch Name must be a string")
    .required("Branch Name is required"),
  beneficiaryName: yup
    .string()
    .typeError("Beneficiary Name must be a string")
    .required("Beneficiary Name is required"),
});

const EntityBankingInformation = () => {
  const EntityId = Cookies.get("entityId");
  const [BankingDetails, setBankingDetails] = useState<Bank[]>([]);
  const [isdataSaved, setisDataSaved] = useState(true);
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const { data: CompanyDetails, isLoading: companyLoading } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      onSuccess: (res: any) => {
        setValue("numberOfBanks", res?.data?.bankingInformation?.length);
      },
    }
  );

  const { data: statusDetails } = useQuery(
    ["status", EntityId],
    getStatusbyId,
    { enabled: !!EntityId }
  );

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: () => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: updateBanking, isLoading } = useMutation(
    updateBankingDetails,
    {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries("status-data");
        queryClient.invalidateQueries("getcompanydetails");
      },
      onError: (error: any) => {},
    }
  );

  const deleteBankingForm = (index: number) => {
    const noofBanks = getValues("numberOfBanks");
    setValue("numberOfBanks", noofBanks - 1);
    setBankingDetails((prevBanks) => {
      const newBanks = [...prevBanks];
      for (let i = index; i < newBanks.length - 1; i++) {
        newBanks[i] = newBanks[i + 1];
      }
      newBanks.length = newBanks.length - 1;
      return newBanks;
    });
  };

  const addBankingForm = () => {
    const noofBanks = getValues("numberOfBanks");
    setValue("numberOfBanks", noofBanks + 1);
    setBankingDetails([
      ...BankingDetails,
      {
        isnew: true,
        name: "",
        accountNumber: "",
        branch: "",
        ifscCode: "",
        beneficiaryName: "",
        cancelledCheque: "",
      },
    ]);
  };

  useEffect(() => {
    if (
      CompanyDetails &&
      CompanyDetails?.data &&
      CompanyDetails?.data?.bankingInformation
    ) {
      const bankingData = CompanyDetails?.data?.bankingInformation?.map(
        (banking: any) => {
          return {
            id: banking?.id || "",
            accountNumber: banking?.accountNumber || "",
            branch: banking?.branch || "",
            entityId: banking?.entityId || "",
            ifscCode: banking?.ifscCode || "",
            name: banking?.name || "",
            beneficiaryName: banking?.beneficiaryName || "",
            cancelledCheque: banking?.relatedDocs[0] || "",
            isDataSaved: banking?.isBankingSaved,
          };
        }
      );
      setBankingDetails(bankingData);
    }
    const areAllVerified = CompanyDetails?.data?.signatoryDirectors?.every(
      (director: any) => director?.isEmailVerified && director?.isMobileVerified
    );
    setisDataSaved(!areAllVerified);
  }, [CompanyDetails?.data?.signatoryDirectors, CompanyDetails]);

  useEffect(() => {
    if (BankingDetails?.length < 1) {
      addBankingForm();
    }
  }, [BankingDetails]);

  const onSubmit = () => {
    const arrayOfBankingDetails = CompanyDetails?.data?.bankingInformation?.map(
      (item: any) => item?.id
    );

    if (arrayOfBankingDetails?.length === 0) {
      toast.error("Please add Banking details to continue the KYB/KYC process");
    } else if (arrayOfBankingDetails.length > 0) {
      updateBanking({
        entityid: EntityId,
        body: {
          bankingIds: arrayOfBankingDetails,
          isBankingAdded: true,
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: { xs: "100%", md: "64vw" },
          height: { xs: "52vh", md: "57.9vh" },
          px: { xs: 0, md: 2 },
          mt: "1vh",
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            my: "1vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Banking Information</Typography>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              gap: "20px",
            }}
          >
            {!statusDetails?.data?.banking && (
              <Button variant="outlined" onClick={addBankingForm}>
                Add Banking Details
              </Button>
            )}
          </Box>
        </Box>

        <>
          {companyLoading ? (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {false && (
                <Controller
                  name="numberOfBanks"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={true}
                      field={field}
                      iD={"numberOfBanks"}
                      name={"Number Of Banks"}
                      errors={errors}
                    />
                  )}
                />
              )}

              {BankingDetails.map((banking: any, index: any) => {
                return (
                  <EntityBankingForm
                    index={index}
                    typeId={EntityId}
                    CompanyDetails={CompanyDetails}
                    statusDetails={statusDetails}
                    subType={"CANCELLED_CHEQUE"}
                    typeData="BANKING"
                    popBanking={() => deleteBankingForm(index)}
                    id={banking?.id}
                    name={banking?.name}
                    accountNumber={banking?.accountNumber}
                    branch={banking?.branch}
                    ifscCode={banking?.ifscCode}
                    beneficiaryName={banking?.beneficiaryName}
                    cancelledCheque={banking?.cancelledCheque}
                    isnew={banking?.isnew}
                    isDataSaved={banking?.isDataSaved || false}
                    BankingData={CompanyDetails?.data?.bankingInformation || []}
                  />
                );
              })}
            </Box>
          )}
        </>
      </Box>
      <Box
        sx={{
          paddingY: 2,
          marginTop: "3.5vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        <CustomLoadingButton
          onClick={onSubmit}
          isDisabled={
            statusDetails?.data?.banking ||
            getValues("numberOfBanks") !==
              CompanyDetails?.data?.bankingInformation?.length
            // isBankingAdded
          }
          title="Next"
        />
      </Box>
    </>
  );
};
export default EntityBankingInformation;
