import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OTPInput from "otp-input-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { CustomLoadingButton } from "../../../../../../components/buttons/SubmitLoaderButton";
import "./OtpModal.css";

interface OtpModalProps {
  Text: string;
  open: boolean;
  handleVerify: (otp: string) => void;
  ResendOTP: () => void;
  mobileNumber: any;
  isSignup: boolean;
  isRegister: boolean;
  BuyerBankDetails?: any;
  setOpen?: any;
  isLoad?: boolean;
  cancelTransaction?: any;
}

const PaymentOtpVerification: React.FC<OtpModalProps> = ({
  open,
  handleVerify,
  ResendOTP,
  mobileNumber,
  BuyerBankDetails,
  isLoad,
  cancelTransaction,
}) => {
  const { handleSubmit } = useForm();
  const [otp, setOtp] = useState("");

  const handleVerifyClick = () => {
    handleVerify(otp);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  function maskMobile(mobileNumber: any) {
    if (typeof mobileNumber !== "string" || mobileNumber?.length <= 4) {
      return mobileNumber;
    }
    const maskedPart = "*".repeat(mobileNumber.length - 4);
    const visiblePart = mobileNumber.substring(mobileNumber?.length - 4);
    return maskedPart + visiblePart;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="otp-modal-title"
      aria-describedby="otp-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50vh",
          left: "50vw",
          transform: "translate(-50%, -50%)",
          background: "#FFFFFF",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "13px",
          textAlign: "center",
          height: {
            xs: "370px",
            sm: "400px",
            md: "400px",
            xl: "450px",
            lg: "450px",
          },
          padding: {
            xs: "5%",
            sm: "5%",
            md: "3%",
            xl: "3%",
            lg: "3%",
          },
          width: {
            xs: "90%",
            sm: "80%",
            md: "55%",
            xl: "40%",
            lg: "40%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{ fontWeight: "bold", textAlign: "left" }}
          >
            Almost Done
          </Typography>
        </Box>
        <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "left" }}>
          OTP has been sent to {BuyerBankDetails?.beneficiaryName}:{" "}
          {maskMobile(mobileNumber)}
          <IconButton
            onClick={() => window.location.reload()}
            sx={{ padding: "5px" }}
          >
            {}
          </IconButton>
        </Typography>
        <form onSubmit={handleSubmit(handleVerifyClick)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4vh",
            }}
            className="otp-input-container"
          >
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure={false}
              inputStyles={{
                width: isMobile ? "35px" : "50px",
                height: isMobile ? "35px" : "50px",
                margin: "0 5px",
                textAlign: "center",
                fontSize: isMobile? "20px" : "24px",
                border: "1px solid #E2E2E2",
                borderRadius: "8px",
                backgroundColor: "#F9F9F9",
                outline: "none",
              }}
            />
          </Box>
        </form>
        <Box my={"2vh"}>
          <CustomLoadingButton
            onClick={handleVerifyClick}
            title={`Verify`}
            submitLoader={isLoad}
            width={"75%"}
            marginRight={"0px"}
          ></CustomLoadingButton>
        </Box>

        <Typography
          variant="body1"
          sx={{
            mt: 3,
            textAlign: "center",
            color: "blue",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          <Typography>
            Didn't Receive the OTP?{" "}
            <Button
              onClick={ResendOTP}
              variant="text"
              sx={{
                mt: -1,
                "&:hover": {
                  color: "#7643EB",
                  backgroundColor: "white",
                },
              }}
            >
              Resend OTP
            </Button>
          </Typography>
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 3,
            textAlign: "center",
            color: "blue",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          <Typography variant="subtitle2">
            Do you want to cancel the transaction?{" "}
            <Button
              onClick={cancelTransaction}
              variant="text"
              sx={{
                mt: -1,
                "&:hover": {
                  color: "#7643EB",
                  backgroundColor: "white",
                },
              }}
            >
              Cancel transaction
            </Button>
          </Typography>
        </Typography>
        <Box sx={{ height: "100px" }} />
      </Box>
    </Modal>
  );
};

export default PaymentOtpVerification;
