import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { addMonths, format } from "date-fns";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getUserProfile } from "../../../../api/services";
import {
  getUsersByemail,
  postDealInformation,
} from "../../../../api/services/deals";

import AmountField from "../../../../components/formFields/AmountField";
import DateField from "../../../../components/formFields/DateField";
import DurationField from "../../../../components/formFields/DurationField";
import SimpleCheckoBox from "../../../../components/formFields/SimpleCheckoBox";
import TextField from "../../../../components/formFields/TextField";
import Quill from "../../../../components/Quill/Quill";
import InviteUserDetails from "../userDeal/truupeAccount/transactions/initiateTransaction/InviteuserDeatils";
import DealBankingInformation from "./DealBankingInformation";
import DealRole from "./DealSelectRole";
import DealType from "./DealType";
import SignatoryDirectors from "./SignatoryDirectors";
import BankingDetails from "./DealBankingInformation";
import IndividualSellerModal from "./IndividualSellerModal";

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  title: yup.string().required("Deal title is required"),
  amount: yup.string().required("Amount is required"),
  duration: yup
    .number()
    .required("Deal duration is required")
    .positive("Duration must be positive")
    .integer("Duration must be an integer"),
  closureDate: yup.string().required("Closure date is required"),
  checked: yup.boolean().required("Checkbox must be checked"),
  dealDescription: yup.string().required("Deal Description is required"),
});

const StyledCard = styled(Card)({
  marginX: "1vw",
  marginY: "1vh",
  minHeight: "82vh",
});

const StyledBox = styled(Box)({
  marginX: "20%",
  marginY: "20px",
});

const SectionDivider = styled(Divider)({
  color: "#F9F9F9",
  marginBottom: "15px",
});

const SearchContainer = styled(Box)({
  border: "2px solid #FAFAFA",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
});

const SearchBox = styled(Box)({
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#FAFAFA",
  height: "50px",
});

const CustomIconButton = styled(IconButton)({
  width: "35px",
  height: "35px",
  backgroundColor: "#7643EB",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: "#5e37a6",
  },
});

type FormData = {
  emailAddress: string;
  userType: string;
  type: string;
  title: string;
  amount: any;
  durationType: string;
  duration: number;
  closureDate: string;
  description: string;
  bankingId: number;
  signatoryId: number;
};

export default function DealCreationForm() {
  const navigate = useNavigate();
  const [infoCheck, setInfocheck] = useState(false);
  const [userDetails, setuserdetails] = useState<any>();
  const [dealRole, setDealRole] = useState("Buyer");
  const [dealtype, setDealtype] = useState("Goods");
  const [signDirector, setSignDirector] = useState();
  const [bankingId, setBankingId] = useState("");
  const [text, setText] = useState("");
  const [nonTruupeUser, setNonTruupeUser] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formDataToSubmit, setFormDataToSubmit] = useState<FormData | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [uploadKey, setUploadKey] = useState<string | null>(null);

  const { data: profileData, isLoading } = useQuery(
    "getUser-id",
    getUserProfile
  );
  const dealType = profileData?.data?.entities[0]?.companyType;

  const signatoryDirectors = profileData?.data?.entities[0]?.directors?.filter(
    (item: any) => item.isSignatoryAuthority
  );
  const bankingData = profileData?.data?.entities[0]?.bankingInformation;

  const CompanyType = profileData?.data?.entities[0]?.companyType;

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const duration = watch("duration");

  const getMinDate = () => format(new Date(), "yyyy-MM-dd");

  const getMaxDate = (duration: any) => {
    if (duration && duration > 0) {
      const maxDate = addMonths(new Date(), Number(duration));
      return format(maxDate, "yyyy-MM-dd");
    }
    return format(new Date(), "yyyy-MM-dd");
  };

  const { mutate: getDatabyEmail, isLoading: userLoader } = useMutation(
    getUsersByemail,
    {
      onSuccess: (res: any) => {
        setuserdetails(res?.data?.data);
        if (
          res?.data?.count === 0 &&
          isValidEmail(getValues("emailAddress")?.trim())
        ) {
          setNonTruupeUser(true);
          setIsUserSelected(true);
        } else if (
          res?.data?.count <= 1 &&
          isValidEmail(getValues("emailAddress")?.trim())
        ) {
          setNonTruupeUser(false);
        } else {
          if (
            res?.data?.count === 1 &&
            !isValidEmail(getValues("emailAddress")?.trim())
          ) {
            setNonTruupeUser(false);
          } else if (
            res?.data?.count > 1 &&
            !isValidEmail(getValues("emailAddress")?.trim())
          ) {
            setNonTruupeUser(false);
            setIsUserSelected(false);
          } else {
            setNonTruupeUser(false);
            setIsUserSelected(false);
            toast.error("Entity not present with this name");
          }
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: SubmitData, isLoading: SubmitDataLoader } = useMutation(
    postDealInformation,
    {
      onSuccess: (res: any) => {
        const DealCode = res?.data?.data?.code;
        navigate(`/deal-process/deals/invitation-success/${DealCode}`, {
          replace: true,
        });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  useEffect(() => {
    setValue("closureDate", getMaxDate(duration));
  }, [watch("duration")]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalConfirm = () => {
    setLoading(true);
    const updatedFormData = {
      ...formDataToSubmit,
      ...(uploadKey && { dealKeys: [uploadKey] }),
    };

    SubmitData(updatedFormData, {
      onSuccess: () => {
        setLoading(false);
        setShowModal(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  const onSubmit = (data: any) => {
    const formData = {
      emailAddress: getValues("emailAddress")?.trim(),
      userType: dealRole,
      type: dealtype,
      title: getValues("title"),
      amount: data.amount || getValues("amount").replace(/,/g, ""),
      durationType: "Month",
      duration: getValues("duration"),
      closureDate: getValues("closureDate"),
      description: text,
      bankingId: Number(bankingId),
      signatoryId: Number(signDirector),
    };

    const minDate = new Date(getMinDate());
    const maxDate = new Date(getMaxDate(formData.duration));
    const closureDate = new Date(formData.closureDate);

    isValidEmail(getValues("emailAddress")?.trim());

    if (
      Object.values(formData).some(
        (value) => value === undefined || value === null || value === ""
      )
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (!isValidEmail(formData.emailAddress)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (closureDate < minDate || closureDate > maxDate) {
      toast.error(
        `Closure date must be between ${format(
          minDate,
          "yyyy-MM-dd"
        )} and ${format(maxDate, "yyyy-MM-dd")}.`
      );
      return;
    }

    if (isUserSelected) {
      if (Number(getValues("amount").replace(/,/g, "")) >= 10000) {
        if (CompanyType === "INDIVIDUAL" && formData.userType === "Seller") {
          // Set form data and open the modal
          setFormDataToSubmit(formData);
          setShowModal(true);
        } else {
          SubmitData(formData);
        }
      } else {
        toast.error("Deal amount must be above ₹ 10,000");
      }
    } else {
      toast.error("Please search people by email address");
    }
  };

  const email = getValues("emailAddress");
  localStorage.setItem("dealEmailAddress", email);

  const handleChange = (value: any) => {
    setText(value);
  };

  const searchUsers = () => {
    setSearchFocus(false);
    if (
      getValues("emailAddress") === undefined ||
      getValues("emailAddress") === ""
    ) {
      toast.error(
        "Please enter Email Address or Entity Name to invite the Deal"
      );
    } else {
      setValue("title", "");
      setValue("amount", "");
      setValue("duration", 0);
      setText("");

      const Useremail = getValues("emailAddress")?.trim();
      getDatabyEmail({ email: Useremail });
      setIsUserSelected(false);
    }
  };

  useEffect(() => {
    if (searchFocus) {
      const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
          searchUsers();
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [searchFocus]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <StyledCard>
      <StyledBox
        sx={{
          px: { xs: "2vw", sm: "5vw", lg: "15vw", xl: "20vw" },
          py: "10px",
        }}
      >
        <Typography variant="h6" my={"10px"}>
          Search and Invite users
        </Typography>
        <SectionDivider />

        <SearchContainer>
          <SearchBox>
            <TextField
              control={control}
              name="emailAddress"
              errors={errors}
              isEmail={true}
              placeholder="Please enter Email Address or Entity Name to invite the Deal..."
              setSearchFocus={setSearchFocus}
              sx={{
                "& .MuiInputBase-input": {
                  backgroundColor: "#FAFAFA",
                  border: "none",
                  fontSize: 16,
                  width: { xs: "65vw", xl: "50vw", lg: "50vw" },
                  height: "25px",
                  marginTop: "7px",
                },
              }}
            />
            {userLoader ? (
              <CircularProgress size={"1.5rem"} />
            ) : (
              <CustomIconButton onClick={searchUsers}>
                <SearchIcon style={{ color: "white", fontSize: "20px" }} />
              </CustomIconButton>
            )}
          </SearchBox>

          {userLoader ? (
            <LinearProgress />
          ) : (
            <Box>
              {userDetails && userDetails.length > 0 ? (
                <>
                  {userDetails.length > 1 && (
                    <Typography sx={{ m: "10px" }}>{`Search results for ${
                      getValues("emailAddress") || "all"
                    }..... `}</Typography>
                  )}
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflow: "auto",
                    }}
                  >
                    {userDetails.map((users: any) => (
                      <Box
                        key={users?.id}
                        sx={{
                          bgcolor: "#FAFAFA",
                          m: "15px",
                          borderRadius: "10px",
                        }}
                      >
                        <InviteUserDetails
                          data={users}
                          getDataByEmail={getDatabyEmail}
                          isSelect={userDetails?.length > 0 && !isUserSelected}
                          setIsUserSelected={setIsUserSelected}
                          setValue={setValue}
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              ) : null}

              {nonTruupeUser && (
                <Box sx={{ padding: "20px" }}>
                  <Typography sx={{ fontSize: "20px" }}>
                    {getValues("emailAddress")} Not Found in Truupe Platform
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Note : However, you can still proceed with creating and
                    sending a Deal invitation to this user by filling out the
                    Deal creation form.
                  </Typography>
                  <Divider sx={{ marginTop: "1vh" }} />
                </Box>
              )}

              {isUserSelected && (
                <Box sx={{ my: "10px", px: "25px" }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Deal Information
                  </Typography>
                  <DealRole dealType={dealType} setDealRole={setDealRole} />
                  <DealType setDealType={setDealtype} />

                  <TextField
                    title="Deal Title"
                    name="title"
                    placeholder="Enter Deal Title"
                    control={control}
                    errors={errors}
                    key={"1"}
                    sx={{
                      width: {
                        xs: "250px",
                        sm: "520px",
                        lg: "520px",
                        xl: "520px",
                      },
                    }}
                  />

                  <Stack>
                    <Typography variant="caption" sx={{ color: "#00000099" }}>
                      Deal Description<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Quill
                      data={text}
                      placeholder="Please refer to the examples below when writing the Deal Description"
                      handleChange={(value) => handleChange(value)}
                    />
                    <Typography
                      variant="caption"
                      sx={{ fontStyle: "italic", color: "#00000099" }}
                    >
                      Examples for Deal Description:
                      <br />
                      "User A (Seller) is Selling Tiles to User B"
                      <br />
                      "User A is offering interior design services to User B".
                    </Typography>
                  </Stack>
                  <AmountField
                    control={control}
                    clearErrors={clearErrors}
                    setError={setError}
                    errors={errors}
                    key={"3"}
                  />
                  <DurationField control={control} errors={errors} key={"4"} />
                  <DateField
                    control={control}
                    getMaxDate={getMaxDate(duration)}
                    getMinDate={getMinDate()}
                    errors={errors}
                    key={"5"}
                  />
                  <Divider />
                  <Box>
                    <BankingDetails
                      bankingData={bankingData}
                      setBankingId={setBankingId}
                      profile={profileData?.data}
                    />
                  </Box>
                  <Divider />
                  <SignatoryDirectors
                    directorsData={signatoryDirectors}
                    setSignDirector={setSignDirector}
                    profile={profileData?.data}
                  />
                  <Divider />
                  <SimpleCheckoBox
                    setInfocheck={setInfocheck}
                    control={control}
                    text={
                      "I hereby, that all information provided in this is true."
                    }
                    key={"5"}
                  />
                  {SubmitDataLoader ? (
                    <LoadingButton
                      loading
                      loadingPosition="center"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      sx={{
                        width: "120px",
                        my: "2vh",
                        border: "1px solid black",
                        "& .MuiCircularProgress-root": { color: "#7643EB" },
                        "&.MuiButton-outlined": { border: "2px solid #7643EB" },
                      }}
                    />
                  ) : (
                    <Button
                      disabled={!infoCheck}
                      onClick={onSubmit}
                      variant="contained"
                      sx={{ my: "2vh", width: "120px" }}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              )}
              <IndividualSellerModal
                open={showModal}
                onClose={handleModalClose}
                onConfirm={handleModalConfirm}
                message="You are creating a deal as a seller, please accept the following Terms and Conditions to continue. "
                isLoading={loading}
                uploadKey={setUploadKey}
              />
            </Box>
          )}
        </SearchContainer>
      </StyledBox>
    </StyledCard>
  );
}

function isValidEmail(email: any) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}
