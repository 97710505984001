import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { getUserProfile } from "../../../../../api/services";

type StatusProps = {
  variant: string;
  showTooltip?: boolean;
  tooltipText?: string;
  invitationIssuedStatus?: any;
};

const Status: React.FC<StatusProps> = ({
  variant,
  showTooltip = false,
  tooltipText = "",
  invitationIssuedStatus,
}) => {
  let style: { backgroundColor: string; color: string };

  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);

  const dealCreator = invitationIssuedStatus === profileData?.data?.id;

  switch (variant) {
    case "Invitation Issued":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText =
        "Deal invitation for this deal has been sent to other party. We are currently awaiting for their response";
      break;
    case "Deal Invitation Received":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText =
        "Deal invitation for this deal has been sent to other party. We are currently awaiting for their response";
      break;
    case "Confirmation Awaiting":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText =
        "Confirmation Awaiting for this deal has been sent to other party. We are currently awaiting for their response";
      break;
    case "SUCCESS":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText =
        "Confirmation Awaiting for this deal has been sent to other party. We are currently awaiting for their response";
      break;
    case "PROCESSED":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText =
        "Confirmation Awaiting for this deal has been sent to other party. We are currently awaiting for their response";
      break;
    case "PENDING":
      style = { backgroundColor: "#FFA5001A", color: "#FFA500" };
      break;
    case "Needs other Signatures":
      style = { backgroundColor: "#B8A9201A", color: "#B8A920" };
      break;
    case "Buyer to Pay Platform Fee":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "Payment under Verification":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "Deal Invitation Sent":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      break;
    case "Needs your Signature":
      style = { backgroundColor: "#FFC1071A", color: "#FFC107" };
      break;
    case "Account credited":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "Funds yet to be credited":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "Buyer to Pay Platform Fee":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "Needs other Signatures":
      style = { backgroundColor: "#B8A9201A", color: "#B8A920" };
      break;

    case "Signature Pending":
      style = { backgroundColor: "#FFF3E09A", color: "#FFA500" };
      break;
    case "Needs your Signatures":
      style = { backgroundColor: "#FFF3E09A", color: "#FFA500" };
      break;
    case "Pending":
      style = { backgroundColor: "#FFF3E09A", color: "#FFA500" };
      break;
    case "Signature Awaiting":
      style = { backgroundColor: "#FFF3E01A", color: "#FFA500" };
      break;
    case "Deal Accepted":
      style = { backgroundColor: "#00BCD41A", color: "#00BCD4" };
      tooltipText = "Deal Invitation accepted";
      break;
    case "Deal Rejected":
      style = { backgroundColor: "#FF57221A", color: "#FF5722" };
      tooltipText = "Deal Invitation rejected";
      break;
    case "REJECTED":
      style = { backgroundColor: "#FF57221A", color: "#FF5722" };
      tooltipText = tooltipText || "Deal Invitation rejected";
      break;
    case "Verification Pending":
      style = { backgroundColor: "#FFC1071A", color: "#FFC107" };
      break;
    case "Fee Pending":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "Pay Platform Fee":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "Funds Awaited":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "Credit funds into the account":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "Awaiting Escrow Funds":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "Funds Received":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "Initiate Payment":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "Request Payment":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "Completed":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "Payment Request":
    case "Payout Request":
      style = { backgroundColor: "#607D8B1A", color: "#607D8B" };
      break;
    case "Service Payment":
      style = { backgroundColor: "#607D8B1A", color: "#607D8B" };
      break;
    case "PAYMENT VERIFICATION":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "Under Verification":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "Verify Payment":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "Payment Completed":
      style = { backgroundColor: "#E8F5E91A", color: "#E8F5E9" };
      break;
    case "Deal Completed":
      style = { backgroundColor: "#2196F31A", color: "#2196F3" };
      break;
    case "Deal Created":
      style = { backgroundColor: "#2196F31A", color: "#2196F3" };
      break;
    case "Deal Cancelled":
      style = { backgroundColor: "#F443361A", color: "#F44336" };
      break;
    case "VERIFIED":
      style = { backgroundColor: "#00BCD41A", color: "#00BCD4" };
      tooltipText = "ENTITY VERIFIED";
      break;
    case "VERIFY CHANGES":
      style = { backgroundColor: "#2196F31A", color: "#2196F3" };
      tooltipText = "TO BE RE-VERIFIED BY ADMIN";
      break;
    case "IN PROGRESS":
      style = { backgroundColor: "#FFA5001A", color: "#FFA5001" };
      break;
    case "KYC SUBMITTED":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      tooltipText = "TO BE VERIFIED BY ADMIN";
      break;
    default:
      style = { backgroundColor: "#0000001A", color: "#000000" };
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "4px",
        height: "30px",
        width: "max-content",
        px: "14px",
        ...style,
      }}
    >
      <Typography variant="body1" sx={{ color: style.color }}>
        {variant === "Invitation Issued"
          ? dealCreator
            ? "Confiration Awaiting"
            : "Invitation Issued"
          : variant}
      </Typography>
      {showTooltip && (
        <Tooltip
          title={tooltipText}
          arrow
          sx={{
            fontSize: "0.5rem",
            backgroundColor: "red",
            color: "black",
            "& .MuiTooltip-arrow": {
              color: "white",
            },
          }}
        >
          <IconButton
            size="small"
            sx={{ color: style.color, fontSize: "12px" }}
          >
            <InfoOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

Status.propTypes = {
  variant: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default Status;
