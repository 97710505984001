import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";

type BankingDetailsProps = {
  bankingData: any[];
  setBankingId: (id: string) => void;
  profile: any;
};

export default function BankingDetails({
  bankingData,
  setBankingId,
  profile,
}: BankingDetailsProps) {
  const verifiedBankingData = bankingData?.filter(
    (bankDetail: { status: string }) => bankDetail.status === "Verified"
  );

  useEffect(() => {
    if (verifiedBankingData.length > 0) {
      setBankingId(verifiedBankingData[0]?.id); // Set first verified bank detail as default
    }
  }, []);

  const handleChange = (event: any) => {
    setBankingId(event.target.value); // Update selected bank detail ID
  };

  return (
    <Box my={"10px"}>
      <Typography variant="subtitle2" mb={"10px"}>
        Banking Information
      </Typography>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="bank-details-radio-group"
            name="bank-details-group"
            onChange={handleChange}
            defaultValue={verifiedBankingData[0]?.id || ""}
          >
            {verifiedBankingData?.map((bankDetail: any) => {
              return (
                <>
                  <Stack direction={"row"} key={bankDetail.id}>
                    <FormControlLabel
                      value={bankDetail.id}
                      control={<Radio />}
                      label=""
                      sx={{ width: "25px" }}
                    />
                    <Box
                      my={"1vh"}
                      display={"flex"}
                      gap={"2vw"}
                      key={bankDetail.id}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "750px",
                          lg: "750px",
                          xl: "750px",
                        },
                        flexWrap: {
                          xs: "wrap",
                          sm: "wrap",
                          md: "nowrap",
                          lg: "nowrap",
                          xl: "nowrap",
                        },
                      }}
                    >
                      <Stack sx={{ width: { xs: "100%", sm: "35%" } }}>
                        <Typography variant="caption">
                          Beneficiary Name
                        </Typography>
                        <Typography variant="subtitle2" marginTop={"0.5vh"}>
                          {bankDetail.beneficiaryName}
                        </Typography>
                      </Stack>
                      <Stack sx={{ width: { xs: "100%", sm: "25%" } }}>
                        <Typography variant="caption">
                          Account Number
                        </Typography>
                        <Typography variant="subtitle2" marginTop={"0.5vh"}>
                          {bankDetail.accountNumber}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: { xs: "none", sm: "none", lg: "flex" },
                          width: "30%",
                        }}
                      >
                        <Typography variant="caption">Bank Name</Typography>
                        <Typography variant="subtitle2" marginTop={"0.5vh"}>
                          {bankDetail.name}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: { xs: "none", sm: "none", lg: "flex" },
                          width: "20%",
                        }}
                      >
                        <Typography variant="caption">IFSC Code</Typography>
                        <Typography variant="subtitle2" marginTop={"0.5vh"}>
                          {bankDetail.ifscCode}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
