import { Box } from "@mui/material";
import styled from "styled-components";

import { authImage } from "../../assets";
import Truupeheader from "../../layout/Truupeheader";

const Container = styled(Box)`
  height: 100%;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100vw;
`;

const ImageContainer = styled(Box)`
  width: 60%;
  padding: 150px;
  border-right: 2px solid #33333333;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AuthImage = styled.img`
  height: 550px;
  width: 830px;
`;

export default function OtpVerification() {
  return (
    <Container>
      <Truupeheader height="15vh" />
      <ContentWrapper>
        <ImageContainer>
          <AuthImage src={authImage} alt="Loading..." />
        </ImageContainer>
      </ContentWrapper>
    </Container>
  );
}
