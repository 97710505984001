import {
  Box,
  Button,
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getusersbyid, istermsaccepted } from "../../api/services";
import { Icons } from "../../assets";
import Truupeheader from "../../layout/Truupeheader";
import { termsHtml } from "./content";

const AuthImage = styled.img`
  width: 100%;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 60%;
  }
`;

const TermsAndConditionsPage = () => {
  const navigate = useNavigate();
  const accessToken: any = Cookies.get("access_token");
  const userdata: any = jwtDecode(accessToken);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const { data }: any = useQuery(
    ["getUser-id", userdata?.userId],
    getusersbyid
  );

  const { mutate: termsaccept } = useMutation(istermsaccepted, {
    onSuccess: (res: any) => {
      // toast.success(res?.data?.message);
      localStorage.setItem("isTermsAccepted", "true");
      navigate("/home");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  useEffect(() => {
    if (data?.data?.isTermsAccepted) {
      navigate("/home");
    }
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = () => {
    const userdata = {
      isTermsAccepted: isChecked,
    };
    termsaccept(userdata);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        // border: "2px solid red",

        alignItems: {
          xs: "center",
          sm: "center",
          md: "center",
          lg: "flex-start",
          xl: "flex-start",
        },

        padding: {
          xs: "25px 0px 15px 0px ",
          sm: "10% 0%",
          md: "0px 0px ",
          lg: "0px 0px ",
          xl: "0px 0px ",
        },

        height: { xs: "98vh", lg: "100vh" },
      }}
    >
      {isMobile || isTablet ? null : <Truupeheader />}
      <Box
        sx={{
          marginLeft: {
            xs: "10px",
            sm: "20px",
            md: "40px",
            lg: "60px",
            xl: "120px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "flex-start",
            xl: "flex-start",
          },
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "flex-start",
            xl: "flex-start",
          },
          width: { xs: "100%", sm: "90%", md: "80%" },
          minHeight: "85vh",
          padding: { xs: "20px", sm: "30px", md: "50px 30px", lg: "50px" },
          mt: { xs: 0, sm: -2, md: -4, lg: -6, xl: -8 },
        }}
      >
        {(isMobile || isTablet) && (
          <AuthImage src={Icons.truupe} alt="Loading..." />
        )}
        <Typography
          variant="h1"
          gutterBottom
          align="left"
          sx={{
            fontSize: { xs: "24px", sm: "30px", md: "35px", lg: "40px" },
            fontWeight: "bold",
            my: "10px",
          }}
        >
          Your Registration is almost done
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          sx={{
            fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px" },
          }}
        >
          Please accept the terms and conditions to continue...
        </Typography>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#F8F8F8",
            padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
            overflowY: "auto",
          }}
          dangerouslySetInnerHTML={{ __html: termsHtml }}
        />

        <Box
          display="flex"
          alignItems="center"
          sx={{ fontSize: { xs: "16px", sm: "17px", md: "18px", lg: "18px" } }}
        >
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            color="primary"
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: "30px", sm: "30px", md: "24px" },
              },
              "& .MuiCheckbox-root": {
                color: isChecked ? "primary.main" : "transparent",
              },
              marginBottom: "5px",
            }}
          />
          <Typography
            variant="body1"
            gutterBottom
            align="left"
            fontSize={{ xs: "16px", sm: "18px", md: "20px" }}
            onClick={handleCheckboxChange}
            sx={{ cursor: "pointer" }}
          >
            I Accept the terms and conditions regarding the usage of TruuPe
            Platform
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isChecked}
          sx={{
            marginTop: {
              xs: "20px",
              sm: "20px",
              md: "10px",
              lg: "0px",
              xl: "0px",
            },
            width: { xs: "100%", sm: "auto" },
            // height: "7vh",
            // ml: 2,
            fontSize: "14px",
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsPage;
