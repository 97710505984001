import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  applyCouponCode,
  createOrderBipass,
  createRazorpayOrder,
  getDealsDataById,
  removeCouponCode,
  verifyTransaction,
} from "../../../../../api/services/deals";
import { group } from "../../../../../assets";
import { CustomLoadingButton } from "../../../../../components/buttons/SubmitLoaderButton";
import InputField from "../../../../../components/formFields/kycFields/InputField";
import { stripHTML } from "../../../../../utils/dealFunctions";
import Congrats from "../modalCards/Congrats";

export default function PayPlatformFee() {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { data: dealData, isLoading: dealDataLoading }: any = useQuery(
    ["deal", dealId],
    getDealsDataById
  );
  const [couponCode, setCouponCode] = useState<string | null>(null);

  const dealCode = dealData?.data?.code;

  useEffect(() => {
    setCouponCode(dealData?.data?.coupon?.code);
  }, [dealData]);

  const { mutate: applyCoupon } = useMutation(applyCouponCode, {
    onSuccess: () => {
      toast.success("Coupon applied successfully!");
      queryClient.invalidateQueries("deal");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: removeCoupon } = useMutation(removeCouponCode, {
    onSuccess: () => {
      toast.success("Coupon removed successfully!");
      queryClient.invalidateQueries("deal");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const data = dealData?.data;

  const handlePayClick = async (code?: any) => {
    setLoadingButton(true);
    try {
      let order = await createRazorpayOrder({
        code: code,
      });
      setLoadingButton(false);
      let options = {
        key: "rzp_live_vQKfoWOQkPF2SJ",
        name: "Truupe",
        image:
          "https://truupe-staging-assets.s3.ap-south-1.amazonaws.com/favicon.png",
        currency: order?.data?.data?.currency,
        order_id: order?.data?.data?.rzpOrderId,
        amount: order?.data?.data?.rzpOrderAmount,
        theme: {
          color: "#7643EB",
        },
        modal: {
          ondismiss: function () {
            toast.error("Payment Failed");
          },
        },
        handler: async (response: any) => {
          setLoading(true);
          try {
            await verifyTransaction({
              rzpOrderId: response?.razorpay_order_id,
              rzpPaymentId: response?.razorpay_payment_id,
              rzpSignature: response?.razorpay_signature,
            });
            toast.success("Payment Successful");
            setOpen(true);
            setLoading(false);
          } catch (err) {
            setLoading(false);
          }
        },
      };
      const razorpay = new window.Razorpay(options);
      razorpay?.open();
      razorpay?.on("payment.failed", function (response: any) {
        toast.error("Payment Failed, Please Try Again");
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const { mutate: payServiveBipass, isLoading: lodaing } = useMutation(
    createOrderBipass,
    {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        setOpen(true);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }

  // const totalTaxesSum = data?.taxes.reduce(
  //   (sum: number, tax: any) => sum + tax.taxAmount,
  //   0
  // );
  const taxAmount = data?.taxes?.reduce((acc: any, tax: { taxAmount: any }) => {
    return Number(acc) + Number(tax?.taxAmount);
  }, 0);

  let serviceCharge = data?.amount * 0.01;

  let totalServiceAmount = (data?.amount || 0) + serviceCharge;

  data?.taxes?.forEach((i: any) => {
    totalServiceAmount += serviceCharge * 0.09;
  });

  if (dealDataLoading) {
    return <LinearProgress></LinearProgress>;
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "auto",
            maxWidth: "800px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "auto",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "40px",
            }}
          >
            <CardContent
              sx={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <img src={group} alt="Card" style={{ width: "40%" }} />
              </Box>
              <Typography variant="h3" sx={{ textAlign: "center" }}>
                Pay Platform Fee and continue your Deal Process
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: "center", opacity: 0.6, mt: 2 }}
              >
                Your Truupe Account will be created after paying platform fee
              </Typography>
            </CardContent>
            <Card sx={{ backgroundColor: "#F8F8F8", my: "2vh" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid size={6}>
                    <Typography variant="h3" sx={{ color: "#177CF7" }}>
                      {data?.code}
                    </Typography>
                  </Grid>
                  <Grid size={6} sx={{ textAlign: "right" }}>
                    <Typography variant="h3">
                      ₹ {formatNumberToIndianSystem(data?.amount)}
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Typography variant="h5">{data?.title}</Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Typography variant="caption">Description</Typography>
                    <Typography variant={"subtitle2"} marginTop={"0.5vh"}>
                      {stripHTML(data?.description)}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Stack
                      direction={"row"}
                      alignItems={"end"}
                      justifyContent={{ xs: "space-between", md: "end" }}
                      gap={1}
                    >
                      <InputField
                        iD="couponCode"
                        name="Coupon Code"
                        errors={{}}
                        isdisabled={!!dealData?.data?.coupon?.code}
                        onChange={(e) => {
                          const value = e.target.value
                            .replace(/[^a-zA-Z0-9]/g, "")
                            .toUpperCase();
                          setCouponCode(value);
                        }}
                        value={couponCode || ""}
                        field={undefined}
                        sx={{ width: "200px" }}
                      />
                      {dealData?.data?.coupon?.code ? (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            removeCoupon({
                              dealCode: dealCode,
                              couponCode: couponCode,
                            });
                          }}
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            applyCoupon({
                              dealCode: dealCode,
                              couponCode: couponCode,
                            });
                          }}
                        >
                          Apply
                        </Button>
                      )}
                    </Stack>
                  </Grid>

                  <Grid size={12}>
                    <hr />
                  </Grid>

                  <Grid size={6}>
                    <Typography variant="body1" sx={{ opacity: 0.6 }}>
                      Platform Fee
                    </Typography>
                  </Grid>
                  <Grid size={6} sx={{ textAlign: "right", opacity: 0.6 }}>
                    <Stack direction={"row"} justifyContent={"end"}>
                      {data?.couponId ? (
                        <Typography
                          variant="body1"
                          sx={{
                            // display: "inline",
                            textDecoration: "line-through",
                            marginRight: 1,
                          }}
                        >
                          {" ₹ "}
                          {formatNumberToIndianSystem(data?.subTotal || 0)}
                        </Typography>
                      ) : null}

                      <Typography
                        variant="body1"
                        sx={{
                          // display: "inline",
                          color: data?.couponId ? "green" : "inherit",
                        }}
                      >
                        {" ₹ "}
                        {formatNumberToIndianSystem(
                          data?.couponId
                            ? data?.subTotal - data?.discountAmount
                            : data?.subTotal || 0
                        )}
                      </Typography>
                    </Stack>
                  </Grid>

                  {data?.taxes.map((tax: any) => {
                    return (
                      <>
                        <Grid size={6}>
                          <Typography variant="body1" sx={{ opacity: 0.6 }}>
                            {tax?.type}- ( {tax?.taxPercentage}% )
                          </Typography>
                        </Grid>
                        <Grid size={6} sx={{ textAlign: "right" }}>
                          <Typography variant="body1" sx={{ opacity: 0.6 }}>
                            {" ₹ "}
                            {formatNumberToIndianSystem(tax?.taxAmount)}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}

                  <Grid size={12}>
                    <hr style={{ borderStyle: "dotted", opacity: 0.2 }} />
                  </Grid>

                  <Grid size={6}>
                    <Typography variant="h5">Total Amount</Typography>
                  </Grid>
                  <Grid size={6} sx={{ textAlign: "right" }}>
                    <Typography variant="h5">
                      ₹ {formatNumberToIndianSystem(data?.grandTotal || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box my={"1vh"}>
              <CustomLoadingButton
                onClick={() => {
                  if (Number(data?.grandTotal) === 0) {
                    payServiveBipass({
                      code: data?.code,
                    });
                  } else {
                    handlePayClick(data?.code);
                  }
                }}
                title={Number(data?.grandTotal) === 0 ? "Continue" : "Pay Now"}
                submitLoader={loadingButton || loading}
                width={"80px"}
              ></CustomLoadingButton>
            </Box>
            {}
          </Card>
          <Congrats
            open={open}
            onClose={() => {
              navigate(`/deal-process/deals/${dealId}`, {
                replace: true,
              });
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}
