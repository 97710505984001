import { Avatar, Box, Radio, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
type InviteUserDetailsProps = {
  data: any;
  isSelect: boolean;
  setIsUserSelected: Function;
  getDataByEmail: any;
  setValue: any;
};

export default function InviteUserDetails({
  data,
  getDataByEmail,
  isSelect,
  setIsUserSelected,
  setValue,
}: InviteUserDetailsProps) {
  const UserData = data;
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          height: {
            xs: "auto",
            lg: "auto",
            xl: "auto",
          },
        }}
      >
        <Box
          mx="20px"
          my="20px"
          width={"70%"}
          padding="5px"
          borderRadius="5px"
          // border={"2px solid red"}
        >
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
            }}
          >
            <Grid size={6}>
              <Stack direction="row">
                <Avatar
                  alt={UserData?.name || "BM"}
                  src="/static/images/avatar/2.jpg" // invalid URL
                  sx={{
                    backgroundColor: "#6f559f",
                    width: {
                      xs: "35px",
                      lg: "50px",
                      xl: "50px",
                    },
                    height: {
                      xs: "35px",
                      lg: "50px",
                      xl: "50px",
                    },
                    // display: { xs: "none", lg: "flex", xl: "flex" },
                  }}
                >
                  {UserData?.name &&
                    UserData?.name
                      .split(" ")
                      .map((word: any) => word.charAt(0))
                      .join("")
                      .toUpperCase()
                      .substring(0, 2)}
                </Avatar>
                <Stack mx="10px">
                  <Typography variant="h6" sx={{ fontWeight: "400" }}>
                    {UserData?.name}
                  </Typography>
                  <Typography variant="caption">
                    {UserData?.emailAddress}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              sx={{
                display: { xs: "flex", lg: "flex", xl: "flex" },
                // border: "2px solid red",
              }}
            >
              <Stack>
                <Typography variant="caption">Business Entity Name</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                  {UserData?.entities[0]?.companyType === "INDIVIDUAL" ? (
                    <>{UserData?.entities[0]?.directors[0]?.name}</>
                  ) : (
                    <>
                      {" "}
                      {UserData?.entities[0]?.companyName || "Not Registered"}
                    </>
                  )}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" },
            }}
          >
            <Grid size={6}>
              <Stack>
                <Typography variant="caption">Entity Type</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                  {UserData?.entities[0]?.companyType?.replace(/_/g, " ") ||
                    "Not Registered"}
                </Typography>
              </Stack>
            </Grid>
            <Grid size={6}>
              <Stack>
                <Typography variant="caption">Business Category</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                  {UserData?.entities[0]?.companyType === "INDIVIDUAL" ? (
                    <>{"Not Available"}</>
                  ) : (
                    <> {UserData?.entities[0]?.category || "Not Registered"}</>
                  )}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            mt="1px"
            sx={{
              display: { xs: "none" },
            }}
          >
            <Grid size={6}>
              <Stack>
                <Typography variant="caption">Status</Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                  {UserData?.entities[0]?.status?.replace(/_/g, " ") ||
                    "Not Registered"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {isSelect && (
          <Grid size={6} mx={"15px"}>
            <Radio
              checked={UserData?.selected}
              onClick={() => {
                getDataByEmail({ email: UserData?.emailAddress });
                setIsUserSelected(true);
                setValue("emailAddress", UserData?.emailAddress);
              }}
              value={UserData?.emailAddress}
              name="user-radio"
              inputProps={{ "aria-label": UserData?.emailAddress }}
            />
          </Grid>
        )}
      </Stack>
    </>
  );
}
