import { useState } from "react";
// import { useLocation } from "react-router-dom";

import { useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import { getUserProfile } from "../api/services";
import Header from "../pages/home/Components/Header";

const KYCProcess = () => {
  const [_, setOpen] = useState(false);
  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <Header toggleDrawer={toggleDrawer} profileData={profileData} />
      <Outlet />
    </>
  );
};

export default KYCProcess;
