import {
  Box,
  Button,
  Card,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getComapanyDetailsById, updateKycDocs } from "../../api/services";
import { CustomLoadingButton } from "../../components/buttons/SubmitLoaderButton";
import RejectedDocsGrid from "./Components/RejectedDocsGrid";

export default function ReSubmitDocuments() {
  const EntityId = Cookies.get("entityId");
  const { kyctype }: any = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: CompanyDetails, isLoading } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById
  );
  const reqDocIds = CompanyDetails?.data?.statusHistory[0]?.reqDocIds;
  const historyId = CompanyDetails?.data?.statusHistory[0]?.id;
  const onFileChange = () => queryClient.invalidateQueries("entity");

  const [uploadedDocs, setUploadedDocs] = useState<any[]>([]);

  const handleFileData = (subtype: string, mediaId: string, typeId: string) => {
    setUploadedDocs((prev) => {
      const existingIndex = prev.findIndex((doc) => doc.subtype === subtype);

      if (existingIndex !== -1) {
        const updatedArray = [...prev];
        updatedArray[existingIndex] = { subtype, mediaId: mediaId, typeId };
        return updatedArray;
      } else {
        return [...prev, { subtype, mediaId: mediaId, typeId }];
      }
    });
  };

  const mutation = useMutation(updateKycDocs, {
    onSuccess: () => {
      queryClient.invalidateQueries("getcompanydetails");
      navigate(`/kycprocess/resubmit-success`, { replace: true });
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleSubmit = () => {
    const body = {
      historyId: historyId,
      media: uploadedDocs.map(({ subtype, ...rest }) => rest),
    };
    mutation.mutate(body);
  };

  const { reason, firmDocuments, bankingInformation, signatoryDirectors } =
    CompanyDetails?.data || {};

  if (isLoading) return <LinearProgress />;

  return (
    <Card sx={{ mt: 2, width: "96%", ml: "2%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          backgroundColor: "#f5f5f5",
          p: { xs: 2, md: 4 },
        }}
      >
        <Typography variant="h4">Re-submit Documents for KYC</Typography>
        <Divider sx={{ width: "100%", mt: 1, mb: 2 }} />

        {/* Reason for Rejection */}
        {reason && (
          <Box width="96%" mb={2}>
            <Typography variant="subtitle1">Reason for Rejection</Typography>
            <Divider sx={{ width: "100%", mb: 1 }} />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: reason }}
            />
          </Box>
        )}

        {/* Firm Documents */}
        <RejectedDocsGrid
          title="Firm Documents"
          documents={firmDocuments}
          onFileChange={onFileChange}
          rejectedDocIds={reqDocIds}
          fileData={handleFileData}
        />

        {/* Banking Documents */}
        {bankingInformation?.map((bank: any, index: number) => (
          <RejectedDocsGrid
            key={index}
            title={`Bank ${index + 1} - ${bank.beneficiaryName}`}
            documents={bank.relatedDocs}
            onFileChange={onFileChange}
            rejectedDocIds={reqDocIds}
            fileData={handleFileData}
          />
        ))}

        {/* Signatory Directors */}
        {signatoryDirectors?.map((director: any, index: number) => (
          <RejectedDocsGrid
            key={index}
            title={`Signatory ${index + 1} - ${director.name}`}
            documents={director.documents}
            onFileChange={onFileChange}
            rejectedDocIds={reqDocIds}
            fileData={handleFileData}
          />
        ))}

        {/* Actions */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            mt: 2,
            width: "96%",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/home")}
            sx={{ width: "100px" }}
          >
            Cancel
          </Button>
          <CustomLoadingButton
            title="Submit"
            onClick={handleSubmit}
            width="100px"
          />
        </Box>
      </Box>
    </Card>
  );
}
