import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import RejectionModal from "../../modalCards/Reasonrejection";
import IndividualSellerDealApprove from "../../modalCards/IndividualSellerDealApprove";
import ApproveModel from "../../modalCards/DealApproveModel";

export default function InvitationReceived({
  data,
  secondUser,
  profileUser,
}: any) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const IsSellerIndividual =
    profileUser?.userType === "Seller" &&
    profileUser?.entity?.companyType === "INDIVIDUAL";

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Invitation Received
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The {secondUser?.userType} has sent you the following Business Deal
          invitation. Please check the terms and "Accept" if you confirm or
          "Reject" if you disagree.
        </Typography>
      </Stack>
      <RejectionModal
        open={open}
        onClose={handleClose}
        data={data}
        secondUser={secondUser}
      />

      {IsSellerIndividual ? (
        <IndividualSellerDealApprove
          open={open2}
          onClose={handleClose2}
          data={data}
          secondUser={secondUser}
        />
      ) : (
        <ApproveModel
          open={open2}
          onClose={handleClose2}
          data={data}
          secondUser={secondUser}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          gap: "20px",
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          sx={{
            width: 150,
            height: 40,
            border: "2px solid #7643EB",
          }}
        >
          Reject Deal
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen2}
          sx={{
            width: 150,
            height: 40,
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          Accept Deal
        </Button>
      </Box>
    </Box>
  );
}
