import { Link, Stack, Typography } from "@mui/material";
import FileUploadContainer from "./FileUploadContainer";

const DocumentUploader = ({
  documentLabel,
  subtype,
  entityId,
  statusDetails,
  documents,
  onFileChange,
  showTemplate = false,
  templateUrl = "",
  customMessage = "",
}: any) => {
  const uploaded = !!documents?.filter((i: any) => i?.subtype === subtype)
    .length;
  const name = documents
    ?.filter((i: any) => i?.subtype === subtype)
    .map((i: any) => i?.originalFileName);
  const url = documents
    ?.filter((i: any) => i?.subtype === subtype)
    .map((i: any) => i?.url);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        mb={1}
        mt={2}
        width={"350px"}
        justifyContent={"space-between"}
      >
        <Typography variant="subtitle2">{documentLabel}</Typography>

        {showTemplate && !statusDetails?.data?.documents && (
          <Link
            href={templateUrl}
            color="primary"
            underline="hover"
            sx={{ fontSize: "14px" }}
          >
            Download Sample template
          </Link>
        )}
      </Stack>

      <FileUploadContainer
        typeId={entityId}
        status={statusDetails?.data?.documents}
        actions={<></>}
        data={subtype}
        typeData="ENTITY"
        uploaded={uploaded}
        name={name}
        url={url}
        onFileChange={onFileChange}
      />

      {customMessage && (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginTop: "10px", opacity: "0.8" }}
        >
          {customMessage}
        </Typography>
      )}
    </>
  );
};

export default DocumentUploader;
