import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import HowToAddFundsModal from "../../dealInformation/HowToAddFundsModel";
type EscrowAccountDetailsProps = {
  data: any;
  profileData: any;
};

export default function EscrowAccountDetails({
  data,
  profileData,
}: EscrowAccountDetailsProps) {
  const [openModel, setOpenModel] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [copiedAccountName, setCopiedAccountName] = useState(false);
  const [copiedAccountNumber, setCopiedAccountNumber] = useState(false);
  const [copiedIfscCode, setCopiedIfscCode] = useState(false);
  const isUserBuyer =
    data?.dealUsers?.filter(
      (user: any) => user?.userId === profileData?.data?.id
    )[0]?.userType === "Buyer";

  const handleOpenModel = () => {
    setOpenModel(true);
  };
  const copyToClipboard = (text: string, setCopied: any) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3500);
    });
  };
  const InstructionTypography = () => (
    <Box sx={{ mt: isMobile ? 0 : 2 }}>
      <Typography variant="caption" sx={{ mb: 1 }}>
        <b>Note:</b>
      </Typography>
      <br />
      <Typography variant="caption" sx={{ mb: 1 }}>
        1. If your account is with Axis Bank, you must still add the Truupe
        account as an <b>other bank</b> account.
      </Typography>
      <br />
      <Typography variant="caption" sx={{ mb: 1 }}>
        2. Ensure that you <b>enter the account name correctly</b>.
      </Typography>
      <br />
      <Typography variant="caption" sx={{ mb: 1 }}>
        3. Please note that the fifth character in the IFSC code is the number
        <b>“0” (zero), not the letter “O.”</b>
      </Typography>
      <br />
      <Typography variant="caption">
        4. Transfer funds via NEFT/IMPS or RTGS (amount &gt; 2Lakhs) only
      </Typography>
    </Box>
  );
  return (
    <Box p={isMobile ? 2 : 3}>
      <Typography
        variant={isMobile ? "h4" : "h2"}
        sx={{ fontWeight: "500", textAlign: isMobile ? "left" : "left" }}
      >
        Truupe Account Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          mt: 2,
          gap: isMobile ? "20px" : "50px",
          rowGap: isMobile ? "20px" : "25px",
          alignItems: isMobile ? "left" : "self-start",
          flexWrap: "wrap",
        }}
      >
        <Grid sx={{ textAlign: isMobile ? "left" : "left" }}>
          <Typography
            variant="subtitle2"
            sx={{ color: "grey", textAlign: isMobile ? "left" : "left" }}
          >
            Account Name
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography
              sx={{
                marginBottom: "5px",
                textAlign: isMobile ? "left" : "left",
                typography: {
                  xs: "subtitle2",
                  sm: "subtitle2",
                  md: "subtitle1",
                  lg: "subtitle1",
                  xl: "subtitle1",
                },
              }}
            >
              {"SAVCITY TECHNOLOGIES PRIVATE LIMITED"}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                color: "#7643EB",
              }}
              onClick={() =>
                copyToClipboard(
                  "SAVCITY TECHNOLOGIES PRIVATE LIMITED",
                  setCopiedAccountName
                )
              }
            >
              {copiedAccountName ? (
                <DoneAllIcon sx={{ fontSize: "15px", marginRight: "4px" }} />
              ) : (
                <ContentCopyIcon
                  sx={{ fontSize: "15px", marginRight: "4px" }}
                />
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid>
          <Typography
            variant="subtitle2"
            sx={{
              color: "grey",
              textAlign: isMobile ? "left" : "left",
            }}
          >
            Account Number
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography
              sx={{
                marginBottom: "5px",
                textAlign: isMobile ? "left" : "left",
                typography: {
                  xs: "subtitle2",
                  sm: "subtitle2",
                  md: "subtitle1",
                  lg: "subtitle1",
                  xl: "subtitle1",
                },
              }}
            >
              {data?.bankingDetails?.accountNumber || "Not Registered"}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                color: "#7643EB",
              }}
              onClick={() =>
                copyToClipboard(
                  data?.bankingDetails?.accountNumber,
                  setCopiedAccountNumber
                )
              }
            >
              {copiedAccountNumber ? (
                <DoneAllIcon sx={{ fontSize: "15px", marginRight: "4px" }} />
              ) : (
                <ContentCopyIcon
                  sx={{ fontSize: "15px", marginRight: "4px" }}
                />
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ textAlign: isMobile ? "left" : "left" }}>
          <Typography
            variant="subtitle2"
            sx={{ color: "grey", textAlign: isMobile ? "left" : "left" }}
          >
            IFSC Code
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography
              sx={{
                marginBottom: "5px",
                textAlign: isMobile ? "left" : "left",
                typography: {
                  xs: "subtitle2",
                  sm: "subtitle2",
                  md: "subtitle1",
                  lg: "subtitle1",
                  xl: "subtitle1",
                },
              }}
            >
              {data?.bankingDetails?.ifscCode || "Not Registered"}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                color: "#7643EB",
              }}
              onClick={() =>
                copyToClipboard(
                  data?.bankingDetails?.ifscCode,
                  setCopiedIfscCode
                )
              }
            >
              {copiedIfscCode ? (
                <DoneAllIcon sx={{ fontSize: "15px", marginRight: "4px" }} />
              ) : (
                <ContentCopyIcon
                  sx={{ fontSize: "15px", marginRight: "4px" }}
                />
              )}
            </Typography>
          </Box>
        </Grid>
        {isMobile && <InstructionTypography />} {/* Typography for mobile */}
        {isUserBuyer && (
          <Button
            sx={{
              mt: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
              alignSelf: isMobile ? "left" : "center",
            }}
            onClick={handleOpenModel}
            variant="contained"
            size={isMobile ? "small" : "medium"}
          >
            How To Add Funds
          </Button>
        )}
        {/* Typography for larger screens */}
      </Box>
      {!isMobile && <InstructionTypography />}{" "}
      <HowToAddFundsModal
        openModel={openModel}
        setOpenModel={setOpenModel}
        data={data}
      />
    </Box>
  );
}
