import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Card, Divider, IconButton } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../../../api/services";
import {
  getDealsDataById,
  getSignerStatus,
} from "../../../../../api/services/deals";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function DealDocumentationView() {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dealPublished, setDealPublished] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handlePublicationSuccess = () => {
    setDealPublished(true);
    handleClose();
  };

  const { data: profiledata }: any = useQuery("getUser-id", getUserProfile);

  const { data: dealData }: any = useQuery(["deal", dealId], getDealsDataById);

  const data = dealData?.data;

  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );

  const firstPerson = dealCreator?.userId === profiledata?.data?.id;

  const [numPages, setNumPages] = useState(0);
  const [formDate, setFormDate] = useState();

  const handleDownload = () => {
    fetch(
      data?.status === "Deal Accepted"
        ? data?.unsignedDocUrl
        : data?.signedDocUrl
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "deal.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  // const { data: signeeData }: any = useQuery(["deal", dealId], getSignerStatus);

  const { mutate: status, isLoading: userLoader } = useMutation(
    getSignerStatus,
    {
      onSuccess: (res: any) => {
        toast.success("status updated successfully");
        localStorage.removeItem("statusUpdated");
        queryClient.invalidateQueries("deal");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleStatus = () => {
    status({ data: { code: data?.code } });
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{
        padding: "0px 20px",
        width: "98vw",
        minHeight: "83vh",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          minHeight: "83vh",
          py: "3vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <Box my={"40"} display={"flex"} flexDirection={"row"}>
            <IconButton
              size="medium"
              sx={{ mt: -0.5 }}
              onClick={() => {
                navigate(`deal-process/deals/${dealId}`, { replace: true });
              }}
            >
              <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "18px" }}
            >
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deal-process/deals/${dealId}`}
              >
                Deals
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deal-process/deals/${dealId}`}
              >
                {dealId}
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deal-process/deals/${dealId}`}
              >
                Deal Document
              </Link>
            </Breadcrumbs>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            sx={{
              mt: -2,
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            Download
          </Button>
        </Box>

        <Divider sx={{ width: "100vw", ml: -8 }} />

        <Box display={"flex"}>
          <Box
            sx={{
              width: "100%",
              padding: 3,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F9F9F9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  //   width: "50vw",
                  padding: "40px",
                  backgroundColor: "#F9F9F9",
                }}
              >
                <Card
                  sx={{
                    padding: "20px",
                    backgroundColor: "white",
                    overflow: "auto",
                    maxHeight: {
                      xs: "30vh", // maxHeight for extra-small screens
                      sm: "55vh", // maxHeight for small screens
                      md: "75vh", // maxHeight for medium screens
                      lg: "95vh", // maxHeight for large screens
                      xl: "115vh", // maxHeight for extra-large screens
                    },
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: "100%",
                      marginLeft: 12,
                      marginTop: -30,
                    }}
                  >
                    <Document
                      file={
                        data?.status === "Deal Accepted"
                          ? data?.unsignedDocUrl
                          : data?.signedDocUrl
                      }
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <div key={index}>
                          {index !== 0 && <Divider sx={{ my: 2 }} />}

                          <Page
                            pageNumber={index + 1}
                            className="pdf-page"
                            renderMode="canvas"
                            width={800}
                            loading="..."
                          />
                        </div>
                      ))}
                    </Document>
                  </div>
                </Card>
              </Box>
            </Box>
          </Box>

          {/* <Box
            sx={{
              width: "30%",
              borderLeft: "1px solid #1212121A",
              padding: 3,
            }}
          >
            <SigneeCard
              data={data}
              accountOwner={firstPerson}
              needed={profiledata}
            />
          </Box> */}
        </Box>
      </Card>

      <style>{`
          .pdf-page {
            margin-bottom: -16px; 
          }
        `}</style>
    </Box>
  );
}
