export const termsHtml = `
    <style>
      .title {
        text-align: center;
      }
      .main-div {
        text-align: justify;
        text-justify: inter-word;
      }
      .subPoints,
      .mainPoints {
        list-style-type: none; /* Remove default list numbering */
        padding-left: 1em; /* Space to the left to account for the custom numbering */
        counter-reset: item; /* Initialize a counter for the list */
        margin: 10px;
      }
      .subPoints li {
        position: relative;
        padding-left: 2em; /* Enough space for longer Roman numerals like viii. */
        text-indent: 0; /* Ensure no additional indent for the text */
        padding-top: 0.8em;
      }
      .mainPoints li {
        position: relative;
        padding-left: 1em;
        text-indent: 0;
        padding-top: 0.8em;
      }
      .subPoints li::before,
      .mainPoints li::before {
        counter-increment: item;
        position: absolute;
        right: 100%;
        width: 2em;
        text-align: right;
        margin-right: 10px;
      }
      .mainPoints li::before {
        content: counter(item, decimal) ". ";
      }
      .subPoints li::before {
        content: counter(item, lower-roman) ". ";
      }
      table {
        border-collapse: collapse;
        align-self: center;
      }
      td,
      th {
        border: 1px solid #dddddd;
        text-align: center;
        padding: 8px;
      }
      .table-div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    </style>

    <h2 class="title">Truupe Terms of Use</h2>
    <div class="main-div">
      <p style="font-weight: 400">
        THIS DOCUMENT/AGREEMENT IS AN ELECTRONIC RECORD IN TERMS OF THE
        INFORMATION TECHNOLOGY ACT, 2000, AND ALL RULES AND REGULATIONS MADE
        THEREUNDER AND DOES NOT REQUIRE PHYSICAL OR DIGITAL SIGNATURE. THIS
        DOCUMENT HAS BEEN PUBLISHED IN ACCORDANCE WITH RULE 3 OF THE INFORMATION
        TECHNOLOGY (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA ETHICS CODE)
        RULES, 2021.
      </p>
      <p>
        This website viz. “www.truupe.com” along with any of its sub domains and
        any other website and mobile applications, and / or other domains or
        platforms owned and /or operated by the Company (hereinafter referred to
        as “<b>Platform</b>”) to provide the Platform Services is owned, hosted
        and operated by Savcity Technologies Private Limited having its
        registered office at Villa no 12, PMR Parkwood, Kompally, Hyderabad,
        India (“<b>We</b>”, “<b>Company</b>”, “<b>Us</b>” or “<b>Truupe</b>”).
      </p>
      <p>
        These terms and conditions along with the Privacy Policy and any other
        policy which the Company may publish on the Platform from time to time
        (“<b>Terms of Use</b>”) govern the use and access of the Platform
        provided by the Company and constitute a binding and enforceable
        agreement between the you and the Company. By selecting to browse,
        access or use the Platform, you hereby consent to the terms and
        conditions of these Terms of Use. Truupe reserves the right to update
        and modify the Terms of Use at any time. The most current version of the
        Terms of Use can be reviewed by clicking on the &quot;Terms of Use&quot;
        hypertext link located at the bottom of our web pages. When we make
        updates to the Terms of Use, Truupe will update the date at the top of
        this page. By using the website after a new version of the Terms of Use
        been posted, you agree to the terms of such new version.
      </p>
      <p>
        In case you access, browse or use this Platform on behalf of another
        Person, you represent that you have the requisite authority to legally
        bind the said Person to these Terms of Use. In case the said Person
        declines to be bound as the principal to these Terms of Use, you consent
        to all liability for any unauthorized, unlawful or wrongful use of the
        Platform arising out of such access or use. If you do not agree to these
        Terms of Use or any other policy published by the Company, including the
        Privacy Policy, please immediately terminate your use of the Platform.
      </p>
      <div>
        <ol type="1" class="mainPoints">
          <li>
            <b>Definitions</b>
            <br /><br />
            In these Terms of Use, unless the context otherwise requires, the
            following words and expressions shall bear the meanings ascribed to
            them below:
            <ol type="i" class="subPoints">
              <li>
                “<b>Applicable Laws</b>” means all applicable provisions of all
                constitutions, treaties, statutes, laws, codes, rules,
                regulations, ordinances, orders, governmental approvals, or any
                similar form of decision of, or determination by, or any
                interpretation, policy or administrative instruction having the
                force of law of any of the foregoing, having jurisdiction over
                the Company and you, as may be in force from time to time;
              </li>
              <li>
                “<b>Approved Payment Method</b>” means NEFT/RTGS in relation to
                payment Deal Amounts and means any online payment method of
                payment which is made available to the Users on the Platform
                including through credit card/internet banking etc.;
              </li>
              <li>
                “<b>Approved Third Party Platform</b>” means third party
                platforms integrated with the Platform for execution of
                documents electronically/ digitally;
              </li>
              <li>
                “<b>Authorised Signatory</b>” means one or more individuals who
                are authorized and whose details are more specifically set out
                under the Deal Agreement by the relevant User to register a
                Truupe Account on the Platform, execute Deal Agreement and any
                other documents or agreements required to be executed in order
                to use the Platform Services, including issuance of any Release
                Instructions;
              </li>
              <li>
                “<b>Buyer</b>” means a User who intends to purchase goods and/or
                services from the Seller(s) and uses the Platform to make
                payment for such purchase through the Escrow Account;
              </li>
              <li>
                “<b>Deal</b>” means opening of an Escrow Account pursuant to the
                sale of goods or services by the Seller to the Buyer;
              </li>
              <li>
                “<b>Deal Agreement</b>” means an agreement executed on the
                Platform between a Buyer, Seller, Escrow Agent and the Company
                in relation to provision of the Platform Services;
              </li>
              <li>
                “<b>Deal Amounts</b>” means the aggregate funds actually
                deposited by the Buyer in the Escrow Account, to be paid to the
                Seller through the Platform pursuant to the terms of the Deal
                Agreement, irrespective of the amount set out as ‘Deal Amount’
                under the Deal Agreement;
              </li>
              <li>
                “<b>Escrow Account</b>” means a neutral third party listed on
                the Platform to act as a trustee for the Escrow Accounts, to
                administer the terms of the Deal Agreement including
                communication of the Release Instructions to the Escrow Bank;
              </li>
              <li>
                “<b>Escrow Agent</b>” means a neutral third party listed on the
                Platform to act as a trustee for the Escrow Accounts, to
                administer the terms of the Deal Agreement including
                communication of the Release Instructions to the Escrow Bank;
              </li>
              <li>
                “<b>Escrow Bank</b>” means RBI licensed banks or financial
                institutions, onboarded on the Platform, which banks or
                financial institutions provide escrow services;
              </li>
              <li>
                “<b>Know Your Customer</b>” or “<b>KYC</b>” means the
                verification of identity and address of any User in accordance
                with the policies of Truupe, Escrow Agent and Escrow Banks. To
                comply with KYC requirements, Truupe procures and verifies
                personal identification and other details such as address, bank
                account etc. from you before any Platform Services can be
                delivered;
              </li>
              <li>
                “<b>Master Escrow Account</b>” means the master notional escrow
                account(s) of Truupe opened with the Escrow Bank;
              </li>
              <li>
                “<b>Person</b>” means any natural person, limited or unlimited
                liability company, corporation, partnership (whether limited or
                unlimited), proprietorship, Hindu undivided family, trust,
                union, association, society, co-operative society, government or
                any agency or political subdivision thereof or any other entity
                that may be treated as a person under Applicable Laws;
              </li>
              <li>
                “<b>Privacy Policy</b>” means the privacy and data protection
                policy adopted by the Company, available at <b>[●]</b>;
              </li>
              <li>“<b>RBI</b>” the Reserve Bank of India;</li>
              <li>
                “<b>Release Instructions</b>” means the instructions for
                disbursal of the Deal Amount, initiated by one User and
                confirmed by other User;
              </li>
              <li>
                “<b>Seller</b>” means a User offering goods and/or services for
                sale to the Buyer(s) and uses the Platform to receive payment
                for such sale through the Escrow Account;
              </li>
              <li>
                “<b>User</b>” or "<b>you</b>" means any Person who accesses or
                visits the Platform and/or avails one or more Platform Services
                or a Person which is registered on the Platform and agrees to
                use the Platform Services as a Seller and/or a Buyer. The terms
                “your” and “yourself” shall be construed accordingly.
              </li>
            </ol>
          </li>
          <li>
            <b>Description of Platform Services</b>
            <br /><br />
            The Company aims to bridge a trust gap in terms of both payments and
            specific performance for transactions which Buyers and Sellers
            intend to carry out in India. In order to do so, the Company
            facilitates the transaction contemplated in a Deal Agreement
            interalia:
            <ol type="i" class="subPoints">
              <li>
                recording the commercial terms agreed between the Buyer and
                Seller;
              </li>
              <li>
                availing escrow services by getting connected with Escrow Banks
                and Escrow Agents;
              </li>
              <li>
                opening no-lien, interest-free Escrow Accounts and deposit the
                Deal Amounts pursuant to the commercial agreements executed
                amongst the Users;
              </li>
              <li>
                requesting the Escrow Agent to release the Deal Amounts to the
                Seller as per the Release Instructions; and
              </li>
              <li>
                providing ancillary or supplementary services, directly or
                through third parties, to ensure smooth collection and payment
                of Deal Amounts (collectively “Platform Services”).
              </li>
            </ol>
            Please note that the Company offers Platform Services only within
            the state of India. All Deal Amounts collected or received in the
            Escrow Account shall be in Indian Rupees only.
          </li>
          <li>
            <b>Truupe Account </b>
            <br /><br />
            You accept and acknowledge the following conditions to access and
            use of the Platform and Platform Services:
            <ol type="i" class="subPoints">
              <li>
                To access the Platform services, you will need to register a
                Truupe Account by providing the information requested on the
                Platform (including full name, email address and date of birth)
                and creating a password.
              </li>
              <li>
                You consent to the use of this information to create your Truupe
                Account in accordance with our Privacy Policy and acknowledge
                that we rely on the information provided by you during the
                registration process, being true, accurate, current and
                complete.
              </li>
              <li>
                You agree to provide all information and documents we, Escrow
                Agent or Escrow Bank may need from time to time so we can verify
                your identity and/or your bank account and so we may comply with
                our obligations to combat financial crime, including money
                laundering and fraud. We may use third-party data services that
                specialize in customer due diligence services to verify your
                identity. You also authorize us to hold your information,
                including your browsing and activity history, so we may perform
                ongoing monitoring of the Platform Services and your behaviours
                when using the Platform to comply with our legal, contractual
                and regulatory obligations.
              </li>
              <li>
                If we do not receive satisfactory information to verify your
                identity at the point of registration, we reserve the right to
                refuse to register your Truupe Account and prevent you from
                gaining access to the Platform and Platform Services. We may
                also have obligations to report such activity to governmental
                agencies and we do not require your consent to do so.
              </li>
              <li>
                It is your responsibility to keep your username and password
                (“Account Details”) confidential and secure. In furtherance
                hereof, the User agrees to choose a new password, whenever
                required for security reasons and shall be responsible for any
                transactions made by User under its Truupe Account.
              </li>
              <li>
                You indemnify us for any loss or damage we suffer as a result of
                your fraudulent or unauthorised use of your Truupe Account
                including you knowingly sharing your Account Details with a
                third party for the purposes of fraud. You must take all
                reasonable steps to avoid the loss, theft or misuse of the
                Truupe Account.
              </li>
              <li>
                We may suspend your use of the Platform Services if:
                <ol type="a" class="subPoints">
                  <li>
                    we have reasonable concerns about the security of the Truupe
                    Account;
                  </li>
                  <li>
                    we are unable to confirm your identity or there is a
                    potential risk of financial crime as part of our ongoing
                    monitoring process;
                  </li>
                  <li>
                    we suspect the Platform Services are being used in a
                    fraudulent or unauthorised manner;
                  </li>
                  <li>
                    Escrow Agent and/or Escrow Bank instructs us to do so; or
                  </li>
                  <li>
                    we are required to undertake any changes as per Applicable
                    Laws.
                  </li>
                </ol>
              </li>
              <li>
                We may change the Platform, Platform Services on regular
                intervals to reflect changes in relevant laws and regulatory
                requirements, to implement minor technical adjustments and
                improvements, for example to address a security threat or
                otherwise to enhance the Platform Services we provide through
                the Platform. These changes should not affect your access to or
                use of the Platform or Platform Services. If we make more
                significant changes to the Platform or the Platform Services, we
                will use our reasonable endeavours to inform you by email or by
                posting information on our Platform and may make changes to
                these terms to reflect such changes.
              </li>
              <li>
                The User hereby acknowledges and expressly agrees that all
                data/information related to the User or any other individuals
                associated with the User held by the Company may be disclosed to
                any statutory or regulatory or supervisory authority/ies upon
                request or where such disclosure is required under Applicable
                Laws.
              </li>
            </ol>
          </li>
          <li>
            <b>Deals </b>
            <ol type="i" class="subPoints">
              <li>Either User may initiate a Deal via the Platform.</li>
              <li>
                To initiate a Deal, you must log into your Truupe Account, or,
                if you do not have a Truupe Account, register a Truupe Account
                (as set out above in paragraph 3).
              </li>
              <li>
                Once you have logged into your Truupe Account, click on “Start
                Your Deal” under the Dashboard, and enter the details of the
                Deal (including the Deal Amount, details of the transaction and
                the contact details of the counterparty to that Deal) in a form.
              </li>
              <li>
                In order to join a Deal, the counterparty shall be required to
                log into their Truupe Account or, if such User does not have a
                Truupe Account, register a Truupe Account (as set out in
                paragraph 3).
              </li>
              <li>
                Once all the details have been confirmed as correct, the Users
                will be required to execute the Deal Agreement.
              </li>
              <li>
                Upon execution of the Deal Agreements by all parties thereto, a
                Deal ID will be allotted to the Deal and a payment link will be
                created for payment of the Truupe Fees.
              </li>
              <li>
                Upon payment of Truupe Fees, an Escrow Account will be created
                under the Master Escrow Account. Thereafter, the Buyer will
                initiate transfer the Deal Amount to the Escrow Account. The
                Buyer may deposit the Deal Amount in one or more tranches. The
                Deal Amounts will be debited from the Buyer’s bank account /
                credit card and transferred to the Escrow Bank and a Deal will
                be created identifiable by the Deal ID.
              </li>
              <li>
                Once the Buyer has authorised the Deal Amount, it cannot revoke
                that consent and the Deal Amount will not be released from the
                Escrow Account until the transaction has been completed or
                cancelled as per the terms hereof.
              </li>
              <li>
                For sake of clarity, the escrow Services will be provided only
                in relation to the amounts actually deposited by the Buyer, even
                if the said amount is lesser than the Deal Amount set out under
                the Deal Agreement. The Platform will not accept deposit of any
                amounts higher than the Deal Amount set out the Deal Agreement.
                The Escrow Banks shall hold the Deal Amount in their custody
                under the Master Escrow Account belonging to Truupe with the
                Escrow Agent have solely having the withdrawal rights on the
                Escrow Accounts maintained under the Master Escrow Account.
              </li>
              <li>
                Both parties will always be able to check the progress of the
                Deal by clicking on the relevant transaction in the “Deal
                section” in their respective Truupe Accounts.
              </li>
              <li>
                Once the terms of the Deal Agreement are complete, the Seller
                shall initiate the request for release of Deal Amounts, which
                shall be confirmed by the Buyer. If the Buyer confirms the
                release of Deal Amount, the Deal Amount will be automatically
                released from the Escrow Account to the bank account of the
                Seller. Once Release Instructions are submitted, they cannot be
                revoked.
              </li>
              <li>
                No interest will accumulate on the Deal Amounts while they are
                deposited with the Escrow Bank. No funds will be released from
                the Escrow Account unless the Release Instructions are received
                and confirmed by both Buyer and Seller.
              </li>
            </ol>
          </li>
          <li>
            <b>Truupe Fees </b>
            <p>
              For use of the Platform Services, the Company collects a Platform
              usage fees (“Truupe Fees”). Please see the [table below] to check
              the applicable Truupe Fees. We reserve the right to change Truupe
              Fees at any time, by providing notice on the Platform. Please note
              that the Truupe Fees is independent from the Deal Amounts and
              cannot be combined with or adjusted against the Deal Amounts.
            </p>
            <p>
              Payment against Truupe Fees can be made online through Approved
              Payment Method. Surcharges may apply for certain payment methods,
              including charges from your own card issuer. Please note that the
              payment of Deal Amount may only be done through Approved Payment
              Method and is independent of the Truupe Fees.
            </p>
            <p>
              Once paid, the Truupe Fees is non-refundable and non-adjustable,
              except as provided in these Terms of Use. In case the Truupe Fees
              is not paid within 90 (ninety) days from the date of execution of
              the Deal Agreement, then the Deal Agreement shall stand null and
              void, and the Deal Agreement shall automatically be cancelled, as
              if never executed.
            </p>
            <div class="table-div">
              <table>
                <tr>
                  <th>Deal Amount</th>
                  <th>Minimum Truupe Fees (in INR)</th>
                  <th>Maximum Truupe Fees (in INR)</th>
                </tr>
                <tr>
                  <td>Upto 1 Lac</td>
                  <td>499</td>
                  <td>1% of the Deal Amount</td>
                </tr>
                <tr>
                  <td>1 Lac to 5 Lac</td>
                  <td>999</td>
                  <td>0.8% of the Deal Amount</td>
                </tr>
                <tr>
                  <td>5 - 10 Lacs</td>
                  <td>3900</td>
                  <td>0.7% of the Deal Amount</td>
                </tr>
                <tr>
                  <td>10 lacs -50 Lacs</td>
                  <td>6900</td>
                  <td>0.5% of the Deal Amount</td>
                </tr>
                <tr>
                  <td>50 lacs - 1 Crore</td>
                  <td>24900</td>
                  <td>0.4% of the Deal Amount</td>
                </tr>
                <tr>
                  <td>1 Crore and above</td>
                  <td>39900</td>
                  <td>0.35% of the Deal Amount</td>
                </tr>
              </table>
            </div>
          </li>
          <li>
            <b>Deemed Warranties and Representations</b>
            <br /><br />
            By using the Platform and Platform Services, a User is deemed to
            have represented and warranted the following:

            <ol type="i" class="subPoints">
              <li>
                that the User is Person competent to contract as per the
                provisions of the Indian Contract Act, 1872;
              </li>
              <li>
                that all information provided by the User to the Company as part
                of its KYC verification is true and accurate. The User shall
                keep the Company informed of any changes in the information
                previously provided to the Company as part of its KYC
                verification process;
              </li>
              <li>
                that the User bears sole responsibility for ensuring compliance
                with Applicable Laws relevant to the User and bears full and
                sole responsibility arising out of any non-compliance therein;
              </li>
              <li>
                that the User shall be solely responsible for its own tax
                compliance and the payment of any and all taxes, fees, or
                charges arising from the sale of products and services and use
                of the Platform. This includes, but is not limited to, income
                taxes, sales taxes, value-added taxes, and any other applicable
                taxes imposed by governmental authorities. Users agree to
                indemnify and hold harmless Truupe from any claims, liabilities,
                or penalties resulting from the User's failure to comply with
                tax obligations.
              </li>
              <li>
                that the adherence and execution of these Terms of Use do not
                violate any other agreement, decision, judgment, order or decree
                of any court or quasi-judicial authority or any document that
                the User is party to; and
              </li>
              <li>
                that the User understands that these Terms of Use may be annexed
                to and read with a Deal Agreement.
              </li>
            </ol>
          </li>
          <li>
            <b>Links to other websites </b>
            <p>
              This site may link you to other websites that are not owned or
              controlled by the Company. The fact that we provide a link to a
              third party website does not mean that we endorse, authorize or
              sponsor that website or we are affiliated with the third party
              website’s owners or sponsors. These links are provided for your
              convenience only. The Company expressly disclaims any
              responsibility for the content, the accuracy of the information,
              the quality of products or services provided by or advertised on
              these third-party sites and /or the collection and use of
              personally identifiable information by these third-party sites. If
              you choose to hyperlink to another website, you do so at your own
              risk. We reserve the right to terminate a link to a third party
              website at any time.
            </p>
          </li>
          <li>
            <b>Limitation of Liability</b>
            <p>
              PLEASE NOTE THAT THE COMPANY IS NEITHER AN ESCROW BANK NOR AN
              ESCROW AGENT AND BEARS NO RESPONSIBILITY FOR ANY AMOUNTS COLLECTED
              OR TRANSFERRED THROUGH THE USE OF THE PLATFORM.
            </p>
            <p>
              TRUUPE DOES NOT PROMISE THAT THE PLATFROM OR ANY CONTENT THEREOF,
              PLATFORM SERVICES OR FEATURES OF THE PLATFORM WILL BE ERROR-FREE
              OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT
              YOUR USE OF THE PLATFORM WILL PROVIDE SPECIFIC RESULTS. THE
              PLATFORM AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND
              "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE PLATFORM IS
              SUBJECT TO CHANGE WITHOUT NOTICE. TRUUPE CANNOT ENSURE THAT ANY
              FILES OR OTHER DATA YOU DOWNLOAD FROM THE PLATFORM WILL BE FREE OF
              VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. TRUUPE DISCLAIMS
              ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
              ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
              PARTICULAR PURPOSE. TRUUPE DISCLAIMS ANY AND ALL LIABILITY FOR THE
              ACTS, OMISSIONS AND CONDUCT OF ANY USERS OR THIRD PARTIES IN
              CONNECTION WITH OR RELATED TO YOUR USE OF THE PLATFORM AND/OR ANY
              PLATFORM SERVICES. YOU ASSUME COMPLETE RESPONSIBILITY FOR YOUR USE
              OF THE PLATFORM AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST
              TRUUPE FOR DISSATISFACTION WITH THE PLATFORM OR ANY CONTENT IS TO
              STOP USING THE PLATFORM OR ANY SUCH CONTENT. THIS LIMITATION OF
              RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
            </p>
            <p>
              The above disclaimer applies to any damages, liability or injuries
              caused by any failure of performance, error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft or
              destruction of or unauthorized access to, alteration of, or use,
              whether for breach of contract, tort, negligence or any other
              cause of action.
            </p>
            <p>Without limiting the generality of the foregoing:</p>
            <ol type="i" class="subPoints">
              <li>
                The Company bears no responsibility in law or in equity, with
                respect to the contents of the Platform, any Deal Agreement, or
                terms contained therein or any breach thereof by any User;
              </li>
              <li>
                All Users understand and agree that the Company makes no
                representation, warranty or bears any responsibility regarding
                the performance of any obligation or quality of goods or
                services delivered under a Deal Agreement or inaccuracy or
                incompleteness of any information provided under the Deal
                Agreement. Users agree that the use of the Platform and the
                Platform Services is at their own risk;
              </li>
              <li>
                The Users acknowledge and accept that the Platform Services may
                encounter interruptions, errors, and vulnerabilities, including
                viruses and malicious code. The Company will implement necessary
                security measures to maintain service reliability, albeit
                subject to occasional downtime for maintenance. However, it
                cannot guarantee immunity against security breaches resulting in
                unauthorized access as are part and parcel of any service on the
                internet;
              </li>
              <li>
                The User understands and agrees that the Company shall under no
                circumstances be held for any direct or indirect damages or loss
                of any kind whatsoever caused due to any act or omissions as a
                result of using the third party platform including Approved
                Third Party Platform and Approved Payment Method or any such
                contents or for any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, computer virus, communications line failure, theft
                or destruction or unauthorized access to, alteration of, or use
                of information contained on the third party’s platform. No
                representations, warranties or guarantees whatsoever are made by
                the Company as to the accuracy, adequacy, reliability,
                completeness, suitability or applicability of the services
                provided by any third party;
              </li>
              <li>
                All Persons accessing the Platform hereby agree that they shall
                not hold the Company liable in law or in equity for any
                liability, claims, losses, costs (including but not limited to
                direct, indirect, incidental, special or consequential damages)
                or claims arising out of these Terms of Use or use of the
                Platform in any manner whatsoever;
              </li>
              <li>
                All Users agree that the Company shall not be responsible for
                any acts done or omitted by any Buyer or Seller in connection
                with a Deal Agreement or otherwise or for costs, fees,
                penalties, damages, or claims arising therefrom;
              </li>
              <li>
                We are not liable for any loss of profit, business, contracts,
                revenue or anticipated savings; any special, indirect or
                consequential damages or unauthorised and incorrectly executed
                Deals;
              </li>
              <li>
                All Users understand and agree that the Company shall not be
                responsible for any failure on behalf of any User to provide
                accurate, true or complete information, or for the deletion,
                corruption, loss of any information during their use of the
                Platform;
              </li>
              <li>
                All Users agree that they will use the Platform only for
                transactions which are not prohibited by any Applicable Law. The
                Company shall bear no liability for any illegal, unlawful or any
                other transactions which are prohibited by any law in force. The
                Company also reserves the right to request the Escrow Agent and
                Escrow Bank to withhold or decline any payment which is believed
                to be in furtherance of any act or omission in violation of
                these Terms of Use, Privacy Policy or Applicable Laws;
              </li>
              <li>
                The Company shall not be liable to any User or any other third
                party in case of rejection of payments by the Escrow Agent,
                Escrow Bank or any payment gateway due to any technical error,
                insufficiency of funds in the account of any User, force majeure
                event, any illegality or any other reason;
              </li>
              <li>
                The User agrees that Escrow Agent has a right to reject the
                processing of any instruction, if there is any erroneous,
                incomplete, garbled, self- contradictory, unclear or misleading
                information provided by the User or for any other reason
                whatsoever with/without assigning any reason. The User confirms
                that the User shall not hold Truupe responsible or liable for
                any loss on any grounds whatsoever incurred by the User pursuant
                to the Escrow Agent not being able to act on the basis of an
                instruction transmitted through the Platform or for any reason
                whatsoever;
              </li>
              <li>
                The Company shall not be liable for relying and acting upon any
                unauthorized communications from any User, which the Company, in
                its sole discretion, believes to be validly authorized by the
                User. The Company has no obligation to undertake any due
                diligence with respect to a Deal Agreement, request any evidence
                or inquire into the veracity of any communications to it by any
                User or a third party acting on behalf of any User;
              </li>
              <li>
                Neither the Company, Escrow Bank nor the Escrow Agent shall be
                liable for the failure of the Buyer to deposit the Deal Amount;
              </li>
              <li>
                All Users agree that the Platform Services will only be
                available for the Deal Amounts; and
              </li>
              <li>
                Notwithstanding anything contained in these Terms of Use, Deal
                Agreement, the Company’s total aggregate liability to any User
                for any act in connection with these Terms of Use or any Deal
                Agreement in any case shall be limited to the Truupe Fees
                remitted to the Company for the concerned Deal.
              </li>
            </ol>
          </li>
          <li>
            <b>Limitation of Liability</b>
            <p>
              PLEASE NOTE THAT THE COMPANY IS NEITHER AN ESCROW BANK NOR AN
              ESCROW AGENT AND BEARS NO RESPONSIBILITY FOR ANY AMOUNTS COLLECTED
              OR TRANSFERRED THROUGH THE USE OF THE PLATFORM.
            </p>
            <p>
              TRUUPE DOES NOT PROMISE THAT THE PLATFROM OR ANY CONTENT THEREOF,
              PLATFORM SERVICES OR FEATURES OF THE PLATFORM WILL BE ERROR-FREE
              OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT
              YOUR USE OF THE PLATFORM WILL PROVIDE SPECIFIC RESULTS. THE
              PLATFORM AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND
              "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE PLATFORM IS
              SUBJECT TO CHANGE WITHOUT NOTICE. TRUUPE CANNOT ENSURE THAT ANY
              FILES OR OTHER DATA YOU DOWNLOAD FROM THE PLATFORM WILL BE FREE OF
              VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. TRUUPE DISCLAIMS
              ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
              ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
              PARTICULAR PURPOSE. TRUUPE DISCLAIMS ANY AND ALL LIABILITY FOR THE
              ACTS, OMISSIONS AND CONDUCT OF ANY USERS OR THIRD PARTIES IN
              CONNECTION WITH OR RELATED TO YOUR USE OF THE PLATFORM AND/OR ANY
              PLATFORM SERVICES. YOU ASSUME COMPLETE RESPONSIBILITY FOR YOUR USE
              OF THE PLATFORM AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST
              TRUUPE FOR DISSATISFACTION WITH THE PLATFORM OR ANY CONTENT IS TO
              STOP USING THE PLATFORM OR ANY SUCH CONTENT. THIS LIMITATION OF
              RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
            </p>
            <p>
              The above disclaimer applies to any damages, liability or injuries
              caused by any failure of performance, error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft or
              destruction of or unauthorized access to, alteration of, or use,
              whether for breach of contract, tort, negligence or any other
              cause of action.
            </p>
            <p>Without limiting the generality of the foregoing:</p>
            <ol type="i" class="subPoints">
              <li>
                The Company bears no responsibility in law or in equity, with
                respect to the contents of the Platform, any Deal Agreement, or
                terms contained therein or any breach thereof by any User;
              </li>
              <li>
                All Users understand and agree that the Company makes no
                representation, warranty or bears any responsibility regarding
                the performance of any obligation or quality of goods or
                services delivered under a Deal Agreement or inaccuracy or
                incompleteness of any information provided under the Deal
                Agreement. Users agree that the use of the Platform and the
                Platform Services is at their own risk;
              </li>
              <li>
                The Users acknowledge and accept that the Platform Services may
                encounter interruptions, errors, and vulnerabilities, including
                viruses and malicious code. The Company will implement necessary
                security measures to maintain service reliability, albeit
                subject to occasional downtime for maintenance. However, it
                cannot guarantee immunity against security breaches resulting in
                unauthorized access as are part and parcel of any service on the
                internet;
              </li>
              <li>
                The User understands and agrees that the Company shall under no
                circumstances be held for any direct or indirect damages or loss
                of any kind whatsoever caused due to any act or omissions as a
                result of using the third party platform including Approved
                Third Party Platform and Approved Payment Method or any such
                contents or for any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, computer virus, communications line failure, theft
                or destruction or unauthorized access to, alteration of, or use
                of information contained on the third party’s platform. No
                representations, warranties or guarantees whatsoever are made by
                the Company as to the accuracy, adequacy, reliability,
                completeness, suitability or applicability of the services
                provided by any third party;
              </li>
              <li>
                All Persons accessing the Platform hereby agree that they shall
                not hold the Company liable in law or in equity for any
                liability, claims, losses, costs (including but not limited to
                direct, indirect, incidental, special or consequential damages)
                or claims arising out of these Terms of Use or use of the
                Platform in any manner whatsoever;
              </li>
              <li>
                All Users agree that the Company shall not be responsible for
                any acts done or omitted by any Buyer or Seller in connection
                with a Deal Agreement or otherwise or for costs, fees,
                penalties, damages, or claims arising therefrom;
              </li>
              <li>
                We are not liable for any loss of profit, business, contracts,
                revenue or anticipated savings; any special, indirect or
                consequential damages or unauthorised and incorrectly executed
                Deals;
              </li>
              <li>
                All Users understand and agree that the Company shall not be
                responsible for any failure on behalf of any User to provide
                accurate, true or complete information, or for the deletion,
                corruption, loss of any information during their use of the
                Platform;
              </li>
              <li>
                All Users agree that they will use the Platform only for
                transactions which are not prohibited by any Applicable Law. The
                Company shall bear no liability for any illegal, unlawful or any
                other transactions which are prohibited by any law in force. The
                Company also reserves the right to request the Escrow Agent and
                Escrow Bank to withhold or decline any payment which is believed
                to be in furtherance of any act or omission in violation of
                these Terms of Use, Privacy Policy or Applicable Laws;
              </li>
              <li>
                The Company shall not be liable to any User or any other third
                party in case of rejection of payments by the Escrow Agent,
                Escrow Bank or any payment gateway due to any technical error,
                insufficiency of funds in the account of any User, force majeure
                event, any illegality or any other reason;
              </li>
              <li>
                The User agrees that Escrow Agent has a right to reject the
                processing of any instruction, if there is any erroneous,
                incomplete, garbled, self- contradictory, unclear or misleading
                information provided by the User or for any other reason
                whatsoever with/without assigning any reason. The User confirms
                that the User shall not hold Truupe responsible or liable for
                any loss on any grounds whatsoever incurred by the User pursuant
                to the Escrow Agent not being able to act on the basis of an
                instruction transmitted through the Platform or for any reason
                whatsoever;
              </li>
              <li>
                The Company shall not be liable for relying and acting upon any
                unauthorized communications from any User, which the Company, in
                its sole discretion, believes to be validly authorized by the
                User. The Company has no obligation to undertake any due
                diligence with respect to a Deal Agreement, request any evidence
                or inquire into the veracity of any communications to it by any
                User or a third party acting on behalf of any User;
              </li>
              <li>
                Neither the Company, Escrow Bank nor the Escrow Agent shall be
                liable for the failure of the Buyer to deposit the Deal Amount;
              </li>
              <li>
                All Users agree that the Platform Services will only be
                available for the Deal Amounts; and
              </li>
              <li>
                Notwithstanding anything contained in these Terms of Use, Deal
                Agreement, the Company’s total aggregate liability to any User
                for any act in connection with these Terms of Use or any Deal
                Agreement in any case shall be limited to the Truupe Fees
                remitted to the Company for the concerned Deal.
              </li>
            </ol>
          </li>
          <li>
            <b>Dispute</b>
            <p>
              Any dispute between the Users in relation to a Deal will be
              between the Users. Truupe, Escrow Bank(s) and Escrow Agents will
              not get involved beyond their respective obligations under the
              Deal. Truupe may offer assistance or facilitate the resolution of
              a dispute between the Users if a dispute between Users arises but
              is under no obligation to do so.
            </p>
            <p>
              In case of dispute, the Escrow Agents will hold the funds in the
              Escrow Account until the Users resolve the dispute and confirm and
              submit the Release Instructions or the Escrow Agent receives an
              order from an arbitrator or from an appropriate court of law or
              government body for release of Deal Amounts.
            </p>
          </li>
          <li>
            <b>Prohibited goods and services </b>
            <p>
              You agree that you will not use the Platform Services in
              connection with any prohibited goods and services or businesses,
              business activities or business practices prohibited under
              Applicable Laws, including without limitation:
            </p>
            <ol type="i" class="subPoints">
              <li>
                Alcohol which includes alcohol or alcoholic beverages such as
                beer, liquor, wine, or champagne etc.;
              </li>
              <li>Body parts which include organs or other body parts;</li>
              <li>
                Bulk marketing tools which include email lists, software, or
                other products enabling unsolicited email messages (spam);
              </li>
              <li>
                Cable descramblers and black boxes which include devices
                intended to obtain cable and satellite signals for free;
              </li>
              <li>
                Adult goods and services which include pornography, child
                pornography and pornographic materials involving minors, and
                other sexually suggestive materials (including literature,
                imagery and other media), escort or prostitution services,
                website access and/or website memberships of pornography or
                illegal sites;
              </li>
              <li>
                Copyright unlocking devices which include mod chips or other
                devices designed to circumvent copyright protection;
              </li>
              <li>
                Copyrighted media which includes unauthorized copies of books,
                music, movies, and other licensed or protected materials;
              </li>
              <li>
                Copyrighted software which includes unauthorized copies of
                software, video games and other licensed or protected materials,
                including OEM or bundled software;
              </li>
              <li>
                Counterfeit and unauthorized goods which include replicas or
                imitations of designer goods, items without a celebrity
                endorsement that would normally require such an association,
                fake autographs, counterfeit stamps, and other potentially
                unauthorized goods;
              </li>
              <li>
                Drugs and drug paraphernalia which include illegal drugs and
                drug accessories, including herbal drugs like marijuana, salvia
                and magic mushrooms etc.;
              </li>
              <li>
                Drug test circumvention aids which include drug cleansing
                shakes, urine test additives, and related items;
              </li>
              <li>
                Endangered species which include plants, animals or other
                organisms (including product derivatives) in danger of
                extinction;
              </li>
              <li>
                Gaming/ gambling which include lottery tickets, sports bets,
                stock bets, memberships/ enrolment in online gambling sites, and
                related content;
              </li>
              <li>
                Government IDs or documents which include fake IDs, passports,
                diplomas, and noble titles;
              </li>
              <li>
                Hacking and cracking materials which include manuals, how-to
                guides, information, or equipment enabling illegal access to
                software, servers, website, or other protected property;
              </li>
              <li>
                Illegal goods which include materials, products, or information
                promoting illegal goods or enabling illegal acts;
              </li>
              <li>
                Narcotic drugs and psychotropic substances, miracle cures which
                include unsubstantiated cures, remedies or other items marketed
                as quick health fixes;
              </li>
              <li>
                Offensive goods which include literature, products or other
                materials that inter alia defame or slander any person or groups
                of people based on race, ethnicity, national origin, religion,
                sex, or other factors; encourage or incite violent acts; or
                promote intolerance or hatred;
              </li>
              <li>
                Offensive goods which include crime scene photos or items, such
                as personal belongings, associated with criminals;
              </li>
              <li>
                Pyrotechnic devices, combustibles, corrosives and hazardous
                materials which include explosives and related goods, toxic,
                flammable, and radioactive materials and substances;
              </li>
              <li>
                Regulated goods which include air bags, batteries containing
                mercury, freon or similar substances/ refrigerants, chemical/
                industrial solvents, government uniforms, car titles, license
                plates, police badges and law enforcement equipment,
                lock-picking devices, pesticides, postage meters, recalled
                items, slot machines, surveillance equipment, goods regulated by
                government or other agency specifications;
              </li>
              <li>
                Securities which include government and/ or public sector unit
                bonds, stocks, debentures or related financial products;
              </li>
              <li>
                Tobacco and cigarettes which include cigarettes, cigars, chewing
                tobacco, and related products;
              </li>
              <li>
                Weapons which include firearms, ammunition, knives, brass
                knuckles, gun parts, gun powder or explosive mixtures and other
                armaments;
              </li>
              <li>
                Wholesale currency which includes discounted currencies or
                currency exchanges;
              </li>
              <li>
                Live animals or hides/ skins/ teeth, nails and other parts etc.
                of animals;
              </li>
              <li>Mailing lists Database providers (for tele-callers);</li>
              <li>
                Virtual currency, cryptocurrency, prohibited investments for
                commercial gain or credits that can be monetized, re-sold or
                converted to physical or digital goods or services or otherwise
                exit the virtual world;
              </li>
              <li>Money laundering services; and</li>
              <li>
                Activities prohibited by the Telecom Regulatory Authority of
                India.
              </li>
            </ol>
            The above list is subject to changes by Truupe without prior
            intimation to User.
          </li>
          <li>
            <b>Indemnity </b>
            <p>
              You agree to indemnify and hold harmless the Company and any of
              its members, officers, directors, employees, agents or
              representatives (“<b>Indemnified Parties</b>”) from any liability
              including but not limited to any and all claims, demands, losses,
              costs, expenses, damages, and deficiencies, including, without
              limitation, interest, penalties, and attorneys’ fees that
              Indemnified Parties may suffer or incur arising from (i) your
              breach of any representations or obligations under these Terms of
              Use; (ii) any action done or omitted by the Company based on
              information supplied to it by you, including but not limited to
              information supplied during KYC verification, as part of Deal
              Agreement or Release Instructions; or (iii) as a consequence of
              any breach or misrepresentation by a User.
            </p>
          </li>
          <li>
            <b>Refund and Cancellation </b>
            <p>
              The Buyer may request for cancellation of a transaction within 14
              (fourteen) days after the payment of Truupe Fees and therefore be
              eligible for the receipt of a refund. All requests for
              cancellations or refunds must be made within 14 (fourteen) days of
              payment of Truupe Fees, and prior to the release of the Deal
              Amount, in writing to support@truupe.com. Upon the expiration of
              the 14 (fourteen) day period post payment, the Truupe Fees shall
              be non-refundable, non-transferable, non-adjustable and agnostic
              of the outcome of the transaction against which the Truupe Fees
              was collected.
            </p>
            <p>
              [Once the Deal Amounts have been deposited into the Escrow Bank,
              if the Deal has not been completed, or as otherwise agreed/set by
              the Users on the Platform, the Buyer may lodge a complaint via the
              Platform. The Seller shall be notified of such Buyer request and,
              should the refund be agreed by both parties, a refund shall be
              initiated, and the Deal Amounts shall be returned to the Buyer. We
              reserve the right to charge any administrative fees or other
              charges for a cancellation. While both parties can mutually agree
              to cancel a Deal, however the Truupe Fees will be non-refundable.
            </p>
          </li>
          <li>
            <b>Privacy </b>
            <p>
              The Company is committed to safeguarding user privacy and ensuring
              that all Users are informed about the Company's policy for data
              protection. Request you to kindly refer to the Privacy Policy,
              which is hereby incorporated by reference, for further details. By
              using the Platform Services and accessing the Platform, you
              consent to our collection and use of personal data as outlined
              therein. Further, you give consent to Truupe to contact you via
              WhatsApp, Email, SMS, and other modes of communication.
            </p>
            <p>
              When you provide suggestions or feedback about our Platform
              Services/Platform, you agree that We can utilize and share this
              feedback for any purpose without compensating you, and the Company
              is not obligated to keep such feedback confidential.
            </p>
          </li>
          <li>
            <b>Aadhaar </b>
            <p>
              The User approves the collection, storage, use and dissemination
              of data governed under the Aadhaar (Sharing of Information)
              Regulations 2016 and rules and regulation made thereunder (“<b
                >Aadhar Regulations</b
              >”), including for KYC verification and execution of various
              agreements and documents for using Platform Services. Truupe will
              undertake necessary steps to encrypt or anonymise such data /
              information to ensure compliance with the Aadhar Regulations.
            </p>
          </li>
          <li>
            <b>Intellectual Property </b>
            <ol type="i" class="subPoints">
              <li>
                All brand names, logos, trademarks, design marks, domain names,
                trade dress, and specific webpage layouts of the Platform,
                including look and feel, calls to action, text placement,
                images, technology, content, software, and other materials, are
                the exclusive property of the Company, its affiliates, partners,
                or licensors ("<b>Company IP</b>"). This intellectual property,
                whether registered or unregistered, is protected by Indian laws
                governing all forms of intellectual property. You are prohibited
                from directly or indirectly copying, reproducing, modifying,
                duplicating, republishing, reselling, or redistributing any
                Company IP, in whole or in part, or in any other form. Accessing
                the Platform or Platform Services does not grant authorization
                to use any Company IP or its components in any manner.
              </li>
              <li>
                The Company expressly reserves all intellectual property rights
                in all text, programs, products, processes, technology, content,
                software, and other materials appearing on the Platform,
                including its visual appearance.
              </li>
              <li>
                No rights, titles, or interests in any downloaded materials or
                software are transferred to you through downloading, copying,
                reproducing, duplicating, republishing, posting, transmitting,
                distributing, or modifying such materials.
              </li>
              <li>
                Subject to your compliance with these Terms of Use and payment
                of TruupeFees, the Company grants you a limited, non-exclusive,
                non-transferable license to access and use the Platform
                Services, strictly for your personal use. Users are not
                permitted to sublicense the rights granted herein.
              </li>
              <li>
                No part of the Platform and no content thereon may be copied,
                reproduced, republished, uploaded, posted, publicly displayed,
                encoded, translated, transmitted or distributed in any way
                (including "mirroring") to any other computer, server, web site
                or other medium for publication or distribution or for any
                commercial enterprise.
              </li>
            </ol>
          </li>
          <li>
            <b>Use of Platform </b>
            <ol type="i" class="subPoints">
              <li>
                You shall not use any "deep-link", "page-scrape", "robot",
                "spider" or other automatic device, program, algorithm or
                methodology, or any similar or equivalent manual process, to
                access, acquire, copy or monitor any portion of the Platform or
                any content thereon, or in any way reproduce or circumvent the
                navigational structure or presentation of the Platform or any
                content thereon, to obtain or attempt to obtain any materials,
                documents or information through any means not purposely made
                available through the Platform. Truupe reserves the right to bar
                any such activity.
              </li>
              <li>
                You shall not attempt to gain unauthorized access to any portion
                or feature of the Platform, or any other systems or networks
                connected to the Platform or to any server of Truupe, or to any
                of the Platform Services offered on or through the Platform, by
                hacking, password "mining" or any other illegitimate means.
              </li>
              <li>
                You shall not probe, scan or test the vulnerability of the
                Platform or any network connected to the Platform, nor breach
                the security or authentication measures on the Platform or any
                network connected to the Platform. You may not reverse look-up,
                trace or seek to trace any information on any other User of or
                visitor to the Platform, including any Truupe Account not owned
                by you, to its source, or exploit the Platform or any Platform
                Service or information made available or offered by or through
                the Platform, in any way where the purpose is to reveal any
                information, including but not limited to personal
                identification or information, other than your own information,
                as provided for by the Platform.
              </li>
              <li>
                You agree not to take any action that imposes an unreasonable or
                disproportionately large load on the infrastructure of the
                Platform or Truupe’s systems or networks, or any systems or
                networks connected to the Platform or Truupe.
              </li>
              <li>
                You agree not to use any device, software or routine to
                interfere or attempt to interfere with the proper working of the
                Platform or any transaction being conducted on the Platform, or
                with any other person’s use of the Platform.
              </li>
              <li>
                You shall not forge headers or otherwise manipulate identifiers
                in order to disguise the origin of any message or transmittal
                you send to Truupe on or through the Platform or any Platform
                Service offered on or through the Platform. You may not pretend
                that you are, or that you represent, someone else, or
                impersonate any other individual or entity.
              </li>
              <li>
                You may not use the Platform or any content thereon for any
                purpose that is unlawful or prohibited by these Terms of Use, or
                to solicit the performance of any illegal activity or other
                activity which infringes the rights of Truupe, Escrow Agents,
                Escrow Banks or others.
              </li>
            </ol>
          </li>
          <li>
            <b>Miscellaneous </b>
            <ol type="i" class="subPoints">
              <li>
                <u>Force Majeure</u>: The Company shall not be deemed in breach
                of its obligations under these Terms of Use, nor shall it incur
                any liability towards any User or third party, due to
                occurrences beyond its reasonable control that could not have
                been prevented through reasonable diligence, such as delays
                caused by acts of God, governmental or supra-national authority
                regulations, war, national emergency, accidents, fires, riots,
                strikes, lock-outs, and industrial disputes.
              </li>
              <li>
                <u>Term and Termination</u>: The Company reserves the right to
                suspend or terminate your access to the Platform at any time,
                with or without cause, and with or without notice, including but
                not limited to, if the Company believes that you have violated
                these Terms of Use or engaged in any fraudulent, abusive, or
                illegal activities. Upon termination of your access, you will no
                longer have access to the Platform Services. These Terms of Use
                will remain in effect indefinitely unless and until the Company,
                at its sole discretion and without prior notice to you, decides
                to terminate them.
              </li>
              <li>
                <u>Amendment</u>: The Company reserves the right to modify or
                amend these Terms of Use at any time with notice. Any changes to
                these Terms of Use will be effective immediately upon
                publication on the Platform. By continuing to access or use the
                Platform after any modifications to these Terms of Use have been
                posted, you agree to be bound by the updated Terms of Use. Users
                are encouraged to review these Terms of Use regularly to stay
                informed of any changes. Further, Truupe reserves the right to
                do any of the following, at any time, without notice: (1) to
                modify, suspend or terminate operation of or access to the
                Platform, or any portion of the Platform, for any reason; (2) to
                modify or change the Platform, or any portion of the Platform,
                and any applicable policies or terms; and (3) to interrupt the
                operation of the Platform, or any portion of the Platform, as
                necessary to perform routine or non-routine maintenance, error
                correction, or other changes. Your continued use of the Platform
                following the posting of any changes constitutes acceptance of
                those changes.
              </li>
              <li>
                <u>Entire Agreement</u>: You acknowledge that these Terms of Use
                constitute the entire agreement between You and the Company
                relating to the use and access of the Platform and supersedes
                all prior agreements, understandings, negotiations, and
                discussions, whether oral or written. You further understand
                that these Terms of Use may be read with and be supplemented
                with any additional agreements or documents at the time of
                deposit of Deal Amounts.
              </li>
              <li>
                <u>Electronic Signature</u>: The documents/instructions for any
                transactions executed through the Platform and documents
                required to be uploaded on the Platform shall be submitted
                through and affixed with electronic signatures by use of an
                Approved Third Party Platform. The Users shall always affix the
                electronic signatures by logging in through the email address of
                the Authorised Signatory. Usage of any other email address for
                affixing electronic signatures, would, in Truupe’s sole
                discretion lead to rejection of the document sent or
                instructions of the Users. The Users understand that documents
                received in electronic form online (or electronically signed)
                are equivalent to physical documents with wet signatures. Truupe
                and Escrow Agent shall be entitled to fully rely on the
                authority of the persons electronically signing the
                documents/instructions on behalf of the Users, without being
                required to check the validity of internal authorization
                provided by the Users. It shall be sole responsibility of the
                Users to ensure that the person/s electronically signing the
                documents is/are validly authorized in respect of the documents
                electronically signed by them on behalf of the User and Truupe
                and Escrow Agent shall be fully entitled to treat such documents
                as validly executed by the relevant User.
                <br />
                The User hereby requests and authorizes Truupe, Escrow Agent and
                Escrow Bank to rely upon and act or omit to act, at its absolute
                discretion, in accordance with any instructions submitted to
                through the Platform using electronic signatures. The User
                declares and confirms that the User has, after being fully aware
                of, and having duly considered, the risks involved (which risks
                shall be borne fully by the User) requested and authorised each
                of Truupe, Escrow Agent and Escrow Bank to act on instructions
                signed electronically.
              </li>
              <li>
                <u>Assignment</u>: The Company reserves the right to assign,
                transfer, or delegate any of its rights and obligations under
                these Terms of Use to any third party, without your consent or
                without providing notice of the same to you. You may not assign,
                transfer, or delegate any of your rights or obligations under
                these Terms of Use without the prior written consent of the
                Company.
              </li>
              <li>
                <u>Severability</u>: If any provision of these Terms of Use is
                held to be invalid or unenforceable, the remaining provisions
                shall continue to be valid and enforceable to the fullest extent
                permitted by law.
              </li>
              <li>
                <u>Governing Law and Dispute Resolution</u>: These Terms of Use
                shall be governed by and construed in accordance with the laws
                of Hyderabad, India. Any disputes arising out of or relating to
                these Terms of Use or the use of the Platform shall be resolved
                exclusively through legal proceedings in a court of competent
                jurisdiction in Hyderabad, India. Each party hereby submits to
                the exclusive jurisdiction of the courts in Hyderabad, India for
                such purposes.
              </li>
              <li>
                <u>Grievance Redressal</u>: In case of any complaints or
                concerns regarding the use of the Platform, the Platform
                Services, or these Terms of Use please feel free to write to us
                at [Villa no 12, PMR Parkwood, Kompally, Hyderabad, India] or
                via mail at
                <a href="mailto:support@truupe.com" target="_blank"
                  >[support@truupe.com]</a
                >.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
`;
