import { Box, LinearProgress, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getComapanyDetailsById,
  getFirmAddressProof,
  getStatusbyId,
  userActivity,
} from "../../../api/services";

import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import DocumentSelect from "../../../components/shared/DocumentSelect";
import DocumentUploader from "../../../components/shared/DocumentUploader";

const EntityDocuments = ({ onNextClick, tick }: any) => {
  const [togglesave, settogglesave] = useState(false);
  const EntityId = Cookies.get("entityId");
  const { data: FirmAddressProof } = useQuery(
    "FirmAddressProof",
    getFirmAddressProof
  );
  const { data: CompanyDetails, isLoading: isCompanyDetailsLoading } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById
  );

  const queryClient = useQueryClient();
  const [BusinessAddressProof, setBusinessAddressProof] = useState();

  const handleDocumentChange = (event: any) => {
    setBusinessAddressProof(event.target.value);
  };
  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {},
  });

  const { data: statusDetails } = useQuery(
    ["status", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  const companyDocuments = CompanyDetails?.data?.firmDocuments || [];

  const documentList = [
    {
      label: "Board Resolution",
      subtype: "BOARD_RESOLUTION",
      showTemplate: true,
      templateUrl:
        "https://truupe-staging-assets.s3.ap-south-1.amazonaws.com/docs/Board_Resolution.docx",
      customMessage:
        "Update your details, please re-upload the signed document",
    },
    {
      label: "Certificate of Incorporation",
      subtype: "CERTIFICATE_OF_INCORPORATION",
    },
    {
      label: "AOA Document",
      subtype: "AOA",
    },
    {
      label: "MOA Document",
      subtype: "MOA",
    },
    {
      label: "Company PAN Card",
      subtype: "PAN",
    },
    {
      label: "GSTIN Certificate",
      subtype: "GSTIN",
    },
    {
      label: "Company Management",
      subtype: "COMPANY_MANAGEMENT",
    },
  ];

  useEffect(() => {
    const subtypes = CompanyDetails?.data?.firmDocuments?.map(
      (document: any) => document?.subtype
    );

    const requiredFiles = [
      "BOARD_RESOLUTION",
      "CERTIFICATE_OF_INCORPORATION",
      "AOA",
      "MOA",
      "PAN",
      "GSTIN",
      "COMPANY_MANAGEMENT",
      "ADDRESS_PROOF",
    ];

    const allDocsPresent = subtypes?.every((subtype: any) =>
      requiredFiles.includes(subtype)
    );

    const exactMatch =
      allDocsPresent && requiredFiles.length === subtypes?.length;

    if (exactMatch) {
      settogglesave(true);
    } else {
      settogglesave(false);
    }
  }, [CompanyDetails]);

  const onSubmit = () => {
    toast.success("Information updated Successfully");
    queryClient.invalidateQueries("entity");
    queryClient.invalidateQueries("status-data");
    queryClient.invalidateQueries("getuser-id");
    const accessToken: any = Cookies.get("access_token");
    const userdata: any = jwtDecode(accessToken);
    const draftdata = {
      userId: userdata?.userId,
      activity: "KYB/KYC Process in Draft",
      entityId: EntityId,
    };
    useractivity(draftdata);
  };

  if (isCompanyDetailsLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: "64vw",
          height: "58.5vh",
          px: 2,
          mt: "1vh",
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <form>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", color: "black" }}
            gutterBottom
          >
            Entity Documents
          </Typography>

          {documentList.map((doc) => (
            <DocumentUploader
              key={doc.subtype}
              documentLabel={doc.label}
              subtype={doc.subtype}
              entityId={EntityId}
              statusDetails={statusDetails}
              documents={companyDocuments}
              onFileChange={() =>
                queryClient.invalidateQueries("getcompanydetails")
              }
              showTemplate={doc.showTemplate}
              templateUrl={doc.templateUrl}
              customMessage={doc.customMessage}
            />
          ))}

          <DocumentSelect
            label="Business Address Proof"
            entityId={EntityId}
            statusDetails={statusDetails}
            companyDocuments={companyDocuments}
            firmAddressProof={FirmAddressProof?.data}
            handleDocumentChange={handleDocumentChange}
            selectedDocument={BusinessAddressProof}
            filetype={
              BusinessAddressProof ||
              CompanyDetails?.data?.firmDocuments?.find(
                (obj: any) => obj.filetype !== "null"
              )?.filetype
            }
            onFileChange={() =>
              queryClient.invalidateQueries("getcompanydetails")
            }
            documentSubtype="ADDRESS_PROOF"
          />
        </form>
        {!statusDetails?.data?.documents && (
          <Typography
            variant="body1"
            sx={{
              color: "#800000",
              my: "25px",
              fontStyle: "italic",
              opacity: "0.8",
            }}
          >
            Please confirm that you have uploaded all the required documents for
            the {CompanyDetails?.data?.companyName} for KYB/KYC
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "2.7vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        <CustomLoadingButton
          onClick={() => onSubmit()}
          isDisabled={!togglesave || statusDetails?.data?.documents}
          title="Next"
        />
      </Box>
    </>
  );
};

export default EntityDocuments;
