import { Box, Button, Stack, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { postNotify } from "../../../../../../api/services/deals";

export default function DealSignatureAwaiting({ data, secondUser }: any) {
  const { mutate: notify } = useMutation(postNotify, {
    onSuccess: (res: any) => {
      toast.success("Notification sent successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleNotifySeller = () => {
    notify({ dealCode: data?.code, type: "Signature" });
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Needs other Signatures
          {/* Invitation Sent to the {secondUser?.userType} */}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The {secondUser?.userType} has published the Deal Document, and we’re
          now waiting for the remaining signees to complete the signing process.
          Click the “Notify Signees” button to send a reminder to the
          outstanding signees.
        </Typography>
      </Stack>

      <Button
        variant="contained"
        onClick={handleNotifySeller}
        sx={{ width: "auto", mt: 2 }}
      >
        Notify Signees
      </Button>
    </Box>
  );
}
