import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

const DocumentSelect = ({
  value,
  onChange,
  options,
  disabled,
  label,
  entityId,
  fileType,
  firmDocuments,
  status,
  queryKey,
  setIsFileDeleted,
}: any) => {
  const [disableSelect, setDisableSelect] = useState(false);

  const getFirmDocumentData = (
    documents: any[],
    subtype: string,
    field: string
  ) =>
    documents
      ?.filter((doc: any) => doc?.subtype === subtype)
      ?.map((doc: any) => doc[field])[0];

  useEffect(() => {
    const isValueFromAPI = firmDocuments?.some(
      (doc: any) => doc?.subtype === fileType && doc?.filetype === value
    );
    // Disable the select dropdown if the value is fetched from firmDocuments
    if (isValueFromAPI) {
      setDisableSelect(true);
    } else {
      setDisableSelect(false);
    }
  }, [value, firmDocuments, fileType]);

  return (
    <Box sx={{ width: "500px", my: "20px" }}>
      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "15px" }}>
        {label}
      </Typography>
      <Select
        size="small"
        sx={{ width: "350px", marginBottom: "10px" }}
        value={value || ""}
        onChange={onChange}
        displayEmpty
        disabled={disabled || disableSelect}
        MenuProps={{
          PaperProps: {
            style: { fontSize: "50px", maxHeight: "400px", width: "20ch" },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        <MenuItem disabled value="" sx={{ fontSize: "16px" }}>
          <em style={{ fontSize: "14px", color: "GrayText" }}>
            Select Document
          </em>
        </MenuItem>
        {options?.map((document: any, index: any) => (
          <MenuItem key={index} value={document}>
            {document.replaceAll("_", " ")}
          </MenuItem>
        ))}
      </Select>
      <Box>
        {value && (
          <FileUploadContainer
            typeId={entityId}
            status={status}
            actions={<></>}
            filetype={value}
            data={fileType}
            subType={value}
            typeData="ENTITY"
            uploaded={
              !!firmDocuments?.filter((i: any) => i?.subtype === fileType)
                ?.length
            }
            name={getFirmDocumentData(
              firmDocuments,
              fileType,
              "originalFileName"
            )}
            url={getFirmDocumentData(firmDocuments, fileType, "url")}
            onFileChange={(key) => {
              if (key === "") {
                setDisableSelect(false);
                setIsFileDeleted(true);
              } else {
                setDisableSelect(true);
                setIsFileDeleted(false);
                queryKey && queryKey();
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DocumentSelect;
