import { CircularProgress, Fade, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getStatusbyId, getUserProfile } from "../../../api/services";
import { Icons, firmDocuments } from "../../../assets";
import banking from "../../../assets/icons/banking.svg";
import company from "../../../assets/icons/company.svg";
import director from "../../../assets/icons/director.svg";
import directorDocuments from "../../../assets/icons/directorDocuments.svg";
import { kycDetailsStore } from "../../../store/kycProcess";
import { formatDate } from "../../../utils/Kycprocessutils";
import EntityBankingInformation from "../components/EntityBankingInformation";
import SignatoryAuthorityDocuments from "../components/SignatoryAuthorityDocuments";
import CompanyInformationForm from "./CompanyInformationForm";
import DirectorInformation from "./DirectorInformationForm";
import EntityDocuments from "./EntityDocuments";
interface TabPanelProps {
  icon?: any;
  children?: React.ReactNode;
  index: number;
  value: number;
  onChangeTab: (index: number) => void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, onChangeTab, ...other } = props;

  return (
    <Fade in={value === index} timeout={800}>
      <Box
        sx={{
          height: "75vh",
          overflow: "auto",
          mt: 2,
        }}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </Box>
    </Fade>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function PrivateLimitedCompany() {
  const [allStatus, setStatus] = useState<any>();
  const [activeTab, setActiveTab] = React.useState(0);
  const storeLastupdatedTime = kycDetailsStore.useState((s) => s.lastUpdatedAt);

  const EntityId = Cookies.get("entityId");

  const sideTabs = [
    {
      name: "Entity Information",
      icon: <img src={company} alt="id-1" width={"20px"} />,
      status: allStatus?.company,
    },
    {
      name: "Signatory Authority Information",
      icon: <img src={director} alt="id-1" style={{ width: "20px" }} />,
      status: allStatus?.director,
    },
    {
      name: "Banking Information",
      icon: <img src={banking} alt="id-1" style={{ width: "20px" }} />,
      status: allStatus?.banking,
    },
    {
      name: "Entity Documents",
      icon: <img src={firmDocuments} alt="id-1" style={{ width: "20px" }} />,
      status: allStatus?.documents,
    },
    {
      name: "Signatory Authority Documents",
      icon: (
        <img src={directorDocuments} alt="id-1" style={{ width: "20px" }} />
      ),
      status: allStatus?.directorDocuments,
    },
  ];

  const { data }: any = useQuery("getUser-id", getUserProfile);

  const { data: statusDetails, isLoading: load } = useQuery(
    ["status-data", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    setStatus(statusDetails?.data);
  }, [statusDetails]);

  const inputTime = data?.data?.entities?.[0]?.lastUpdatedAt;

  const formattedTime = formatDate(inputTime);

  function countTrues(obj: any) {
    if (!obj || typeof obj !== "object") {
      return 0;
    }
    return Object.values(obj).filter((value) => value === true).length;
  }

  useEffect(() => {
    if (countTrues(statusDetails?.data) === 5) {
      setActiveTab(4);
    } else {
      setActiveTab(countTrues(statusDetails?.data));
    }
  }, [statusDetails?.data]);

  const handleChangeTab = (index: number) => {
    setActiveTab(index);
  };

  if (load) {
    return (
      <Box
        width={"100%"}
        height={"60vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress></CircularProgress>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        marginX: "8%",
        minHeight: "75vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          scrollButtons={false}
          value={activeTab}
          onChange={(_, newValue) => handleChangeTab(newValue)}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            height: "calc(100% - 10px)",
            "& .MuiTab-root": {
              width: "19vw",
              alignItems: "start",
              paddingLeft: 4,
              marginTop: -1,
              marginLeft: 1,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              gap: "5px",
            },
            "& .Mui-selected": {
              color: "black !important",
            },
            "& .MuiTabs-indicator": {
              color: "transparent",
              width: 0,
            },
          }}
        >
          {sideTabs.map((tab, i) => {
            const filterData = sideTabs.filter(
              (obj: any) => obj?.status === true
            );

            const nextToTick = filterData?.length === i;

            return (
              <Tab
                disabled={!tab.status && !nextToTick}
                sx={{
                  paddingTop: "20px",
                  fontSize: "18px",
                  display: "flex",
                }}
                key={i}
                icon={tab.icon}
                iconPosition="start"
                label={
                  <Grid gap={"5px"} container justifyContent="left">
                    <Grid
                      sx={{
                        width: "10vw",
                      }}
                      textAlign="left"
                    >
                      <Typography
                        variant="subtitle2"
                        color={!tab.status && !nextToTick ? "grey" : "black"}
                      >
                        {tab.name}
                      </Typography>
                    </Grid>
                    {tab.status && (
                      <img
                        height={"20px"}
                        src={Icons.greenCheck}
                        alt="loading...."
                      />
                    )}
                  </Grid>
                }
                onClick={() => handleChangeTab(i)}
                {...a11yProps(i)}
                disableRipple
              />
            );
          })}
        </Tabs>

        <Typography
          sx={{
            borderBottom: "1px solid #cacaca",
            width: "100%",
            mx: "auto",
            textAlign: "left",

            marginBottom: "12.7vh",
            "@media (min-width: 1440px) and (max-width: 1535px)": {
              marginBottom: "12.7vh",
            },
            "@media (min-width: 1536px) and (max-width: 1919px)": {
              marginBottom: "12.7vh",
            },
            "@media (min-width: 1920px)": {
              marginBottom: "12.7vh",
            },
          }}
        >
          {data?.data?.entities?.length > 0 && (
            <Box sx={{ display: "flex", gap: "10px", ml: 3 }}>
              <Typography fontSize={"0.95vw"}>
                Last Updated At :{" "}
                {inputTime ? formattedTime : <>{storeLastupdatedTime}</>}
              </Typography>
            </Box>
          )}
        </Typography>
      </Box>

      <TabPanel
        value={activeTab}
        index={0}
        onChangeTab={(index) => handleChangeTab(index)}
      >
        <CompanyInformationForm />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={1}
        onChangeTab={(index) => handleChangeTab(index)}
      >
        <DirectorInformation />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={2}
        onChangeTab={(index) => handleChangeTab(index)}
      >
        <EntityBankingInformation />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={3}
        onChangeTab={(index) => handleChangeTab(index)}
      >
        {/* <FirmDocumentsForm /> */}
        <EntityDocuments />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={4}
        onChangeTab={(index) => handleChangeTab(index)}
      >
        <SignatoryAuthorityDocuments />
      </TabPanel>
    </Box>
  );
}
