import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useQueryClient } from "react-query";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

interface MainDataProps {
  companyType: string;
}

interface EntityDocumentsProps {
  data: any[];
  mainData: MainDataProps[];
  title?: string;
  isSupporting?: boolean;
}

const EntityDocuments: React.FC<EntityDocumentsProps> = ({
  data,
  mainData,
  title = "Firm Documents",
  isSupporting = false,
}: any) => {
  const queryClient = useQueryClient();

  const onFileChange = (data: any) => {
    queryClient.invalidateQueries("entity");
  };

  const filteredData = data?.filter(
    (document: { subtype: string }) => document.subtype !== "CANCELLED_CHEQUE"
  );

  return (
    <Box marginLeft="20px" marginBottom="20px">
      {mainData[0]?.companyType === "SOLE_PROPRIETORSHIP" ? (
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Entity Documents
        </Typography>
      ) : (
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          {title}
        </Typography>
      )}

      <Grid container spacing={3} sx={{ marginBottom: "20px" }}>
        {filteredData?.length > 0 &&
          filteredData.map((document: any, index: number) => (
            <Grid size={{ xs: 12, sm: 4, md: 4 }} key={index}>
              <FileUploadContainer
                typeId={mainData?.id}
                actions={<></>}
                data={document}
                typeData="ENTITY"
                name={document.originalFileName}
                url={document.subtype}
                subType={document.subtype}
                onFileChange={onFileChange}
                showIcons={false}
                uploaded={true}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default EntityDocuments;
