import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  AddDirectorDetails,
  surepassValidations,
  updateDirectorDetails,
  userActivity,
} from "../../../api/services";
import { Icons } from "../../../assets";

import Editbutton from "../../../components/buttons/Editbutton";

import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import MultiInputField, {
  Formate,
} from "../../../components/formFields/kycFields/MultiInputField";
import AadharAlertDialog from "../../kyc/components/AadharAlertModel";

type Info = {
  directorName: string;
  dpin: string;
  aadharNumber: string;
  aadharOTP: string;
  registeredAddress: string;
  gender: string;
  dateOfBirth: string;
  panCard: string;
  Signatory: boolean;
  MobileNumber: string;
  mobileOTP: string;
  Email: string;
  emailOTP: string;
  Designation: string;
  isDataSaved: boolean;
  SignatoryAuthoritydata: number[];
};

interface DirectorFormProps {
  Dname: string;
  isnew: boolean;
  Dpin: string;
  index: number;
  panNumber: string;
  aadharNumber: string;
  address: string;
  Signatory: boolean;
  directorData: any;
  id: any;
  isAadharMismatch: boolean;
  isPanMisMatch: boolean;
  MobileNumber?: string;
  designation: string;
  mobileNumber: string;
  emailAddress: string;
  isMobileVerified: boolean;
  isEmailVerified: boolean;
  isDataSaved?: boolean;
  SignatoryAuthoritydata?: number[];
  entityId: string;
  close: any;
  entityType: string;
}

export default function SignatoryAuthorityForm({
  isnew = false,
  Dname,
  Dpin,
  panNumber,
  aadharNumber,
  address,
  Signatory,
  isAadharMismatch,
  index,
  isPanMisMatch,
  id = null,
  designation,
  mobileNumber,
  emailAddress,
  isDataSaved,
  SignatoryAuthoritydata = [],
  entityId,
  entityType,
  close,
}: DirectorFormProps) {
  const queryClient = useQueryClient();
  const EntityId = entityId;
  const [open, setOpen] = useState(false);
  const [disable, isdisable] = useState(false);
  const [clientId, setClientId] = useState();
  const [aadharVerified, setAadharVerified] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const [panAlert, setpanAlert] = useState(false);
  const [aadharAlert, setAadharalert] = useState(false);
  const [aadharOtp, setAadharOtp] = useState(false);
  const [disableName, setdisableName] = useState(false);
  const [showname, setShowname] = useState(false);

  const ArrayData = SignatoryAuthoritydata.filter(
    (item: any) => item?.id !== id
  ).map((item: any) => item?.id);

  const { mutate: createDirectordetails, isLoading: submitloader } =
    useMutation(AddDirectorDetails, {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries("entity");
        close(true);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const { mutate: Updatedirectordetails, isLoading: submitloaderU } =
    useMutation(updateDirectorDetails, {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("entity");
        close(true);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const { mutate: validatePan, isLoading: panLoader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        setShowname(true);
        if (res?.data?.isPanValid) {
          setPanVerified(true);
          setpanAlert(false);
          if (isnew) {
            setValue("directorName", res?.data?.data?.full_name);
            setdisableName(true);
          }
        } else {
          setPanVerified(true);
          setpanAlert(true);
          if (isnew) {
            setValue("directorName", res?.data?.data?.full_name);
            setdisableName(true);
          }
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: generateOTP, isLoading: getotploader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        if (res?.data?.success) {
          setAadharOtp(true);
          toast.success(res?.data?.message);
          setClientId(res?.data?.clientId);
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: verifyOTP, isLoading: verifyotploader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        if (res?.data?.isAadharValid) {
          setAadharOtp(false);
          setAadharVerified(true);
          setAadharalert(false);
        } else {
          setAadharalert(true);
          setAadharOtp(false);
          setAadharVerified(true);
        }
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const statusDetails = {
    data: {
      director: false,
    },
  };

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Info>();

  useEffect(() => {
    if (Dname.length === 0 && Dpin.length === 0) {
      isdisable(false);
    } else {
      setValue("directorName", Dname);
      setValue("dpin", Dpin);
      setValue("aadharNumber", aadharNumber);
      setValue("panCard", panNumber);
      setValue("registeredAddress", address);
      setValue("Signatory", Signatory);
      setValue("Designation", designation);
      setValue("MobileNumber", mobileNumber);
      setValue("Email", emailAddress);
      isdisable(true);
    }
  }, [
    Dname,
    Dpin,
    isdisable,
    setValue,
    aadharNumber,
    panNumber,
    address,
    Signatory,
    designation,
    mobileNumber,
    emailAddress,
  ]);

  const validateSubmitData = () => {
    const errors = [];

    const data = {
      entityId: EntityId,
      name: getValues("directorName"),
      dpin: getValues("dpin"),
      aadharNumber: getValues("aadharNumber")?.replace(/\s/g, ""),
      aadharOTP: getValues("aadharOTP"),
      panNumber: getValues("panCard"),
      isAadharVerified: aadharVerified,
      designation: getValues("Designation") || "Director",
      isPanVerified: panVerified,
      isAadharMismatch: aadharAlert,
      isPanMismatch: panAlert,
      isnew: isnew,
      emailAddress: getValues("Email"),
      mobileNumber: getValues("MobileNumber"),
      isMobileVerified: true,
      isEmailVerified: true,
      status: "In Progress",
    };

    const isEmpty = (value: any) => !value || value.trim() === "";

    const validateMobileNumber = (mobile: any) => {
      const mobileRegex = /^[6-9]\d{9}$/;
      return mobileRegex.test(mobile);
    };

    const validateEmail = (email: any) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if (isEmpty(data.name)) errors.push("Director name is required.");
    if (isEmpty(data.aadharNumber)) errors.push("Aadhar number is required.");
    if (isEmpty(data.panNumber)) errors.push("PAN number is required.");
    if (isEmpty(data.designation)) errors.push("Designation is required.");

    if (!validateMobileNumber(data.mobileNumber))
      errors.push("Invalid mobile number.");
    if (!validateEmail(data.emailAddress))
      errors.push("Invalid email address.");

    if (errors.length > 0) {
      const errorMessage = errors.join(" ");
      return { isValid: false, errorMessage };
    }

    return { isValid: true, data };
  };

  const showToast = (message: any) => {
    toast.error(message); // Replace with actual toast implementation
  };

  const submitData = () => {
    const validation = validateSubmitData();
    if (!validation.isValid) {
      showToast(validation.errorMessage);
      return;
    }

    const data = validation.data;
    if (data?.isnew) {
      createDirectordetails(data);
    } else {
      Updatedirectordetails({ id: id, body: data });
    }
  };

  return (
    <>
      <form>
        <Box
          sx={{
            mx: "10px",
            display: "flex",
            flexDirection: "column",
            paddingBottom: 2,
            marginTop: "1vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ my: "1vh" }}>
              Signatory Authority {index + 1}
            </Typography>
          </Box>

          {isnew ? (
            <>
              <Box>
                <Stack direction={"row"} gap={"1vw"}>
                  <MultiInputField
                    control={control}
                    label={"PAN Number"}
                    name={"panCard"}
                    key="panCard"
                    variant={Formate.StrictUpperCase}
                    type="text"
                    errors={errors}
                    disabled={isDataSaved}
                    sx={{ width: "300px" }}
                  />
                  {panLoader ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1vh",
                      }}
                    >
                      <CircularProgress size={"2rem"} color="primary" />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {panVerified ||
                      statusDetails?.data?.director ||
                      isDataSaved ? (
                        <Box display={"flex"} alignItems={"center"}>
                          {isnew ? (
                            <Box marginTop={"2vh"}>
                              <img
                                height={"25px"}
                                src={Icons.greenCheck}
                                alt="loading...."
                              />
                            </Box>
                          ) : (
                            <>
                              {panAlert || isPanMisMatch ? (
                                <Box marginTop={"20px"}>
                                  <img
                                    height={"25px"}
                                    src={Icons.alert}
                                    alt="loading...."
                                  />{" "}
                                </Box>
                              ) : (
                                <>
                                  <Box marginTop={"2vh"}>
                                    <img
                                      height={"25px"}
                                      src={Icons.greenCheck}
                                      alt="loading...."
                                    />
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                          {!(statusDetails?.data?.director || isDataSaved) && (
                            <Box marginTop={"1.5vh"}>
                              <Editbutton
                                onclick={() => {
                                  setShowname(false);
                                  setPanVerified(false);
                                  setpanAlert(false);
                                  setAadharalert(false);
                                  setAadharVerified(false);
                                  setValue("aadharNumber", "");
                                  setValue("aadharOTP", "");
                                }}
                              />
                            </Box>
                          )}{" "}
                        </Box>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            disabled={statusDetails?.data?.director}
                            onClick={() => {
                              if (
                                getValues("panCard") === "" ||
                                getValues("panCard") === undefined
                              ) {
                                toast.error(
                                  "Please enter the PAN Number of Signatory Authority"
                                );
                              } else {
                                if (isnew) {
                                  validatePan({
                                    type: "NEW_PAN",
                                    value: getValues("panCard"),
                                    entityType: entityType,
                                    entityId: EntityId,
                                  });
                                } else {
                                  validatePan({
                                    type: "PAN",
                                    value: getValues("panCard"),
                                    din: getValues("dpin"),
                                    entityType: entityType,
                                    entityId: EntityId,
                                  });
                                }
                              }
                            }}
                            sx={{
                              marginTop: "2.5vh",
                              padding: 0,
                              fontSize: "12px",
                              maxHeight: "30px",
                            }}
                          >
                            Verify
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </Stack>
                {isPanMisMatch || panAlert ? (
                  <>
                    {isnew ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#800000",
                            fontStyle: "italic",
                          }}
                        >
                          The Pan Number for {""}
                          {getValues("directorName")} doesn't match with DIN.
                          Please verify.
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Box>

              {(showname || statusDetails?.data?.director || isDataSaved) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 2,
                    rowGap: 0,
                  }}
                >
                  <MultiInputField
                    control={control}
                    label={"Signatory Authority Name"}
                    name={"directorName"}
                    key="directorName"
                    variant={Formate.StrictUpperCase}
                    type="text"
                    errors={errors}
                    disabled={disable || disableName}
                    sx={{ width: "300px" }}
                  />
                </Box>
              )}
              <>
                <MultiInputField
                  control={control}
                  label={"Designation"}
                  name={"Designation"}
                  key="Designation"
                  variant={Formate.Alphanumeric}
                  type="text"
                  errors={errors}
                  disabled={
                    false || statusDetails?.data?.director || isDataSaved
                  }
                  sx={{ width: "300px" }}
                />{" "}
              </>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 2,
                  rowGap: 0,
                }}
              >
                <MultiInputField
                  control={control}
                  label={"Signatory Authority Name"}
                  name={"directorName"}
                  key="directorName"
                  variant={Formate.StrictUpperCase}
                  type="text"
                  errors={errors}
                  disabled={disable || disableName}
                  sx={{ width: "300px" }}
                />
                {false && (
                  <MultiInputField
                    control={control}
                    label={"DPIN"}
                    name={"dpin"}
                    key="dpin"
                    variant={Formate.Alphanumeric}
                    type="text"
                    errors={errors}
                    disabled={disable}
                    sx={{ width: "300px" }}
                  />
                )}

                <MultiInputField
                  control={control}
                  label={"Designation"}
                  name={"Designation"}
                  key="Designation"
                  variant={Formate.Alphanumeric}
                  type="text"
                  errors={errors}
                  disabled={
                    false || statusDetails?.data?.director || isDataSaved
                  }
                  sx={{ width: "300px" }}
                />
              </Box>

              <Box>
                <Stack direction={"row"} gap={"1vw"}>
                  <MultiInputField
                    control={control}
                    label={"PAN Number"}
                    name={"panCard"}
                    key="panCard"
                    variant={Formate.StrictUpperCase}
                    type="text"
                    errors={errors}
                    disabled={
                      false ||
                      statusDetails?.data?.director ||
                      panVerified ||
                      isDataSaved
                    }
                    sx={{ width: "300px" }}
                  />
                  <Box
                    sx={{
                      paddingTop: "1vh",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {panLoader ? (
                      <>
                        <CircularProgress size={"2rem"} color="primary" />
                      </>
                    ) : (
                      <>
                        {panVerified ||
                        statusDetails?.data?.director ||
                        isDataSaved ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            paddingTop={"1vh"}
                          >
                            {isnew ? (
                              <Box marginTop={"2vh"}>
                                <img
                                  height={"25px"}
                                  src={Icons.greenCheck}
                                  alt="loading...."
                                />
                              </Box>
                            ) : (
                              <>
                                {panAlert || isPanMisMatch ? (
                                  <Box marginTop={"2vh"}>
                                    <img
                                      height={"25px"}
                                      src={Icons.alert}
                                      alt="loading...."
                                    />{" "}
                                  </Box>
                                ) : (
                                  <>
                                    <Box marginTop={"2vh"}>
                                      <img
                                        height={"25px"}
                                        src={Icons.greenCheck}
                                        alt="loading...."
                                      />
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                            {!(
                              statusDetails?.data?.director || isDataSaved
                            ) && (
                              <Box marginTop={"1.5vh"}>
                                <Editbutton
                                  onclick={() => {
                                    setPanVerified(false);
                                    setpanAlert(false);
                                    setAadharalert(false);
                                    setAadharVerified(false);
                                    setValue("aadharNumber", "");
                                    setValue("aadharOTP", "");
                                  }}
                                />
                              </Box>
                            )}{" "}
                          </Box>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              disabled={statusDetails?.data?.director}
                              onClick={() => {
                                if (
                                  getValues("Designation") === null ||
                                  getValues("Designation") === "" ||
                                  getValues("Designation") === undefined
                                ) {
                                  toast.error(
                                    "Please enter the Designation of Signatory Authority"
                                  );
                                } else {
                                  if (
                                    getValues("panCard") === "" ||
                                    getValues("panCard") === null
                                  ) {
                                    toast.error(
                                      "Please enter the Pan Number of Signatory Authority"
                                    );
                                  } else {
                                    if (isnew) {
                                      validatePan({
                                        type: "NEW_PAN",
                                        value: getValues("panCard"),
                                        entityType: entityType,
                                        entityId: EntityId,
                                      });
                                    } else {
                                      validatePan({
                                        type: "PAN",
                                        value: getValues("panCard"),
                                        din: getValues("dpin"),
                                        entityType: entityType,
                                        entityId: EntityId,
                                      });
                                    }
                                  }
                                }
                              }}
                              sx={{
                                marginTop: "2.5vh",
                                padding: 0,
                                fontSize: "12px",
                                maxHeight: "30px",
                              }}
                            >
                              Verify
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Stack>
                {isPanMisMatch || panAlert ? (
                  <>
                    {isnew ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#800000",
                            // mt: -1.6,
                            fontStyle: "italic",
                          }}
                        >
                          The Pan Number for {""}
                          {getValues("directorName")} doesn't match with DIN.
                          Please verify.
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </>
          )}

          <Box>
            <Stack direction={"column"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "32.5vw",
                }}
              >
                <MultiInputField
                  control={control}
                  label={"Aadhar Number"}
                  name={"aadharNumber"}
                  key="aadharNumber"
                  variant={Formate.AadharNumber}
                  type="text"
                  errors={errors}
                  disabled={
                    false ||
                    statusDetails?.data?.director ||
                    aadharVerified ||
                    isDataSaved ||
                    aadharOtp ||
                    getotploader
                  }
                  sx={{ width: "300px" }}
                />
                {getotploader ? (
                  <>
                    <CircularProgress
                      sx={{
                        marginLeft: "1vw",

                        marginTop: "1vh",
                      }}
                      size={"2rem"}
                      color="primary"
                    />
                  </>
                ) : (
                  <>
                    {aadharVerified ||
                    statusDetails?.data?.director ||
                    isDataSaved ? (
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: "12px",
                          display: " flex",
                          marginLeft: "18px",
                          alignItems: "center",
                        }}
                      >
                        {aadharAlert || isAadharMismatch ? (
                          <>
                            <Box marginTop={"2vh"}>
                              <img
                                height={"25px"}
                                src={Icons.alert}
                                alt="loading...."
                              />{" "}
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box marginTop={"2vh"}>
                              <img
                                height={"25px"}
                                src={Icons.greenCheck}
                                alt="loading...."
                              />
                            </Box>
                          </>
                        )}

                        {!(statusDetails?.data?.director || isDataSaved) && (
                          <Box marginTop={"1.5vh"}>
                            <Editbutton
                              onclick={() => {
                                setAadharalert(false);
                                setAadharVerified(false);
                                setValue("aadharOTP", "");
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Button
                        disabled={statusDetails?.data?.director}
                        variant="contained"
                        onClick={() => {
                          if (getValues("aadharNumber") === "") {
                            toast.error(
                              "Please Enter the Aadhar Number of Signatory Authority"
                            );
                            setValue("aadharOTP", "");
                          } else if (panVerified) {
                            if (getValues("Designation") === undefined) {
                              toast.error(
                                "Please enter the Designation of Signatory Authority"
                              );
                            } else {
                              setValue("aadharOTP", "");
                              setOpen(true);
                            }
                          } else
                            toast.error("Please verify PAN card before Aadhar");
                        }}
                        sx={{
                          marginLeft: "1vw",
                          marginTop: "3vh",
                          padding: 0,
                          fontSize: "12px",
                          maxHeight: "30px",
                        }}
                      >
                        Get OTP
                      </Button>
                    )}
                  </>
                )}
              </Box>

              {statusDetails?.data?.director || isDataSaved ? (
                <></>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  {aadharOtp && (
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "1vw" }}
                    >
                      <MultiInputField
                        control={control}
                        label={"OTP"}
                        name={"aadharOTP"}
                        key="aadharOTP"
                        variant={Formate.Otp}
                        type="text"
                        errors={errors}
                        disabled={false || statusDetails?.data?.director}
                        sx={{
                          marginBottom: 0,
                          width: "300px",
                        }}
                      />
                      {verifyotploader ? (
                        <>
                          <CircularProgress
                            sx={{
                              marginLeft: "25px",
                              marginTop: "20px",
                            }}
                            size={"2rem"}
                            color="primary"
                          />
                        </>
                      ) : (
                        <Stack direction={"row"}>
                          <Button
                            variant="contained"
                            onClick={() => {
                              verifyOTP({
                                type: "VERIFY_AADHAR_OTP",
                                clientId: clientId,
                                value: getValues("aadharOTP").replace(
                                  /\D/g,
                                  ""
                                ),
                                pan: getValues("panCard"),
                                din: getValues("dpin"),
                                entityType: entityType,
                                isNew: isnew,
                              });
                            }}
                            sx={{
                              marginTop: "2.5vh",
                              padding: 0,
                              fontSize: "12px",
                              maxHeight: "30px",
                            }}
                          >
                            Verify
                          </Button>
                          <Box marginTop={"2.5vh"}>
                            <Editbutton
                              onclick={() => {
                                setAadharalert(false);
                                setAadharVerified(false);
                                setValue("aadharOTP", "");
                                setAadharOtp(false);
                              }}
                            />
                          </Box>
                        </Stack>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Stack>

            {isAadharMismatch || aadharAlert ? (
              <Typography
                variant="body1"
                sx={{
                  color: "#800000",
                  fontStyle: "italic",
                }}
              >
                The AADHAR Number for {""}
                {getValues("directorName")} doesn't match with DIN. Please
                verify.
              </Typography>
            ) : (
              <></>
            )}
          </Box>

          <Stack direction={"row"} gap={"1vw"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "32.5vw",
              }}
            >
              <MultiInputField
                control={control}
                label={"Mobile Number"}
                name={"MobileNumber"}
                key="MobileNumber"
                variant={Formate.MobileNumber}
                type="text"
                errors={errors}
                disabled={false || statusDetails?.data?.director || isDataSaved}
                sx={{ width: "300px" }}
              />
            </Box>
          </Stack>

          <Stack direction={"row"} gap={"1vw"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "32.5vw",
              }}
            >
              <MultiInputField
                control={control}
                label={"Official Email Address"}
                name={"Email"}
                key="Email"
                variant={Formate.EmailAddress}
                type="text"
                errors={errors}
                disabled={false || statusDetails?.data?.director || isDataSaved}
                sx={{ width: "300px" }}
              />
            </Box>
          </Stack>
        </Box>
        <Box sx={{ display: "flex", mx: "10px" }}>
          <CustomLoadingButton
            submitLoader={submitloader}
            isSubmit={false}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              submitData();
            }}
            isDisabled={!aadharVerified || !panVerified || isDataSaved}
            title="Save"
            height={"30px"}
          />
        </Box>
      </form>

      <AadharAlertDialog
        username={getValues().directorName}
        openDialogue={open}
        closeModel={setOpen}
        aadharNumber={getValues().aadharNumber}
        sendOtp={() => {
          generateOTP({
            type: "GENERATE_AADHAR_OTP",
            value: getValues("aadharNumber")?.replace(/\s/g, ""),
            entityType: entityType,
            entityId: EntityId,
          });
        }}
      />
    </>
  );
}
