import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { getUserProfile } from "../../../api/services";
import { desktopView } from "../../../assets";
import Header from "./Header";

const DesktopAlert = () => {
  const [_, setOpen] = useState(false);
  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const Status = !!profileData?.data?.entities.filter(
    (i: any) => i?.status === "Verified"
  );

  return (
    <Box>
      <Box position="sticky" top={0} zIndex={1}>
        <Header
          toggleDrawer={toggleDrawer}
          profileData={profileData}
          deal={false}
          desktopAlert={true}
        />
      </Box>
      <Box
        sx={{
          height: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 2,
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "65%", md: "45%", lg: "35%", xl: "35%" },
          }}
        >
          <img width="100%" src={desktopView} alt="Access Denied" />
        </Box>

        {profileData?.data?.entities.length > 0 && Status ? (
          <Typography my={3} variant={"h4"}>
            Please switch to a desktop computer for creating or browsing your
            deals.
          </Typography>
        ) : (
          <Typography my={3} variant={"h4"}>
            Please switch to a desktop computer for better experience when
            submitting your KYC/KYB.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DesktopAlert;
