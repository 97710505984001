import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getusersbyid, userLocation } from "../../api/services";
import { getUserId } from "../../utils/authUtils";
import { isIndianLocation } from "../../utils/Location";
import Header from "./Components/Header";
import Main from "./Main";

const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const userId = getUserId();
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const { data: profileData }: any = useQuery(
    ["getUser-id", userId],
    getusersbyid
  );

  const { mutate: findLocation } = useMutation(userLocation, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {},
  });

  useEffect(() => {
    isIndianLocation((result: any) => {
      if (result === "prompt") {
        setOpenModel(true);
      } else if (result) {
        setOpenModel(false);
      } else {
        navigate("/access-denied", { replace: true });
        setOpenModel(false);
      }
    });
  }, [isIndianLocation]);

  useEffect(() => {
    const ip = Cookies.get("ip");
    const longitude = Cookies.get("longitude");
    const latitude = Cookies.get("latitude");

    findLocation({
      ipAddress: ip,
      latitude: latitude,
      longitude: longitude,
    });
  }, [findLocation]);

  localStorage.setItem("entityStatus", profileData?.data?.entities[0]?.status);

  useEffect(() => {
    if (profileData && profileData?.data?.entities[0]?.status === "Verified") {
      navigate("/deal-process/deals/all");
      localStorage.setItem("isEntityVerified", "true");
    }
  }, [profileData]);

  return (
    <>
      <Header toggleDrawer={toggleDrawer} profileData={profileData} />
      <Main />
      {/* <Drawer
        PaperProps={{
          elevation: 2,
          sx: {
            marginTop: "71px",
            borderRadius: "0",
          },
        }}
        sx={{
          zIndex: 500,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 500,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            marginTop: "71px",
            padding: 0,
          },
        }}
        anchor={"right"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      ></Drawer> */}
      {/* <LocationModel
        setOpenModel={setOpenModel}
        openModel={openModel}
      ></LocationModel> */}
    </>
  );
};

export default Home;
