import { Height } from "@mui/icons-material";
import { Box, FormHelperText, InputLabel, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme, width }: any) => ({
  "label + &": {
    marginTop: theme.spacing(-1),
  },

  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: "14px",
    width: "25vw",
    maxHeight: "13px",
    padding: "10px 10px",
    color: "black",
  },
  "&::placeholder": {
    fontFamily: "mundial",
    fontSize: "12px", // Adjusted placeholder font size to 12px
  },
}));

type Props = {
  iD: string;
  name?: string;
  errors: any;
  sx?: any;
  multiline?: boolean;
  type?: string;
  field: any;
  isdisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: any;
  Name?: string;
  endAdornment?: any;
  value?: any;
};

const InputField = ({
  iD,
  name,
  errors,
  sx,
  type,
  field,
  isdisabled = false,
  onChange,
  width,
  Name,
  endAdornment,
  value,
}: Props) => {
  const placeHolder = `Enter your ${name}`;
  type = type === "" ? "text" : type;

  return (
    <Box sx={{ marginTop: "5px" }}>
      <InputLabel
        shrink
        htmlFor={iD}
        sx={{
          fontSize: "0px",
          fontWeight: 300,
          "&.Mui-focused": {
            color: "#00000099",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={
            {
              // my: "0.1vh",
            }
          }
        >
          {name}
        </Typography>
      </InputLabel>

      <BootstrapInput
        disabled={isdisabled}
        id={iD}
        placeholder={name ? placeHolder : Name}
        sx={sx}
        width={width}
        type={type}
        endAdornment={endAdornment}
        onChange={onChange}
        value={value}
        {...field}
      />

      {errors[iD] && (
        <FormHelperText error>
          <Typography variant="body2" sx={{ color: "red", paddingLeft: 1 }}>
            {errors[iD]?.message}
          </Typography>
        </FormHelperText>
      )}
    </Box>
  );
};

export default InputField;
