import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Typography } from "@mui/material";

import { styled } from "@mui/system";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  AddCompanyDetails,
  getComapanyDetailsById,
  surepassValidations,
  userActivity,
} from "../../../api/services";
import { Icons } from "../../../assets";

import Editbutton from "../../../components/buttons/Editbutton";
import GetDetailsButton from "../../../components/buttons/Getdetails";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import AutocompleteField from "../../../components/formFields/kycFields/AutocompleteField";
import InputField from "../../../components/formFields/kycFields/InputField";
import { kycDetailsStore } from "../../../store/kycProcess";

type Info = {
  category: string;
  llpinNumber: string;
  companyName: string;
  gstNumber: string;
  panNumber: string;
  subCategory: string;
};

const EntityInformationAlert = styled(Typography)({
  opacity: "85%",
  color: "#800000",
  margin: "8px 0",
  fontStyle: "italic",
});

const schema = yup.object().shape({
  subCategory: yup.string().required("Business Category is required"),
  category: yup.string().required("Business Category is required"),
  llpinNumber: yup
    .string()
    .typeError("CIN Number must be a number")
    .required("CIN Number is required"),
  companyName: yup.string().required("Firm Name is required"),

  gstNumber: yup
    .string()
    .typeError("GST Number must be a string")
    .required("GST Number is required"),
  panNumber: yup
    .string()
    .typeError("PAN Number must be a string")
    .required("PAN Number is required"),
});

const CompanyInformationForm = ({
  value,
  setValues,
  onNextClick,
  tick,
}: any) => {
  const [show, setShow] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [Cindissable, setCindissable] = useState(false);
  const [Gstdissable, setGstdissable] = useState(false);
  const [tickcompanyname, settickcompanyname] = useState(false);
  const [tickcompanyGstin, settickcompanGstin] = useState(false);
  const [isFirmValidated, setIsFirmValidated] = useState(false);

  const [firm, setFirm] = useState(false);
  const EntityId = Cookies.get("entityId");

  const queryClient = useQueryClient();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<Info>({
    resolver: yupResolver(schema),
  });

  const { data: CompanyDetails } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    if (CompanyDetails && EntityId) {
      setdisabled(true);
      reset({
        subCategory: CompanyDetails?.data?.subCategory,
        category: CompanyDetails?.data?.category,
        llpinNumber: CompanyDetails?.data?.llpinNumber,
        companyName: CompanyDetails?.data?.companyName,
        gstNumber: CompanyDetails?.data?.gstNumber,
        panNumber: CompanyDetails?.data?.panNumber,
      });
      setIsFirmValidated(CompanyDetails?.data?.isFirmValidated);
    }
  }, [CompanyDetails, EntityId, reset]);

  const moment = require("moment");

  const { mutate: getcindetails, isLoading: Cinloader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        const LLPINdata = res?.data?.data;
        settickcompanyname(true);

        setValue("companyName", LLPINdata);

        const dateTime = moment(Date());
        const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = savedDateTime;
        });

        setCindissable(true);
        setShow(true);
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter LLPIN");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );
  const { mutate: getpandetails, isLoading: Panloader } = useMutation(
    surepassValidations,
    {
      onSuccess: (res: any) => {
        const GstData = res?.data?.data;
        const panNumber = res?.data?.panNumber;
        setIsFirmValidated(res?.data?.isFirmValidated);
        setFirm(res?.data?.isFirmValidated);
        setValue("companyName", GstData);
        setIsFirmValidated(res?.data?.isFirmValidated);
        if (GstData === getValues("companyName")) {
          settickcompanGstin(true);
          setValue("panNumber", panNumber);
          setGstdissable(true);
        } else {
          toast.error("please provide the GST number linked to the LLPIN");
        }
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter GST Number");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: postcompanydetails, isLoading: submitloader } = useMutation(
    AddCompanyDetails,
    {
      onSuccess: (res: any) => {
        toast.success("Information Updated Successfully");
        queryClient.invalidateQueries("getcompanydetails");
        queryClient.invalidateQueries("getuser-id");
        const accessToken: any = Cookies.get("access_token");
        const userdata: any = jwtDecode(accessToken);
        const entityId = res?.data?.data?.id;
        Cookies.set("entityId", entityId);
        queryClient.invalidateQueries("status-data");
        const draftdata = {
          userId: userdata?.userId,
          activity: "KYB/KYC Process in Draft",
          entityId: entityId,
        };
        useractivity(draftdata);
        window.location.reload();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (data: Info) => {
    const finalData = { ...data, companyType: "PRIVATE_LIMITED" };
    postcompanydetails(finalData);
  };

  const Submitdata = () => {
    const data = {
      subCategory: getValues("subCategory") || null,
      category: getValues("category"),
      llpinNumber: getValues("llpinNumber"),
      companyName: getValues("companyName"),
      gstNumber: getValues("gstNumber"),
      panNumber: getValues("panNumber"),
      isFirmValidated: isFirmValidated,

      companyType: "LIMITED_LIABILITY_PARTNERSHIP",
    };
    postcompanydetails(data);
  };

  const optionsArray = [
    { name: "Agriculture & Forestry", category: "Agriculture & Forestry" },
    { name: "Arts, Crafts & Hobbies", category: "Arts, Crafts & Hobbies" },
    { name: "Energy & Utilities", category: "Energy & Utilities" },
    { name: "Environmental Services", category: "Environmental Services" },
    { name: "Fashion & Apparel", category: "Fashion & Apparel" },
    { name: "Finance & Banking", category: "Finance & Banking" },
    { name: "Food & Beverage", category: "Food & Beverage" },
    {
      name: "Healthcare & Pharmaceuticals",
      category: "Healthcare & Pharmaceuticals",
    },
    { name: "Hospitality & Tourism", category: "Hospitality & Tourism" },
    { name: "Manufacturing", category: "Manufacturing" },
    { name: "Media & Entertainment", category: "Media & Entertainment" },
    { name: "Mining & Metals", category: "Mining & Metals" },
    {
      name: "Non-Profit & Social Services",
      category: "Non-Profit & Social Services",
    },
    {
      name: "Other",
      category: "Other",
    },
    {
      name: "Professional Services (Legal, Consulting, etc.)",
      category: "Professional Services (Legal, Consulting, etc.)",
    },
    {
      name: "Real Estate & Construction",
      category: "Real Estate & Construction",
    },
    { name: "Retail & Consumer Goods", category: "Retail & Consumer Goods" },
    { name: "Technology & IT Services", category: "Technology & IT Services" },
    { name: "Telecommunications", category: "Telecommunications" },
    {
      name: "Transportation & Logistics",
      category: "Transportation & Logistics",
    },
    { name: "Education & Training", category: "Education & Training" },
  ];

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: "64vw",
          height: "60vh",
          paddingLeft: 4,
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ my: "1vh", fontWeight: "500", color: "black" }}
        >
          Entity Information
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={{ color: "#8D8E88" }}>
              Entity Category
            </Typography>
            <AutocompleteField
              name="category"
              control={control}
              options={optionsArray}
              label="Category"
              placeholder="Select Option"
              disabled={CompanyDetails?.data?.status?.company}
            />
            {(watch("category") === "Other" ||
              getValues("category") === "Other") && (
              <Controller
                name="subCategory"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={
                      CompanyDetails?.data?.status?.company || tickcompanyname
                    }
                    field={{
                      ...field,
                      onChange: (e: any) => field.onChange(e.target.value),
                    }}
                    iD={"subCategory"}
                    name={"Sub Category"}
                    errors={errors}
                  />
                )}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Controller
                name="llpinNumber"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={
                      CompanyDetails?.data?.status?.company || tickcompanyname
                    }
                    field={{
                      ...field,
                      onChange: (e: any) =>
                        field.onChange(e.target.value.toUpperCase()),
                    }}
                    iD={"cinNumber"}
                    name={"LLPIN Number"}
                    errors={errors}
                  />
                )}
              />

              <>
                {" "}
                {Cinloader ? (
                  <>
                    <CircularProgress
                      sx={{
                        marginLeft: "25px",
                        marginTop: "15px",
                      }}
                      size={"2rem"}
                      color="primary"
                    />
                  </>
                ) : (
                  <>
                    {tickcompanyname ||
                    CompanyDetails?.data?.status?.company ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginTop={"1.5vh"}
                      >
                        <Box
                          sx={{
                            marginTop: "12px",
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.greenCheck}
                            alt="loading...."
                          />
                        </Box>

                        {!CompanyDetails?.data?.status?.company && (
                          <Box sx={{ marginTop: "1vh" }}>
                            <Editbutton
                              onclick={() => {
                                settickcompanyname(false);
                                settickcompanGstin(false);
                                setValue("gstNumber", "");
                                setValue("panNumber", "");
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box marginTop={"3vh"}>
                        {" "}
                        <GetDetailsButton
                          title="Get Details"
                          onclick={() => {
                            if (
                              getValues("category") === "Other" &&
                              (getValues("subCategory") === "" ||
                                getValues("subCategory") === undefined)
                            ) {
                              toast.error("Please Enter Subcategory");
                            } else {
                              const llpin = getValues().llpinNumber;
                              const llpinRegex = /^[A-Z]{3}-\d{4}$/;
                              if (
                                typeof llpin === "string" &&
                                llpinRegex.test(llpin)
                              ) {
                                getcindetails({
                                  type: "LLP",
                                  value: llpin,
                                  entityType: "LIMITED_LIABILITY_PARTNERSHIP",
                                });
                              } else {
                                toast.error("Please Enter Valid LLPIN");
                              }
                            }
                          }}
                        ></GetDetailsButton>
                        {}
                      </Box>
                    )}
                  </>
                )}
              </>
            </Box>
          </Box>

          {!!watch("companyName") && (
            <Box>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={Cindissable || disabled}
                    field={field}
                    iD={"firmName"}
                    name={"Registered Entity Name"}
                    errors={errors}
                  />
                )}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Controller
              name="gstNumber"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled={
                    CompanyDetails?.data?.status?.company || tickcompanyGstin
                  }
                  field={{
                    ...field,
                    onChange: (e: any) =>
                      field.onChange(e.target.value.toUpperCase()),
                  }}
                  iD={"gstNumber"}
                  name={"GST Number"}
                  errors={errors}
                />
              )}
            />

            {Panloader ? (
              <>
                <CircularProgress
                  sx={{
                    marginLeft: "25px",
                    marginTop: "15px",
                  }}
                  size={"2rem"}
                  color="primary"
                />
              </>
            ) : (
              <>
                {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
                  <Box display={"flex"} alignItems={"center"} marginTop={"2vh"}>
                    {isFirmValidated ? (
                      <>
                        {" "}
                        <Box
                          sx={{
                            marginTop: "12px",
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.greenCheck}
                            alt="loading...."
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Box
                          sx={{
                            marginTop: "12px",
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.alert}
                            alt="loading...."
                          />
                        </Box>
                      </>
                    )}

                    {!CompanyDetails?.data?.status?.company && (
                      <Box marginTop={"1vh"}>
                        <Editbutton onclick={settickcompanGstin} />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box marginTop={"3vh"}>
                    <GetDetailsButton
                      title="Get Details"
                      onclick={() => {
                        const Gstdetails = getValues().gstNumber;
                        const llpin = getValues().llpinNumber;

                        getpandetails({
                          type: "GST",
                          value: Gstdetails,
                          cinNumber: llpin,
                          entityType: "LIMITED_LIABILITY_PARTNERSHIP",
                        });
                      }}
                    ></GetDetailsButton>
                    {}
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box sx={{ display: "flex" }}>
            {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
              <>
                {}
                <Controller
                  name="panNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={true}
                      field={field}
                      iD={"panName"}
                      name={"Entity PAN Number"}
                      errors={errors}
                    />
                  )}
                />
              </>
            ) : (
              <></>
            )}

            {(tickcompanyGstin || CompanyDetails?.data?.status?.company) && (
              <>
                <Box
                  sx={{
                    marginTop: "2.5vh",
                    marginLeft: 2,
                    display: " flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={"25px"}
                    src={Icons.greenCheck}
                    alt="loading...."
                  />
                </Box>
              </>
            )}
          </Box>
          {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
            <>
              {" "}
              {isFirmValidated ? (
                <></>
              ) : (
                <>
                  {" "}
                  <EntityInformationAlert variant="body1">
                    Business name mismatch between LLPIN & GST.
                  </EntityInformationAlert>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </form>
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "2.4vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        <CustomLoadingButton
          submitLoader={submitloader}
          onClick={() => Submitdata()}
          isDisabled={
            CompanyDetails?.data?.status?.company ||
            !tickcompanyGstin ||
            !tickcompanyname
          }
          title="Next"
        />
        {}
      </Box>
    </>
  );
};

export default CompanyInformationForm;
