import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../../../api/services";
import {
  getDealsDataById,
  getSignerStatus,
} from "../../../../../api/services/deals";
import { Icons } from "../../../../../assets";
import { Deal } from "../../../../../interfaces/deal";
import { DealBox, DealCard } from "../../../../../styles/styled-components";
import DealTop from "../DealTop";
import SigneeCard from "./DealSignature";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentBoxMobile = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "grid",
  },
  [theme.breakpoints.up("sm")]: {
    display: "grid",
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
  [theme.breakpoints.up("xl")]: {
    display: "none",
  },
  padding: "10px",
  backgroundColor: "#efefef",
  margin: "2%",
  marginTop: "4%",
  borderRadius: "8px",
  gap: "10px",
}));

const PdfContainer = styled(Box)({
  backgroundColor: "#F9F9F9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "30px",
});

const PdfViewer = styled(Card)(({ theme }) => ({
  padding: "15px",
  backgroundColor: "white",
  overflow: "auto",
  [theme.breakpoints.up("xs")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.up("sm")]: {
    maxHeight: "55vh",
  },
  [theme.breakpoints.up("md")]: {
    maxHeight: "70vh",
  },
  [theme.breakpoints.up("lg")]: {
    maxHeight: "90vh",
  },
  [theme.breakpoints.up("xl")]: {
    maxHeight: "110vh",
  },
}));

function formatDate(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

const RightPanel = styled(Box)({
  padding: 2,
});

export default function DealDocumentationSignee() {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [numPages, setNumPages] = useState(0);

  const { data: profileData } = useQuery("getUser-id", getUserProfile);
  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: (e: any) => {
        navigate("/deal-process/deals/all");
      },
    }
  );
  const deal: Deal = dealData?.data;
  const dealCreator = deal?.dealUsers?.find(
    (user: any) => user?.userId === deal?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.data?.id;

  // const { mutate: status, isLoading: userLoader } = useMutation(
  //   getSignerStatus,
  //   {
  //     onSuccess: () => {
  //       toast.success("Status updated successfully");
  //       localStorage.removeItem("statusUpdated");
  //       queryClient.invalidateQueries("deal");
  //       window.location.reload();
  //     },
  //     onError: (error: any) => {
  //       toast.error(error?.response?.data?.message);
  //     },
  //   }
  // );

  // const { mutate: statusForReload, isLoading: userLoaders } = useMutation(
  //   getSignerStatus,
  //   {
  //     onSuccess: () => {
  //       localStorage.removeItem("statusUpdated");
  //       queryClient.invalidateQueries("deal");
  //     },
  //     onError: (error: any) => {
  //       toast.error(error?.response?.data?.message);
  //     },
  //   }
  // );

  // useEffect(() => {
  //   statusForReload({ data: { code: deal?.code } });
  // }, [statusForReload]);

  // const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);

  const handleDownload = () => {
    if (!deal?.signedDocUrl) {
      console.error("Signed document URL is undefined");
      return;
    }
    fetch(deal?.signedDocUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "deal.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  // const handleStatus = () => {
  //   status({ data: { code: deal?.code } });
  // };

  const { mutate: status, isLoading: userLoader } = useMutation(
    getSignerStatus,
    {
      onSuccess: (res: any) => {
        toast.success("Status updated successfully");
        queryClient.invalidateQueries("deal");
        window.location.reload();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: Reloadstatus, isLoading: userLoaders } = useMutation(
    getSignerStatus,
    {
      onSuccess: (res: any) => {
        // toast.success("Status updated successfully");
        queryClient.invalidateQueries("deal");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleStatus = () => {
    status({ data: { code: deal?.code } });
  };

  useEffect(() => {
    Reloadstatus({ data: { code: deal?.code } });
  }, [status, deal?.code]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  if (isLoading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <DealBox>
      <DealCard>
        <DealTop
          dealData={deal}
          profileData={profileData?.data}
          dealCode={deal?.code}
          breadcrumbs={[
            { label: "Deals", href: "/deal-process/deals/all" },
            { label: dealId, href: `/deal-process/deals/${dealId}` },
            {
              label: "Deal Document",
              href: `/deal-process/deals/${dealId}/documentationSignee`,
            },
          ]}
        />
        <DocumentBoxMobile>
          <Box
            sx={{
              px: "1vw",
              width: "100%",
              opacity: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              // justifyContent={"space-between"}
              gap={"10px"}
            >
              <Box>
                <img
                  height={"30px"}
                  src={Icons.pdf}
                  alt="loading....."
                  style={{ cursor: "pointer" }}
                />
              </Box>

              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Deal Document
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    marginTop: "5px",
                    fontWeight: "100",
                  }}
                >
                  Created on {formatDate(deal?.createdAt)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                background: "#F2F2F2",
                borderRadius: "5px",
                opacity: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  window.open(deal?.signedDocUrl, "_blank");
                }}
                sx={{
                  backgroundColor: "#d1d1d1",
                  borderRadius: "6px",
                  "&:hover": {
                    backgroundColor: "#c0c0c0", // Slightly darker shade for hover
                  },
                  "&:active": {
                    backgroundColor: "#d1d1d1", // Reverts back to original color on click
                  },
                }}
              >
                <RemoveRedEyeIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownload}
            sx={{
              boxShadow: "0px 3px 6px #00000029",
              height: "30px",
              marginRight: "2%",
              marginLeft: "2%",
              marginBottom: "2%",
            }}
          >
            Download Document
          </Button>
        </DocumentBoxMobile>
        <Box display={"flex"} padding={"0px 0px"}>
          <Box
            sx={{
              width: "60%",
              padding: 2,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <PdfContainer>
              <PdfViewer>
                <div
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    marginLeft: 10,
                    marginTop: -25,
                  }}
                >
                  <Document
                    file={deal?.signedDocUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <div key={index}>
                        {index !== 0 && <Divider sx={{ my: 1.5 }} />}
                        <Page
                          pageNumber={index + 1}
                          className="pdf-page"
                          renderMode="canvas"
                          width={700}
                          loading="..."
                        />
                      </div>
                    ))}
                  </Document>
                </div>
              </PdfViewer>
            </PdfContainer>
          </Box>

          <RightPanel
            sx={{
              borderLeft: {
                xs: "none",
                sm: "none",
                md: "1px solid #1212121A",
                lg: "1px solid #1212121A",
                xl: "1px solid #1212121A",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "32%",
                lg: "32%",
                xl: "32%",
              },
            }}
          >
            <SigneeCard
              data={deal}
              accountOwner={firstPerson}
              needed={profileData}
            />
          </RightPanel>
        </Box>
      </DealCard>

      <style>{`
        .pdf-page {
          margin-bottom: -16px; 
        }
      `}</style>
    </DealBox>
  );
}
