import { Box, Stack, Typography } from "@mui/material";

export default function UnderVerification({ data, secondUser, pay }: any) {
  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Payment under Verification
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Payment details initiated by{" "}
          {secondUser?.userType === "Buyer" ? "Buyer" : "You"} are being
          verified. Once the verification is complete, the funds will be
          securely transferred into
          {secondUser?.userType === "Buyer" ? " Your " : " Seller "} account.
        </Typography>
      </Stack>
    </Box>
  );
}
