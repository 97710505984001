import { LinearProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserProfile } from "../../api/services";
import ConfirmDialogProvider, {
  useConfirm,
} from "../../components/shared/ConfirmDialogProvider";
import LimitedLiabilityPartnership from "./limitedLiabilityPartnership/LimitedLiabilityPartnership";
import PartnershipFirm from "./partnershipFirm/PartnershipFirm";
import PrivateLimitedCompany from "./privateLimitedCompany/PrivateLimitedCompany";
import SoleProprietorship from "./soleProprietorship/SoleProprietorship";
import OtherEntity from "./otherEntity/OtherEntity";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const tabStyles = {
  height: "2vh",
  paddingBottom: "2px",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "16px",
  },
};

export default function KYCRegistration() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginX: "8%",
        }}
      >
        <Box>
          <Typography
            variant="h1"
            sx={{ color: "#7643EB", marginTop: "2vh", fontWeight: "500" }}
          >
            KYC/ KYB Application
          </Typography>
        </Box>
      </Box>

      <ConfirmDialogProvider>
        <KYCRegistrationWrapper />
      </ConfirmDialogProvider>
    </Box>
  );
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            borderTop: "1px solid #cacaca",
            width: "100vw",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function KYCRegistrationWrapper() {
  const [isEntityAvailable, setIsEntityAvailable] = useState(false);
  const [value, setValue] = useState(0);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const endpoint = pathname.split("/").pop();
  const { data: profileData, isLoading }: any = useQuery(
    "getUser-id",
    getUserProfile
  );

  useEffect(() => {
    if (endpoint === "private-public") {
      setValue(0);
    } else if (endpoint === "limited-liability") {
      setValue(1);
    } else if (endpoint === "partnership-firm") {
      setValue(2);
    } else if (endpoint === "sole-proprietorship") {
      setValue(3);
    } else if (endpoint === "other-entity") {
      setValue(4);
    }

    if (Cookies.get("entityId")) {
      setIsEntityAvailable(true);
    } else {
      setIsEntityAvailable(false);
    }
  }, [endpoint]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {};

  if (isLoading) {
    return <LinearProgress sx={{ my: "20px" }}></LinearProgress>;
  }
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          "& .Mui-selected": {
            paddingX: 0,
            width: "max-content",
          },
          "& .MuiTabs-indicator": {
            height: "4px",
            borderRadius: "10px",
          },
          "& .MuiTabs-flexContainer": {
            gap: "5%",
          },
          marginX: "8%",
          height: "5vh",
          paddingLeft: "21vw",
        }}
      >
        <Tab
          onClick={() => {
            if (profileData?.data?.entities.length > 0) {
              confirm({
                msg: "Are you sure you want to leave this Entity Type ? Changes that you made may not be saved. ",
                action: () => {
                  navigate("/kycprocess/private-public", { replace: true });
                },
              });
            } else {
              navigate("/kycprocess/private-public", { replace: true });
            }
          }}
          label="Private/Public Limited Company"
          {...a11yProps(0)}
          sx={tabStyles}
        />
        <Tab
          onClick={() => {
            if (isEntityAvailable) {
              confirm({
                msg: "Are you sure you want to leave this Entity Type ? Changes that you made may not be saved. ",
                action: () => {
                  navigate("/kycprocess/limited-liability", { replace: true });
                },
              });
            } else {
              navigate("/kycprocess/limited-liability", { replace: true });
            }
          }}
          label="Limited Liability Partnership"
          {...a11yProps(1)}
          sx={tabStyles}
        />{" "}
        <Tab
          onClick={() => {
            if (isEntityAvailable) {
              confirm({
                msg: "Are you sure you want to leave this Entity Type ? Changes that you made may not be saved. ",
                action: () => {
                  navigate("/kycprocess/partnership-firm", { replace: true });
                },
              });
            } else {
              navigate("/kycprocess/partnership-firm", { replace: true });
            }
          }}
          label="Partnership Firm"
          {...a11yProps(2)}
          sx={tabStyles}
        />
        <Tab
          onClick={() => {
            if (isEntityAvailable) {
              confirm({
                msg: "Are you sure you want to leave this Entity Type ? Changes that you made may not be saved. ",
                action: () => {
                  navigate("/kycprocess/sole-proprietorship", {
                    replace: true,
                  });
                },
              });
            } else {
              navigate("/kycprocess/sole-proprietorship", { replace: true });
            }
          }}
          label="Sole Proprietorship"
          {...a11yProps(3)}
          sx={tabStyles}
        />
        {/* <Tab
          onClick={() => {
            if (isEntityAvailable) {
              confirm({
                msg: "Are you sure you want to leave this Entity Type ? Changes that you made may not be saved. ",
                action: () => {
                  navigate("/kycprocess/other-entity", {
                    replace: true,
                  });
                },
              });
            } else {
              navigate("/kycprocess/other-entity", { replace: true });
            }
          }}
          label="Other Entity"
          {...a11yProps(4)}
          sx={tabStyles}
        /> */}
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <PrivateLimitedCompany />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <LimitedLiabilityPartnership />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <PartnershipFirm />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <SoleProprietorship />
      </CustomTabPanel>

      {/* <CustomTabPanel value={value} index={4}>
        <OtherEntity />
      </CustomTabPanel> */}
    </Box>
  );
}
