import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  AddbankingInformation,
  VerifyIfscCode,
  verifyBankDetails,
} from "../../../api/services";
import { Icons } from "../../../assets";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import MultiInputField, {
  Formate,
} from "../../../components/formFields/kycFields/MultiInputField";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";
import Editbutton from "../../../components/buttons/Editbutton";

type Info = {
  ifscCode: string;
  bankAccountNumber: string;
  bankName: string;
  branchName: string;
  BeneficiaryName: string;
};

const BankingForm = ({ data, close, subType, typeId, typeData }: any) => {
  const EntityId = data?.data?.id;
  const [disabled, setdisabled] = useState(false);
  const [bankName, setBankName] = useState(false);
  const [fileData, setFileData] = useState<{ [key: string]: string }>({});
  const [verifyDetails, setVerifyDetails] = useState(true);
  const [bankGreenTick, setbankGreenTick] = useState(false);
  const queryClient = useQueryClient();
  const [showname, setshowname] = useState(false);
  const [bankDetails, setBankDetails] = useState<any>();
  const [bankingStatus, setBankingStatus] = useState(false);
  const [chequeKey, setChequeKey] = useState("");
  const [isFileDeleted, setIsFileDeleted] = useState(true);
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<Info>();

  const CompanyDetails = data;

  const statusDetails = {
    data: {
      banking: bankingStatus,
    },
  };

  useEffect(() => {
    if (CompanyDetails && EntityId) {
      setdisabled(true);
      setBankName(true);
      setVerifyDetails(false);
    }
  }, [CompanyDetails, EntityId]);

  const { mutate: BankDetails, isLoading: BankDetailLoading } = useMutation(
    VerifyIfscCode,
    {
      onSuccess: (BankDetails: any) => {
        setValue("bankName", BankDetails?.data?.BANK);
        setValue("branchName", BankDetails?.data?.BRANCH);
        verifyBank({
          id_number: getValues("bankAccountNumber"),
          ifsc: getValues("ifscCode"),
        });
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter Aadhar OTP");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: verifyBank, isLoading: addBankLoader } = useMutation(
    verifyBankDetails,
    {
      onSuccess: (res: any) => {
        setbankGreenTick(true);
        setValue("BeneficiaryName", res?.data?.data?.full_name);
        setshowname(true);

        const finalData = {
          entityId: EntityId,
          name: getValues("bankName"),
          accountNumber: getValues("bankAccountNumber"),
          ifscCode: getValues("ifscCode"),
          branch: getValues("bankName"),
          beneficiaryName: getValues("BeneficiaryName"),
          status: "In Progress",
        };
        setBankDetails(finalData);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
        }
      },
    }
  );

  const { mutate: postbankingdetails, isLoading: submitloader } = useMutation(
    AddbankingInformation,
    {
      onSuccess: (res: any) => {
        toast.success("Information Updated Successfully");
        queryClient.invalidateQueries("status-data");
        queryClient.invalidateQueries("banking");
        queryClient.invalidateQueries("getuser-id");
        queryClient.invalidateQueries("entity");
        close(true);
        reset();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = () => {
    const bankingKeys = [chequeKey];

    const updatedBankDetails = {
      ...bankDetails,
      bankingKeys,
    };
    postbankingdetails(updatedBankDetails);
  };

  const handleFileChange = (fileKey: any) => {
    setChequeKey(fileKey);
  };

  const resetData = () => {
    reset({
      bankAccountNumber: "",
      ifscCode: "",
      branchName: "",
      bankName: "",
      BeneficiaryName: "",
    });
  };

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Banking Information
        </Typography>
        <form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <MultiInputField
              control={control}
              label={"Bank Account Number"}
              name={"bankAccountNumber"}
              key="bankAccountNumber"
              variant={Formate.OnlyNumbers}
              disabled={statusDetails?.data?.banking || bankGreenTick}
              type="text"
              errors={errors}
              sx={{ width: "300px" }}
            />

            {(statusDetails?.data?.banking || bankGreenTick) && (
              <>
                <Box
                  sx={{
                    marginTop: "12px",
                    display: " flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                >
                  <Box marginTop={"2.4vh"}>
                    <img
                      height={"25px"}
                      src={Icons.greenCheck}
                      alt="loading...."
                    />
                  </Box>
                </Box>

                {!statusDetails?.data?.banking && (
                  <>
                    <Box marginTop={"3vh"}>
                      <Editbutton
                        onclick={() => {
                          setbankGreenTick(false);
                          resetData();
                        }}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
            }}
          >
            <MultiInputField
              control={control}
              label={"IFSC Code"}
              name={"ifscCode"}
              key="ifscCode"
              variant={Formate.StrictUpperCase}
              disabled={statusDetails?.data?.banking || bankGreenTick}
              type="text"
              errors={errors}
              sx={{ width: "300px" }}
            />

            {BankDetailLoading || addBankLoader ? (
              <>
                <CircularProgress
                  sx={{
                    marginLeft: "25px",
                    marginTop: "15px",
                  }}
                  size={"2rem"}
                  color="primary"
                />
              </>
            ) : (
              <>
                {bankGreenTick || statusDetails?.data?.banking ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <Box
                      sx={{
                        marginTop: "12px",
                        display: " flex",
                        alignItems: "center",
                      }}
                    >
                      <Box marginTop={"2.4vh"}>
                        <img
                          height={"25px"}
                          src={Icons.greenCheck}
                          alt="loading...."
                        />
                      </Box>

                      {!statusDetails?.data?.banking && (
                        <>
                          <Box marginTop={"1.5vh"}>
                            <Editbutton
                              onclick={() => {
                                setbankGreenTick(false);
                                resetData();
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        const IfscCode = getValues().ifscCode;
                        if (IfscCode === undefined) {
                          toast.error("Please Enter IFSC Code ");
                        } else {
                          BankDetails(IfscCode);
                        }
                      }}
                      sx={{
                        marginTop: "3vh",
                        padding: 0,
                        fontSize: "12px",
                        height: "30px",
                      }}
                    >
                      Verify
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>

          {bankName && (
            <>
              <MultiInputField
                control={control}
                label={"Bank Name"}
                name={"bankName"}
                key="bankName"
                variant={Formate.AlphabetsOnly}
                disabled={statusDetails?.data?.banking || bankGreenTick || true}
                type="text"
                errors={errors}
                sx={{ width: "300px" }}
              />

              <MultiInputField
                control={control}
                label={"Branch Name"}
                name={"branchName"}
                key="branchName"
                variant={Formate.AlphabetsOnly}
                disabled={statusDetails?.data?.banking || bankGreenTick || true}
                type="text"
                errors={errors}
                sx={{ width: "300px" }}
              />

              {/* {(showname || statusDetails?.data?.banking) && ( */}
              <MultiInputField
                control={control}
                label={"Beneficiary Name"}
                name={"BeneficiaryName"}
                key="BeneficiaryName"
                variant={Formate.AlphabetsOnly}
                disabled={statusDetails?.data?.banking || bankGreenTick || true}
                type="text"
                errors={errors}
                sx={{ width: "300px" }}
              />
              {/* )} */}
            </>
          )}
        </form>
        <Stack my={"10px"}>
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Cancelled Cheque
            </Typography>
            <FileUploadContainer
              typeId={null}
              actions={<></>}
              data={"CANCELLED_CHEQUE"}
              typeData={"BANKING"}
              onFileChange={(key) => {
                if (key === "") {
                  setIsFileDeleted(true);
                } else {
                  setIsFileDeleted(false);
                  handleFileChange(key);
                }
              }}
            />
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginX: 0,
          alignContent: "center",
        }}
      >
        <CustomLoadingButton
          submitLoader={submitloader}
          onClick={onSubmit}
          isDisabled={
            !getValues("bankAccountNumber") ||
            !getValues("ifscCode") ||
            isFileDeleted
          }
          title="submit"
        />
      </Box>
    </>
  );
};

export default BankingForm;
