import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Typography } from "@mui/material";

import { styled } from "@mui/system";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  AddCompanyDetails,
  getComapanyDetailsById,
  surepassValidations,
  userActivity,
} from "../../../api/services";
import { Icons } from "../../../assets";

import Editbutton from "../../../components/buttons/Editbutton";
import GetDetailsButton from "../../../components/buttons/Getdetails";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import AutocompleteField from "../../../components/formFields/kycFields/AutocompleteField";
import MultiInputField, {
  Formate,
} from "../../../components/formFields/kycFields/MultiInputField";
import { kycDetailsStore } from "../../../store/kycProcess";

type Info = {
  category: string;
  cinNumber: string;
  companyName: string;
  gstNumber: string;
  panNumber: string;
  subCategory: string;
};

const EntityInformationAlert = styled(Typography)({
  opacity: "85%",
  color: "#800000",
  margin: "8px 0",
  fontStyle: "italic",
});

const schema = yup.object().shape({
  subCategory: yup.string().required("Business Category is required"),
  category: yup.string().required("Business Category is required"),
  cinNumber: yup
    .string()
    .typeError("CIN Number must be a number")
    .required("CIN Number is required"),
  companyName: yup.string().required("Firm Name is required"),
  gstNumber: yup
    .string()
    .typeError("GST Number must be a string")
    .required("GST Number is required"),
  panNumber: yup
    .string()
    .typeError("PAN Number must be a string")
    .required("PAN Number is required"),
});

const CompanyInformationForm = ({ onNextClick }: any) => {
  const moment = require("moment");
  const EntityId = Cookies.get("entityId");
  const queryClient = useQueryClient();
  const [disabled, setdisabled] = useState(false);
  const [tickcompanyname, settickcompanyname] = useState(false);
  const [tickcompanyGstin, settickcompanGstin] = useState(false);
  const [cinloader, SetCinloader] = useState(false);
  const [gstloader, setGstloader] = useState(false);
  const [goToNext, setGoToNext] = useState(false);
  const [isFirmValidated, setIsFirmValidated] = useState(false);

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<Info>({
    resolver: yupResolver(schema),
  });
  const { data: CompanyDetails } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    if (CompanyDetails && EntityId) {
      setdisabled(true);
      reset({
        subCategory: CompanyDetails?.data?.subCategory,
        category: CompanyDetails?.data?.category,
        cinNumber: CompanyDetails?.data?.cinNumber,
        companyName: CompanyDetails?.data?.companyName,
        gstNumber: CompanyDetails?.data?.gstNumber,
        panNumber: CompanyDetails?.data?.panNumber,
      });
      setIsFirmValidated(CompanyDetails?.data?.isFirmValidated);
    }
  }, [CompanyDetails, EntityId, reset]);

  const { mutate: surepassValidate } = useMutation(surepassValidations, {
    onSuccess: (res: any) => {
      SetCinloader(false);
      setGstloader(false);
      setValue("companyName", res?.data?.data);
      setIsFirmValidated(res?.data?.isFirmValidated);
      settickcompanyname(true);
      const dateTime = moment(Date());
      const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
      kycDetailsStore.update((s) => {
        s.lastUpdatedAt = savedDateTime;
      });
      if (tickcompanyname) {
        setValue("panNumber", res?.data?.panNumber);
        setGoToNext(true);
        settickcompanGstin(true);
      }
    },
    onError: (error: any) => {
      SetCinloader(false);
      setGstloader(false);
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: postcompanydetails, isLoading: submitloader } = useMutation(
    AddCompanyDetails,
    {
      onSuccess: (res: any) => {
        toast.success("Information Updated Successfully");
        const accessToken: any = Cookies.get("access_token");
        const userdata: any = jwtDecode(accessToken);
        const entityId = res?.data?.data?.id;
        Cookies.set("entityId", entityId);
        queryClient.invalidateQueries("status-data");
        // queryClient.invalidateQueries("status");
        queryClient.invalidateQueries("getcompanydetails");
        queryClient.invalidateQueries("getuser-id");
        const draftdata = {
          userId: userdata?.userId,
          entityId: entityId,
        };
        useractivity(draftdata);
        window.location.reload();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const Submitdata = () => {
    const data = {
      subCategory: getValues("subCategory") || null,
      activity: "KYB/KYC Process in Draft",
      category: getValues("category"),
      cinNumber: getValues("cinNumber"),
      companyName: getValues("companyName"),
      gstNumber: getValues("gstNumber"),
      panNumber: getValues("panNumber"),
      companyType: "PRIVATE_LIMITED",
      isFirmValidated: isFirmValidated,
    };
    postcompanydetails(data);
  };

  const optionsArray = [
    { name: "Agriculture & Forestry", category: "Agriculture & Forestry" },
    { name: "Arts, Crafts & Hobbies", category: "Arts, Crafts & Hobbies" },
    { name: "Energy & Utilities", category: "Energy & Utilities" },
    { name: "Environmental Services", category: "Environmental Services" },
    { name: "Fashion & Apparel", category: "Fashion & Apparel" },
    { name: "Finance & Banking", category: "Finance & Banking" },
    { name: "Food & Beverage", category: "Food & Beverage" },
    {
      name: "Healthcare & Pharmaceuticals",
      category: "Healthcare & Pharmaceuticals",
    },
    { name: "Hospitality & Tourism", category: "Hospitality & Tourism" },
    { name: "Manufacturing", category: "Manufacturing" },
    { name: "Media & Entertainment", category: "Media & Entertainment" },
    { name: "Mining & Metals", category: "Mining & Metals" },
    {
      name: "Non-Profit & Social Services",
      category: "Non-Profit & Social Services",
    },
    {
      name: "Other",
      category: "Other",
    },
    {
      name: "Professional Services (Legal, Consulting, etc.)",
      category: "Professional Services (Legal, Consulting, etc.)",
    },
    {
      name: "Real Estate & Construction",
      category: "Real Estate & Construction",
    },
    { name: "Retail & Consumer Goods", category: "Retail & Consumer Goods" },
    { name: "Technology & IT Services", category: "Technology & IT Services" },
    { name: "Telecommunications", category: "Telecommunications" },
    {
      name: "Transportation & Logistics",
      category: "Transportation & Logistics",
    },
    { name: "Education & Training", category: "Education & Training" },
  ];

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: "64vw",
          height: "60vh",
          paddingLeft: 4,
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ my: "1vh", fontWeight: "500", color: "black" }}
        >
          Entity Information
        </Typography>
        <form>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={{ color: "#8D8E88" }}>
              Entity Category
            </Typography>
            <AutocompleteField
              name="category"
              control={control}
              options={optionsArray}
              label="Category"
              placeholder="Select Option"
              disabled={CompanyDetails?.data?.status?.company}
            />
            {(watch("category") === "Other" ||
              getValues("category") === "Other") && (
              <MultiInputField
                control={control}
                label={"Sub Category"}
                name={"subCategory"}
                key="subCategory"
                variant={Formate.AlphabetsOnly}
                type="text"
                errors={errors}
                disabled={CompanyDetails?.data?.status?.company}
              />

              // <Controller
              //   name="subCategory"
              //   control={control}
              //   render={({ field }) => (
              //     <InputField
              //       isdisabled={
              //         CompanyDetails?.data?.status?.company || tickcompanyname
              //       }
              //       field={{
              //         ...field,
              //         onChange: (e: any) => field.onChange(e.target.value),
              //       }}
              //       iD={"subCategory"}
              //       name={"Sub Category"}
              //       errors={errors}
              //     />
              //   )}
              // />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1vw",
              }}
            >
              <MultiInputField
                control={control}
                label={"CIN Number"}
                name={"cinNumber"}
                key="cinNumber"
                variant={Formate.StrictUpperCase}
                disabled={
                  CompanyDetails?.data?.status?.company || tickcompanyname
                }
                type="text"
                errors={errors}
              />

              {/* <Controller
                name="cinNumber"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={
                      CompanyDetails?.data?.status?.company || tickcompanyname
                    }
                    field={{
                      ...field,
                      onChange: (e: any) => {
                        const finalvalue = (e: any) => {
                          return e.target.value.toUpperCase();
                        };
                        field.onChange(finalvalue(e));
                      },
                    }}
                    iD={"cinNumber"}
                    name={"CIN Number"}
                    errors={errors}
                  />
                )}
              /> */}

              <Box height={"100%"}>
                {" "}
                {cinloader ? (
                  <>
                    <CircularProgress
                      sx={{
                        marginLeft: "1vw",
                        marginTop: "2vh",
                      }}
                      size={"2rem"}
                      color="primary"
                    />
                  </>
                ) : (
                  <>
                    {tickcompanyname ||
                    CompanyDetails?.data?.status?.company ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginTop={"1.5vh"}
                      >
                        <Box
                          sx={{
                            marginTop: "12px",
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.greenCheck}
                            alt="loading...."
                          />
                        </Box>

                        {!CompanyDetails?.data?.status?.company && (
                          <Box sx={{ marginTop: "1vh" }}>
                            <Editbutton
                              onclick={() => {
                                settickcompanyname(false);
                                settickcompanGstin(false);
                                setValue("gstNumber", "");
                                setValue("panNumber", "");
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box marginTop={"3vh"}>
                        <GetDetailsButton
                          onclick={() => {
                            if (
                              getValues("category") === "Other" &&
                              (getValues("subCategory") === "" ||
                                getValues("subCategory") === undefined)
                            ) {
                              toast.error("Please Enter Subcategory");
                            } else {
                              const cinnumber = getValues().cinNumber;
                              surepassValidate({
                                type: "CIN",
                                value: cinnumber,
                                entityType: "PRIVATE_LIMITED",
                              });
                              SetCinloader(true);
                            }
                          }}
                          title=" Get Details"
                        ></GetDetailsButton>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {(tickcompanyname || CompanyDetails?.data?.status?.company) && (
            <Box>
              <MultiInputField
                control={control}
                label={"Registered Entity Name"}
                name={"companyName"}
                key="companyName"
                variant={Formate.StrictUpperCase}
                disabled={tickcompanyname || disabled}
                type="text"
                errors={errors}
              />

              {/* <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={tickcompanyname || disabled}
                    field={field}
                    iD={"firmName"}
                    name={"Registered Entity Name"}
                    errors={errors}
                  />
                )}
              /> */}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <MultiInputField
              control={control}
              label={"GST Number"}
              name={"gstNumber"}
              key="gstNumber"
              variant={Formate.StrictUpperCase}
              disabled={
                CompanyDetails?.data?.status?.company || tickcompanyGstin
              }
              type="text"
              errors={errors}
            />

            {/* <Controller
              name="gstNumber"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled={
                    CompanyDetails?.data?.status?.company || tickcompanyGstin
                  }
                  field={{
                    ...field,
                    onChange: (e: any) =>
                      field.onChange(e.target.value.toUpperCase()),
                  }}
                  iD={"gstNumber"}
                  name={"GST Number"}
                  errors={errors}
                />
              )}
            /> */}

            {gstloader ? (
              <>
                <CircularProgress
                  sx={{
                    marginLeft: "1vw",
                    marginTop: "2vh",
                  }}
                  size={"2rem"}
                  color="primary"
                />
              </>
            ) : (
              <>
                {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
                  <Box display={"flex"} alignItems={"center"} marginTop={"2vh"}>
                    {isFirmValidated ? (
                      <>
                        {" "}
                        <Box
                          sx={{
                            marginTop: "12px",
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.greenCheck}
                            alt="loading...."
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Box
                          sx={{
                            marginTop: "12px",
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.alert}
                            alt="loading...."
                          />
                        </Box>
                      </>
                    )}

                    {!CompanyDetails?.data?.status?.company && (
                      <Box marginTop={"1vh"}>
                        <Editbutton
                          onclick={() => {
                            settickcompanGstin(false);
                            setGoToNext(false);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box marginTop={"3vh"}>
                    <GetDetailsButton
                      onclick={() => {
                        setGstloader(true);
                        surepassValidate({
                          type: "GST",
                          value: getValues("gstNumber"),
                          cinNumber: getValues("cinNumber"),
                          entityType: "PRIVATE_LIMITED",
                        });
                      }}
                      title=" Get Details"
                    ></GetDetailsButton>
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box sx={{ display: "flex" }}>
            {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
              <>
                <MultiInputField
                  control={control}
                  label={"Entity PAN Number"}
                  name={"panNumber"}
                  key="panNumber"
                  variant={Formate.StrictUpperCase}
                  disabled={true}
                  type="text"
                  errors={errors}
                />

                {/* <Controller
                  name="panNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={true}
                      field={field}
                      iD={"panName"}
                      name={"Entity PAN Number"}
                      errors={errors}
                    />
                  )}
                /> */}
              </>
            ) : (
              <></>
            )}

            {(tickcompanyGstin || CompanyDetails?.data?.status?.company) && (
              <>
                <Box
                  sx={{
                    marginTop: "2.5vh",
                    marginLeft: 2,
                    display: " flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={"25px"}
                    src={Icons.greenCheck}
                    alt="loading...."
                  />
                </Box>
              </>
            )}
          </Box>

          {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
            <>
              {" "}
              {isFirmValidated ? (
                <></>
              ) : (
                <>
                  {" "}
                  <EntityInformationAlert variant="body1">
                    Business name mismatch between CIN & GST.
                  </EntityInformationAlert>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </form>
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "2.2vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        <CustomLoadingButton
          submitLoader={submitloader}
          onClick={() => Submitdata()}
          isDisabled={CompanyDetails?.data?.status?.company || !goToNext}
          title="Next"
        />
      </Box>
    </>
  );
};

export default CompanyInformationForm;
