import { Box, Button, SelectChangeEvent, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getComapanyDetailsById,
  getDirectorAddressProof,
  getStatusbyId,
  userActivity,
} from "../../../api/services";
import SignatoryDocuments from "../components/SignatoryDocuments";

const DirectorDocuments = ({ addDirector }: any) => {
  const [togglesave, settogglesave] = useState(true);
  const { kyctype } = useParams();
  const [save, setsave] = useState(false);
  const navigate = useNavigate();
  const EntityId = Cookies.get("entityId");
  const [areFilesDeleted, setAreFilesDeleted] = useState(false);
  const { data: CompanyDetails } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      enabled: !!EntityId,
      onSuccess: () => {},
    }
  );

  const queryClient = useQueryClient();

  const [addressProofTypes, setAddressProofTypes] = useState<{
    [key: string]: string;
  }>({});

  const [optionsOpen, setOptionsOpen] = useState(
    Array(CompanyDetails?.data?.signatoryDirectors?.length).fill(false)
  );

  const { data: FirmAddressProof } = useQuery(
    "FirmAddressProof",
    getDirectorAddressProof
  );

  const handleAddressProofChange = (
    event: SelectChangeEvent<string>,
    directorId: string
  ) => {
    const value = event.target.value as string;
    setAddressProofTypes((prevTypes) => ({
      ...prevTypes,
      [directorId]: value,
    }));
  };

  const directorsWithFileType = CompanyDetails?.data?.signatoryDirectors?.map(
    (director: any) => {
      const documentWithFileType = director.documents?.find(
        (doc: any) => doc.filetype !== "null"
      );
      const fileType = documentWithFileType?.filetype || "";
      return {
        ...director,
        filetype: fileType,
      };
    }
  );

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const navigateAfterSubmit = () => {
    navigate(`/kycprocess/individual/preview`, { replace: true });
  };
  const { data: statusDetails } = useQuery(
    ["status-data", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    if (CompanyDetails?.data?.directorDocuments) {
      setsave(true);
      queryClient.invalidateQueries("documents");
      queryClient.invalidateQueries("getuser-id");
      toast.success("Your data is saved successfully");
    }
  }, [CompanyDetails?.data?.directorDocuments, queryClient]);

  const onSubmit = () => {
    const accessToken: any = Cookies.get("access_token");
    const userdata: any = jwtDecode(accessToken);
    const draftdata = {
      userId: userdata?.userId,
      activity: "KYB/KYC Process in Draft",
      entityId: EntityId,
    };
    onSave();
    useractivity(draftdata);
    navigateAfterSubmit();
  };

  const onSave = () => {
    queryClient.invalidateQueries("status-data");
  };

  const handleSelectOpen = (index: any) => {
    const updatedOptionsOpen = [...optionsOpen];
    updatedOptionsOpen[index] = true;
    setOptionsOpen(updatedOptionsOpen);
  };

  // useEffect(() => {
  //   const isAllUploaded = CompanyDetails?.data?.signatoryDirectors?.every(
  //     (director: any) => {
  //       return (
  //         director?.documents?.some((doc: any) => doc?.subtype === "AADHAR") &&
  //         director?.documents?.some((doc: any) => doc?.subtype === "PAN") &&
  //         director?.documents?.some(
  //           (doc: any) => doc?.subtype === "ADDRESS_PROOF"
  //         )
  //       );
  //     }
  //   );
  //   settogglesave(!isAllUploaded);
  // }, [CompanyDetails?.data?.signatoryDirectors]);
  useEffect(() => {
    if (
      CompanyDetails?.data?.signatoryDirectors &&
      Array.isArray(CompanyDetails.data.signatoryDirectors)
    ) {
      const allDocumentsUploaded = CompanyDetails.data.signatoryDirectors.every(
        (director: any) => director.areDocumentsUploaded === true
      );
      settogglesave(allDocumentsUploaded);
    }
  }, [CompanyDetails?.data?.signatoryDirectors]);

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: { xs: "100%", md: "64vw" },
          height: { xs: "52vh", md: "57.9vh" },
          paddingLeft: { xs: 0, md: 2 },
          mt: 2,
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography variant="h6" gutterBottom>
          Signatory Information
        </Typography>

        {CompanyDetails?.data?.signatoryDirectors?.map(
          (director: any, index: any) => {
            const addressProof = director?.documents?.find(
              (obj: any) => obj?.filetype !== "null"
            );
            let modifiedaddressProof = addressProof?.filetype.replace(
              /_/g,
              " "
            );
            return (
              <SignatoryDocuments
                director={director}
                index={index}
                statusDetails={statusDetails}
                CompanyDetails={CompanyDetails}
                setIsFileDeleted={setAreFilesDeleted}
              />
            );
          }
        )}
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "2.9vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        {}
        <Button
          type="submit"
          onClick={onSubmit}
          disabled={!(togglesave && !areFilesDeleted)}
          variant="contained"
          sx={{
            marginRight: 4,
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default DirectorDocuments;
