import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function DealSignaturePending({ data, secondUser }: any) {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Needs your Signature
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Sign the Deal Document to complete the signing process.
        </Typography>
      </Stack>

      <Button
        variant="contained"
        onClick={() =>
          navigate(`/deal-process/deals/${data?.code}/documentationSignee`, {
            replace: true,
          })
        }
        sx={{
          mt: 3,
          backgroundColor: "#7643EB",
          boxShadow: 1,
          borderRadius: 1,
          px: 4,
          ml: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        Sign Document
      </Button>
    </Box>
  );
}
