import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  styled,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { getusersbyid, KycKybSubmitted } from "../../../api/services";
import { getUserId } from "../../../utils/authUtils";
import PreviewComponent1 from "./components/PreviewComponent1";
import ProprietorshipPreviewComponet from "./components/ProprietorshipPreviewComponent";
import OtherEntityPreview from "./components/OtherEntityPreview";
import IndividualPreviewComponent from "./components/IndividualPreviewComponent";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "92vh",
  display: "flex",
  flexDirection: "column",
}));

const Header = styled(Box)(({ theme }) => ({
  flex: "0 0 auto",
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
  paddingBottom: "16px",
  borderBottom: "1px solid #cacaca",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "10px 0px 0px 8vw",
}));

const MainContent = styled(Box)(({ theme }) => ({
  backgroundColor: "#f2f2f2",
  width: "100vw",
  flex: "1 1 auto",
  overflowY: "auto",
}));

const InnerContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: "white",
  display: "flex",
  // margin: "0 8%",
  // minHeight: "75vh",
  // marginBottom: "8vh",
  height: "85%",
  overflow: "scroll",
  margin: "2%",
  padding: "2%",
  paddingTop: "1%",
  marginTop: "1%",
  borderRadius: "8px",
  [theme.breakpoints.down("md")]: {
    margin: "5%",
    padding: "3%",
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  // position: "fixed",
  // top: "91vh",
  // height: "10vh",
  // width: "100vw",
  // backgroundColor: "white",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  margin: "2%",
  [theme.breakpoints.down("md")]: {
    margin: "5%",
  },
}));

const RenderPreview = () => {
  const { kyctype }: any = useParams();
  const navigate = useNavigate();
  const EntityId = Cookies.get("entityId");
  const userId = getUserId();
  const { data }: any = useQuery(["preview", userId], getusersbyid);

  const { mutate: KycKybSubmit, isLoading } = useMutation(KycKybSubmitted, {
    onSuccess: (res: any) => {
      navigate(`/kycprocess/${kyctype}/success`, { replace: true });
    },
    onError: (error: any) => {},
  });

  const navigateSuccess = () => {
    KycKybSubmit({ id: EntityId, body: { status: "Kyc Submitted" } });
  };

  if (isLoading) {
    return <LinearProgress></LinearProgress>;
  }

  return (
    <StyledBox>
      {/* <Header>
        <HeaderContent>
          <Avatar
            variant="square"
            alt={data?.data?.name || "BM"}
            src=""
            sx={{
              width: 65,
              height: 65,
              marginBottom: 0,
              marginX: 3,
              marginLeft: 0,
              borderRadius: "5px",
            }}
          >
            {data?.data?.name &&
              data?.data?.name
                .split(" ")
                .map((word: any) => word.charAt(0))
                .join("")
                .toUpperCase()
                .substring(0, 2)}
          </Avatar>
          <Box>
            <Typography variant="h3" sx={{ color: "#444444" }}>
              {data?.data?.name}
            </Typography>
            <Typography variant="body1" color={"GrayText"}>
              {data?.data?.emailAddress}
            </Typography>
          </Box>
        </HeaderContent>
      </Header> */}
      <MainContent>
        <InnerContent>
          {kyctype === "sole-proprietorship" ? (
            <ProprietorshipPreviewComponet
              kycType={kyctype}
              data={data}
              EntityId={EntityId}
            />
          ) : kyctype === "individual" ? (
            <IndividualPreviewComponent
              kycType={kyctype}
              data={data}
              EntityId={EntityId}
            />
          ) : kyctype === "other-entity" ? (
            <OtherEntityPreview
              kycType={kyctype}
              data={data}
              EntityId={EntityId}
            />
          ) : (
            <PreviewComponent1
              kycType={kyctype}
              data={data}
              EntityId={EntityId}
            />
          )}
        </InnerContent>
        <Footer>
          <Button
            type="submit"
            onClick={navigateSuccess}
            variant="contained"
            // sx={{
            //   marginRight: "8vw",
            // }}
          >
            Submit
          </Button>
        </Footer>
      </MainContent>
    </StyledBox>
  );
};

export default RenderPreview;
