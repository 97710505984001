import AddIcon from "@mui/icons-material/Add";
import Logout from "@mui/icons-material/Logout";
import {
  Button,
  CircularProgress,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { googleLogout } from "@react-oauth/google";
import Cookies from "js-cookie";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { isEntityVerified } from "../../../routes/Routes";

const styledPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
type AccountMenuProps = {
  profileData: any;
  deal?: Boolean;
  toggleDrawer: Function;
};

export default function AccountMenu({
  profileData,
  deal,
  toggleDrawer,
}: AccountMenuProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {deal ? (
          <>
            <Button
              sx={{
                color: "black",
                ":hover": { backgroundColor: "transparent", color: "black" },
                display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" },
              }}
              disableRipple
              onClick={toggleDrawer(true)}
            >
              {profileData?.data?.entities?.[0]?.companyType ===
              "INDIVIDUAL" ? (
                <>{profileData?.data?.name}</>
              ) : (
                <>{profileData?.data?.entities?.[0]?.companyName}</>
              )}
            </Button>{" "}
            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/deal-process/deals/create", { replace: true });
              }}
              sx={{
                boxShadow: 1,
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                py: 1.2,
                minWidth: "10px",
                width: "max-content",
              }}
            >
              <AddIcon
                sx={{
                  borderRadius: 1,
                }}
              />
              {/* <Typography
                variant="body1"
                sx={{
                  display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
                  color: "#7643EB",
                  width: "80px",
                  marginLeft: "5px",
                }}
              > */}
              Create Deal
              {/* </Typography> */}
            </Button>
          </>
        ) : (
          <></>
        )}

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              alt={profileData?.data?.name || "BM"}
              src="/static/images/avatar/2.jpg" //invalid url
              sx={{
                backgroundColor: "#6f559f",
                width: "40px",
                height: "40px",
              }}
            >
              {profileData?.data?.name ? (
                profileData?.data?.name
                  .split(" ")
                  .map((word: any) => word.charAt(0))
                  .join("")
                  .toUpperCase()
                  .substring(0, 2)
              ) : (
                <CircularProgress size={20} sx={{ color: "white" }} />
              )}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={styledPaperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              if (isEntityVerified()) {
                navigate("/deal-process/user-profile");
              } else {
                navigate("/kycprocess/user-profile");
              }
            }}
          >
            <Avatar /> {profileData?.data?.name}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            Cookies.remove("newEmail");
            Cookies.remove("access_token");
            Cookies.remove("otptoken");
            Cookies.remove("access_token");
            Cookies.remove("entityId");
            localStorage.clear();
            googleLogout();
            navigate("/login", { replace: true });
            handleClose();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
