import { Box, Button, Card, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../api/services";
import { getUserDeals } from "../../../api/services/deals";
import { dealCreate } from "../../../assets";
import Loading from "../../../components/shared/Loading";

import ActiveDeals from "./ActiveDeals";
import DealWorkflow from "./Dealworkflow";

export default function NewdealDashboard() {
  const navigate = useNavigate();
  const { data: profiledata }: any = useQuery("getUser-id", getUserProfile);
  const { data: dealData, isLoading } = useQuery("user-deals", getUserDeals);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      {dealData?.data?.data?.length === 0 ? (
        <>
          <Typography my={"1%"} variant="h2">
            Hello, {profiledata?.data?.name}!
          </Typography>
          <Card sx={{ display: "flex", paddingRight: "6%", mx: "1vw" }}>
            <Box sx={{ px: "2%", py: "1%", flex: 1 }}>
              <Typography variant="subtitle1" color={"black"}>
                Create a New Deal
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#333333", opacity: 0.85 }}
              >
                Ready to grow your business with new deals? Begin here to
                outline your offer, set terms, and invite stakeholders—all in
                just a few steps. Let's pave the way for your next successful
                venture.
              </Typography>
              <Button
                variant="contained"
                sx={{ marginTop: "3vh" }}
                onClick={() => {
                  navigate("/deal-process/deals/create", { replace: true });
                }}
              >
                Start Your Deal
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "25%",
              }}
            >
              <img
                src={dealCreate}
                alt="loadeing..."
                style={{ width: "50%", marginLeft: "auto" }}
              />
            </Box>
          </Card>
          <DealWorkflow />
        </>
      ) : (
        <>
          <ActiveDeals profiledata={profiledata} dealData={dealData} />
        </>
      )}
    </Box>
  );
}
