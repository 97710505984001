import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { requestOtp } from "../../api/services";
import { Icons } from "../../assets";

import { ContainedButton } from "../../components/buttons/Buttons";
import AuthFormWrapper from "../../components/wrapper/Authformwarpper";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

type LoginFormProps = {
  LoginWithGmail: (data: any) => void;
  setOpen: (value: boolean) => void;
  setFormData: (value: any) => void;
  isMobile: boolean;
};

export default function LoginForm({
  LoginWithGmail,
  setOpen,
  setFormData,
  isMobile,
}: LoginFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutate: SendOtpEmail, isLoading: load1 } = useMutation(requestOtp, {
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      toast.success("OTP sent to your email successfully");
      setOpen(true);
      localStorage.setItem("mobileMasked", res?.data?.mobile);
      localStorage.setItem("emailAddressMasked", res?.data?.emailAddress);
      localStorage.setItem("otpaccessktoken", res?.data?.access_token);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const onSubmit = (data: any) => {
    const Formdata = { inputValue: data.email, inputType: "LOGIN" };
    setFormData(data.email);
    SendOtpEmail(Formdata);
  };

  return (
    <AuthFormWrapper
      title="Login into your TruuPe Account"
      // buttonText="Sign up"
      // DividerText1="Login Using Google Account"
      // DividerText2="Login Using Your Email Address"
      // googleButtonText="Login with Google"
      isLogin={true}
      LoginWithGmail={LoginWithGmail}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={"2vh"}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel
                  size="normal"
                  shrink
                  sx={{ marginLeft: isMobile ? "-3vw" : "-0.7vw" }}
                >
                  Email Address
                </InputLabel>
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <img height={"25px"} src={Icons.email} alt="" />
                    </InputAdornment>
                  }
                  sx={{
                    marginTop: "15px",
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      fontSize: "14px",
                      height: "1vh",
                    },
                  }}
                  placeholder="Enter your Email ID.."
                  {...field}
                  error={!!errors.email}
                />
                <FormHelperText error>
                  {errors.email && errors.email.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          <ContainedButton type="submit" Title="Login" submitLoader={load1} />
        </Stack>
      </form>
    </AuthFormWrapper>
  );
}
