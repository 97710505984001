import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import InputField from "../../../components/formFields/kycFields/InputField";

import { jwtDecode } from "jwt-decode";
import {
  AddCompanyDetails,
  getComapanyDetailsById,
  getStatusbyId,
  userActivity,
} from "../../../api/services";

import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import DirectorForm from "./components/PartnerForm";

const PartnerInformation = ({ setAddDirector }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const EntityId = Cookies.get("entityId");
  const queryClient = useQueryClient();
  const {
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const { data: statusDetails } = useQuery(
    ["status-data", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  const { data: CompanyDetails, isLoading: companyLoading } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      onSuccess: (res: any) => {
        setValue("numberOfDirectors", res?.data?.directors?.length);
      },
      enabled: !!EntityId,
    }
  );

  const { mutate: postcompanydetails, isLoading: submitloader } = useMutation(
    AddCompanyDetails,
    {
      onSuccess: (res: any) => {
        toast.success("Information Updated Successfully");
        const accessToken: any = Cookies.get("access_token");
        const userdata: any = jwtDecode(accessToken);
        const entityId = res?.data?.data?.id;
        Cookies.set("entityId", entityId);
        queryClient.invalidateQueries("getcompanydetails");
        queryClient.invalidateQueries("getuser-id");
        const draftdata = {
          userId: userdata?.userId,
          activity: "KYB/KYC Process in Draft",
          entityId: entityId,
        };
        useractivity(draftdata);
        window.location.reload();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const [Directors, setDirectors] = useState<any>([]);
  const [combinedFormData, setCombinedFormData] = useState<any>([]);

  useEffect(() => {
    if (
      CompanyDetails &&
      CompanyDetails?.data &&
      CompanyDetails?.data?.directors
    ) {
      const directorsData = CompanyDetails?.data?.directors?.map(
        (director: any) => ({
          din_number: director?.dpin || "",
          director_name: director?.name || "",
          id: director?.id || "",
          aadharNumber: director?.aadharNumber || "",
          panNumber: director?.panNumber,
          address: director?.address,
          dateOfBirth: director?.dateOfBirth,
          gender: director?.gender,
          isAadharMismatch: director?.isAadharMismatch,
          isSignatoryAuthority: director?.isSignatoryAuthority,
        })
      );
      setDirectors(directorsData);
    }
  }, [CompanyDetails]);

  const handleDirectorFormSubmit = (formData: any) => {
    const index = combinedFormData.findIndex(
      (item: any) => item.id === formData.id
    );
    if (index !== -1) {
      const Updateddata = [...combinedFormData];

      Updateddata[index] = formData;
      setCombinedFormData(Updateddata);
    } else {
      const Updateddata = [...combinedFormData, formData];

      setCombinedFormData(Updateddata);
    }
  };

  const addDirector = () => {
    const noofDirectors = getValues("numberOfDirectors") || 0;
    setValue("numberOfDirectors", noofDirectors + 1);
    setDirectors([
      ...Directors,
      { din_number: "", director_name: "", isnew: true },
    ]);
  };

  const onSubmit = () => {
    combinedFormData.forEach((formData: any) => {
      const finalData = {
        proprietorName: formData?.directorName,
        gender: formData?.gender === "M" ? "MALE" : "FEMALE",
        dateOfBirth: formData?.dateOfBirth,
        aadharNumber: formData?.aadharNumber,
        isAadharVerified: formData?.isAadharVerified,
        panNumber: formData?.panCard,
        isPanVerified: formData?.isPanVerified,
        registeredAddress: formData?.registeredAddress,
        isAadharValidate: formData?.isAadharValidate,
        isAadharMismatch: formData?.isAadharMismatch,
        companyType: "INDIVIDUAL",
      };
      postcompanydetails(finalData);
    });
  };

  return (
    <>
      {companyLoading ? (
        <Box
          sx={{
            width: "50vw",
            height: "98%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <Stack
          justifyContent={"space-between"}
          // sx={{ height: { xs: "90%", md: "auto" } }}
        >
          <Box
            sx={{
              overflow: "auto",
              width: { xs: "100%", md: "64vw" },
              height: { xs: "52vh", md: "57.9vh" },
              // height: "57.9vh",
              // mx: { xs: "5%", md: "0px" },
              paddingLeft: { xs: 0, md: 4 },
              mt: 2,
              display: "flex",
              flexDirection: "column",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                marginBottom: 1,
                fontWeight: "500",
                color: "black",
                display: { xs: "none", md: "block" },
              }}
            >
              Individual Information
            </Typography>

            {false && (
              <Controller
                name="numberOfDirectors"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={true}
                    field={field}
                    iD={"numberOfDirectors"}
                    name={"Number Of Directors"}
                    errors={errors}
                  />
                )}
              />
            )}

            <DirectorForm
              isnew={true}
              key={1}
              id={null}
              Dname={Directors[0]?.director_name || ""}
              Dpin={""}
              aadharNumber={Directors[0]?.aadharNumber || ""}
              panNumber={Directors[0]?.panNumber || ""}
              address={""}
              dateOfBirth={""}
              gender={""}
              Signatory={false}
              index={1}
              directorData={""}
              onSubmit={handleDirectorFormSubmit}
              isAadharMismatch={Directors[0]?.isAadharMismatch}
            />
          </Box>

          <Box
            sx={{
              paddingY: 2,
              marginTop: "2.7vh",
              marginX: 0,
              textAlign: "end",
              alignContent: "center",
              borderTop: "1px solid #cacaca",
            }}
          >
            <CustomLoadingButton
              submitLoader={submitloader}
              onClick={() => onSubmit()}
              isDisabled={
                statusDetails?.data?.director ||
                !combinedFormData[0]?.isAadharVerified
              }
              title="Next"
            />
            {}
          </Box>
        </Stack>
      )}
    </>
  );
};

export default PartnerInformation;
