import Cookies from "js-cookie";

export const isIndianLocation = (callback: any) => {
  const indiaBounds = {
    minLatitude: 6.7542,
    maxLatitude: 35.5047,
    minLongitude: 68.1624,
    maxLongitude: 97.3956,
  };

  const isCoordinatesInIndia = (latitude: any, longitude: any) => {
    return (
      latitude >= indiaBounds.minLatitude &&
      latitude <= indiaBounds.maxLatitude &&
      longitude >= indiaBounds.minLongitude &&
      longitude <= indiaBounds.maxLongitude
    );
  };

  const success = (position: any) => {
    const { latitude, longitude } = position.coords;
    Cookies.set("latitude", latitude);
    Cookies.set("longitude", longitude);
    const isInIndia = isCoordinatesInIndia(latitude, longitude);
    callback(isInIndia);
  };

  const error = () => {
    callback(false);
  };

  const options = {
    enableHighAccuracy: true,
    maximumAge: 0,
  };

  const handlePermissionChange = (permissionStatus: any) => {
    if (permissionStatus.state === "granted") {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else if (permissionStatus.state === "prompt") {
      callback("prompt");
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else if (permissionStatus.state === "denied") {
      callback(false);
    }
  };

  navigator.permissions
    .query({ name: "geolocation" })
    .then((permissionStatus) => {
      handlePermissionChange(permissionStatus);
      permissionStatus.onchange = () => {
        handlePermissionChange(permissionStatus);
      };
    });
};
