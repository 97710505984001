import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useQueryClient } from "react-query";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

const subtypeOrder = ["AADHAR", "PAN", "ADDRESS PROOF"];

const compareSubtypes = (a: { subtype: string }, b: { subtype: string }) => {
  const orderA = subtypeOrder.indexOf(a.subtype);
  const orderB = subtypeOrder.indexOf(b.subtype);
  if (orderA !== -1 && orderB !== -1) {
    return orderA - orderB;
  }
  if (orderA !== -1) return -1;
  if (orderB !== -1) return 1;
  return a.subtype.localeCompare(b.subtype);
};

type SignatoryAuthorityDocumentProps = {
  data: any;
  key: any;
  currentDirectorCount: number;
};

const SignatoryAuthorityDocument = ({
  data,
  key,
  currentDirectorCount,
}: SignatoryAuthorityDocumentProps) => {
  const queryClient = useQueryClient();

  const onFileChange = () => {
    queryClient.invalidateQueries("entity");
  };

  return (
    <Box marginLeft="20px" marginBottom="25px">
      {data?.companyType === "INDIVIDUAL" ? (
        <></>
      ) : (
        <>
          <Typography variant="subtitle2" sx={{ mb: 2, mt: 3 }}>
            {`Signatory ${" "} ${currentDirectorCount} - ${data?.name}`}
          </Typography>

          <Grid container spacing={3} sx={{ marginBottom: "20px" }}>
            {data?.documents
              ?.sort(compareSubtypes)
              .map((document: any, index: number) => {
                return (
                  <Grid size={{ xs: 12, sm: 4, md: 4 }} key={index}>
                    <FileUploadContainer
                      typeId={data?.id}
                      actions={<></>}
                      data={data.documents[index]}
                      typeData="DIRECTOR"
                      name={document.originalFileName}
                      url={document.subtype}
                      subType={document.subtype}
                      onFileChange={onFileChange}
                      showIcons={false}
                      uploaded={true}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SignatoryAuthorityDocument;
