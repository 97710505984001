import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

type AadharAlertDialogProps = {
  username: string;
  openDialogue: boolean;
  aadharNumber: string;
  closeModel: (value: boolean) => void;
  sendOtp: () => void;
};
export default function AadharAlertDialog({
  username,
  openDialogue,
  aadharNumber,
  closeModel,
  sendOtp,
}: AadharAlertDialogProps) {
  const handleClose = () => {
    closeModel(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDialogue}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle color={"red"} id="alert-dialog-title">
          {"Alert!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure the Aadhar number <strong>{aadharNumber}</strong>{" "}
            belongs to <strong>{username}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              sendOtp();
              handleClose();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
