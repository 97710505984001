import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useNavigate, useParams } from "react-router-dom";
import { greenCheck } from "../../../../../../../assets";
import failed from "./failed.json";

const style = {
  position: "relative",
  top: "25%",
  left: "14%",
};

export default function InitiatePaymentModel({
  openModel,
  setOpenModel,
  amount,
  isFailed,
}: any) {
  const { dealId } = useParams();
  const navigate = useNavigate();

  return (
    <Box>
      <Modal
        open={openModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            sx={{
              position: "relative",
              top: "25%",
              left: {
                xs: "10%",
                sm: "20%",
                md: "30%",
                xl: "30%",
                lg: "30%",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "80%",
                  sm: "60%",
                  md: "40%",
                  xl: "40%",
                  lg: "40%",
                },
                display: "flex",
                justifyContent: "end",
                my: "1vh",
              }}
            ></Box>

            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 24,
                width: {
                  xs: "80%",
                  sm: "60%",
                  md: "40%",
                  xl: "40%",
                  lg: "40%",
                },
                p: 4,
                borderRadius: "10px",
              }}
            >
              <Stack gap={"1vh"} alignItems={"center"}>
                {isFailed ? (
                  <>
                    {" "}
                    <Lottie animationData={failed} loop={false} autoplay />
                  </>
                ) : (
                  <>
                    {" "}
                    <img width={"25%"} src={greenCheck} alt="loading...." />
                  </>
                )}

                <Typography variant="h3" textAlign={"center"}>
                  {isFailed ? (
                    <>Payment Failed!</>
                  ) : (
                    <>Payment Initiated Successfully!</>
                  )}
                </Typography>
                <Typography variant="h3">{" ₹ " + amount || "NA"}</Typography>
                <Typography variant="body1" color={"grey"} textAlign={"center"}>
                  {isFailed ? (
                    <>
                      The transaction has failed. Please try again later. If the
                      issue persists, contact Truupe team for further
                      assistance.
                    </>
                  ) : (
                    <>
                      Payment to be approved by the Trustee. Once approved it
                      will automatically be completed.
                    </>
                  )}
                </Typography>
                <Button
                  sx={{ marginTop: "1vh" }}
                  variant="contained"
                  onClick={() => {
                    navigate(`/deal-process/deals/${dealId}/truupe-account`, {
                      replace: true,
                    });
                  }}
                >
                  Go back
                </Button>
              </Stack>
            </Box>
          </Box>
        </>
      </Modal>
    </Box>
  );
}
