import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { getUserProfile } from "../../../../../../api/services";
import DealDeclined from "../../modalCards/DealDeclined";

export default function InvitationRejected({ data, secondUser }: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);
  const rejectedDeal = data?.statusHistory.find(
    (status: any) => status.status === "Deal Rejected"
  )?.userId;
  const IRejected = rejectedDeal === profileData?.data?.id;

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Rejected
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          {IRejected ? (
            <>
              {" "}
              You have rejected the business deal invitation sent by the{" "}
              {secondUser?.userType}
            </>
          ) : (
            <>
              The {secondUser?.userType}has unfortunately rejected the business
              Deal invitation you sent. You can view the reason provided by the{" "}
              {secondUser?.userType}for rejecting the invitation. This will
              allow you to understand their decision and determine if you would
              like to revise your offer or pursue other opportunities.
            </>
          )}
        </Typography>
      </Stack>
      <DealDeclined open={isModalOpen} onClose={handleClose} data={data} />
      {(!IRejected || true) && (
        <Button
          variant="contained"
          sx={{ width: "auto", my: "20px" }}
          onClick={handleOpen}
        >
          View Reason
        </Button>
      )}
    </Box>
  );
}
