export const truncateText = (text: any, maxLength: any) => {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

export function maskAadharNumber(aadharNumber: any) {
  if (!aadharNumber) return null;

  const maskedLength = aadharNumber.length - 4;
  const maskedPart = "*".repeat(maskedLength);
  const lastFourDigits = aadharNumber.slice(-4);

  return maskedPart + lastFourDigits;
}

export function maskedPanNumber(panNumber: any) {
  if (!panNumber || panNumber.length < 5) return panNumber || "Not Available";

  const maskedLength = panNumber.length - 4;
  const maskedPart = "X".repeat(maskedLength);
  const visiblePart = panNumber.substring(maskedLength);

  return maskedPart + visiblePart;
}

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

export const formattedDate = formatDate(Date());
