// AutocompleteField.tsx
import React from "react";
import { Controller, Control, FieldErrors } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

interface AutocompleteFieldProps {
  name: string;
  control: Control<any>;
  options: { name: string }[];
  label: string;
  placeholder?: string;
  disabled?: boolean;
  errors?: any;
  sx?: any;
  size?: "small" | "medium";
}

const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  name,
  control,
  options,
  label,
  placeholder = "Select Option",
  disabled = false,
  errors,
  sx = {},
  size = "small",
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          disabled={disabled}
          size={size}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "none",
              boxShadow: "none",
              cursor: "default",
            },

            "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
              width: "26.2vw",
              height: "37px",
              fontSize: "16px",
            },
            "& .css-1h80n9g-MuiFormControl-root-MuiTextField-root": {
              marginTop: "0.2vh",
            },
            "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
              {
                fontSize: "14px",
              },
            "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiInputBase-input":
              {
                padding: "0px 6px",
              },
          }}
          {...field}
          options={options}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Option",
              }}
            />
          )}
          value={options.find((option) => option.name === field.value) || null}
          onChange={(e, newValue) => {
            field.onChange(newValue ? newValue.name : null);
          }}
        />
      )}
    />
  );
};

export default AutocompleteField;
