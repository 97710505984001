import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";

export default function DealSignStatus() {
  const signees = ["Signee 1", "Signee 2", "Signee 3", "Signee 4"];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: {
          // xl: "10vh",
          // lg: "3.5vh",
          // md: "5vh",
          // sm: "5vh",
          // xs: "5vh",
        },
      }}
    >
      <Timeline
        sx={{ display: "flex", flexDirection: "row", padding: 0, margin: 0 }}
      >
        {signees.map((signee, index) => (
          <TimelineItem
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineSeparator>
              <TimelineDot sx={{ bgcolor: "#7643EB" }}>
                <DoneRoundedIcon sx={{ color: "white", fontSize: "14px" }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent sx={{ px: 1, py: 0 }}>
              <Typography
                variant="body1"
                sx={{ color: "#7643EB", textWrap: "nowrap" }}
              >
                {signee}
              </Typography>
            </TimelineContent>
            {index < signees.length - 1 && (
              <TimelineConnector
                sx={{ bgcolor: "#7643EB", minWidth: "40px", height: "2px" }}
              />
            )}
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
}
