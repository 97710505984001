import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(-1),
  },

  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: "14px",
    width: "500px",
    maxHeight: "13px",
    padding: "10px 10px",
    color: "black",
    "-webkit-appearance": "none",
    lineHeight: "1.5",
    verticalAlign: "middle", 

    "@supports (-webkit-touch-callout: none)": {
      "& input[type='date']": { // Target only date inputs
        paddingTop: "5px", // Adjust the value as needed for proper alignment on iOS
        paddingBottom: "15px",

      },
    },
  },
  "&::placeholder": {
    fontFamily: "mundial",
    fontSize: "12px",
  },
}));

type DealInputFieldProps = {
  title?: string;
  iD?: string;
  placeholder: string;
  errors?: any;
  sx?: any;
  multiline?: boolean;
  type?: string;
  field: any | null;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: any;
  Name?: string;
  rightEndAdornment?: any;
  isEmail?: any;
  startAdornment?: any;
  InputProps?: any;
  min?: string;
  max?: string;
};

const DealInputField = ({
  iD = "",
  placeholder,
  errors,
  title = "",
  sx,
  type,
  field,
  isDisabled = false,
  onChange,
  rightEndAdornment,
  isEmail = false,
  startAdornment,
  InputProps,
  min,
  max,
}: DealInputFieldProps) => {
  type = type === "" ? "text" : type;
  return (
    <Box sx={{ marginY: 2 }}>
      <InputLabel
        shrink
        htmlFor={iD}
        sx={{
          fontSize: "0px",
          fontWeight: 300,
          "&.Mui-focused": {
            color: "#00000099",
          },
        }}
      >
        {isEmail ? (
          <></>
        ) : (
          <>
            <Typography
              variant="body2"
              sx={{
                fontSize: "16px",
                my: "0.2vh",
              }}
            >
              {title}
              <span style={{ color: "red" }}>*</span>
            </Typography>
          </>
        )}
      </InputLabel>

      <BootstrapInput
        disabled={isDisabled}
        id={iD}
        placeholder={placeholder}
        // sx={sx}
        sx={
          rightEndAdornment
            ? {
                "& .MuiInputBase-input": {
                  width: {
                    xs: "200px",
                    sm: "465px",
                    lg: "465px",
                    xl: "465px",
                  },
                },
              }
            : sx
        }
        type={type}
        onChange={onChange}
        InputProps={InputProps}
        startAdornment={
          rightEndAdornment && (
            <InputAdornment
              sx={{
                borderTopRightRadius: 1,
                borderBottomRightRadius: 1,
                borderTop: "1px solid #E0E3E7",
                borderLeft: "1px solid #E0E3E7",
                borderBottom: "1px solid #E0E3E7",
                maxHeight: "35px",
                padding: "10px",
                margin: "0px",
                height: " 35px",
              }}
              position="start"
            >
              <Typography variant="h4">₹</Typography>
            </InputAdornment>
          )
        }
        {...field}
        inputProps={{
          min,
          max,
        }}
      />

      {isEmail ? (
        <></>
      ) : (
        <>
          {true && (
            <FormHelperText error>
              <Typography variant="body2" sx={{ color: "red", paddingLeft: 1 }}>
                {errors[iD]?.message}
              </Typography>
            </FormHelperText>
          )}
        </>
      )}
    </Box>
  );
};

export default DealInputField;
