import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function DealStates({ data }: any) {
  const steps = [
    "Deal Invitation",
    "Deal Documentation",
    "Escrow Account",
    "Deal InProgress",
    "Deal Completion",
  ];

  return (
    <Box
      sx={{
        px: "20px",
        position: "fixed",
        left: "0px",
        bottom: "0px",
        bgcolor: "white",
        width: "100%",
        boxShadow: "0px -1px 5px rgba(0,0,0,0.1)",
        py: "6px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={4}
      >
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Box display="flex" alignItems="center">
              {data?.state === step || index < steps.indexOf(data?.state) ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20px",
                    height: "20px",
                    bgcolor: "#7643EB",
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                >
                  <CheckRoundedIcon sx={{ color: "white", fontSize: "16px" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20px",
                    height: "20px",
                    border: "1.5px solid #7643EB",
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                >
                  <Typography variant="body1" color="#7643EB">
                    {index + 1}
                  </Typography>
                </Box>
              )}
              <Typography>{step}</Typography>
            </Box>
            {index < steps.length - 1 && (
              <Box
                sx={{
                  flexGrow: 1,
                  height: "2px",
                  bgcolor: "#7643EB",
                  margin: "0 15px",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
}
