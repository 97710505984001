import {
  Timeline,
  TimelineConnector,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { Icons } from "../../../../../assets";

const EventDetails = styled(Paper)(({ theme }) => ({
  padding: "8px",
  border: "1.5px solid #1212121A",
  marginBottom: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "75%",
  },
  [theme.breakpoints.up("md")]: {
    width: "55%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "45%",
  },
  [theme.breakpoints.up("xl")]: {
    width: "75%",
  },
}));

const DateContainer = styled(Typography)(({ theme }) => ({
  marginTop: "15px",
  width: "100px",
  textAlign: "left",
  fontSize: "0.875rem",
}));

const CustomTimelineDot = styled(Box)(({ theme }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  border: "1.5px solid #7643EB",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
}));

const CustomTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  backgroundColor: "#7643EB",
  height: "15px",
}));

const TimelineComponent = ({ data }: any) => {
  return (
    <Box sx={{ padding: "15px" }}>
      <Typography
        variant="h2"
        sx={{ fontWeight: "500", px: "5px" }}
        gutterBottom
      >
        Deal Timeline
      </Typography>
      <Box sx={{ padding: 2 }}>
        <Timeline position="left" sx={{ display: { xs: "contents" } }}>
          {data?.statusHistory?.map((event: any, index: any) => {
            return (
              <TimelineItem
                key={index}
                sx={{
                  width: "80vw",
                  "::before": { padding: 0 },
                }}
              >
                <EventDetails
                  elevation={0}
                  sx={{ marginLeft: "15px", marginTop: "15px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6">{event?.status}</Typography>
                  </Box>
                </EventDetails>
                <TimelineSeparator>
                  {index !== data.statusHistory.length && (
                    <CustomTimelineConnector />
                  )}
                  <CustomTimelineDot>
                    <img
                      src={Icons.timelineIcon}
                      alt="Timeline Icon"
                      style={{ width: "40%", height: "40%" }}
                    />
                  </CustomTimelineDot>
                  {index !== data.statusHistory.length && (
                    <CustomTimelineConnector />
                  )}
                </TimelineSeparator>
                <Box>
                  <DateContainer variant="subtitle2">
                    <Typography variant="subtitle2">
                      {moment(event?.createdAt).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography variant="subtitle2">
                      {moment(event?.createdAt).format("hh:mm A")}
                    </Typography>
                  </DateContainer>
                </Box>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Box>
  );
};

export default TimelineComponent;
