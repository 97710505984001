import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../../api/services";
import Status from "../userDeal/dealStatus/DealStatus";

// Style objects
const styles = {
  cellStyle: (theme: any) => ({
    textAlign: "left",
    marginLeft: "20px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  }),
  tableContainer: {
    borderRadius: "10px",
    overflow: "hidden",
    border: "1px solid #1212121A",
  },
  tableHead: {
    backgroundColor: "#F3F3F3",
    fontFamily: "Mundial",
  },
  tableCell: {
    padding: "6px",
  },
  pagination: (theme: any) => ({
    "& .MuiPaginationItem-root": {
      color: theme.palette.primary.main,
      height: "27px",
      minWidth: "27px",
      fontSize: "14px",
      fontWeight: "bold",
      margin: "0 4px",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }),
  select: {
    width: "auto",
    height: "25px",
    background: "#FFFFFF",
    borderRadius: "5px",
    textAlign: "center",
    lineHeight: "20px",
    marginLeft: "10px",
    outline: "none",
    boxShadow: "none",
  },
};

// Utility function for formatting numbers to the Indian system
const formatNumberToIndianSystem = (number: any) =>
  new Intl.NumberFormat("en-IN").format(number);

// Component to render a table cell with optional click functionality
const RenderTableCell = ({ content, onClick, style }: any) => (
  <TableCell align="left" style={styles.tableCell}>
    <Typography variant="body1" sx={onClick ? style : null} onClick={onClick}>
      {content}
    </Typography>
  </TableCell>
);

// Component to render the status of a deal
const RenderStatus = ({ deal, profileData }: any) => {
  const isUserDocumentSigned =
    deal?.dealSignatures?.find(
      (signature: any) => signature?.userId === profileData?.data?.id
    )?.status === "Completed";

  const profileUser = deal?.dealUsers.find(
    (user: any) => user?.userId === profileData?.data?.id
  );

  const dealCreator = deal?.createdBy?.id === profileData?.data?.id;

  switch (deal?.status) {
    case "Invitation Issued":
      return dealCreator ? (
        <Status variant="Confirmation Awaiting" />
      ) : (
        <Status variant="Deal Invitation Received" />
      );
    case "Deal Cancelled":
      return <Status variant="Deal Cancelled" />;
    case "Signature Pending":
      return isUserDocumentSigned ? (
        <Status variant="Needs other Signatures" />
      ) : (
        <Status variant="Needs your Signatures" />
      );
    case "Fee Pending":
      return profileUser?.userType === "Buyer" ? (
        <Status variant="Pay Platform Fee" />
      ) : (
        <Status variant="Buyer to Pay Platform Fee" />
      );
    case "Funds Awaited":
      return profileUser?.userType === "Buyer" ? (
        <Status variant="Credit funds into the account" />
      ) : (
        <Status variant="Funds yet to be credited" />
      );
    case "Funds Received":
      return profileUser?.userType === "Buyer" ? (
        <Status variant="Initiate Payment" />
      ) : (
        <Status variant="Request Payment" />
      );
    case "Payment Verification":
      return <Status variant="Payment under Verification" />;
    default:
      return <Status variant={deal?.status} />;
  }
};

// Main component
const DealsTable = ({
  paginatedData,
  currentPage,
  totalPages,
  totalItems,
  setCurrentPage,
  itemsPerPage,
  handleChangeItemsPerPage,
}: any) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCellClick = (id: any) => {
    navigate(`/deal-process/deals/${id}`);
  };

  const { data: profileData } = useQuery("getUser-id", getUserProfile);

  return (
    <>
      <TableContainer component={Paper} style={styles.tableContainer}>
        <Table>
          <TableHead style={styles.tableHead}>
            <TableRow>
              {[
                "Deal ID",
                "Deal Title",
                "Deal Amount",
                "Deal Initiator",
                "Deal Status",
                "Next Step",
              ].map((header) => (
                <TableCell key={header} align="left" style={styles.tableCell}>
                  <Typography variant="body1" sx={{ marginLeft: "20px" }}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ fontFamily: "Mundial", fontSize: "14px" }}>
            {paginatedData.map((deal: any) => (
              <TableRow key={deal?.id}>
                <RenderTableCell
                  content={deal?.code}
                  onClick={() => handleCellClick(deal?.code)}
                  style={styles.cellStyle(theme)}
                />
                <RenderTableCell
                  content={deal?.title}
                  onClick={() => handleCellClick(deal?.code)}
                  style={styles.cellStyle(theme)}
                />
                <RenderTableCell
                  content={`₹ ${formatNumberToIndianSystem(deal?.amount)}`}
                  onClick={() => handleCellClick(deal?.code)}
                  style={styles.cellStyle(theme)}
                />
                <RenderTableCell
                  content={deal?.createdBy?.name}
                  onClick={() => handleCellClick(deal?.code)}
                  style={styles.cellStyle(theme)}
                />
                <RenderTableCell content={<Status variant={deal?.status} />} />
                <RenderTableCell
                  content={
                    <RenderStatus deal={deal} profileData={profileData} />
                  }
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        marginBottom="20px"
        marginTop="10px"
      >
        <Grid
          size={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            mt: 2,
          }}
        >
          {/* <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            shape="rounded"
            sx={styles.pagination(theme)}
          />
          <Select
            value={itemsPerPage}
            onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
            variant="outlined"
            sx={styles.select}
          >
            {[50, 100, 150].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select> */}
          <TablePagination
            component="div"
            count={totalItems}
            page={currentPage - 1}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={(event) =>
              handleChangeItemsPerPage(Number(event.target.value))
            }
            rowsPerPageOptions={[50, 100, 150]}
            sx={{
              "& .MuiPaginationItem-root": {
                color: theme.palette.primary.main,
                height: "27px",
                minWidth: "27px",
                fontSize: "14px",
                fontWeight: "bold",
                margin: "0 4px",
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DealsTable;
