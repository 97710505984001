import styled from "@emotion/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Card,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  dealtransaction,
  getDealsDataById,
} from "../../../../../../api/services/deals";
import { newDeal } from "../../../../../../assets";
import TransactionsCard from "./TransactionsCard";
import { useState } from "react";

const StyledBreadcrumbs = styled(Breadcrumbs)({
  color: "#7643EB",
  fontSize: "18px",
});

export default function TransactionsMobile() {
  const navigate = useNavigate();
  const { dealId } = useParams();
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);
  const { data: transactionData, isLoading: isLoad } = useQuery(
    ["dealTransactions", dealId],
    dealtransaction
  );

  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deal-process/deals/all");
      },
    }
  );

  const transactions = transactionData?.data?.data;

  const handleExpandClick = (transactionId: string) => {
    setExpandedCardId((prevId) =>
      prevId === transactionId ? null : transactionId
    );
  };

  return (
    <Card
      sx={{
        padding: "10px",
        paddingTop: "5px",
        margin: "10px",
      }}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          color="#7643EB"
          sx={{ cursor: "pointer" }}
        >
          <IconButton
            size="medium"
            sx={{ mt: 0.5 }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <StyledBreadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="#7643EB"
              href="/deal-process/deals/all"
              variant="body1"
            >
              Deals
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}`}
              variant="body1"
            >
              {dealId}
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}/truupe-account`}
              variant="body1"
            >
              Truupe Account
            </Link>
          </StyledBreadcrumbs>
        </Box>
        <Box>
          {transactions?.length > 0 ? (
            <>
              <Typography mx={"10px"} variant="h4" marginTop={"3%"}>
                Deal Transactions
              </Typography>{" "}
              {transactions.map((transaction: any, index: any) => {
                return (
                  <>
                    <TransactionsCard
                      transaction={transaction}
                      key={transaction?.id}
                      dealData={dealData?.data}
                      expanded={expandedCardId === transaction?.id}
                      handleExpandClick={() =>
                        handleExpandClick(transaction?.id)
                      }
                    />
                  </>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={newDeal}
                  alt="No Deals"
                  style={{
                    width: "250px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                />
              </Box>{" "}
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1.15rem", sm: "1.5rem", md: "1.75rem" },
                  mb: 1,
                  textAlign: "center",
                }}
              >
                There is no transaction in this account!
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
}
