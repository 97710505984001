import { Box, Button, Stack, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import {
  enableLocation_m1,
  enableLocation_m2,
  enableLocation_m3,
  enableLocation_m4,
  enableLocation_w1,
  enableLocation_w2,
  enableLocation_w3,
  enableLocation_w4,
  enableLocation_w5,
  enableLocation_w6,
} from "../../../assets";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
}));

export default function EnableLocation() {
  const navigate = useNavigate();
  return (
    <Container>
      <Typography variant="h1">
        Steps to Enable Location in Windows and Mac{" "}
      </Typography>
      <Typography variant="h1" fontWeight={"600"} my={"20px"}>
        1. Windows
      </Typography>

      <Grid container spacing={2}>
        <Grid size={3.5}>
          <img src={enableLocation_w1} width={"50%"} alt="loading..." />
        </Grid>
        <Grid size={8.5}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 1 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Open Chrome, At the top right, Click on 3 more. and then click on
              settings
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={9}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 2 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Click on Privacy and Security.
            </Typography>
          </Stack>
        </Grid>
        <Grid size={3}>
          <img src={enableLocation_w2} width={"50%"} alt="loading..." />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={5}>
          <img src={enableLocation_w3} width={"100%"} alt="loading..." />
        </Grid>
        <Grid size={7}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 3 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Click on Site Settings.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={8}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 4 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Click on Location.
            </Typography>
          </Stack>
        </Grid>
        <Grid size={4}>
          <img src={enableLocation_w4} width={"100%"} alt="loading..." />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={6}>
          <img src={enableLocation_w5} width={"100%"} alt="loading..." />
        </Grid>
        <Grid size={6}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 5 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Select Truupe site and click on the arrow icon.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={8}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 6 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Change the location option to Allow.
            </Typography>
          </Stack>
        </Grid>
        <Grid size={4}>
          <img src={enableLocation_w6} width={"100%"} alt="loading..." />
        </Grid>
      </Grid>
      <Box
        borderTop={"1px dashed #9DA1A9"}
        width={"100%"}
        height={"10px"}
        my={"20px"}
      ></Box>

      <Typography variant="h1" fontWeight={"600"}>
        2. Mac
      </Typography>

      <Grid container spacing={2}>
        <Grid size={3.5}>
          <img src={enableLocation_m1} width={"50%"} alt="loading..." />
        </Grid>
        <Grid size={8.5}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 1 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Open Chrome, At the top left, Click on Safari. and then click on
              settings
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={6}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 2 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Click on Websites in Navigation Tab.
            </Typography>
          </Stack>
        </Grid>
        <Grid size={6}>
          <img src={enableLocation_m2} width={"100%"} alt="loading..." />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={3.5}>
          <img src={enableLocation_m3} width={"50%"} alt="loading..." />
        </Grid>
        <Grid size={8.5}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 3 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Now Select Location in side Navigation Tabs.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={6}>
          <Stack height={"100%"} justifyContent={"center"}>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Step 4 :
            </Typography>
            <Typography my={"1vh"} fontWeight={"600"} variant="h2">
              Change the location option to Allow.
            </Typography>
          </Stack>
        </Grid>
        <Grid size={6}>
          <img src={enableLocation_m4} width={"100%"} alt="loading..." />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          sx={{ width: "180x", my: "30px" }}
          onClick={() => {
            navigate("/home");
          }}
          variant="contained"
        >
          home
        </Button>
      </Box>
    </Container>
  );
}
