import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OTPInput from "otp-input-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface OtpModalProps {
  Text: string;
  open: boolean;
  handleVerify: (otp: string) => void;
  ResendOTP: () => void;
  mobileNumber: any;
  isSignUp: boolean;
  isRegister: boolean;
  isLogin?: boolean;
  isLoading?: boolean;
}

const ModalContainer = styled(Box)<{ isMobile: boolean; isTablet: boolean }>`
  position: absolute;
  top: ${(props) =>
    props.isMobile ? "50%" : props?.isTablet ? "55vh" : "45vh"};
  left: ${(props) =>
    props.isMobile ? "50%" : props?.isTablet ? "73vw" : "80vw"};
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 13px;
  text-align: center;
  padding: 4vw;
  width: ${(props) => (props.isMobile ? "90%" : "auto")};
  // max-width: ${(props) => (props.isMobile ? "400px" : "none")};
`;

const Title = styled(Typography)`
  font-weight: bold;
  text-align: left;
`;

const Subtitle = styled(Typography)`
  text-align: left;
`;

const OtpInputContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 4vh;
`;

const ResendLink = styled(Box)({
  textAlign: "center",
  marginTop: "1vh",
});

const otpStyles = {
  width: "4vw",
  height: "4vw",
  margin: "0 5px",
  textAlign: "center",
  fontSize: "24px",
  border: "1px solid #E2E2E2",
  borderRadius: "8px",
  backgroundColor: "#F9F9F9",
  outline: "none",
};

const otpStylesMobile = {
  width: "11vw",
  height: "11vw",
  margin: "0 5px",
  textAlign: "center",
  fontSize: "24px",
  border: "1px solid #E2E2E2",
  borderRadius: "8px",
  backgroundColor: "#F9F9F9",
  outline: "none",
};

const otpStylesTab = {
  width: "6vw",
  height: "6vw",
  margin: "0 5px",
  textAlign: "center",
  fontSize: "24px",
  border: "1px solid #E2E2E2",
  borderRadius: "8px",
  backgroundColor: "#F9F9F9",
  outline: "none",
};

const OtpModal: React.FC<OtpModalProps> = ({
  open,
  handleVerify,
  isSignUp,
  ResendOTP,
  isLogin,
  isRegister = false,
  isLoading = false,
}) => {
  const { handleSubmit } = useForm();
  const [otp, setOtp] = useState("");

  const maskedEmail = localStorage.getItem("newEmail");
  const maskedEmailAddress = localStorage.getItem("emailAddressMasked");
  const maskedMobileNumber = localStorage.getItem("mobile");
  const maskedMobile = localStorage.getItem("mobileMasked");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const handleVerifyClick = () => {
    handleVerify(otp);
  };

  const isVerifyDisabled = otp.length !== 6;

  const getMaskedValue = () => {
    if (isRegister) return maskedMobileNumber;
    if (isSignUp) return maskedEmail || maskedMobileNumber;
    if (isLogin) return maskedEmailAddress || maskedMobile;
    return "";
  };

  return (
    <Modal
      open={open}
      aria-labelledby="otp-modal-title"
      aria-describedby="otp-modal-description"
    >
      <ModalContainer isMobile={isMobile} isTablet={isTablet}>
        <Title variant={isMobile ? "h5" : "h1"} fontWeight={"700"} gutterBottom>
          Almost Done
        </Title>
        <Subtitle variant="subtitle1" gutterBottom>
          Please enter the otp sent to{" "}
          <span style={{ color: "#7643EB" }}>{getMaskedValue()}</span>
          <IconButton
            onClick={() => window.location.reload()}
            sx={{ padding: "5px" }}
          >
            <DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>
          </IconButton>
        </Subtitle>
        <form onSubmit={handleSubmit(handleVerifyClick)}>
          <OtpInputContainer className="otp-input-container">
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure={false}
              inputStyles={
                isMobile ? otpStylesMobile : isTablet ? otpStylesTab : otpStyles
              }
            />
          </OtpInputContainer>
        </form>
        <Button
          variant="contained"
          color="primary"
          onClick={handleVerifyClick}
          sx={{ my: "20px", width: "100%", height: "40px", fontSize: "14px" }}
          disabled={isVerifyDisabled || isLoading}
        >
          {isLoading ? (
            <>
              {" "}
              <CircularProgress size={"1.2rem"} />
            </>
          ) : (
            "Verify"
          )}
        </Button>

        <ResendLink>
          Didn't Receive the OTP?{" "}
          <Button
            onClick={ResendOTP}
            variant="text"
            sx={{
              mt: -0.5,
              "&:hover": {
                color: "#7643EB",
                backgroundColor: "white",
              },
            }}
          >
            Resend OTP
          </Button>
        </ResendLink>
      </ModalContainer>
    </Modal>
  );
};

export default OtpModal;
