import { Box, Button, Typography, styled } from "@mui/material";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import Tick from "../../kyc/successCreen/Lottie.json";

const ReSubmitSuccess = () => {
  const navigate = useNavigate();

  const StyledDfAcJc = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }));

  const navigateHome = () => {
    navigate("/home", { replace: true });
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundColor: "#F2F2F2",
          width: "100vw",
          flex: "1 1 auto",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            margin: { xs: "5%", md: "5%" },
            marginTop: "1%",
            borderRadius: "8px",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignSelf: { xs: "center", sm: "start" },
            }}
          >
            <StyledDfAcJc>
              <Box
                sx={{
                  maxWidth: { xs: "150px", md: "250px" },
                  maxHeight: { xs: "150px", md: "250px" },
                }}
                marginBottom={-1}
                marginTop={-1}
              >
                <Lottie animationData={Tick} loop={false} autoplay />
              </Box>
              <Typography variant="h4"> Re-submission Successful !</Typography>
              <Typography
                fontSize={"18px"}
                sx={{
                  margin: "0 auto",
                  px: { xs: "10%", md: "20%" },
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  textAlign: "justify",
                }}
              >
                Thank you for re-submitting the KYC/KYB documents. Your
                application is now under review by our team. The verification
                process typically takes 24-48 hours to ensure compliance with
                all necessary regulations and guidelines. During this waiting
                period, you can monitor the status of your application in your
                dashboard.
              </Typography>

              <Button
                type="submit"
                onClick={navigateHome}
                variant="contained"
                sx={{
                  paddingX: "20px",
                  // paddingY: 3,
                  // mt: 2,
                  fontSize: "15px",
                  mb: 2,
                }}
              >
                Go Back To Home
              </Button>
            </StyledDfAcJc>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ReSubmitSuccess;
