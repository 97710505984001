import dwAgreement from "./icons/agreement.png";
import alert from "./icons/alert.png";
import business from "./icons/business.png";
import businessHi from "./icons/businessHi.png";
import checked from "./icons/checked.png";
import dwDeposit from "./icons/deposit.png";
import document from "./icons/document.png";
import downloaded from "./icons/download.png";
import email from "./icons/email.png";
import error from "./icons/error.png";
import editPlain from "./icons/edit-plain.png";
import dwFunds from "./icons/executeFunds.png";
import firmDocuments from "./icons/firmDocuments.svg";
import google from "./icons/googleLogo.png";
import greenCheck from "./icons/greenCheck.png";
import individual from "./icons/Individual.png";
import individualHi from "./icons/individualHi.png";
import dwInitiateFundRelease from "./icons/initiateFundRelease.png";
import dwInviteParties from "./icons/inviteParties.png";
import normaCheck from "./icons/normalCheck.png";
import notification from "./icons/notification.svg";
import PaymentReceived from "./icons/paymentReceived.png";
import paymentSent from "./icons/paymentSent.png";
import pdf from "./icons/pdf.png";
import pdfIcon from "./icons/pdfIcon.png";
import right from "./icons/right.png";
import dwSecuredEscrow from "./icons/secureEscrow.png";
import settings from "./icons/setting-2.svg";
import timelineIcon from "./icons/timeLineMessage.png";
import truupe from "./icons/truupeLogo.png";
import verificationFailed from "./icons/verificationFailed.png";
import verified from "./icons/verifiedLogo.png";
import view from "./icons/view.png";
import accessDenied from "./images/accessDenied.png";
import authImage from "./images/authImage.png";
import goods from "./images/boxes.png";
import goodsHi from "./images/boxesHi.png";
import buyer from "./images/buyer.png";
import buyerHi from "./images/buyerHi.png";
import calender from "./images/calendar.png";
import clock from "./images/clock@2x.png";
import dealAmount from "./images/dealAmount.png";
import dealCreate from "./images/dealCreate.png";
import dealReject from "./images/dealReject.jpg";
import desktopView from "./images/desktop-view.png";
import enableLocation_m1 from "./images/enableLocation_m1.png";
import enableLocation_m2 from "./images/enableLocation_m2.png";
import enableLocation_m3 from "./images/enableLocation_m3.png";
import enableLocation_m4 from "./images/enableLocation_m4.png";
import enableLocation_w1 from "./images/enableLocation_w1.png";
import enableLocation_w2 from "./images/enableLocation_w2.png";
import enableLocation_w3 from "./images/enableLocation_w3.png";
import enableLocation_w4 from "./images/enableLocation_w4.png";
import enableLocation_w5 from "./images/enableLocation_w5.png";
import enableLocation_w6 from "./images/enableLocation_w6.png";
import escrow2 from "./images/escrow2.png";
import escrowCard from "./images/escrowCard.png";
import escrow1 from "./images/escrowCreate.png";
import group from "./images/group2.png";
import rupee from "./images/indianRupees.png";
import logo from "./images/logo.png";
import newDeal from "./images/newDeal.png";
import pageNotFound from "./images/pageNotFound404.png";
import services from "./images/services.png";
import servicesHi from "./images/servicesHi.png";
import truupeLogo from "./images/truupeLogo.png";
export {
  accessDenied,
  authImage,
  buyer,
  buyerHi,
  calender,
  checked,
  clock,
  dealAmount,
  dealCreate,
  dealReject,
  desktopView,
  enableLocation_m1,
  enableLocation_m2,
  enableLocation_m3,
  enableLocation_m4,
  enableLocation_w1,
  enableLocation_w2,
  enableLocation_w3,
  enableLocation_w4,
  enableLocation_w5,
  enableLocation_w6,
  escrow1,
  escrow2,
  escrowCard,
  firmDocuments,
  goods,
  goodsHi,
  greenCheck,
  group,
  logo,
  newDeal,
  pageNotFound,
  rupee,
  services,
  servicesHi,
  truupeLogo,
};

export const Icons = {
  PaymentReceived,
  dwInviteParties,
  normaCheck,
  notification,
  dwFunds,
  dwInitiateFundRelease,
  paymentSent,
  verificationFailed,
  error,
  editPlain,
  view,
  downloaded,
  timelineIcon,
  dwDeposit,
  dwAgreement,
  dwSecuredEscrow,
  checked,
  alert,
  pdf,
  pdfIcon,
  google,
  document,
  settings,
  truupe,
  email,
  businessHi,
  business,
  individual,
  individualHi,
  greenCheck,
  verified,
  right,
};
