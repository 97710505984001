import { ThemeProvider } from "@emotion/react";
import { Box, Fade } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import router from "../routes/Routes";
import { theme } from "../styles/Theme";
import "./App.css";

function App() {
  const getData = async (cancelToken: any) => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json", {
        cancelToken: cancelToken.token,
      });
      Cookies.set("ip", res?.data?.ip);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getData(cancelToken);

    return () => {
      cancelToken.cancel("Component unmounted, request canceled.");
    };
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const clientid = process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID || "";

  <script src="https://checkout.razorpay.com/v1/razorpay.js" async></script>;

  <script src="https://checkout.razorpay.com/v1/checkout.js" async></script>;

  return (
    <GoogleOAuthProvider clientId={clientid}>
      <Box className="App">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
            <ToastContainer position="top-right" />
          </ThemeProvider>
        </LocalizationProvider>
      </Box>
    </GoogleOAuthProvider>
  );
}

export default App;
