import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { stripHTML } from "../../../utils/dealFunctions";
import Status from "../../dealProcess/deals/userDeal/dealStatus/DealStatus";
import DrawerButtonAction from "../components/DrawerButtonAction";
import BankDetails from "./BankDetails";
import BankingForm from "./BankingForm";

const BankingInformation = ({ data, isEditable }: any) => {
  const mainData = data?.data;
  const bankingInformation = data?.data?.bankingInformation;
  const entityStatus = data?.data?.entityStatus;
  const entityType = mainData?.companyType;

  return (
    <Box sx={{ marginLeft: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {!(entityType === "INDIVIDUAL" && !isEditable) && (
          <Typography variant="h5">Banking Information</Typography>
        )}
        {isEditable &&
          !["Kyc Submitted", "Rejected"].includes(entityStatus) && (
            <DrawerButtonAction
              subtype={`Add Banking Information`}
              title={`Add Banking Information`}
              anchor={({ open }) => (
                <Button onClick={open} variant="contained">
                  Add Banking Details
                </Button>
              )}
              content={({ close }) => (
                <BankingForm
                  typeId={mainData?.id}
                  subType={"CANCELLED_CHEQUE"}
                  typeData="BANKING"
                  close={close}
                  data={data}
                />
              )}
            />
          )}
      </Box>

      {bankingInformation?.map((bankData: any, index: number) => (
        <Box key={index} sx={{ marginBottom: "20px" }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h6"
              sx={{ marginBottom: "10px" }}
            >{`Bank Account ${index + 1}`}</Typography>
            {isEditable &&
              !["Kyc Submitted", "Rejected"].includes(entityStatus) &&
              (bankData.status === "In Progress" ? (
                <Status variant="PENDING" />
              ) : bankData.status === "Verified" ? (
                <Status variant="VERIFIED" />
              ) : bankData.status === "Rejected" ? (
                <Status
                  variant="REJECTED"
                  showTooltip={true}
                  tooltipText={stripHTML(bankData.reason)}
                />
              ) : null)}
          </Stack>

          <BankDetails bankData={bankData} mainData={mainData} />
          {index !== bankingInformation.length - 1 && (
            <Divider sx={{ marginY: 2 }} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default BankingInformation;
