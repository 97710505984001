import { Box, Divider, Typography } from "@mui/material";
import { Deal } from "../../../../interfaces/deal";
import { User } from "../../../../interfaces/user";
import DealTabs from "../DealTabs";
import DealBreadcrumbs from "./DealBreadCrumbs";
import Status from "./dealStatus/DealStatus";

interface DealTopProps {
  dealData: Deal;
  profileData: User;
  dealCode: string;
  breadcrumbs: any;
}
export default function DealTop({
  dealData,
  profileData,
  dealCode,
  breadcrumbs,
}: DealTopProps) {
  const dealCreator = dealData?.dealUsers?.find(
    (user: any) => user?.userId === dealData?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.id;
  return (
    <>
      <DealBreadcrumbs links={breadcrumbs} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: {
            xs: "0px 16px",
            sm: "0px 16px",
            md: "0px 16px",
            xl: "0px 16px",
            lg: "0px 16px",
          },
        }}
      >
        <Typography variant="h5" my="10px">
          {dealCode}
        </Typography>
        <Status variant={dealData?.status} />
      </Box>

      <DealTabs data={dealData} accountOwner={firstPerson} />
      <Divider sx={{ width: "100%", opacity: "0.5" }} />
    </>
  );
}
