import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router-dom";
import { newDeal } from "../../../../../../../assets";
import { DealUser } from "../../../../../../../interfaces/deal";
import {
  formatIndianIntegers,
  stripHTML,
} from "../../../../../../../utils/dealFunctions";
import Status from "../../../dealStatus/DealStatus";

interface Transaction {
  type: string;
  amount: number;
}

function descendingComparator(a: any, b: any, orderBy: string): number {
  function getNestedValue(obj: any, path: string): any {
    return path.split(".").reduce((value, key) => {
      // Check if we are accessing an array element like 'transactions[0]'
      if (key.includes("[")) {
        const arrayIndex = key.match(/\[(\d+)\]/); // Get the index from 'transactions[0]'
        if (arrayIndex) {
          const actualKey = key.split("[")[0]; // Get 'transactions'
          const index = parseInt(arrayIndex[1], 10); // Get the index 0
          return value?.[actualKey]?.[index]; // Access the array element
        }
      }
      return value?.[key];
    }, obj);
  }

  let aValue: number | undefined = getNestedValue(a, orderBy);
  let bValue: number | undefined = getNestedValue(b, orderBy);

  // Custom sorting logic for credit and debit fields
  if (orderBy === "credit") {
    aValue = a.type === "Credit" ? a.amount : 0;
    bValue = b.type === "Credit" ? b.amount : 0;
  } else if (orderBy === "debit") {
    aValue = a.type === "Debit" ? a.amount : 0;
    bValue = b.type === "Debit" ? b.amount : 0;
  }

  if (bValue! < aValue!) {
    return -1;
  }
  if (bValue! > aValue!) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator(order: Order, orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: any) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort, refundExists } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "transactions[0].crn", // Updated to handle array index correctly
      label: "Transaction ID",
    },
    {
      id: "createdAt",
      label: "Date and Time",
    },
    {
      id: "transactions[0].route", // Updated to handle array index correctly
      label: "Transaction Route",
    },
    {
      id: "mode",
      label: "Mode", // Assuming mode is directly on the row object
    },
    {
      id: "credit",
      label: "Credit",
    },
    {
      id: "debit",
      label: "Debit",
    },
    ...(refundExists ? [{ id: "refund", label: "Refund" }] : []),
    {
      id: "transactions[0].statusHistory[0].status", // Handling nested arrays
      label: "Status",
    },
  ];

  return (
    <TableHead sx={{ bgcolor: "#1212121A" }}>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TransactionTableSortable({
  dealData,
  transactionData,
}: any) {
  const { dealId } = useParams();
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  let seller: any = null;
  let buyer: any = null;

  dealData?.dealUsers?.forEach((user: any) => {
    if (user.userType === "Seller") {
      seller = user;
    } else if (user.userType === "Buyer") {
      buyer = user;
    }
  });

  const rows = transactionData?.data?.data || [];

  const refundExists = rows.some((row: any) => row?.type === "Refund");

  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTransactionRoute = (row: any, buyer: DealUser, seller: DealUser) => {
    const getName = (entity: any, user: any) =>
      entity?.companyType === "INDIVIDUAL" ? user?.name : entity?.companyName;

    switch (row?.type) {
      case "Debit":
        return `Truupe to ${getName(seller?.entity, seller?.user)}`;
      case "Refund":
        return `Truupe to ${getName(buyer?.entity, buyer?.user)}`;
      case "Credit":
        return `${getName(buyer?.entity, buyer?.user)} to Truupe`;
      default:
        return "";
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );
  const PaymentMethodLabels: any = {
    NE: "NEFT",
    RT: "RTGS",
    PA: "IMPS",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ mb: 2, border: "1px solid #1212121A" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              refundExists={refundExists}
            />
            <TableBody>
              {visibleRows?.map((row: any) => {
                return (
                  <TableRow key={row?.id}>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell align="left">
                      {row?.type === "Credit" ? row?.utr : row?.crn}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row?.createdAt).format("DD/MM/YYYY, hh:mm A")}
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: "flex" }} gap={"10px"}>
                        {" "}
                        <Box>
                          {/* {row?.type === "Debit"
                            ? "Truupe to Seller"
                            : "Buyer to Truupe"} */}
                          {row?.route}
                        </Box>
                        <Tooltip
                          title={getTransactionRoute(row, buyer, seller)}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </Box>

                      {/* {row?.transactions[0]?.route} */}
                    </TableCell>
                    <TableCell align="left">
                      {PaymentMethodLabels[row?.mode] || "NA"}
                    </TableCell>
                    <TableCell align="left">
                      <Typography color="green">
                        {row.type === "Credit" ? (
                          <>
                            {"  ₹  "}
                            {formatIndianIntegers(row?.amount)}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography color="red">
                        {row?.type === "Debit" ? (
                          <>
                            {"  ₹  "}
                            {formatIndianIntegers(row?.amount)}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>
                    {refundExists && (
                      <TableCell align="left">
                        <Typography color="orange">
                          {row?.type === "Refund" ? (
                            <>
                              {"  ₹  "}
                              {formatIndianIntegers(row?.amount)}
                            </>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <Stack
                        direction={"row"}
                        gap={"5px"}
                        alignItems={"center"}
                      >
                        <Status variant={row?.status || "NA"}></Status>

                        <Tooltip
                          title={
                            stripHTML(row?.statusHistory[0]?.remarks) || "NA"
                          }
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {visibleRows.length === 0 && (
          <Box sx={{ width: "100vw" }}>
            <Stack alignItems={"center"} gap={"1vh"} padding={"1vh"}>
              <img width={"10%"} src={newDeal} alt="loading...." />
              <Typography variant="h3">
                It looks like you don't have any Payments yet.
              </Typography>
              <Typography variant="body1">
                Start your Payment journey on Truupe by creating your first
                Payment. It's straightforward!
              </Typography>
            </Stack>
          </Box>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
}
