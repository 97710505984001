import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getSignatoryAuthority } from "../../../api/services";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function SignatoryAuthorityModel({
  openModel,
  setOpenModel,
  data,
  signatoryDirectors,
  addDirector,
}: any) {
  const EntityId = Cookies.get("entityId");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const handleClose = () => {
    setOpenModel(false);
    setSelectedIds([]);
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds?.includes(id)) {
        return prevSelectedIds?.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const { mutate: signaturyAuth, isLoading } = useMutation(
    getSignatoryAuthority,
    {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries("status");
        queryClient.invalidateQueries("getcompanydetails");
        setOpenModel(false);
        // addDirector();
      },
      onError: (error: any) => {},
    }
  );

  const arrayOfsignatoryDirectorsIds = signatoryDirectors?.map(
    (item: any) => item?.id
  );

  const AddSignatutyAuthority = () => {
    if (selectedIds.length > 0) {
      if (selectedIds.length === 1 && selectedIds[0] === "0") {
        setOpenModel(false);
        addDirector();
        setSelectedIds([]);
      } else if (selectedIds.length > 1 && selectedIds.includes("0")) {
        let filteredArray = selectedIds?.filter((item) => item !== "0");
        const ConcatenateArray =
          arrayOfsignatoryDirectorsIds?.concat(filteredArray);
        signaturyAuth({
          entityid: EntityId,
          body: {
            directorIds: ConcatenateArray,
            isSignatorySelected: false,
          },
        });
        addDirector();
        setSelectedIds([]);
      } else {
        const ConcatenateArray =
          arrayOfsignatoryDirectorsIds?.concat(selectedIds);
        if (ConcatenateArray.includes["0"]) {
        } else {
          signaturyAuth({
            entityid: EntityId,
            body: {
              directorIds: ConcatenateArray,
              isSignatorySelected: false,
            },
          });
          setSelectedIds([]);
        }
      }
    } else {
      toast.error("Please Select Signatory Authorities ");
    }
  };

  return (
    <Box>
      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                my: "1vh",
              }}
            >
              <IconButton
                sx={{ bgcolor: "white", size: "small" }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Box>

            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 24,
                width: "40vw",
                p: 4,
                borderRadius: "5px",
              }}
            >
              <Typography
                id="modal-modal-title"
                sx={{ fontWeight: 600 }}
                variant="h4"
              >
                List of Directors
              </Typography>
              <Typography variant="subtitle2" sx={{ my: 1 }}>
                Here are the list of signatories fetched from the CIN Number
              </Typography>
              <Box
                sx={{
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                <Grid container spacing={2} my={1}>
                  {data?.map((director: any) => {
                    const isdisabled = signatoryDirectors.some(
                      (obj: any) => obj?.id === director?.id
                    );
                    return (
                      <React.Fragment key={director?.dpin}>
                        <Grid size={6} display={"flex"} alignItems={"center"}>
                          <Stack>
                            <Typography variant="body1" sx={{ color: "grey" }}>
                              Director Name
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={isdisabled}
                                    checked={selectedIds?.includes(
                                      director?.id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(director?.id)
                                    }
                                  />
                                }
                                label={director?.name}
                              />
                            </FormGroup>
                          </Stack>
                        </Grid>
                        <Grid size={6} display={"flex"}>
                          <Stack>
                            <Typography variant="body1" sx={{ color: "grey" }}>
                              DIN
                            </Typography>

                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: isdisabled ? "grey" : "black",
                                my: "1vh",
                              }}
                            >
                              {director?.dpin}
                            </Typography>
                          </Stack>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid size={6} display={"flex"} alignItems={"center"}>
                    <Stack>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event: any) => {
                                handleCheckboxChange("0");
                              }}
                            />
                          }
                          label="Add Signatory Authority "
                        />
                      </FormGroup>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  marginTop: "2.4vh",
                  marginX: 0,
                  textAlign: "end",
                  alignContent: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <LoadingButton
                      loading
                      loadingPosition="center"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      sx={{
                        "& .MuiCircularProgress-root": {
                          color: "#7643EB",
                        },
                        "&.MuiButton-outlined": {
                          border: "2px solid #7643EB",
                        },
                      }}
                    ></LoadingButton>
                  </>
                ) : (
                  <>
                    <Button onClick={AddSignatutyAuthority} variant="contained">
                      Next
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      </Modal>
    </Box>
  );
}
