// utilities/formatting.ts

export const upperCaseValue = (value: string): string => {
  return value.toUpperCase();
};

export const lowerCaseValue = (value: string): string => {
  return value.toLowerCase();
};

export const onlyNumbers = (value: string): string => {
  return value.replace(/[^0-9]/g, "");
};

export const alphanumeric = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9\s]/g, "");
};

export const alphabetsOnly = (value: string): string => {
  return value.replace(/[^a-zA-Z\s]/g, ""); // \s allows spaces
};

export const formatAadharNumber = (value: string): string => {
  // Remove all non-numeric characters
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  // Format as "xxxx xxxx xxxx" while the user is typing
  const formattedValue = onlyNumbers.replace(/(\d{4})(?=\d)/g, "$1 ");

  // Ensure no trailing space and limit to 14 characters (12 digits + 2 spaces)
  return formattedValue.slice(0, 14);
};

export const formatOtp = (value: string): string => {
  // Remove all non-numeric characters
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  // Format as "x x x x x x" while the user is typing
  const formattedValue = onlyNumbers.replace(/(\d{1})(?=\d)/g, "$1 ");

  // Ensure no trailing space and limit to 7 characters (6 digits + 5 spaces)
  return formattedValue.slice(0, 11); // 6 digits + 5 spaces
};

export const formatMobileNumber = (value: string): string => {
  // Remove all non-numeric characters
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  // Ensure the string is exactly 10 digits
  const formattedValue = onlyNumbers.slice(0, 10);

  return formattedValue;
};

export const formatEmail = (value: string): string => {
  // Trim leading and trailing spaces
  const trimmedValue = value.trim();

  // Optionally, convert to lowercase
  const formattedValue = trimmedValue.toLowerCase();

  return formattedValue;
};
