import { Controller } from "react-hook-form";
import DealInputField from "../../pages/dealProcess/deals/createDeal/DealInputField";

type AmountFieldProps = {
  control: any;
  clearErrors: Function;
  setError: Function;
  errors: any;
};

export default function AmountField({
  control,
  clearErrors,
  setError,
  errors,
}: AmountFieldProps) {
  return (
    <Controller
      name="amount"
      control={control}
      render={({ field }) => (
        <DealInputField
          title="Deal Amount"
          rightEndAdornment={true}
          field={{
            ...field,
            onChange: (e: any) => {
              let value = e.target.value.replace(/[^0.1-9]/g, "");
              if (value.length > 15) {
                value = value.slice(0, 15);
              }
              if (value === "") {
                field.onChange(value);
                clearErrors("amount");
                return;
              }
              const numericValue = Number(value);
              if (numericValue > 100000000) {
                setError("amount", {
                  type: "manual",
                  message: "Value must be between 10,000 and 10,00,00,000",
                });
                return;
              } else {
                clearErrors("amount");
              }
              const formatIndianNumber = (num: any) => {
                const lastThree = num.substring(num.length - 3);
                const otherNumbers = num.substring(0, num.length - 3);
                return (
                  otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
                  (otherNumbers ? "," : "") +
                  lastThree
                );
              };
              const data = value.split(".");
              const formattedValue = formatIndianNumber(data[0]);
              const finaldata =
                formattedValue +
                (data[1] !== undefined ? "." + data[1]?.slice(0, 2) : "");
              field.onChange(finaldata);
            },
          }}
          placeholder="Enter Deal Amount"
          iD={"Deal Amount"}
          errors={errors}
        />
      )}
    />
  );
}
