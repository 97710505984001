import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  AddbankingInformation,
  VerifyIfscCode,
  userActivity,
  verifyBankDetails,
} from "../../../api/services";
import { Icons } from "../../../assets";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import MultiInputField, {
  Formate,
} from "../../../components/formFields/kycFields/MultiInputField";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";
import Editbutton from "../../../components/buttons/Editbutton";
import * as yup from "yup";
import moment from "moment";
import { kycDetailsStore } from "../../../store/kycProcess";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const stylesBefore = {
  width: "230px",
  height: "100px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "230px",
  height: "100px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

type Info = {
  ifscCode: string;
  bankAccountNumber: string;
  bankName: string;
  branchName: string;
  beneficiaryName: string;
};

interface EntityBankingFormProps {
  isnew: boolean;
  CompanyDetails: any;
  statusDetails: any;
  subType: any;
  typeId: any;
  typeData: any;
  index: any;
  isDataSaved?: boolean;
  popBanking: any;
  BankingData?: number[];
  id: string;
  name: any;
  accountNumber: any;
  branch: any;
  ifscCode: any;
  beneficiaryName: any;
  cancelledCheque: any;
}

const EntityBankingForm = ({
  isnew,
  CompanyDetails,
  statusDetails,
  subType,
  typeId,
  typeData,
  index,
  isDataSaved,
  popBanking,
  BankingData = [],
  id,
  name,
  accountNumber,
  branch,
  ifscCode,
  beneficiaryName,
  cancelledCheque,
}: EntityBankingFormProps) => {
  const EntityId = typeId;
  const [disabled, setdisabled] = useState(false);
  const [bankName, setBankName] = useState(false);
  const [fileData, setFileData] = useState<{ [key: string]: string }>({});
  const [verifyDetails, setVerifyDetails] = useState(true);
  const [bankGreenTick, setbankGreenTick] = useState(false);
  const [isFileDeleted, setIsFileDeleted] = useState(true);
  const [disable, isdisable] = useState(false);
  const queryClient = useQueryClient();
  const [showname, setshowname] = useState(false);
  const [bankDetails, setBankDetails] = useState<any>();
  const [bankingStatus, setBankingStatus] = useState(false);
  const [chequeKey, setChequeKey] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<Info>();

  const FormContainer = styled(Box)({
    overflow: "auto",
    width: "64vw",
    height: "57.8vh",
    padding: "0 16px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  });

  const FormSection = styled(Box)({
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  });

  const InfoSection = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  });

  const BankInfoMessage = styled(Typography)({
    opacity: "85%",
    color: "#800000",
    margin: "8px 0",
    fontStyle: "italic",
  });

  const schema = yup.object().shape({
    ifscCode: yup
      .string()
      .typeError("IFSC Code must be a string")
      .required("IFSC Code is required"),
    bankAccountNumber: yup
      .number()
      .typeError("Bank Account Number must be a number")
      .required("Bank Account Number is required"),
    bankName: yup
      .string()
      .typeError("Bank Name must be a string")
      .required("Bank Name is required"),
    branchName: yup
      .string()
      .typeError("Branch Name must be a string")
      .required("Branch Name is required"),
    beneficiaryName: yup
      .string()
      .typeError("Beneficiary Name must be a string")
      .required("Beneficiary Name is required"),
  });

  const { mutate: BankDetails, isLoading: BankDetailLoading } = useMutation(
    VerifyIfscCode,
    {
      onSuccess: (BankDetails) => {
        setValue("bankName", BankDetails?.data?.BANK);
        setValue("branchName", BankDetails?.data?.BRANCH);
        verifyBank({
          id_number: getValues("bankAccountNumber"),
          ifsc: getValues("ifscCode"),
        });
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter Aadhar OTP");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: verifyBank, isLoading: addBankLoader } = useMutation(
    verifyBankDetails,
    {
      onSuccess: (res) => {
        setbankGreenTick(true);
        setValue("beneficiaryName", res?.data?.data?.full_name);
        setshowname(true);
        setBankName(true);
        const finalData = {
          entityId: EntityId,
          name: getValues("bankName"),
          accountNumber: getValues("bankAccountNumber"),
          ifscCode: getValues("ifscCode"),
          branch: getValues("bankName"),
          beneficiaryName: getValues("beneficiaryName"),
        };
        setBankDetails(finalData);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please try again");
        }
      },
    }
  );

  const { mutate: deletesignaturyAuth, isLoading: deleteLoader } = useMutation({
    onSuccess: (res: any) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries("status");
      queryClient.invalidateQueries("getcompanydetails");
    },
    onError: (error: any) => {},
  });

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: () => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleFileChange = (fileKey: any) => {
    setChequeKey(fileKey);
  };

  const { mutate: postbankingdetails, isLoading: submitloader } = useMutation(
    AddbankingInformation,
    {
      onSuccess: () => {
        toast.success("Information Updated Successfully");
        queryClient.invalidateQueries("banking");
        queryClient.invalidateQueries("getuser-id");
        queryClient.invalidateQueries("getcompanydetails");

        const accessToken: any = Cookies.get("access_token");
        const userdata: any = jwtDecode(accessToken);
        const draftdata: any = {
          userId: userdata?.userId,
          activity: "KYB/KYC Process in Draft",
          entityId: EntityId,
        };
        useractivity(draftdata);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = () => {
    const bankingKeys = [chequeKey];

    const updatedBankDetails = {
      ...bankDetails,
      bankingKeys,
    };
    postbankingdetails(updatedBankDetails);
  };

  useEffect(() => {
    if (accountNumber.length === 0 && ifscCode.length === 0) {
      isdisable(false);
    } else {
      setValue("bankAccountNumber", accountNumber);
      setValue("ifscCode", ifscCode);
      setValue("bankName", name);
      setValue("branchName", branch);
      setValue("beneficiaryName", beneficiaryName);
      isdisable(true);
    }
  }, [accountNumber, ifscCode, isdisable, setValue, name, branch]);

  return (
    <>
      <form>
        <Box
          sx={{
            mx: "10px",
            display: "flex",
            flexDirection: "column",
            paddingBottom: 2,
            marginTop: "1vh",
          }}
        >
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" sx={{ my: "1vh" }}>
              Bank Details {index + 1}
            </Typography>

            {!(statusDetails?.data?.banking || isDataSaved) && (
              <>
                {deleteLoader ? (
                  <>
                    <CircularProgress size={"1.5rem"}></CircularProgress>
                  </>
                ) : (
                  <>
                    {" "}
                    <IconButton
                      onClick={() => {
                        popBanking(index);
                      }}
                    >
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </>
                )}
              </>
            )}
          </Box>

          <FormSection>
            <MultiInputField
              control={control}
              label={"Bank Account Number"}
              name={"bankAccountNumber"}
              key="bankAccountNumber"
              variant={Formate.OnlyNumbers}
              type="text"
              errors={errors}
              disabled={isDataSaved || bankGreenTick}
              sx={{
                "& .MuiInputBase-input": {
                  width: { xs: "200px", md: "25vw" },
                },
              }}
            />

            {(isDataSaved || statusDetails?.data?.banking || bankGreenTick) && (
              <>
                <Box
                  sx={{
                    marginTop: "12px",
                    display: " flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                >
                  <Box marginTop={"2.4vh"}>
                    <img
                      height={"25px"}
                      src={Icons.greenCheck}
                      alt="loading...."
                    />
                  </Box>
                </Box>
                {!(isDataSaved || statusDetails?.data?.banking) && (
                  <Box marginTop={"3vh"}>
                    <Editbutton
                      onclick={() => {
                        setbankGreenTick(false);
                        setBankName(false);
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </FormSection>

          <InfoSection>
            <MultiInputField
              control={control}
              label={"IFSC Code"}
              name={"ifscCode"}
              key="ifscCode"
              variant={Formate.StrictUpperCase}
              type="text"
              errors={errors}
              disabled={isDataSaved || bankGreenTick}
              sx={{
                "& .MuiInputBase-input": {
                  width: { xs: "200px", md: "25vw" },
                },
              }}
            />

            {BankDetailLoading || addBankLoader ? (
              <CircularProgress
                sx={{ marginLeft: "25px", marginTop: "15px" }}
                size={"2rem"}
                color="primary"
              />
            ) : (
              <>
                {bankGreenTick ||
                statusDetails?.data?.banking ||
                isDataSaved ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <Box
                      sx={{
                        // marginTop: "12px",
                        display: " flex",
                        alignItems: "center",
                      }}
                    >
                      <Box marginTop={"2.4vh"}>
                        <img
                          height={"25px"}
                          src={Icons.greenCheck}
                          alt="loading...."
                        />
                      </Box>
                      {!(isDataSaved || statusDetails?.data?.banking) && (
                        <Box marginTop={"1.5vh"}>
                          <Editbutton
                            onclick={() => {
                              setBankName(false);
                              setbankGreenTick(false);
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      const IfscCode = getValues().ifscCode;
                      if (!IfscCode) {
                        toast.error("Please Enter IFSC Code ");
                      } else {
                        BankDetails(IfscCode);
                      }
                      const dateTime = moment(Date());
                      const savedDateTime = dateTime.format(
                        "DD MMM YYYY [at] hh:mm A"
                      );
                      kycDetailsStore.update((s) => {
                        s.lastUpdatedAt = savedDateTime;
                      });
                    }}
                    sx={{
                      marginTop: "2.5vh",
                      padding: 0,
                      fontSize: "12px",
                      height: "30px",
                    }}
                  >
                    Verify
                  </Button>
                )}
              </>
            )}
          </InfoSection>

          {(bankName || statusDetails?.data?.banking || isDataSaved) && (
            <>
              <MultiInputField
                control={control}
                label={"Bank Name"}
                name={"bankName"}
                key="bankName"
                variant={Formate.Alphanumeric}
                type="text"
                errors={errors}
                disabled={isDataSaved || bankGreenTick || true}
                sx={{
                  "& .MuiInputBase-input": {
                    width: { xs: "200px", md: "25vw" },
                  },
                }}
              />

              <MultiInputField
                control={control}
                label={"Branch Name"}
                name={"branchName"}
                key="branchName"
                variant={Formate.Alphanumeric}
                type="text"
                errors={errors}
                disabled={isDataSaved || bankGreenTick || true}
                sx={{
                  "& .MuiInputBase-input": {
                    width: { xs: "200px", md: "25vw" },
                  },
                }}
              />
              {(showname || statusDetails?.data?.banking || isDataSaved) && (
                <MultiInputField
                  control={control}
                  label={"Beneficiary Name"}
                  name={"beneficiaryName"}
                  key="beneficiaryName"
                  variant={Formate.Alphanumeric}
                  type="text"
                  errors={errors}
                  disabled={isDataSaved || bankGreenTick || true}
                  sx={{
                    "& .MuiInputBase-input": {
                      width: { xs: "200px", md: "25vw" },
                    },
                  }}
                />
              )}
            </>
          )}
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ marginTop: "20px" }}
          >
            Upload Cancelled Cheque
          </Typography>
          <FileUploadContainer
            typeId={id || null}
            actions={<></>}
            status={isDataSaved}
            data={"CANCELLED_CHEQUE"}
            typeData="BANKING"
            uploaded={!!cancelledCheque?.url}
            name={cancelledCheque.originalFileName || ""}
            url={cancelledCheque.url || ""}
            onFileChange={(key) => {
              if (key === "") {
                setIsFileDeleted(true);
              } else {
                setIsFileDeleted(false);
                handleFileChange(key);
              }
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />

          {!isDataSaved && (
            <BankInfoMessage variant="body1">
              Please make sure you have entered the banking information of{" "}
              {CompanyDetails?.data?.companyName ||
                CompanyDetails?.data?.directors[0]?.name}
              .
            </BankInfoMessage>
          )}
        </Box>
        <Box sx={{ display: "flex", mx: "10px" }}>
          <CustomLoadingButton
            width={"50px"}
            submitLoader={submitloader}
            onClick={onSubmit}
            isDisabled={
              isDataSaved ||
              !getValues("bankAccountNumber") ||
              !getValues("ifscCode") ||
              isFileDeleted
            }
            title="save"
          />
        </Box>
      </form>
    </>
  );
};

export default EntityBankingForm;
