import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Icons } from "../../../assets";

function DealWorkflow() {
  const steps = [
    {
      img: Icons.dwInviteParties,
      title: "1. Invite Parties",
      description:
        "Start your deal by inviting participants via email. Quick, simple, and secure.",
    },
    {
      img: Icons.dwAgreement,
      title: "2. Seal the Deal",
      description:
        "Review and accept the deal terms, and confirm your participation to move forward.",
    },
    {
      img: Icons.dwSecuredEscrow,
      title: "3. Establish Secure Escrow",
      description:
        "Set up a protected escrow account to safely hold funds until the deal terms are met.",
    },
    {
      img: Icons.dwDeposit,
      title: "4. Deposit Funds into Escrow Account",
      description:
        "Secure the deal by depositing funds into the escrow account.",
    },
    {
      img: Icons.dwInitiateFundRelease,
      title: "5. Initiate Fund Release",
      description:
        "Request the release of escrow funds upon fulfilling deal terms.",
    },
    {
      img: Icons.dwFunds,
      title: "6. Execute Funds Transfer",
      description:
        "Initiate the transfer of funds from the escrow to the relevant party upon deal fulfillment.",
    },
  ];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={2}>
      <Card
        sx={{
          my: "2vh",
          p: 3,
          background: "#FFFFFF",
          border: "1px solid #1212121A",
          borderRadius: "10px",
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "#151515", mb: 2 }}>
          Deal Workflow Steps
        </Typography>
        <Grid rowGap={"4vh"} container>
          {steps.map((step, index) => (
            <Grid
              size={{ xs: 4 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "start",
              }}
              key={index}
            >
              <img
                src={step.img}
                alt={`Step ${index + 1}`}
                style={{ width: "200px", height: "auto", opacity: 1 }}
              />
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "20px", color: "#111111", mt: 1 }}
                >
                  {step.title}
                </Typography>
                <Typography sx={{ color: "#333333", opacity: 0.85 }}>
                  {step.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Box>
  );
}

export default DealWorkflow;
