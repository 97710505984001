import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserDeals } from "../../../../api/services/deals";
import { newDeal } from "../../../../assets";

import { googleLogout } from "@react-oauth/google";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { getusersbyid } from "../../../../api/services";
import { getUserId } from "../../../../utils/authUtils";
import AllDeals from "../allDeals/AllDeals";

function CreateNewDeal() {
  const navigate = useNavigate();
  const { data: dealData, isLoading }: any = useQuery(
    "user-deals",
    getUserDeals,
    {
      onError: (data: any) => {
        if (data?.response?.data?.statusCode === 404) {
          Cookies.remove("newEmail");
          Cookies.remove("access_token");
          Cookies.remove("otptoken");
          Cookies.remove("access_token");
          Cookies.remove("entityId");
          localStorage.clear();
          googleLogout();
          navigate("/login", { replace: true });
        }
      },
    }
  );

  const userID = getUserId();
  const { data: entityData }: any = useQuery(
    ["getUser-id", userID],
    getusersbyid
  );

  useEffect(() => {
    const entityId = entityData?.data?.entities[0]?.id;
    if (entityId === undefined) {
      Cookies.remove("entityId");
    } else {
      Cookies.set("entityId", entityId);
    }
  }, [entityData?.data?.entities]);

  localStorage.removeItem("dealcode");
  localStorage.removeItem("presentStatus");
  localStorage.removeItem("presentState");
  localStorage.removeItem("UserRole");

  if (isLoading) {
    return <LinearProgress color="primary" />;
  }

  return dealData?.data?.count > 0 ? (
    <AllDeals />
  ) : (
    <Box
      sx={{
        width: "100%",
        // height: "83vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: 2, sm: 3, md: 5 },
      }}
    >
      <Card
        sx={{
          my: { xs: 3, sm: 4, md: 5 },
          mx: { xs: 2, sm: 3, md: 5 },
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Box
              sx={{
                width: 400,
                height: 400,
                borderRadius: "50%",
                display: "flex",
              }}
            >
              <img
                src={newDeal}
                alt="No Deals"
                style={{
                  // width: "100%",
                  // height: "100%",
                  width: "90%",
                  height: "90%",
                  marginLeft: "5%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: "1.15rem", sm: "1.5rem", md: "1.75rem" },
                mb: 1,
              }}
            >
              It looks like you don't have any deals yet.
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{
                opacity: "0.85", // Setting font opacity
                fontSize: { xs: "16px", sm: "18px" },
                mb: 2,
              }}
            >
              Start your Deal journey on Truupe by creating your first deal.
              It's straightforward!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/deal-process/deals/create", { replace: true });
              }}
              sx={{
                px: { xs: 2, sm: 4 },
                py: { xs: 1, sm: 1.5 },
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Create a New Deal
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
export default CreateNewDeal;
