import { Box, Button, SelectChangeEvent, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getComapanyDetailsById,
  getDirectorAddressProof,
  getStatusbyId,
  userActivity,
} from "../../../api/services";
import SignatoryDocuments from "./SignatoryDocuments";

const SignatoryAuthorityDocuments = ({ addDirector }: any) => {
  const [togglesave, settogglesave] = useState(true);
  const { kyctype } = useParams();
  const [save, setsave] = useState(false);
  const navigate = useNavigate();
  const EntityId = Cookies.get("entityId");
  const [areFilesDeleted, setAreFilesDeleted] = useState(false);

  const { data: CompanyDetails } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      enabled: !!EntityId,
      onSuccess: () => {},
    }
  );

  const queryClient = useQueryClient();

  const [addressProofTypes, setAddressProofTypes] = useState<{
    [key: string]: string;
  }>({});

  const [optionsOpen, setOptionsOpen] = useState(
    Array(CompanyDetails?.data?.signatoryDirectors?.length).fill(false)
  );

  const { data: FirmAddressProof } = useQuery(
    "FirmAddressProof",
    getDirectorAddressProof
  );

  const handleAddressProofChange = (
    event: SelectChangeEvent<string>,
    directorId: string
  ) => {
    const value = event.target.value as string;
    setAddressProofTypes((prevTypes) => ({
      ...prevTypes,
      [directorId]: value,
    }));
  };

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const navigateAfterSubmit = () => {
    navigate(`/kycprocess/${kyctype}/preview`, { replace: true });
  };
  const { data: statusDetails } = useQuery(
    ["status", EntityId],
    getStatusbyId,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    if (CompanyDetails?.data?.directorDocuments) {
      setsave(true);
      queryClient.invalidateQueries("documents");
      queryClient.invalidateQueries("getuser-id");
      toast.success("Your data is saved successfully");
    }
  }, [CompanyDetails?.data?.directorDocuments, queryClient]);

  const onSubmit = () => {
    const accessToken: any = Cookies.get("access_token");
    const userdata: any = jwtDecode(accessToken);
    const draftdata = {
      userId: userdata?.userId,
      activity: "KYB/KYC Process in Draft",
      entityId: EntityId,
    };
    onSave();
    useractivity(draftdata);
    navigateAfterSubmit();
  };

  const onSave = () => {
    queryClient.invalidateQueries("status");
  };

  useEffect(() => {
    if (
      CompanyDetails?.data?.signatoryDirectors &&
      Array.isArray(CompanyDetails.data.signatoryDirectors)
    ) {
      const allDocumentsUploaded = CompanyDetails.data.signatoryDirectors.every(
        (director: any) => director.areDocumentsUploaded === true
      );
      settogglesave(allDocumentsUploaded);
    }
  }, [CompanyDetails?.data?.signatoryDirectors]);

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: "64vw",
          height: "57.8vh",
          px: 2,
          mt: "1vh",
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography variant="h5" gutterBottom>
          Signatory Information
        </Typography>

        {CompanyDetails?.data?.signatoryDirectors?.map(
          (director: any, index: any) => {
            return (
              <>
                <SignatoryDocuments
                  director={director}
                  index={index}
                  statusDetails={statusDetails}
                  CompanyDetails={CompanyDetails}
                  setIsFileDeleted={setAreFilesDeleted}
                />
              </>
            );
          }
        )}
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "3.4vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        {}
        <Button
          type="submit"
          onClick={onSubmit}
          variant="contained"
          disabled={!(togglesave && !areFilesDeleted)}
          sx={{
            marginRight: 4,
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default SignatoryAuthorityDocuments;
