import { Controller } from "react-hook-form";
import DealInputField from "../../pages/dealProcess/deals/createDeal/DealInputField";

type DurationFieldProps = {
  control: any;
  errors: any;
};

export default function DurationField({ control, errors }: DurationFieldProps) {
  return (
    <Controller
      name="duration"
      control={control}
      render={({ field }) => (
        <DealInputField
          title={"Deal Duration (in months)"}
          type="string"
          field={{
            ...field,
            onChange: (e: any) => {
              let parsedValue = parseInt(e.target.value) || 0;
              const newValue = Math.min(Math.max(0, parsedValue), 12);
              field.onChange(newValue.toString());
            },
          }}
          placeholder="Enter Deal Duration…"
          sx={{
            width: {
              xs: "255px",
              sm: "520px",
              lg: "520px",
              xl: "520px",
            },

            "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
              { WebkitAppearance: "none", margin: 0 },
          }}
          errors={errors}
        />
      )}
    />
  );
}
