import { Box, Button } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Navigate, createBrowserRouter, useNavigate } from "react-router-dom";
import { pageNotFound } from "../assets";
import DealLayout from "../layout/Deals/DealLayout";
import KycprocessLayout from "../layout/KycprocessLayout";
import NewdealDashboard from "../pages/dealProcess/dashboard/NewDealDashboard";
import CreateNewDeal from "../pages/dealProcess/deals/createDeal/CreateNewDeal";
import DealCreationForm from "../pages/dealProcess/deals/createDeal/DealCreationForm";
import DealDocumentation from "../pages/dealProcess/deals/userDeal/dealDocumentation/DealDocumentation";
import DealDocumentationSignee from "../pages/dealProcess/deals/userDeal/dealDocumentation/DealDocumentationSignee";
import DealDocumentationView from "../pages/dealProcess/deals/userDeal/dealDocumentation/DealDocumentationView";
import DealAccepted from "../pages/dealProcess/deals/userDeal/modalCards/DealAccepted";
import DealSuccess from "../pages/dealProcess/deals/userDeal/modalCards/DealSuccess";
import PayPlatformFee from "../pages/dealProcess/deals/userDeal/platformFee/PayPlatformFee";
import EscrowTransactions from "../pages/dealProcess/deals/userDeal/truupeAccount/accountInformation/EscrowTransactions";
import TransactionsMobile from "../pages/dealProcess/deals/userDeal/truupeAccount/mobile/TransactionsMobile";
import AddFundsToSeller from "../pages/dealProcess/deals/userDeal/truupeAccount/transactions/initiateTransaction/AddFundsToSeller";
import RequestReceived from "../pages/dealProcess/deals/userDeal/truupeAccount/transactions/requesttTansactions/RequestReceived";
import SellerRequestPayment from "../pages/dealProcess/deals/userDeal/truupeAccount/transactions/requesttTansactions/SellerRequestPayment";
import UserDeal from "../pages/dealProcess/deals/userDeal/UserDeal";
import Esign from "../pages/esign/Esign";
import AccessDenaid from "../pages/extras/AccessDenaid";
import EnableLocation from "../pages/extras/enableLocation/EnableLocation";
import DesktopAlert from "../pages/home/Components/DesktopAlert";
import ReSubmitSuccess from "../pages/home/Components/ReSubmitSuccess";
import Home from "../pages/home/Home";
import ReSubmitDocuments from "../pages/home/ReSubmitDocuments";
import Individual from "../pages/kyc/individual/Individual";
import KYCRegistration from "../pages/kyc/KYCRegistration";
import RenderPreview from "../pages/kyc/preview/MultiPreview";
import SuccessScreen from "../pages/kyc/successCreen/SuccessScree";
import Login from "../pages/login/Login";
import Otpverification from "../pages/otpVerification/Otpverification";
import UserRegistration from "../pages/registration/Registration";
import Signup from "../pages/signup/Signup";
import TermsAndConditionsPage from "../pages/tandc/Termsandconditions";
import Testing from "../pages/testing";
import UserProfile from "../pages/userProfile/ProfilePage";
import {
  RestrictDealDocument,
  RestrictEntityType,
  RestrictInitiatePayment,
  RestrictPlatformFee,
  RestrictRequestPayment,
  RestrictRequestReceived,
  RestrictTruupeAccount,
} from "./restrictedRoutes";

const isAuthenticated = () => {
  const accessToken = Cookies.get("access_token");
  return accessToken ? true : false;
};

export const isEntityVerified = () => {
  const localEntityVerification =
    localStorage.getItem("isEntityVerified") === "true" ? true : false;
  const accessToken: any = Cookies.get("access_token");
  const userdata: any = jwtDecode(accessToken);
  return userdata?.isEntityVerified || localEntityVerification ? true : false;
};

const ProtectedRoute: any = ({ component, componentType }: any) => {
  const isAuth = isAuthenticated();
  const DealRoutes = [
    "Deallayout",
    "newdeal",
    "createdeal",
    "createnewdeal",
    "invitationsuccess",
    "accepted",
    "DealById",
    "EscrowTransactions",
    "dealsDocumentation",
    "dealsDocumentationSignee",
    "dealsServiceFee",
    "accepted",
    "dealsEscrowAccount",
    "dealsDocumentationView",
    "DealProfilePage",
    "EscrowInitiatePayment",
    "EscrowAddFunds",
    "sellerRequestPayment",
    "requestReceived",
    "TransactionsMobile",
  ];
  const kycRoutes = [
    "KYCProcess",
    "Individual",
    "KycPreview",
    "SuccessScreen",
    "Home",
    "ResubmitDocuments",
    "ResubmitSuccess",
    "ProfilePage",
    "TermsAndConditionsPage",
  ];
  const authRoutes = ["Signup", "Login", "UserRegistration", "otp"];

  if (isAuth) {
    if (authRoutes.includes(componentType)) {
      return <Navigate to="/home" replace />;
    } else if (kycRoutes.includes(componentType)) {
      if (isEntityVerified()) {
        return <Navigate to="/deal-process/deals/all" replace />;
      } else {
        return component;
        // if (localStorage.getItem("isTermsAccepted") === "true") {
        //   return component;
        // } else {
        //   return <TermsAndConditionsPage />;
        // }
      }
    } else if (DealRoutes.includes(componentType)) {
      return isEntityVerified() ? component : <Navigate to="/home" replace />;
    }
  } else {
    Cookies.remove("access_token");
    Cookies.remove("entityId");
    if (authRoutes.includes(componentType)) {
      if (componentType !== "UserRegistration") {
        localStorage.clear();
        return component;
      } else {
        return component;
      }
    } else {
      localStorage.clear();
      return <Navigate to="/signup" replace />;
    }
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute componentType={"Signup"} component={<Signup />} />,
    errorElement: <NotFound></NotFound>,
  },

  {
    path: "/signup",
    element: <ProtectedRoute componentType={"Signup"} component={<Signup />} />,
  },

  {
    path: "/login",
    element: <ProtectedRoute componentType={"Login"} component={<Login />} />,
  },

  {
    path: "/register",
    element: (
      <ProtectedRoute
        componentType={"UserRegistration"}
        component={<UserRegistration />}
      />
    ),
  },
  {
    path: "/otp",
    element: (
      <ProtectedRoute componentType={"otp"} component={<Otpverification />} />
    ),
  },
  {
    path: "/testing",
    element: <Testing />,
  },
  {
    path: "/terms",
    element: (
      <ProtectedRoute
        componentType={"TermsAndConditionsPage"}
        component={<TermsAndConditionsPage />}
      />
    ),
  },

  {
    path: "/home",
    element: <ProtectedRoute componentType={"Home"} component={<Home />} />,
  },
  {
    path: "/view-desktop",
    element: <DesktopAlert />,
  },
  {
    path: "/access-denied",
    element: <AccessDenaid />,
  },
  {
    path: "/access-denied/location-help",
    element: <EnableLocation />,
  },

  {
    path: "/deal-process",
    element: (
      <ProtectedRoute componentType={"Deallayout"} component={<DealLayout />} />
    ),
    children: [
      {
        path: "user-profile",
        element: (
          <ProtectedRoute
            componentType={"DealProfilePage"}
            component={<UserProfile />}
          />
        ),
      },
      {
        path: "dashboard/new",
        element: (
          <ProtectedRoute
            componentType={"newdeal"}
            component={<NewdealDashboard />}
          />
        ),
      },
      {
        path: "deals/create",
        element: (
          <ProtectedRoute
            componentType={"createdeal"}
            component={<DealCreationForm />}
          />
        ),
      },
      {
        path: "deals/all",
        element: (
          <ProtectedRoute
            componentType={"createnewdeal"}
            component={<CreateNewDeal />}
          />
        ),
      },
      {
        path: "deals/:modelType/:dealId",
        element: (
          <ProtectedRoute
            componentType={"invitationsuccess"}
            component={<DealSuccess />}
          />
        ),
      },
      {
        path: "deals/accepted/:dealId",
        element: (
          <ProtectedRoute
            componentType={"accepted"}
            component={<DealAccepted />}
          />
        ),
      },
      {
        path: "deals/:dealId",
        element: (
          <ProtectedRoute componentType={"DealById"} component={<UserDeal />} />
        ),
      },
      {
        path: "deals/:dealId/transactions",
        element: (
          <ProtectedRoute
            componentType={"EscrowTransactions"}
            component={<EscrowTransactions />}
          />
        ),
      },
      {
        path: "deals/:dealId/documentation",
        element: (
          <ProtectedRoute
            componentType={"dealsDocumentation"}
            component={<DealDocumentation />}
          />
        ),
      },
      {
        path: "deals/:dealId/documentationSignee",
        element: (
          <>
            <RestrictDealDocument>
              <ProtectedRoute
                componentType={"dealsDocumentationSignee"}
                component={<DealDocumentationSignee />}
              />
            </RestrictDealDocument>
          </>
        ),
      },
      {
        path: "deals/:dealId/platform-fee",
        element: (
          <RestrictPlatformFee>
            <ProtectedRoute
              componentType={"dealsServiceFee"}
              component={<PayPlatformFee />}
            />
          </RestrictPlatformFee>
        ),
      },
      {
        path: "deals/:dealId/truupe-account",
        element: (
          <RestrictTruupeAccount>
            <ProtectedRoute
              componentType={"dealsEscrowAccount"}
              component={<EscrowTransactions />}
            />
          </RestrictTruupeAccount>
        ),
      },
      {
        path: "deals/:dealId/truupe-account/transactions",
        element: (
          <RestrictTruupeAccount>
            <ProtectedRoute
              componentType={"TransactionsMobile"}
              component={<TransactionsMobile />}
            />
          </RestrictTruupeAccount>
        ),
      },
      {
        path: "deals/:dealId/documentation-view",
        element: (
          <ProtectedRoute
            componentType={"dealsDocumentationView"}
            component={<DealDocumentationView />}
          />
        ),
      },
      {
        path: "deals/:dealId/request-received",
        element: (
          <RestrictRequestReceived>
            <ProtectedRoute
              componentType={"requestReceived"}
              component={<RequestReceived />}
            />
          </RestrictRequestReceived>
        ),
      },
      {
        path: "deals/:dealId/initiate-payment",
        element: (
          <RestrictInitiatePayment>
            <ProtectedRoute
              componentType={"EscrowInitiatePayment"}
              component={<AddFundsToSeller />}
            />
          </RestrictInitiatePayment>
        ),
      },
      {
        path: "deals/:dealId/request-payment",
        element: (
          <RestrictRequestPayment>
            <ProtectedRoute
              componentType={"sellerRequestPayment"}
              component={<SellerRequestPayment />}
            />
          </RestrictRequestPayment>
        ),
      },
    ],
  },

  {
    path: "/kycprocess",
    element: (
      <ProtectedRoute
        componentType={"KYCProcess"}
        component={<KycprocessLayout />}
      />
    ),
    children: [
      {
        path: "user-profile",
        element: (
          <ProtectedRoute
            componentType={"ProfilePage"}
            component={<UserProfile />}
          />
        ),
      },
      {
        path: "resubmit-documents",
        element: (
          <ProtectedRoute
            componentType={"ResubmitDocuments"}
            component={<ReSubmitDocuments />}
          />
        ),
      },
      {
        path: "resubmit-success",
        element: (
          <ProtectedRoute
            componentType={"ResubmitSuccess"}
            component={<ReSubmitSuccess />}
          />
        ),
      },
      {
        path: ":kyctype",
        element: (
          <RestrictEntityType>
            <ProtectedRoute
              componentType={"KYCProcess"}
              component={<KYCRegistration />}
            />
          </RestrictEntityType>
        ),
      },
      {
        path: "individual",
        element: (
          <RestrictEntityType>
            <ProtectedRoute
              componentType={"Individual"}
              component={<Individual />}
            />
          </RestrictEntityType>
        ),
      },
      {
        path: ":kyctype/preview",
        element: (
          <RestrictEntityType>
            <ProtectedRoute
              componentType={"KycPreview"}
              component={<RenderPreview />}
            />
          </RestrictEntityType>
        ),
      },
      {
        path: ":kyctype/success",
        element: (
          <RestrictEntityType>
            <ProtectedRoute
              componentType={"SuccessScreen"}
              component={<SuccessScreen />}
            />
          </RestrictEntityType>
        ),
      },
    ],
  },

  {
    path: "/esign-success",
    element: <Esign />,
  },
]);

function NotFound() {
  const navigate = useNavigate();
  return (
    <Box
      height={"100vh"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={"50%"} src={pageNotFound} alt="./loading..." />
      <Button
        sx={{ height: "50px", width: "150px", my: "20px" }}
        variant="contained"
        onClick={() => {
          navigate("/home");
        }}
      >
        Home
      </Button>
    </Box>
  );
}

export default router;
