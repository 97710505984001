import { Box, Card, styled } from "@mui/material";

export const DealBox = styled(Card)(({ theme }) => ({
  minHeight: "83vh",
}));

export const DealCard = styled(Card)(({ theme }) => ({
  borderRadius: "8px",
  padding: "20px",
  [theme.breakpoints.down("xl")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px",
  },
}));

export const DealBreadcrumbBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
