import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getDealsDataById,
  sellerPaymnetRequest,
} from "../../../../../../../api/services/deals";

import Quill from "../../../../../../../components/Quill/Quill";
import FileUploadContainer from "../../../../../../../components/shared/FileUploadContainer";
import DealInputField from "../../../../createDeal/DealInputField";

const SellerRequestPayment = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const { data: dealData }: any = useQuery(["deal", dealId], getDealsDataById, {
    onError: () => {
      navigate("/deal-process/deals/all");
    },
  });

  const balance =
    dealData?.data?.overview?.totalAmount - dealData?.data?.overview?.debit;

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    setError,
  } = useForm({});

  const { mutate: SellerPaymnetRequest } = useMutation(sellerPaymnetRequest, {
    onSuccess: (res: any) => {
      toast.success("Request Sent successfully");
      navigate(`/deal-process/deals/${dealId}/truupe-account`, {
        replace: true,
      });
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const submitRequest = () => {
    const dealCode = dealId;
    const amount = getValues("amount")?.replace(/,/g, "");
    const reasonForRequest = text;
    const mediaKey = getValues("document");

    if (!dealCode || !amount || !reasonForRequest) {
      toast.error("Please enter all the details");
      return;
    }

    const requestData = {
      dealCode,
      amount,
      reasonForRequest,
      mediaKey,
    };

    SellerPaymnetRequest(requestData);
  };

  const handleFileChange = (fileKey: any) => {
    setValue("document", fileKey);
  };

  const formatIndianIntegers = (num: any) => {
    num = num.toString();
    const [integerPart, decimalPart] = num.split(".");

    const lastThree = integerPart.substring(integerPart.length - 3);
    const otherNumbers = integerPart.substring(0, integerPart.length - 3);

    const formattedInteger =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      (otherNumbers ? "," : "") +
      lastThree;

    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  const handleChange = (value: any) => {
    setText(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          width: "800px",
          border: "1px solid #1212121A",
          borderRadius: "10px",
          opacity: 1,
          padding: "20px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          color="#7643EB"
          sx={{ cursor: "pointer" }}
        >
          <IconButton size="medium" onClick={() => navigate(-1)}>
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px" }}
          >
            <Link
              underline="hover"
              color="#7643EB"
              href="/deal-process/deals/all"
              variant="subtitle2"
            >
              Deals
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}`}
              variant="subtitle2"
            >
              {dealId}
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}/request-payment`}
              variant="subtitle2"
            >
              Request Payment
            </Link>
          </Breadcrumbs>
        </Box>
        <Typography variant="h3" sx={{ fontWeight: 600, my: "1vh" }}>
          {}
        </Typography>
        <Typography variant="h6">Payment Request Details</Typography>
        <Divider sx={{ marginBottom: "20px", my: "1vh" }} />
        {}
        <form>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <DealInputField
                title="Amount"
                rightEndAdornment={true}
                field={{
                  ...field,
                  onChange: (e: any) => {
                    let value = e.target.value.replace(/[^0.1-9]/g, "");

                    if (value.length > 15) {
                      value = value.slice(0, 15);
                    }

                    if (value === "") {
                      field.onChange(value);
                      clearErrors("amount");
                      return;
                    }

                    const numericValue = Number(value);

                    if (numericValue > balance) {
                      field.onChange(formatIndianIntegers(balance));

                      setError("amount", {
                        type: "manual",
                        message:
                          "Value must be between 10,000 and 10,00,00,000",
                      });
                      return;
                    } else {
                      clearErrors("amount");
                    }

                    const formatIndianNumber = (num: any) => {
                      const lastThree = num.substring(num.length - 3);
                      const otherNumbers = num.substring(0, num.length - 3);
                      return (
                        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
                        (otherNumbers ? "," : "") +
                        lastThree
                      );
                    };
                    const data = value.split(".");

                    const formattedValue = formatIndianNumber(data[0]);

                    const finalData =
                      formattedValue +
                      (data[1] !== undefined ? "." + data[1]?.slice(0, 2) : "");
                    field.onChange(finalData);
                  },
                }}
                placeholder="Enter Amount"
                iD={"Deal Amount"}
                errors={errors}
              />
            )}
          />
          <Typography sx={{ color: "red" }}>
            You can Request up to a maximum of ₹ {formatIndianIntegers(balance)}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1vh",
            }}
          >
            <Typography variant="caption" sx={{ color: "#00000099" }}>
              Description
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <Quill data={text} handleChange={(value) => handleChange(value)} />
          </Box>
          <Box my={"2vh"}>
            <Typography variant="caption" sx={{ color: "#00000099" }}>
              Supporting Documents (Ex: Invoice)
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FileUploadContainer
              onFileChange={handleFileChange}
              typeData="DEAL"
              data="PAYMENT_REQUEST"
              uploaded={false}
              url=""
              filetype="pdf"
              actions={null}
              status={false}
              typeId={dealId}
              isDeleted={setIsDeleted}
            />
          </Box>
          <Button
            variant="contained"
            onClick={submitRequest}
            disabled={
              !getValues("amount") ||
              !text ||
              text == "<p><br></p>" 
              // || !isDeleted
            }
          >
            submit
          </Button>
        </form>
      </Card>
    </Box>
  );
};

export default SellerRequestPayment;
