import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DealFeePending({ data, secondUser }: any) {
  const navigate = useNavigate();
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);

  // const { mutate: payServiceBipass } = useMutation(createOrderBipass, {
  //   onSuccess: (res: any) => {
  //     toast.success(res?.data?.message);
  //     setOpenModel(true);
  //     setLoading(false);
  //   },
  //   onError: (error: any) => {
  //     toast.error(error?.response?.data?.message);
  //     setLoading(false);
  //   },
  // });

  const handlePayPlatformFee = () => {
    navigate(`/deal-process/deals/${data?.code}/platform-fee`, {
      replace: true,
    });
    // setLoading(true);
    // payServiceBipass({
    //   code: data?.code,
    // });
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Pay Platform Fee
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          {secondUser?.userType === "Buyer" ? (
            <>
              The deal document has been successfully verified by Trustee. The
              next step is for
              {secondUser?.userType === "Buyer" ? "the buyer" : "you"}
              to pay the platform fee in order to create an account. Click the
              “Pay Platform Fee” button to submit your payment.
            </>
          ) : (
            "Please pay the Platform Fee to create the Deal Account. After you have paid the fee we shall provide you the Deal Account details to proceed further."
          )}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        onClick={handlePayPlatformFee}
        sx={{ width: "auto", mt: 2 }}
        disabled={loading} // Disable the button when loading
      >
        {loading ? <CircularProgress size={24} /> : "Pay Platform Fee"}
      </Button>

      {/* <Congrats open={openModel} inDealDocument={false} /> */}
    </Box>
  );
}
