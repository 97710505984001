import { Box, Stack, Typography } from "@mui/material";

export default function RequestPayment({ data, secondUser, pay }: any) {
  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Request Payment
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          You can now request payment for this Deal. Once the Buyer accepts the
          payment, the funds will be securely transferred to your account.
        </Typography>
      </Stack>
    </Box>
  );
}
