import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Card,
  Divider,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

const RefreshButton = styled.button`
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: inherit;
  cursor: pointer;
  outline: none;

  .refresh-icon.rotating {
    animation: ${rotate} 1s infinite;
    animation-timing-function: cubic-bezier(0.42, 0.2, 0.58, 1);
  }
`;

type DealCardProps = {
  icon: string;
  label: string;
  value: string;
  status: string;
  progressText: string;
  progress: number;
  showRefresh?: boolean;
  onRefresh?: () => void;
};

const DealCard = ({
  icon,
  label,
  value,
  status,
  progressText,
  progress,
  showRefresh = false,
  onRefresh,
}: DealCardProps) => {
  const [isRotating, setIsRotating] = useState(false);

  const handleRefreshClick = () => {
    if (onRefresh) {
      setIsRotating(true);
      onRefresh();
      setTimeout(() => setIsRotating(false), 1000);
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F3EFFF",
        borderRadius: "10px",
        border: "1px solid #1212121A",
        width: "100%",
        // padding: "4%",
      }}
    >
      <Box
        sx={{
          padding: {
            xs: "4% 4% 3% 4%",
            sm: "4% 4% 3% 4%",
            md: "4% 3% 3% 3%",
            lg: [
              "Invitation Issued",
              "Deal Accepted",
              "Deal Rejected",
              "Signature Pending",
              "Verification Pending",
              "Fee Pending",
            ].includes(status)
              ? "1% 1% 1% 1%"
              : "4% 3% 3% 3%",
            xl: [
              "Invitation Issued",
              "Deal Accepted",
              "Deal Rejected",
              "Signature Pending",
              "Verification Pending",
              "Fee Pending",
            ].includes(status)
              ? "1% 1% 1% 1%"
              : "4% 3% 3% 3%",
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row" gap="30px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="20%"
              bgcolor="rgba(106, 27, 154, 0.1)"
              sx={{
                height: { xs: "40px", sm: "40px", md: "60px", lg: "60px" },
                width: { xs: "40px", sm: "40px", md: "60px", lg: "60px" },
              }}
            >
              <img
                src={icon}
                style={{ height: "75%", width: "75%" }}
                alt="Icon"
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography
                sx={{
                  fontWeight: 500,
                  typography: {
                    xs: "subtitle1",
                    sm: "subtitle1",
                    md: "h6",
                    lg: "h6",
                  },
                }}
              >
                ₹ {value}
              </Typography>
              <Typography
                sx={{
                  opacity: 0.6,
                  typography: {
                    xs: "body2",
                    sm: "body2",
                    md: "subtitle2",
                    lg: "subtitle2",
                  },
                }}
              >
                {label}
              </Typography>
            </Box>
          </Box>
          {showRefresh && (
            <RefreshButton onClick={handleRefreshClick}>
              <Tooltip title="Refresh Balance">
                <RefreshIcon
                  className={`refresh-icon ${isRotating ? "rotating" : ""}`}
                  fontSize="small"
                  color="primary"
                />
              </Tooltip>
            </RefreshButton>
          )}
        </Stack>

        <Divider sx={{ my: "10px" }} />
        <Typography
          sx={{
            opacity: 0.6,
            textAlign: "right",
            typography: {
              xs: "subtitle2",
              sm: "subtitle2",
              md: "subtitle1",
              lg: "subtitle1",
            },
          }}
        >
          {progressText}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress || 0}
          sx={{ mt: "10px", mb: "5px" }}
        />
      </Box>
    </Card>
  );
};

export default DealCard;
