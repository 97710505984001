import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Icons } from "../../../assets";

const DashedBox = styled(Box)({
  marginLeft: "26px",
  borderLeft: "2px dashed grey",
  height: "3vh",
});

const TimelineItemContent = styled(TimelineContent)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

type HomeTimelineProps = {
  timeLineData: any;
  isMobile?: boolean;
};

function formatDate(inputDate: any) {
  const dateObj = new Date(inputDate);

  const year = dateObj.getFullYear();
  const month = dateObj
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes}${ampm}`;

  return formattedDate;
}

function TimelineEntry({ description, createdAt, isFirst }: any) {
  const formattedTime = formatDate(createdAt);

  return (
    <>
      {!isFirst && <DashedBox />}
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: "white" }}>
            <img width={"40"} src={Icons.normaCheck} alt="loading..." />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineItemContent>
          <Typography sx={{ fontSize: isFirst ? "14px" : "12pxvw" }}>
            {description}
          </Typography>
          <Typography
            sx={{ fontSize: isFirst ? "10px" : "8px", color: "grey" }}
          >
            {formattedTime}
          </Typography>
        </TimelineItemContent>
      </TimelineItem>
    </>
  );
}

export default function HomeTimeline({
  timeLineData,
  isMobile,
}: HomeTimelineProps) {
  const TimelineBox = styled(Box)({
    marginTop: isMobile ? "0px" : "10%",
    width: isMobile ? "100%" : "300px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    display: "flex",
    backgroundColor: "white",
    overflowY: "auto",
  });

  return (
    <TimelineBox>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {timeLineData?.map((item: any, index: any) => (
          <TimelineEntry
            key={index}
            description={item?.description}
            createdAt={item?.createdAt}
            isFirst={index === 0}
          />
        ))}
      </Timeline>
    </TimelineBox>
  );
}
