import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Cookies from "js-cookie";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteComapanyDetailsById } from "../../api/services";
import { kycDetailsStore } from "../../store/kycProcess";

interface StateProps {
  msg: string;
  action: () => void;
}

type ContextProps = (args: StateProps) => void;

export const ConfirmDialogContext = React.createContext<ContextProps>(() => {});

function ConfirmDialogProvider({ children, endPoint }: any) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<StateProps>({
    msg: "",
    action: () => {},
  });

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: DelateData, isLoading: deleteLoader } = useMutation(
    deleteComapanyDetailsById,
    {
      onSuccess: (res: any) => {
        handleClose();
        toast.success(res?.data?.data?.message);
        queryClient.invalidateQueries("status-data");
        queryClient.invalidateQueries("getcompanydetails");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = " ";
        });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onOk = () => {
    state.action();
    const EntityId = Cookies.get("entityId");
    if (EntityId) {
      DelateData(EntityId);
      Cookies.remove("entityId");
      navigate(endPoint, { replace: true });
    } else {
      kycDetailsStore.update((s) => {
        s.lastUpdatedAt = " ";
      });
      handleClose();
    }
  };

  const confirm = (args: StateProps) => {
    const { msg, action } = args;
    setOpen(true);
    setState({
      msg,
      action,
    });
  };

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <Box p={2}>
          <Box>
            <Typography color="primary" gutterBottom variant="subtitle1">
              Warning
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {state.msg}
            </Typography>
          </Box>
          <Box mt={3} display="flex" gap={2} justifyContent="flex-end">
            <Button variant="outlined" size="small" onClick={onOk}>
              Leave Anyway
            </Button>

            {deleteLoader ? (
              <>
                <LoadingButton
                  loading
                  loadingPosition="center"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  sx={{
                    border: "1px solid black",
                    "& .MuiCircularProgress-root": {
                      color: "#7643EB",
                    },
                    "&.MuiButton-outlined": {
                      border: "2px solid #7643EB",
                    },
                  }}
                ></LoadingButton>
              </>
            ) : (
              <>
                <Button variant="contained" size="small" onClick={handleClose}>
                  Stay Back
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
}

export const useConfirm = () => React.useContext(ConfirmDialogContext);

export default ConfirmDialogProvider;
