import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Icons } from "../../../assets";

export default function VerificationRejectedCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        display: "flex",
        padding: "15px",
        borderRadius: "10px",
        backgroundColor: "#FBE7E5",
        marginRight: "1%",
        alignItems: "center",
      }}
    >
      {!isMobile && (
        <img
          width={"30px"}
          height={"30px"}
          src={Icons.error}
          alt="loading..."
        />
      )}

      <Typography
        variant="body1"
        sx={{
          marginLeft: "20px",
          color: "GrayText",
          fontWeight: "300",
          fontSize: "16px",
          width: "96%",
        }}
      >
        {isMobile ? (
          <>
            Please re-submit the required documents below to complete your
            verification smoothly.
          </>
        ) : (
          <>
            Uh-oh! It looks like there's a hiccup in your KYC/KYB verification
            process. The TruuPe team has identified an issue or requires
            additional documents to finalize your verification. Please re-submit
            the required documents to complete your verification smoothly.
          </>
        )}
      </Typography>
    </Box>
  );
}
