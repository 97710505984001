import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { accessDenied } from "../../assets";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  border: "none",
  outline: "none",
};

interface LocationModelProps {
  openModel: boolean;
  setOpenModel: Function;
}

export default function LocationModel({
  openModel,
  setOpenModel,
}: LocationModelProps) {
  const handleClose = () => setOpenModel(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box sx={style}>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              my: "3vh",
            }}
          >
            <img
              style={{
                width: isMobile ? "70vw" : "60%",
                height: isMobile ? "30vh" : "auto",
              }}
              src={accessDenied}
              alt="loading...."
            />
            <Typography
              variant={isMobile ? "h5" : "h1"}
              sx={{ marginTop: "1vh", fontWeight: "500" }}
            >
              Access Denied!
            </Typography>
          </Stack>
          <Typography
            variant="subtitle2"
            id="modal-modal-description"
            sx={{ color: "red", textAlign: "center", marginTop: -2 }}
          >
            To use this application, we need access to your location. Please
            enable location services in your browser settings.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
