import { Box, Stack, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Icons } from "../../../assets";

type ItemProps = {
  item: { title: string; content: string[] };
};
function Item(props: ItemProps) {
  return (
    <Box
      sx={{
        alignItems: "center",
        borderRadius: "5px",
        paddingLeft: 2,
        marginTop: "10px",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "600", marginBottom: 2 }}
      >
        {props.item.title}
      </Typography>
      {props.item.content.map((c) => (
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            mt: "10px",
            paddingLeft: 1,
          }}
        >
          <img width="24px" src={Icons.pdfIcon} alt="....loading" />

          <Typography
            variant="body1"
            sx={{
              color: "GrayText",
              fontWeight: "500",
              paddingLeft: 1,
            }}
          >
            {c}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
}

function CarouselComponent() {
  const items = [
    {
      title: "Private/ Public Limited",
      content: [
        "AOA Document",
        "MOA Document",
        "Company PAN",
        "GSTIN Certificate",
        "AADHAR Card",
        "PAN Card",
        "Address Proof",
      ],
    },
    {
      title: "Limited Liability Partnership",
      content: [
        "Certificate of Incorporation",
        "AOA Document",
        "MOA Document",
        "Company PAN",
        "GSTIN Certificate",
        "AADHAR Card",
        "PAN Card",
        "Address Proof",
      ],
    },
    {
      title: "Partnership Firm ",
      content: [
        "Registration Certificate",
        "Partnership Deed",
        "Company PAN Card",
        "GSTIN Certificate",
        "Partnership Information",
        "Beneficial Owner",
        "Business Address Proof",
        "AADHAR Card",
        "PAN Card",
        "Address Proof",
      ],
    },
    {
      title: "Sole Proprietorship",
      content: [
        "Business Address Proof",
        "UDYAM Registration Certificate",
        "Trade License",
        "PAN Card",
        "GSTIN Certificate",
        "Professional Tax Registration",
        "Importer Exporter Code",
      ],
    },
    {
      title: "Individual",
      content: ["AADHAR Card", "PAN Card", "Address Proof"],
    },
  ];

  return (
    <Carousel
      autoPlay={true}
      animation="slide"
      indicators={true}
      sx={{
        height: "420px",
        border: "1px solid #ccc",
        width: "250px",
        borderRadius: "10px",
        backgroundColor: "white",
      }}
      indicatorContainerProps={{
        style: {
          position: "absolute",
          alignContent: "end",
          bottom: 8,
        },
      }}
    >
      {items.map((item, i) => (
        <Box sx={{ height: "425px" }}>
          <Item key={i} item={item} />
        </Box>
      ))}
    </Carousel>
  );
}

export default CarouselComponent;
