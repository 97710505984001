import { Box, Stack, Typography } from "@mui/material";

export default function AwaitingEscrowFunds({ data, secondUser }: any) {
  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Buyer to Pay Platform Fee
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The Deal Document has been successfully verified by Trustee. The next
          step is for the Buyer to pay the platform fee in order to create
          account.
        </Typography>
      </Stack>
    </Box>
  );
}
