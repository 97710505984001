import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Slide,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
  Accordion,
  styled,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getUserProfile } from "../../../../../api/services";
import { acceptDeal } from "../../../../../api/services/deals";

import { CustomLoadingButton } from "../../../../../components/buttons/SubmitLoaderButton";
import SignatoryDirectors from "../../createDeal/SignatoryDirectors";
import BankingDetails from "../../createDeal/DealBankingInformation";
import FileUploadContainer from "../../../../../components/shared/FileUploadContainer";
import SimpleCheckoBox from "../../../../../components/formFields/SimpleCheckoBox";
import { query } from "express";
import { getFirmDocumentData } from "../../../../../utils/Kycprocessutils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IndividualSellerDealApproveProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  secondUser?: any;
  deal?: any;
}

const stylesBefore = {
  width: "230px",
  height: "100px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "230px",
  height: "100px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

const StyledAccordion = styled(Accordion)({
  "&:hover": { backgroundColor: "transparent" },
  boxShadow: "none",
});

const IndividualSellerDealApprove: React.FC<
  IndividualSellerDealApproveProps
> = ({ open, onClose, data, secondUser, deal }) => {
  const [showPart, setShowPart] = useState(1); // State to track which part is shown
  const [slideDirection, setSlideDirection] = useState<"left" | "right">(
    "left"
  );
  const [infoCheckState, setInfoCheckState] = useState(false);
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const [expandedAccordion, setExpandedAccordion] = useState("terms");

  const handleAccordionChange = (accordionName: any) => {
    setExpandedAccordion((prevAccordion) =>
      prevAccordion === accordionName ? null : accordionName
    );
  };

  const navigate = useNavigate();

  const { mutate: accept, isLoading } = useMutation(acceptDeal, {
    onSuccess: (res: any) => {
      toast.success("Deal accepted successfully");
      navigate(`/deal-process/deals/deal-accepted/${data?.code}`);
      onClose();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);
  const [signDirector, setSignDirector] = useState();
  const [bankingId, setBankingId] = useState("");
  const [isFileDeleted, setIsFileDeleted] = useState(true);
  const [uploadKey, setUploadKey] = useState<string | null>(null);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      infoCheck: infoCheckState,
    },
  });
  const infoCheck = watch("infoCheck");
  // const isNextEnabled = !isFileDeleted && infoCheck;
  const isNextEnabled = infoCheck;
  const bankingData = profileData?.data?.entities[0]?.bankingInformation;

  const signatoryDirectors = profileData?.data?.entities[0]?.directors?.filter(
    (item: any) => item.isSignatoryAuthority
  );

  const dealSupportingDocuments = data?.dealSupportingDocuments;

  const handleAccept = () => {
    if (data?.code) {
      accept({
        data: {
          bankingId: bankingId,
          signatoryId: signDirector,
          dealKeys: [uploadKey],
        },
        id: data?.code,
      });
    } else {
      accept({
        data: {
          bankingId: bankingId,
          signatoryId: signDirector,
          dealKeys: [uploadKey],
        },
        id: deal?.code,
      });
    }
  };

  const handleNext = () => {
    setSlideDirection("left");
    setShowPart(2);
    setValue("infoCheck", infoCheckState);
  };

  const handleBack = () => {
    setSlideDirection("right");
    setShowPart(1);
    setValue("infoCheck", infoCheckState);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "80%", md: "60%", lg: "50%" },
          bgcolor: "background.paper",
          borderRadius: "15px",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          overflow: "hidden",
          height: "30pc",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: -40,
            right: 0,
            bgcolor: "background.paper",
            borderRadius: "50%",
            height: 25,
            width: 25,
          }}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        {/* Container to hold both sliding components */}
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        >
          <Slide
            direction={
              slideDirection === "left" && showPart === 1 ? "left" : "right"
            }
            in={showPart === 1}
            mountOnEnter
            unmountOnExit
            timeout={500}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "25pc",
              }}
            >
              <Box>
                <Typography variant="body1" color="textSecondary">
                  You are accepting the deal as a seller, Please review the
                  terms and conditions to proceed..
                </Typography>
                <StyledAccordion
                  expanded={expandedAccordion === "terms"}
                  onChange={() => handleAccordionChange("terms")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="subtitle2">
                      Terms and Conditions
                    </Typography>
                  </AccordionSummary>
                  <Divider sx={{ mt: -1 }} />
                  <AccordionDetails
                    sx={{ paddingLeft: "0", paddingBottom: "0" }}
                  >
                    <Typography
                      variant="body1"
                      paragraph
                      align="left"
                      textAlign="justify"
                      maxHeight={{
                        xs: "7pc",
                        sm: "10pc",
                      }}
                      overflow={"scroll"}
                    >
                      <ol
                        type="1"
                        style={{ paddingLeft: "15px", paddingRight: "5px" }}
                      >
                        <li>
                          You acknowledge that you have read, understood, and
                          agreed to be bound by Truupe Terms of Use and Privacy
                          Policy.
                        </li>
                        <li>
                          These terms govern your use of the Truupe Platform and
                          any related services.
                        </li>
                        <li>
                          If you do not agree to these terms, please do not use
                          the website.
                        </li>
                      </ol>
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expandedAccordion === "supporting"}
                  onChange={() => handleAccordionChange("supporting")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="subtitle2">
                      Supporting Document (if any)
                    </Typography>
                  </AccordionSummary>
                  <Divider sx={{ mt: -1 }} />
                  <AccordionDetails>
                    <FileUploadContainer
                      typeId={data?.id}
                      actions={<></>}
                      subType={"INDIVIDUAL_SELLER"}
                      data={"INDIVIDUAL_SELLER"}
                      typeData={"DEAL"}
                      isSupporting={"true"}
                      uploaded={
                        !!dealSupportingDocuments?.filter(
                          (i: any) => i?.subtype === "INDIVIDUAL_SELLER"
                        )?.length
                      }
                      name={getFirmDocumentData(
                        dealSupportingDocuments,
                        "INDIVIDUAL_SELLER",
                        "originalFileName"
                      )}
                      url={getFirmDocumentData(
                        dealSupportingDocuments,
                        "INDIVIDUAL_SELLER",
                        "url"
                      )}
                      onFileChange={(key) => {
                        if (key === "") {
                          setIsFileDeleted(true);
                        } else {
                          setIsFileDeleted(false);
                          // setUploadKey(key);
                          queryClient.invalidateQueries("deal");
                        }
                      }}
                      {...(isMobile || isTablet
                        ? {
                            stylesBefore: stylesBefore,
                            stylesAfter: stylesAfter,
                            shrinked: true,
                          }
                        : {})}
                    />
                  </AccordionDetails>
                </StyledAccordion>
              </Box>
              <Controller
                name="infoCheck"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      position: "absolute",
                      left: 0,
                      bottom: "35px",
                    }}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.checked);
                          setInfoCheckState(event.target.checked);
                        }}
                      />
                    }
                    label="I accept all the terms and conditions."
                  />
                )}
              />
            </Box>
          </Slide>
          {/* )} */}

          {/* Slide 2: Second part */}
          <Slide
            direction={
              slideDirection === "right" && showPart === 2 ? "right" : "left"
            }
            in={showPart === 2}
            mountOnEnter
            unmountOnExit
            timeout={500} // Optional: Adjust duration of animation
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "25pc",
              }}
            >
              <Box>
                <BankingDetails
                  bankingData={bankingData}
                  setBankingId={setBankingId}
                  profile={profileData?.data}
                />
                <SignatoryDirectors
                  directorsData={signatoryDirectors}
                  setSignDirector={setSignDirector}
                  profile={profileData?.data}
                />
              </Box>
            </Box>
          </Slide>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          {showPart === 1 ? (
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ width: "120px" }}
            >
              Close
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ width: "120px" }}
            >
              Back
            </Button>
          )}

          {showPart === 1 ? (
            <CustomLoadingButton
              onClick={handleNext}
              title="Next"
              submitLoader={false}
              width="120px"
              isDisabled={!isNextEnabled}
            />
          ) : (
            <CustomLoadingButton
              onClick={handleAccept}
              title="Submit"
              submitLoader={isLoading}
              width="120px"
            />
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default IndividualSellerDealApprove;
