import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Typography } from "@mui/material";

import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  AddCompanyDetails,
  getCinDetails,
  getComapanyDetailsById,
  getGstDetails,
  getPanDetails,
  userActivity,
} from "../../../api/services";
import { Icons } from "../../../assets";

import Editbutton from "../../../components/buttons/Editbutton";
import GetDetailsButton from "../../../components/buttons/Getdetails";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import AutocompleteField from "../../../components/formFields/kycFields/AutocompleteField";
import InputField from "../../../components/formFields/kycFields/InputField";
import { kycDetailsStore } from "../../../store/kycProcess";

type Info = {
  category: string;
  llpinNumber: string;
  companyName: string;
  registeredAddress: string;
  dateOfIncorporation: string;
  emailAddress: string;
  gstNumber: string;
  panNumber: string;
  contactAddress: string;
  RegistrationNumber: string;
  ROCCode: string;
  iecCode: string;
  subCategory: string;
};

const schema = yup.object().shape({
  subCategory: yup.string().required("Business Category is required"),
  category: yup.string().required("Business Category is required"),
  llpinNumber: yup
    .string()
    .typeError("CIN Number must be a number")
    .required("CIN Number is required"),
  companyName: yup.string().required("Firm Name is required"),
  registeredAddress: yup
    .string()
    .required("Register Office Address is required"),
  RegistrationNumber: yup
    .string()
    .required("Register Registration Number is required"),
  ROCCode: yup.string().required("Register ROC Code is required"),

  dateOfIncorporation: yup
    .string()
    .typeError("Date of Incorporation must be a date")
    .required("Date of Incorporation is required"),
  emailAddress: yup
    .string()
    .email("Invalid email format")
    .required("Email Address is required"),
  gstNumber: yup
    .string()
    .typeError("GST Number must be a string")
    .required("GST Number is required"),
  panNumber: yup
    .string()
    .typeError("PAN Number must be a string")
    .required("PAN Number is required"),
  iecCode: yup.string().required("IEC Code is required"),
  contactAddress: yup
    .string()
    .typeError("Contact Address must be a string")
    .required("Contact Address is required"),
});

const CompanyInformationForm = ({
  value,
  setValues,
  onNextClick,
  tick,
}: any) => {
  const [show, setShow] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [Cindissable, setCindissable] = useState(false);
  const [Gstdissable, setGstdissable] = useState(false);
  const [tickcompanyname, settickcompanyname] = useState(false);
  const [tickcompanyGstin, settickcompanGstin] = useState(false);
  const [NoGst, setNoGst] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const EntityId = Cookies.get("entityId");

  const queryClient = useQueryClient();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<Info>({
    resolver: yupResolver(schema),
  });

  const { data: CompanyDetails } = useQuery(
    ["getcompanydetails", EntityId],
    getComapanyDetailsById,
    {
      enabled: !!EntityId,
    }
  );

  useEffect(() => {
    if (CompanyDetails && EntityId) {
      setdisabled(true);

      reset({
        subCategory: CompanyDetails?.data?.subCategory,
        category: CompanyDetails?.data?.category,
        llpinNumber: CompanyDetails?.data?.llpinNumber,
        companyName: CompanyDetails?.data?.companyName,
        registeredAddress: CompanyDetails?.data?.registeredAddress,
        dateOfIncorporation: CompanyDetails?.data?.dateOfIncorporation,
        emailAddress: CompanyDetails?.data?.emailAddress,
        gstNumber: CompanyDetails?.data?.gstNumber,
        panNumber: CompanyDetails?.data?.panNumber,
        contactAddress: CompanyDetails?.data?.contactAddress,
      });
    }
  }, [CompanyDetails, EntityId, reset]);

  const moment = require("moment");

  const { mutate: getcindetails, isLoading: Cinloader } = useMutation(
    getCinDetails,
    {
      onSuccess: (res: any) => {
        const LLPINdata = res?.data?.data;
        const companyname = LLPINdata?.company_name;
        const registeredaddress =
          LLPINdata?.details?.company_info?.registered_address;
        const dateofincorporatiion =
          LLPINdata?.details?.company_info?.date_of_incorporation;
        const emailid = LLPINdata?.details?.company_info?.email_id;
        const ROCCode = LLPINdata?.details?.company_info?.roc_code;
        const RegistrationNumber =
          LLPINdata?.details?.company_info?.registration_number;
        const iecCode = settickcompanyname(true);
        setValue("companyName", companyname);
        setValue("registeredAddress", registeredaddress);
        setValue("dateOfIncorporation", dateofincorporatiion);
        setValue("emailAddress", emailid);
        setValue("ROCCode", ROCCode);
        setValue("RegistrationNumber", RegistrationNumber);

        const dateTime = moment(Date());
        const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = savedDateTime;
        });

        setCindissable(true);
        setShow(true);
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter CIN Number");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: getpandetails, isLoading: Panloader } = useMutation(
    getGstDetails,
    {
      onSuccess: (res: any) => {
        const type = res?.data?.data?.constitution_of_business;
        if (type === "Partnership") {
          const GstData = res?.data?.data;
          const PANName = GstData?.pan_number;
          const ContactAddress = GstData?.address;
          const Bussinessname = GstData?.business_name;
          const Address = GstData?.address;
          const DateOfRegistraion = GstData?.date_of_registration;
          settickcompanGstin(true);
          setValue("panNumber", PANName);
          setValue("contactAddress", ContactAddress);
          setValue("companyName", Bussinessname);
          setValue("registeredAddress", Address);
          setValue("dateOfIncorporation", DateOfRegistraion);

          setGstdissable(true);
        } else {
          toast.error(
            `Incorrect GST entered: ${getValues(
              "gstNumber"
            )} number is for ${type}, Please enter correct GST number or change the entity type.`
          );
        }
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter GST Number");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: useractivity } = useMutation(userActivity, {
    onSuccess: (res: any) => {},
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: postcompanydetails, isLoading: submitloader } = useMutation(
    AddCompanyDetails,
    {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        const accessToken: any = Cookies.get("access_token");
        const userdata: any = jwtDecode(accessToken);
        const entityId = res?.data?.data?.id;
        Cookies.set("entityId", entityId);
        queryClient.invalidateQueries("getcompanydetails");
        queryClient.invalidateQueries("getuser-id");
        const draftdata = {
          userId: userdata?.userId,
          activity: "KYB/KYC Process in Draft",
          entityId: entityId,
        };
        useractivity(draftdata);
        window.location.reload();
        // onNextClick();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = (data: Info) => {
    const finalData = { ...data, companyType: "PARTNERSHIP" };
    postcompanydetails(finalData);
  };

  const Submitdata = () => {
    const data = {
      subCategory: getValues("subCategory") || null,
      category: getValues("category"),
      companyName: getValues("companyName"),
      registeredAddress: getValues("registeredAddress"),
      dateOfIncorporation: getValues("dateOfIncorporation"),
      emailAddress: getValues("emailAddress"),
      gstNumber: getValues("gstNumber"),
      panNumber: getValues("panNumber"),
      contactAddress: getValues("registeredAddress"),
      registrationNumber: getValues("RegistrationNumber"),
      companyType: "PARTNERSHIP",
    };
    postcompanydetails(data);
  };

  const { mutate: verifyPan, isLoading: verifyPanloader } = useMutation(
    getPanDetails,
    {
      onSuccess: (res: any) => {
        setPanVerified(true);
        setValue("companyName", res?.data?.data?.full_name);
        setValue("emailAddress", res?.data?.data?.email);
        const dateTime = moment(Date());
        const savedDateTime = dateTime.format("DD MMM YYYY [at] hh:mm A");
        kycDetailsStore.update((s) => {
          s.lastUpdatedAt = savedDateTime;
        });
      },

      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter PAN Number");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const optionsArray = [
    { name: "Agriculture & Forestry", category: "Agriculture & Forestry" },
    { name: "Arts, Crafts & Hobbies", category: "Arts, Crafts & Hobbies" },
    { name: "Energy & Utilities", category: "Energy & Utilities" },
    { name: "Environmental Services", category: "Environmental Services" },
    { name: "Fashion & Apparel", category: "Fashion & Apparel" },
    { name: "Finance & Banking", category: "Finance & Banking" },
    { name: "Food & Beverage", category: "Food & Beverage" },
    {
      name: "Healthcare & Pharmaceuticals",
      category: "Healthcare & Pharmaceuticals",
    },
    { name: "Hospitality & Tourism", category: "Hospitality & Tourism" },
    { name: "Manufacturing", category: "Manufacturing" },
    { name: "Media & Entertainment", category: "Media & Entertainment" },
    { name: "Mining & Metals", category: "Mining & Metals" },
    {
      name: "Non-Profit & Social Services",
      category: "Non-Profit & Social Services",
    },
    {
      name: "Other",
      category: "Other",
    },
    {
      name: "Professional Services (Legal, Consulting, etc.)",
      category: "Professional Services (Legal, Consulting, etc.)",
    },
    {
      name: "Real Estate & Construction",
      category: "Real Estate & Construction",
    },
    { name: "Retail & Consumer Goods", category: "Retail & Consumer Goods" },
    { name: "Technology & IT Services", category: "Technology & IT Services" },
    { name: "Telecommunications", category: "Telecommunications" },
    {
      name: "Transportation & Logistics",
      category: "Transportation & Logistics",
    },
    { name: "Education & Training", category: "Education & Training" },
  ];

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          width: "64vw",
          height: "60vh",
          paddingLeft: 4,
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ my: "1vh", fontWeight: "500", color: "black" }}
        >
          Entity Information
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={{ color: "#8D8E88" }}>
              Entity Category
            </Typography>
            <AutocompleteField
              name="category"
              control={control}
              options={optionsArray}
              label="Category"
              placeholder="Select Option"
              disabled={CompanyDetails?.data?.status?.company}
            />

            {(watch("category") === "Other" ||
              getValues("category") === "Other") && (
              <Controller
                name="subCategory"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={
                      CompanyDetails?.data?.status?.company || tickcompanyname
                    }
                    field={{
                      ...field,
                      onChange: (e: any) => field.onChange(e.target.value),
                    }}
                    iD={"subCategory"}
                    name={"Sub Category"}
                    errors={errors}
                  />
                )}
              />
            )}

            {false && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Controller
                  name="llpinNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={
                        CompanyDetails?.data?.status?.company || tickcompanyname
                      }
                      field={field}
                      iD={"cinNumber"}
                      name={"LLPIN Number"}
                      errors={errors}
                    />
                  )}
                />

                <>
                  {" "}
                  {Cinloader ? (
                    <>
                      <CircularProgress
                        sx={{
                          marginLeft: "25px",
                          marginTop: "15px",
                        }}
                        size={"2rem"}
                        color="primary"
                      />
                    </>
                  ) : (
                    <>
                      {tickcompanyname ||
                      CompanyDetails?.data?.status?.company ? (
                        <Box display={"flex"} alignItems={"center"}>
                          <Box
                            marginTop={"2vh"}
                            sx={{
                              marginTop: "12px",
                              display: " flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              height={"25px"}
                              src={Icons.greenCheck}
                              alt="loading...."
                            />
                          </Box>

                          {!CompanyDetails?.data?.status?.company && (
                            <Box marginTop={"2vh"}>
                              <Editbutton onclick={settickcompanyname} />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box marginTop={"3vh"}>
                          <GetDetailsButton
                            title="Get Details"
                            onclick={() => {
                              const cinnumber = getValues().llpinNumber;

                              getcindetails({
                                id_number: cinnumber,
                                entityType: "PARTNERSHIP",
                              });
                            }}
                          ></GetDetailsButton>
                        </Box>
                      )}
                    </>
                  )}
                </>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Controller
              name="gstNumber"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled={
                    CompanyDetails?.data?.status?.company ||
                    tickcompanyGstin ||
                    NoGst
                  }
                  field={{
                    ...field,
                    onChange: (e: any) =>
                      field.onChange(e.target.value.toUpperCase()),
                  }}
                  iD={"gstNumber"}
                  name={"GST Number"}
                  errors={errors}
                />
              )}
            />

            {Panloader ? (
              <>
                <CircularProgress
                  sx={{
                    marginLeft: "25px",
                    marginTop: "15px",
                  }}
                  size={"2rem"}
                  color="primary"
                />
              </>
            ) : (
              <>
                {tickcompanyGstin || CompanyDetails?.data?.status?.company ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <Box
                      marginTop={"2vh"}
                      sx={{
                        display: " flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        height={"25px"}
                        src={Icons.greenCheck}
                        alt="loading...."
                      />
                    </Box>

                    {!CompanyDetails?.data?.status?.company && (
                      <Box marginTop={"2vh"}>
                        <Editbutton onclick={settickcompanGstin} />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box marginTop={"3vh"}>
                    <GetDetailsButton
                      title="Get Details"
                      onclick={() => {
                        if (
                          getValues("category") === "Other" &&
                          (getValues("subCategory") === "" ||
                            getValues("subCategory") === undefined)
                        ) {
                          toast.error("Please Enter Subcategory");
                        } else {
                          const Gstdetails = getValues().gstNumber;
                          if (
                            getValues("category") === "Others" &&
                            (getValues("subCategory") === "" ||
                              getValues("subCategory") === undefined ||
                              getValues("subCategory") === null)
                          ) {
                            toast.error("Please enter Subcategory");
                          } else {
                            getpandetails({
                              id_number: Gstdetails,
                              entityType: "PARTNERSHIP",
                            });
                          }
                        }
                      }}
                    ></GetDetailsButton>
                  </Box>
                )}
              </>
            )}
          </Box>

          {NoGst ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box display={"flex"}>
                  <Controller
                    name="panNumber"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        isdisabled={tickcompanyGstin || !NoGst || panVerified}
                        field={field}
                        iD={"panName"}
                        name={"Entity PAN Number"}
                        errors={errors}
                      />
                    )}
                  />
                </Box>

                {verifyPanloader ? (
                  <>
                    <CircularProgress
                      sx={{
                        marginLeft: "25px",
                        marginTop: "15px",
                      }}
                      size={"2rem"}
                      color="primary"
                    />
                  </>
                ) : (
                  <>
                    {tickcompanyGstin ||
                    panVerified ||
                    CompanyDetails?.data?.status?.company ? (
                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          marginTop={"2vh"}
                          sx={{
                            display: " flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            height={"25px"}
                            src={Icons.greenCheck}
                            alt="loading...."
                          />
                        </Box>

                        {!CompanyDetails?.data?.status?.company && (
                          <Box marginTop={"2vh"}>
                            <Editbutton
                              onclick={() => {
                                setPanVerified(false);
                                setValue("companyName", "");
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box marginTop={"2vh"}>
                        <GetDetailsButton
                          title="Get Details"
                          onclick={() => {
                            const pannum = getValues().panNumber;
                            verifyPan({
                              id_number: pannum,
                              entityType: "PARTNERSHIP",
                            });
                          }}
                        ></GetDetailsButton>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                {tickcompanyGstin ||
                NoGst ||
                CompanyDetails?.data?.status?.company ? (
                  <Box display={"flex"}>
                    <Controller
                      name="panNumber"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          isdisabled={tickcompanyGstin || !NoGst}
                          field={field}
                          iD={"panName"}
                          name={"Entity PAN Number"}
                          errors={errors}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <></>
                )}

                {(tickcompanyGstin ||
                  CompanyDetails?.data?.status?.company) && (
                  <>
                    <Box
                      marginTop={"2vh"}
                      sx={{
                        marginLeft: 2,
                        display: " flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        height={"25px"}
                        src={Icons.greenCheck}
                        alt="loading...."
                      />
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}

          <Box sx={{ display: "flex" }}>
            {tickcompanyGstin ||
            NoGst ||
            CompanyDetails?.data?.status?.company ? (
              <Box>
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      isdisabled={tickcompanyGstin || !NoGst || panVerified}
                      field={field}
                      iD={"firmName"}
                      name={"Registered Entity Name"}
                      errors={errors}
                    />
                  )}
                />
              </Box>
            ) : (
              <></>
            )}

            {(tickcompanyGstin || CompanyDetails?.data?.status?.company) && (
              <>
                <Box
                  marginTop={"2vh"}
                  sx={{
                    marginLeft: 2,
                    display: " flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    height={"25px"}
                    src={Icons.greenCheck}
                    alt="loading...."
                  />
                </Box>
              </>
            )}
          </Box>

          {false && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
                rowGap: 0,
              }}
            >
              <Controller
                name="contactAddress"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={Gstdissable || disabled}
                    field={field}
                    iD={"contactAddress"}
                    name={"Contact Address"}
                    errors={errors}
                  />
                )}
              />
              <Controller
                name="iecCode"
                control={control}
                render={({ field }) => (
                  <InputField
                    isdisabled={disabled}
                    field={field}
                    iD={"iecCode"}
                    name={"IEC Code"}
                    errors={errors}
                  />
                )}
              />
            </Box>
          )}
        </form>
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginTop: "2.4vh",
          marginX: 0,
          textAlign: "end",
          alignContent: "center",
          borderTop: "1px solid #cacaca",
        }}
      >
        <CustomLoadingButton
          submitLoader={submitloader}
          onClick={() => Submitdata()}
          isDisabled={
            CompanyDetails?.data?.status?.company ||
            !(tickcompanyGstin || panVerified)
          }
          title="Next"
        />
      </Box>
    </>
  );
};

export default CompanyInformationForm;
