import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  width: "100%",
  border: "1px solid black",
  "& .MuiCircularProgress-root": {
    color: "#7643EB",
  },
  "&.MuiButton-outlined": {
    border: "2px solid #7643EB",
  },
  // [theme.breakpoints.up("md")]: {
  //   width: "27vw",
  // },
}));

export const ContainedButton = ({ Title, submitLoader }: any) => {
  return submitLoader ? (
    <StyledLoadingButton
      loading
      loadingPosition="center"
      startIcon={<SaveIcon />}
      variant="outlined"
    />
  ) : (
    <Button
      type="submit"
      variant="contained"
      sx={{
        width: "100%",
      }}
    >
      {Title}
    </Button>
  );
};
