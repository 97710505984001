import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getStatusbyId, userActivity } from "../../../api/services";
import { Icons } from "../../../assets";
import DesktopModal from "./DesktopModal";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28vw",
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "13px",
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    width: "40vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const RoleBox = styled(Box)<{ selected: boolean }>(({ theme, selected }) => ({
  width: "11vw",
  height: "14vh",
  background: selected ? "#F1EBFF" : "#FCFCFC",
  boxShadow: "0px 3px 6px #00000014",
  border: selected ? "1px solid #7643EB" : "1px solid #33333333",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100px",
  },
}));

const SelectRole = ({ open, handleClose, data }: any) => {
  const EntityId = Cookies.get("entityId");
  const navigate = useNavigate();
  const [onSelect, setOnSelect] = useState(true);
  const [nav, setNav] = useState("/kycprocess/private-public");
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleMobileOpen = () => setMobileOpen(true);
  const handleMobileClose = () => setMobileOpen(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const { data: statusDetails } = useQuery(
    ["status", EntityId],
    () => getStatusbyId(EntityId),
    { enabled: !!EntityId }
  );

  const { mutate: userActivityMutate } = useMutation(userActivity, {
    onSuccess: () => {
      // Activity success handler
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleRoleSelect = (role: "business" | "individual") => {
    setOnSelect(role === "business");
    setNav(
      role === "business"
        ? "/kycprocess/private-public"
        : "/kycprocess/individual"
    );
  };

  const handleModelClose = () => {
    const accessToken: any = Cookies.get("access_token");
    const userData: any = jwtDecode(accessToken);
    userActivityMutate({
      userId: userData?.userId,
      activity: "Started KYB/KYC Process",
    });
    if (nav === "/kycprocess/private-public" && (isMobile || isTablet)) {
      handleMobileOpen();
    } else {
      navigate(nav, { state: { data: statusDetails?.data } });
    }
    // navigate(nav, { state: { data: statusDetails?.data } });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <Typography variant="subtitle2">
          Select Category <span style={{ color: "red" }}>*</span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            my: "20px",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 0 },
          }}
        >
          <RoleBox
            selected={onSelect}
            onClick={() => handleRoleSelect("business")}
          >
            <img
              height={"48px"}
              width={"48px"}
              src={onSelect ? Icons.businessHi : Icons.business}
              alt="Business"
            />
            <Typography
              variant="subtitle2"
              color={onSelect ? "primary" : "textPrimary"}
              marginTop={"10px"}
            >
              Business
            </Typography>
          </RoleBox>
          <RoleBox
            selected={!onSelect}
            onClick={() => handleRoleSelect("individual")}
          >
            <img
              height={"48px"}
              width={"48px"}
              src={!onSelect ? Icons.individualHi : Icons.individual}
              alt="Individual"
            />
            <Typography
              variant="subtitle2"
              color={!onSelect ? "primary" : "textPrimary"}
              marginTop={"10px"}
            >
              Individual
            </Typography>
          </RoleBox>
        </Box>
        <Button
          onClick={handleModelClose}
          sx={{
            width: "6vw",
            padding: "0.4vh",
            mx: "1vw",
          }}
          variant="contained"
        >
          <Typography variant="body1" sx={{ fontWeight: 500, color: "white" }}>
            Continue
          </Typography>
        </Button>
        <DesktopModal open={mobileOpen} onClose={handleMobileClose} />
      </ModalContainer>
    </Modal>
  );
};

export default SelectRole;
