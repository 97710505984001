import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import GoogleButton from "../buttons/GoogleButton";

type handleGoogleLoginProps = {
  LoginWithGmail: (data: any) => void;
  isLogin: boolean;
  tokenResponse: any;
};
// Helper function for Google login
const handleGoogleLogin = async ({
  tokenResponse,
  isLogin,
  LoginWithGmail,
}: handleGoogleLoginProps) => {
  try {
    const res = await axios.get(
      "https://www.googleapis.com/oauth2/v2/userinfo",
      {
        headers: {
          Authorization: `Bearer ${tokenResponse?.access_token}`,
        },
      }
    );

    const Email = res?.data?.email;
    const data = { token: tokenResponse?.access_token };

    localStorage.setItem("signupEmail", Email);
    Cookies.set("newEmail", Email);

    if (!isLogin) {
      localStorage.setItem("google_access_token", tokenResponse?.access_token);
    }

    LoginWithGmail(data);
  } catch (error) {
    console.error("Google login error:", error);
  }
};

// Auth Header Component
const AuthHeader = ({ title, isMobile }: any) => (
  <Typography
    variant={isMobile ? "h4" : "h1"}
    sx={{ textAlign: "center", fontWeight: "500" }}
    noWrap
  >
    {title}
  </Typography>
);

// Auth Footer Component
const AuthFooter = ({ isLogin }: any) => (
  <Box sx={{ marginTop: "3vh", display: "flex", justifyContent: "center" }}>
    <Typography variant="subtitle2" marginRight={"10px"}>
      {isLogin ? "New User?" : "Have an account?"}
    </Typography>
    <Link to={isLogin ? "/signup" : "/login"}>
      <Typography variant="subtitle2" color={"primary"}>
        {isLogin ? "Get Started" : "Log in"}
      </Typography>
    </Link>
  </Box>
);

type AuthFormWrapperProps = {
  title: string;
  isLogin?: boolean;
  children: JSX.Element;
  isRegister?: boolean;
  LoginWithGmail?: (data: any) => void;
};

// Main Component
export default function AuthFormWrapper({
  title,
  isLogin = false,
  children,
  isRegister = false,
  LoginWithGmail = () => {},
}: AuthFormWrapperProps) {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      handleGoogleLogin({ tokenResponse, isLogin, LoginWithGmail }),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      padding={isMobile ? "0vw" : "3vw"}
      width={isMobile ? "100%" : isTablet ? "80%" : "40%"}
      // height={isMobile ? "auto" : "60vh"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2vh",
        marginTop: "0px",
      }}
    >
      <AuthHeader title={title} isMobile={isMobile} />

      {isRegister ? (
        <>{children}</>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", color: "grey" }}
          >
            {/* Optional subtitle */}
          </Typography>

          {isMobile ? (
            <Box marginTop={"2vh"} width={"80%"}>
              {children}
              <AuthFooter isLogin={isLogin} />

              <Divider sx={{ marginTop: "20px", width: "100%" }}>
                <Typography sx={{ mx: "20px", fontSize: "15px", mb: 1 }}>
                  {"OR"}
                </Typography>
              </Divider>

              <Box sx={{ marginTop: "25px" }}>
                <GoogleButton
                  typeText={
                    isLogin ? "Login with Google" : "Signup with Google"
                  }
                  actionOnClick={() => login()}
                  width="100%"
                />
              </Box>
            </Box>
          ) : isTablet || isDesktop ? (
            <Box marginTop={"2vh"} width={"100%"}>
              {children}
              <AuthFooter isLogin={isLogin} />

              <Divider sx={{ marginTop: "20px", width: "100%" }}>
                <Typography sx={{ mx: "20px", fontSize: "15px", mb: 1 }}>
                  {"OR"}
                </Typography>
              </Divider>

              <Box sx={{ marginTop: "25px" }}>
                <GoogleButton
                  typeText={
                    isLogin ? "Login with Google" : "Signup with Google"
                  }
                  actionOnClick={() => login()}
                  width="100%"
                />
              </Box>
            </Box>
          ) : (
            <Box marginTop={"2vh"}>
              {children}
              <AuthFooter isLogin={isLogin} />

              <Divider sx={{ marginTop: "20px", width: "27vw" }}>
                <Typography sx={{ mx: "20px", fontSize: "15px", mb: 1 }}>
                  {"OR"}
                </Typography>
              </Divider>

              <Box sx={{ marginTop: "25px" }}>
                <GoogleButton
                  typeText={
                    isLogin ? "Login with Google" : "Signup with Google"
                  }
                  actionOnClick={() => login()}
                  width="27vw"
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
