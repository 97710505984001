import { Box, Typography } from "@mui/material";
import { useState } from "react";

import { goods, goodsHi, services, servicesHi } from "../../../../assets";

type DealTypeProps = {
  setDealType: any;
};

export default function DealType({ setDealType }: DealTypeProps) {
  const [onselect, setOnSelect] = useState(true);
  return (
    <>
      <Box sx={{ marginTop: "1vh" }}>
        <Typography variant="caption" color={"#00000099"}>
          Select Deal Type <span style={{ color: "red" }}>*</span>
        </Typography>
        <Box
          sx={{
            width: {
              xs: "250px",
              sm: "520px",
              lg: "520px",
              xl: "520px",
            },
            display: "flex",
            justifyContent: "start",
            // my: "10px",
            // border: "2px solid red",
          }}
        >
          {onselect ? (
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                width: "130px",
                height: "80px",
                background: "#F1EBFF",
                boxShadow: "0px 3px 6px #00000014",
                border: "1px solid #7643EB",
                borderRadius: "8px",
                paddingTop: "5px",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img height={"50%"} src={goodsHi} alt="loading..." />
              <Typography variant="body1" color={"primary"} marginTop={"10px"}>
                Goods
              </Typography>
            </Box>
          ) : (
            <Box
              onClick={() => {
                setOnSelect(true);
                setDealType("Goods");
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                width: "130px",
                height: "80px",
                background: "#FCFCFC",
                backgroundPosition: "0% 0%",
                backgroundRepeat: "no-repeat",
                border: "1px solid #33333333",
                borderRadius: "8px",
                paddingTop: "5px",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img height={"50%"} src={goods} alt="loading..." />
              <Typography variant="body1" marginTop={"10px"}>
                Goods
              </Typography>
            </Box>
          )}

          {onselect ? (
            <>
              {" "}
              <Box
                onClick={() => {
                  setOnSelect(false);
                  setDealType("Services");
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  marginLeft: "20px",
                  width: "130px",
                  height: "80px",
                  background: "#FCFCFC",
                  backgroundPosition: "0% 0%",
                  backgroundRepeat: "no-repeat",
                  border: "1px solid #33333333",
                  borderRadius: "8px",
                  paddingTop: "5px",
                  opacity: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img height={"50%"} src={services} alt="loading..." />
                <Typography variant="body1" marginTop={"10px"}>
                  Services
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  marginLeft: "20px",
                  width: "130px",
                  paddingTop: "5px",
                  height: "80px",
                  background: "#F1EBFF",
                  boxShadow: "0px 3px 6px #00000014",
                  border: "1px solid #7643EB",
                  borderRadius: "8px",
                  opacity: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img height={"50%"} src={servicesHi} alt="loading..." />
                <Typography
                  variant="body1"
                  color={"primary"}
                  marginTop={"10px"}
                >
                  Services
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
